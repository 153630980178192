import React from 'react';
import PropTypes from 'prop-types'
import { spacingMap } from '../helpers'; /* replace to 'import { spacingMap } from './helpers.js';' */

export default function Email(props) {
    
    const { 
        pageBackground='base-100', 
        emailBackground='base-0',
        corners='sm',
        paddingX=null,
        paddingY=null,
        alignItems='start', 
        gap=null, 
        marginTop=null, 
        hasOutline=false,
        width='560',
        children, attributes, listeners } = props
    const { junoAttributes, showTag, outlineStyle } = props.junoProps || {} /* remove line */
    
    const gapStyles = gap ? `gap-${spacingMap[gap]}` : '';
    const paddingStyles = `${paddingX ? ` px-${spacingMap[paddingX]}` : ''}${paddingY ? ` py-${spacingMap[paddingY]}` : ''}`;

    const fontColor = emailBackground.startsWith('base') ? 'text-base-content' : `text-${emailBackground}-content`
    const cornerStyles = corners != 'none' && `rounded-${corners}`
    let borderStyles = hasOutline ? 'ring-[0.5px] ring-base-300' : '';

    let wrapperClasses = `flex flex-col flex-grow w-full h-auto bg-${pageBackground} ${fontColor}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */
    
    let innerClasses = `flex flex-col w-full h-auto mx-auto ${gapStyles} ${borderStyles} bg-${emailBackground} ${cornerStyles} ${paddingStyles} items-${alignItems}`
    innerClasses += ` ${outlineStyle}` /* remove line */

    return (
        <div className={wrapperClasses} style={{paddingTop: marginTop}}>
            <div 
                className={innerClasses} style={{maxWidth: width != 'stretch' ? `${width}px` : '100%'}} 
                {...attributes} {...listeners} 
                {...junoAttributes} /* remove line */
                >
                {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Email</div> /* remove line */}
                {children}
            </div>
        </div>
    );
}



Email.propTypes = {
    width: PropTypes.oneOf(['stretch', '560', '640', '780', '960']),
    pageBackground: PropTypes.oneOf(['base-0', 'base-50', 'base-100', 'base-200', 'base-300', 'primary', 'secondary', 'accent']),
    emailBackground: PropTypes.oneOf(['none', 'base-50', 'base-0', 'base-100', 'base-200', 'base-300', 'primary', 'secondary', 'accent']),
    hasOutline: PropTypes.bool,
    corners: PropTypes.oneOf(['none', 'sm', 'md', 'lg']),
    
    marginTop: PropTypes.oneOf(["24px", "32px", "48px", "64px"]),
    paddingX: PropTypes.oneOf([ "4px", "6px", "8px", "10px", "12px", "16px", "24px",]),
    paddingY: PropTypes.oneOf(["4px", "6px", "8px", "10px", "12px", "16px", "24px",]),
    gap: PropTypes.oneOf(["4px", "6px", "8px", "10px", "12px", "16px", "24px", ]),

    alignItems: PropTypes.oneOf(['start', 'center', 'end', 'stretch']),
    
    
    
};

/* ignore rest */

Email.definitions = {
    apiName: 'Email',
    displayName: 'Email Template',
    description: 'A flexible email template component designed for building responsive email layouts. It offers customizable options for page and email backgrounds, corner styles, and padding.',
    ai_instructions: 'email template with flex-col layout.',
    type: 'layout',
    relativeSize: 'large',
    acceptedChildren: 'everything except table components, layouts, sections, dialogs',
    propDefinitions: {
        width: {
            type: 'oneOf',
            options: ['stretch', '560', '640', '780', '960',],
            displayName: 'Width',
            default: '560', 
            tile: '1/2'
        },
        
        corners: {
            type: 'corners',
            options: ['none', 'sm', 'md', 'lg'],
            displayName: 'Corners',
            default: 'sm', 
            tile: '1/2'
        },

        emailBackground: {
            type: 'colors',
            options: ['none', 'base-0', 'base-50', 'base-100', 'base-200', 'base-300', 'primary', 'secondary', 'accent'],
            displayName: 'Email Background',
            default: 'base-0'
        },

        pageBackground: {
            type: 'colors',
            options: ['base-0', 'base-50', 'base-100', 'base-200', 'base-300', 'primary', 'secondary', 'accent'],
            displayName: 'Page Background',
            default: 'base-100'
        },
        paddingX: {
            type: "spacing",
            options: ["4px", "6px", "8px", "10px", "12px", "16px", "24px",],
            displayName: "Padding X",
            default: null,
            tile: '1/2'
        },
        paddingY: {
            type: "spacing",
            options: ["4px", "6px", "8px", "10px", "12px", "16px", "24px",],
            displayName: "Padding Y",
            default: null,
            tile: '1/2'
        },
        marginTop: {
            type: 'spacing',
            displayName: 'Top Margin',
            options: ["24px", "32px", "48px", "64px"],
            default: null,
            tile: '1/2'
        },
        alignItems: {
            type: "alignItems",
            options: ["start", "center", "end", "stretch"],
            default: "start", 
            tile: '1/2'
        },
        gap: {
            type: "gap",
            options: ["4px", "6px", "8px", "10px", "12px", "16px", "24px", ],
            displayName: "Gap",
            default: null,
            tile: '1/2'
        },
        hasOutline: {
            type: "bool",
            displayName: "Add outline",
            ai_instructions: 'adds base-300 1px outline',
            default: false, 
            tile: '1/2'
        },
    }
};
