
import { nanoid } from 'nanoid';
import { getChildren } from './helpers';
import { parse } from '@babel/parser';
import * as UIKit from '../ui-kit/local';

const idCount = 10
// JSX

export function convertObjectsToJSXwithId(selector) {
  const frameObjects = selector.frame.objects || [];
  const selectedObject = selector.object ? selector.object : frameObjects.length > 0 ? frameObjects.find(obj => obj.parent == obj.frame) : null
  
  function generateProps(objectProps, mobileProps, objectId) {
    
    let propsArray = [`id="${objectId}"`];

    // Adding the rest of the properties
    propsArray = propsArray.concat(Object.entries(objectProps).map(([key, value]) => {
      if (typeof value === "string") {
        return `${key}="${value}"`;
      } else if (typeof value === "boolean") {
        return value ? key : '';
      } else if (typeof value === "number") {
        return `${key}={${value}}`;
      } else {
        // Handle more complex types as needed, for simplicity we'll stringify them
        return `${key}={${JSON.stringify(value)}}`;
      }
    }).filter(Boolean));

    // Add mobile props with "mob_" prefix
    if (mobileProps) {
      const mobilePropsArray = Object.entries(mobileProps).map(([key, value]) => {
        if (typeof value === "string") {
          return `mob_${key}="${value}"`;
        } else if (typeof value === "boolean") {
          return value ? `mob_${key}` : '';
        } else if (typeof value === "number") {
          return `mob_${key}={${value}}`;
        } else {
          // Handle more complex types as needed, for simplicity we'll stringify them
          return `mob_${key}={${JSON.stringify(value)}}`;
        }
      });
      propsArray = propsArray.concat(mobilePropsArray);
    }

    return propsArray.join(' ');
  }

  function generateNode(object, indentation = 0) {
    const tag = object.componentAPIName;
    // Include mobile_props with "mob_" prefix in the propsString
    const propsString = generateProps(object.object_props || {}, object.mobile_props, object.id);
    const indent = '  '.repeat(indentation);
    
    let childObjects = getChildren(object.id, frameObjects).sort((a, b) => a.index - b.index);
    
    if (childObjects.length === 0) {
      // If there are no children, use self-closing format
      return `${indent}<${tag} ${propsString} />\n`;
    } else {
      let nodeHTML = `${indent}<${tag} ${propsString}>\n`;

      for (let child of childObjects) {
        nodeHTML += generateNode(child, indentation + 1);
      }

      nodeHTML += `${indent}</${tag}>\n`;
      return nodeHTML;
    }
  }
  if (!selectedObject) {return ''}
  else 
  return generateNode(selectedObject);
}

export function convertObjectsToJSX(selector) {
  const frameObjects = selector.frame.objects || [];
  
  const selectedObject = selector.object ? selector.object : frameObjects.length > 0 ? frameObjects.find(obj => obj.parent == obj.frame) : null
  
  
  function generateProps(objectProps, mobileProps) {
    let propsArray = Object.entries(objectProps).map(([key, value]) => {
      // Same logic as before for object_props
      if (typeof value === "string") {
        return `${key}="${value}"`;
      } else if (typeof value === "boolean") {
        return value ? key : '';
      } else if (typeof value === "number") {
        return `${key}={${value}}`;
      } else if (typeof value === "object") {
        const jsonString = JSON.stringify(value);
        const escapedString = jsonString
            .replace(/\\/g, '\\\\') // Escape backslashes first
            .replace(/"/g, '\\"'); // Escape double quotes
        return `${key}={${escapedString}}`;
      } else {
        return `${key}={${JSON.stringify(value)}}`;
      }
    }).filter(Boolean);

    

    // Add mobile props with "mob_" prefix
    if (mobileProps) {
      const mobilePropsArray = Object.entries(mobileProps).map(([key, value]) => {
        if (typeof value === "string") {
          return `mob_${key}="${value}"`;
        } else if (typeof value === "boolean") {
          return value ? `mob_${key}` : '';
        } else if (typeof value === "number") {
          return `mob_${key}={${value}}`;
        } else {
          // Handle more complex types as needed, for simplicity we'll stringify them
          return `mob_${key}={${JSON.stringify(value)}}`;
        }
      });
      propsArray = propsArray.concat(mobilePropsArray);
    }

    return propsArray.join(' ');
  }

  function generateNode(object, indentation = 0) {
    const tag = object.componentAPIName;
    // Include mobile_props with "mob_" prefix in the propsString
    const propsString = generateProps(object.object_props || {}, object.mobile_props);
    const indent = '  '.repeat(indentation);
    
    let childObjects = getChildren(object.id, frameObjects).sort((a, b) => a.index - b.index);
    
    if (childObjects.length === 0) {
      // If there are no children, use self-closing format
      return `${indent}<${tag} ${propsString} />\n`;
    } else {
      let nodeHTML = `${indent}<${tag} ${propsString}>\n`;

      for (let child of childObjects) {
        nodeHTML += generateNode(child, indentation + 1);
      }

      nodeHTML += `${indent}</${tag}>\n`;
      return nodeHTML;
    }
  }
  if (!selectedObject) {return ''}
  else 
  return generateNode(selectedObject);
}


export function getPropTypes(selector) {
  const propTypesCollection = [];

  const uniqueComponents = new Set();

  function gatherComponentNames(object, frameObjects = []) {
      if (!object) return;

      if (object.componentAPIName) {
          uniqueComponents.add(object.componentAPIName);
      }

      // Using the frameObjects from the parent object (if provided) 
      // to avoid the need to keep checking it.
      const childObjects = getChildren(object.id, frameObjects).sort((a, b) => a.index - b.index);
      
      childObjects.forEach(child => {
          // For each child, use the same frameObjects as the parent 
          // (assuming children are in the same frame).
          gatherComponentNames(child, frameObjects);
      });
  }

  gatherComponentNames(selector.object, selector.frame?.objects);

  uniqueComponents.forEach(componentName => {
      if (UIKit[componentName] && UIKit[componentName].propDefinitions) {
          const describedPropTypes = {};
          Object.entries(UIKit[componentName].propDefinitions).forEach(([key, propDef]) => {
              switch (propDef.type) {
                  case 'bool':
                      describedPropTypes[key] = 'bool';
                      break;
                  case 'oneOf':
                      describedPropTypes[key] = `oneOf [${propDef.options.join(', ')}]`;
                      break;
                  case 'ionicIcons': // This is the case you want to add
                      describedPropTypes[key] = 'ionic icon';
                      break;
                  default:
                      describedPropTypes[key] = propDef.type;
              }
          });
          
          propTypesCollection.push({
              componentName: componentName,
              propTypes: describedPropTypes
          });
      }
  });

  return propTypesCollection;
}

export async function convertJSXToObjects(input) {
  const ast = parse(input, {
    sourceType: 'module',
    plugins: ['jsx'],
  });

  const objects = [];

  function processNode(node, parentId, index) {
    if (node.type === 'JSXElement') {
      const componentName = node.openingElement.name.name;

      const id = nanoid(idCount)
      const object = {
        id,
        APIName: 'div',
        componentAPIName: componentName,
        parent: parentId,
        index,
        text: "",
        object_props: {},
        mobile_props: {}, // Initialize mobile_props
      };

      node.openingElement.attributes.forEach(attr => {
        if (attr.type === 'JSXAttribute') {
          let attributeName = attr.name.name;
          const isMobileProp = attributeName.startsWith('mob_');

          if (isMobileProp) {
            // Remove 'mob_' prefix for mobile_props
            attributeName = attributeName.substring(4);
          }

          let attributeValue;
          if (attr.value === null) {
            attributeValue = true;
          } else if (attr.value.type === 'JSXExpressionContainer') {
            attributeValue = attr.value.expression.value;
          } else if (attr.value.type === 'StringLiteral') {
            attributeValue = attr.value.value;
          }

          if (isMobileProp) {
            object.mobile_props[attributeName] = attributeValue;
          } else {
            object.object_props[attributeName] = attributeValue;
          }
        }
      });

      objects.push(object);
      
      let childIndex = 0;
      node.children.forEach((childNode) => {
        if (childNode.type === 'JSXElement') {
          processNode(childNode, id, ++childIndex);
        }
      });
    }
  }

  ast.program.body.forEach(bodyNode => {
    if (bodyNode.type === 'ExpressionStatement' && bodyNode.expression.type === 'JSXElement') {
      processNode(bodyNode.expression, 'rootObject', 1);
    }
  });

  
  return objects;
}

export function convertJSXToObjectsSync(input, useIds=false) {
    
  function parseExpression(expression) {
    switch (expression.type) {
        case 'Literal':
        case 'StringLiteral':
        case 'NumericLiteral':  // Explicit handling for numeric literals
        case 'BooleanLiteral':  // Explicit handling for boolean literals
            return expression.value;  // This will handle numbers, strings, booleans, nulls
        case 'ArrayExpression':
            return parseArrayExpression(expression);
        case 'ObjectExpression':
            return parseObjectExpression(expression);
        case 'Identifier':
            // This returns the identifier's name as a string.
            return expression.name;
        case 'MemberExpression':
            // Handle member expressions which might involve more complex property accesses.
            if (expression.computed) {
                // Computed expressions like obj[prop]
                return `${parseExpression(expression.object)}[${parseExpression(expression.property)}]`;
            } else {
                // Non-computed expressions like obj.prop
                return `${parseExpression(expression.object)}.${parseExpression(expression.property)}`;
            }
        default:
            // console.warn('Unhandled expression type:', expression.type);
            return null; // Placeholder for unhandled types
          }
      }


  function parseArrayExpression(arrayExpression) {
      return arrayExpression.elements.map(element => parseExpression(element));
  }

  function parseObjectExpression(objectExpression) {
      const obj = {};
      objectExpression.properties.forEach(prop => {
          const key = prop.key.name || prop.key.value; // Supports both Identifier and Literal for keys
          const value = parseExpression(prop.value); // Use parseExpression to handle any type of property value
          obj[key] = value;
      });
      return obj;
  }


  const ast = parse(input, {
    sourceType: 'module',
    plugins: ['jsx'],
  });

  const objects = [];
  
  function processNode(node, parentId, index, useIds) {
    
    if (node.type === 'JSXElement') {
      const componentName = node.openingElement.name.name;
      
      let id;
      const idAttribute = node.openingElement.attributes.find(attr => attr.name.name === 'id');
      if (idAttribute && idAttribute.value && useIds) {
        id = idAttribute.value.value;
      } else {
        id = nanoid(idCount);
      }
      
      const object = {
        id,
        APIName: 'div',
        componentAPIName: componentName,
        parent: parentId,
        index,
        text: "",
        object_props: {},
        mobile_props: {}, // Initialize mobile_props
      };
    
      node.openingElement.attributes.forEach(attr => {
        if (attr.type === 'JSXAttribute') {
            let attributeName = attr.name.name;
            const isMobileProp = attributeName.startsWith('mob_');
            
            if (attributeName === 'id') {
                return; // Skip id attributes as per your current logic
            }
            
            if (isMobileProp) {
                attributeName = attributeName.substring(4); // Remove 'mob_' prefix
            }
    
            let attributeValue;
            if (attr.value === null) {
                attributeValue = true; // Attribute is a boolean flag
            } else if (attr.value.type === 'JSXExpressionContainer') {
                attributeValue = parseExpression(attr.value.expression); // Use parseExpression for all expression types
            } else if (attr.value.type === 'StringLiteral') {
                attributeValue = attr.value.value;
            }
    
            if (isMobileProp) {
                object.mobile_props[attributeName] = attributeValue;
            } else {
                object.object_props[attributeName] = attributeValue;
            }
        }
    });
      // dataComponents.includes('TableWidget') && console.log(object.object_props)
      objects.push(object);
      
      let childIndex = 0;
      node.children.forEach((childNode) => {
        if (childNode.type === 'JSXElement') {
          processNode(childNode, id, ++childIndex);
        }
      });
    }
  }
  
  ast.program.body.forEach(bodyNode => {
    if (bodyNode.type === 'ExpressionStatement' && bodyNode.expression.type === 'JSXElement') {
      processNode(bodyNode.expression, 'rootObject', 1, useIds);
    }
  });

  
  return objects;
}





// MAY NOT NEED THESE
export function createCSS(objects, styles) {
  // accumulate all style ids
  let styleIds = [];
  objects.forEach(object => {
    if (object.styles) {
      styleIds = [...styleIds, ...object.styles];
    }
  });

  // remove duplicates
  styleIds = [...new Set(styleIds)];

  // pull corresponding name and cssJSON from styles
  let css = '';
  styleIds.forEach(id => {
    const style = styles.find(s => s.id === id);
    if (style) {
      let cssBlock = `${style.APIName} {\n`;
      for (const [property, value] of Object.entries(style.cssJSON)) {
        // convert property to hyphenated form, e.g., backgroundColor to background-color
        const hyphenatedProperty = property.replace(/([A-Z])/g, '-$1').toLowerCase();
        cssBlock += `\t${hyphenatedProperty}: ${value};\n`;
      }
      cssBlock += '}\n\n';
      css += cssBlock;
    }
  });

  return css.trim();
}




