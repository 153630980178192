import { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { getDescendants, findFrameIndex, canAcceptChildren, createNewIds } from '../../utilities/helpers';
import { EditorContext } from './EditorContext';
import { v1 as uuidv1 } from 'uuid';

const KeyboardShortcutsContext = createContext({
  shortcuts: {},
  registerShortcut: () => {},
  lastShortcut: null,
});
 
export default KeyboardShortcutsContext;

export function KeyboardShortcutsProvider({...props}) {
  
  const {handleAction, project, selector, setSelector, view, setView} = useContext(EditorContext)
  const [shortcuts, setShortcuts] = useState({});
  const [lastShortcut, setLastShortcut] = useState(null);

  const handleKeyDown = useCallback(
    (event) => {
      const keyCombo = getKeyName(event);
      //  on SPACE key, switch to eye mode
      /*if (keyCombo === 'Space') {
        // console.log('pressed space key, hand mode')
        if (view.mode != 'eye') {
          //console.log('changed to hand mode')
          setView({... view, mode: 'eye' })}
        return; 
      }*/


      if (shortcuts[keyCombo]) {
        const target = event.target;
        const isInputElement = target.tagName === 'INPUT' || target.tagName === 'TEXTAREA' || target.contentEditable === 'true'  || target.id === 'code-block' || target.classList.contains('message-bubble');
        
        if ((isInputElement && (keyCombo === 'Ctrl+C' || keyCombo === 'Ctrl+V')) ) {
          // Allow default behavior for text inputs when using Ctrl+C or Ctrl+V, and if the target is within JunoChat component
          return;
        }

        if ((isInputElement && (keyCombo === 'Cmd+C' || keyCombo === 'Cmd+V')) ) {
          // Allow default behavior for text inputs when using Ctrl+C or Ctrl+V, and if the target is within JunoChat component
          return;
        }
  
        // Allow default backspace behavior for input elements
        if (isInputElement && keyCombo === 'Backspace') {
          return;
        }

        // Allow default backspace behavior for input elements
        if (isInputElement && keyCombo === 'Space') {
          return;
        }
        

      // Prevent arrow up and down for input elements
      if (isInputElement && (keyCombo === 'ArrowUp' || keyCombo === 'ArrowDown')) {
        
        return;
      }

      // Prevent arrow left and right for input elements
      if (isInputElement && (keyCombo === 'ArrowLeft' || keyCombo === 'ArrowRight')) {
        return;
      }
    
      if (selector.object || selector.frame || selector.page) {
        event.preventDefault();
        shortcuts[keyCombo]();
        setLastShortcut(keyCombo);
      }
      }
    },
    [shortcuts, selector.object, selector.frame]
  );
  
  /*const handleKeyUp = useCallback(
    (event) => {
      const keyCombo = getKeyName(event);
      
      if (keyCombo === 'Space') {
        setView({...view, mode: 'editor' });
      }
    },
    [setView]
  );*/


  function getKeyName(event) {
    const keys = [];
    if (event.ctrlKey) keys.push('Ctrl');
    if (event.metaKey) keys.push('Cmd'); // Cmd for MacOS
    if (event.altKey) keys.push('Option'); // Add 'Option' when the Alt key is pressed
    if (event.shiftKey) keys.push('Shift');

    // Handle key codes for copy, cut, paste, undo, and redo
    const key = event.code === 'KeyC' ? 'C'
            : event.code === 'KeyX' ? 'X'
            : event.code === 'KeyV' ? 'V'
            : event.code === 'KeyZ' ? 'Z'
            : event.code === 'KeyY' ? 'Y'
            : event.key === ' ' ? 'Space' // Handle the space key explicitly
            : event.key;
    keys.push(key);

    return keys.join('+');
  }

  

  useEffect(() => {
    function registerShortcut(keyCombo, callback) {
      setShortcuts((prevShortcuts) => ({
        ...prevShortcuts,
        [keyCombo]: callback,
      })); }

    // UNDO & REDO
    registerShortcut('Ctrl+Z', () => handleAction({ type: 'UNDO' }));
    registerShortcut('Cmd+Z', () => handleAction({ type: 'UNDO' })); // For MacOS
    registerShortcut('Ctrl+Y', () => handleAction({ type: 'REDO' }));
    registerShortcut('Cmd+Shift+Z', () => handleAction({ type: 'REDO' })); // For MacOS
    
    

    registerShortcut('Option+ArrowUp', () => {

      if (selector.object) {
        const newIndex = selector.object.index - 1;
    
        if (newIndex !== 0) {      
          
          handleAction({ type: 'RELOCATE_OBJECT', object: selector.object, newIndex: newIndex });
        }
      }
    });
    
    
    registerShortcut('Option+ArrowDown', () => {
      if (selector.object) {
        const newIndex = selector.object.index + 1;
        const frameObjects = selector.frame.objects;
        const siblingsCount = frameObjects.filter(obj => obj.parent == selector.object.parent).length
        
        if (newIndex <= siblingsCount) {     
          
          handleAction({ type: 'RELOCATE_OBJECT', object: selector.object, newIndex: newIndex });
        }
      }
    });
    
    function findLastChild(objectList, parentId) {
      const children = objectList.filter(o => o.parent === parentId);
    
      if (children.length === 0) {
        return parentId;
      } else {
        const lastChild = children.reduce((prev, current) => (prev.index > current.index) ? prev : current);
        return findLastChild(objectList, lastChild.id);
      }
    }

    registerShortcut('ArrowUp', () => {
      
      if (selector.object) {
        const frameObjects = selector.frame.objects;
        const newIndex = selector.object.index - 1;
    
        if (newIndex == 0) {
          const parent = frameObjects.find(o => o.id == selector.object.parent);
          if (parent != undefined) {
            setSelector({
              ...selector,
              object: parent,
              style: null
            });
          }
        } else {
          
          const prevObject = frameObjects.find(o => o.index === newIndex && o.parent == selector.object.parent);
          // Find the last child in the hierarchy that has no children
          if (prevObject) {
            const lastChildId = findLastChild(frameObjects, prevObject.id)
      
            // Select the last child
            const lastChild = frameObjects.find(o => o.id === lastChildId);
      
            setSelector({
              ...selector,
              object: lastChild,
              style: null
            });
          }
        }
      }
    });    
    
    
    function findNextParent(frameObjects, currentParent) {
      if (!currentParent) return null;
    
      // Attempt to find the next sibling of the current parent
      const nextSibling = frameObjects.find(obj => 
        obj.index === currentParent.index + 1 && obj.parent === currentParent.parent
      );
    
      if (nextSibling) {
        // If a next sibling is found, return it
        return nextSibling;
      } else {
        // If no next sibling, move up to the grandparent and try again
        const grandparent = frameObjects.find(o => o.id === currentParent.parent);
        return findNextParent(frameObjects, grandparent);
      }
    }
    
    
    registerShortcut('ArrowDown', () => {
      if (selector.frame) {
        if (selector.object) {
          const currentObject = selector.object
          const frameObjects = selector.frame.objects;
          
          const children = frameObjects.filter(obj => obj.parent == currentObject.id) || []

          const siblings = frameObjects.filter(obj => obj.parent == currentObject.parent)
          const nextSibling = siblings.find(obj => obj.index == currentObject.index + 1)

          if (children.length > 0) {
            
            const firstChild = children.find(obj => obj.index == 1)
            setSelector({
              ...selector,
              object: firstChild,
              style: null
            }); 
          } else if (nextSibling) {
            
            setSelector({
              ...selector,
              object: nextSibling,
              style: null
            }); 
          } else { 
            const parent = frameObjects.find(o => o.id === currentObject.parent);
            const nextParent = findNextParent(frameObjects, parent);
            if (nextParent) {
              setSelector({ ...selector, object: nextParent, style: null });
            }
          }
          
          
        } 
      }
    });

    const selectedFrame = selector?.frame
    const allFolders = project?.folders.sort((a, b) => a.index - b.index);
    const allFlows = project?.pages.filter(p => allFolders.map(f=>f.id).includes(p.folder_id)).sort((a, b) => a.index - b.index);
    const allFrames = allFlows?.flatMap(f => f.frames).filter(frame => !frame.isArchived).sort((a, b) => a.index - b.index);
    
    const framesByOrder = allFolders?.flatMap(folder => 
        allFlows
            .filter(flow => flow.folder_id === folder.id)
            .flatMap(flow => 
                allFrames.filter(frame => frame.page_id === flow.id)
            )
    ) || []
    
    const currentIndex = framesByOrder.findIndex(frame => frame.id === selectedFrame?.id);
    
    let previousFrame = null;
    let nextFrame = null;
    
    if (currentIndex > 0) {
        previousFrame = framesByOrder[currentIndex - 1];
    }
    
    if (currentIndex < framesByOrder.length - 1) {
        nextFrame = framesByOrder[currentIndex + 1];
    }
    registerShortcut('ArrowLeft', () => {
      if (!selector.frame || !selector.page) return
      console.log('hello')
      const siblings = selector.page?.frames.filter(frame => !frame.isArchived).sort((a, b) => a.index - b.index) || []
      const currentIndex = siblings.findIndex(frame => frame.id === selector.frame.id);
      
      if (currentIndex > 0) {
        previousFrame = siblings[currentIndex - 1]; 
        setSelector({
            ...selector,
            frame: previousFrame,
            object: null,
            style: null
          });
        
      }
    });
    
    registerShortcut('ArrowRight', () => {
      if (!selector.frame || !selector.page) return
      const siblings = selector.page?.frames.filter(frame => !frame.isArchived).sort((a, b) => a.index - b.index) || []
      const currentIndex = siblings.findIndex(frame => frame.id === selector.frame.id)
      
      if (currentIndex < siblings.length - 1) {
        
        nextFrame = siblings[currentIndex + 1];
        setSelector({
            ...selector,
            frame: nextFrame,
            object: null,
            style: null
          });
      }
    });
    
        
    registerShortcut('Backspace', () => {
      console.log('Shortcut: Backspace');
      const undeletableObjects = ['AppShell', 'Main', 'Email']
  
      if (!selector.object && selector.frame) {
        
          if (!view.overview && !view.miniNav) return
          handleAction({ type: 'DELETE_FRAME', frame: selector.frame, autoSelect: true });

      } else if (undeletableObjects.includes(selector.object?.componentAPIName)) {
          const isDeletionConfirmed = window.confirm(`Are you sure you want to delete ${selector.object?.componentAPIName}?`);
          if (isDeletionConfirmed) {
              handleAction({ type: 'DELETE_OBJECT', object: selector.object });
          } else {
              setView({...view, notification: {type: 'toast', state: 'error', message: `Deletion cancelled for ${selector.object?.componentAPIName}`}})
          }
      } else {
          handleAction({ type: 'DELETE_OBJECT', object: selector.object });
      }
    });  

    
    const copyToClipboard = () => {
      if (selector.object) {
        setView({...view, clipboard: {type: 'object', item: selector.object, action: 'copy'} });
      } else if (!selector.object && selector.frame) {
        setView({...view, clipboard: {type: 'frame', item: selector.frame, action: 'copy'} });
        
      }
    };
    

    const cutFunction = () => {
      if (selector.object) {
        // console.log('CUT OBJECT', selector.object);
        setView({...view, clipboard: {type: 'object', item: selector.object, action: 'cut'} });
      } else if (!selector.object && selector.frame) {
        setView({...view, clipboard: {type: 'frame', item: selector.frame, action: 'cut'} });
      }
    };
    
    const pasteFunction = () => {
      //console.log('Shortcut: Ctrl+V / Cmd+V');
      const canPaste = selector.object ? canAcceptChildren(selector.object.APIName) : true
      // SOMETHING is in clipboard and we have somewhere to paste it
      if (view.clipboard && canPaste ) {
        if (view.clipboard.action == 'cut') {
          if (view.clipboard.type == 'object') {
            // CUT / PASTE OBJECT
            handleAction({ type: 'RELOCATE_OBJECT', object: view.clipboard.item })
          
          
          } else if (view.clipboard.type == 'frame') {
              // CUT / PASTE FRAME
              
              const currentFrame = view.clipboard.item
              let newFrame = { ...currentFrame}

              const currentSelectFrame = selector.frame
              const currentSelectPage = selector.page

              if (!currentSelectFrame && !currentSelectPage) return

              if (currentSelectFrame) { // if a frame is selected -> insert next to it
                newFrame.index = currentSelectFrame.index + 1
                newFrame.page_id = currentSelectFrame.page_id
              } else if (currentSelectPage) { // if a page is selected -> insert at the end of the page
                const newIndex = currentSelectPage.frames?.length + 1 || 1
                newFrame.index = newIndex
                newFrame.page_id = currentSelectPage.id
              } 
              
              handleAction({ 
                type: 'RELOCATE_FRAME', 
                currentFrame: currentFrame,
                newFrame: newFrame,
              }) 
            
          } 
        } else {
          if (view.clipboard.type == 'object') {
              const type = view.clipboard?.item?.APIName
                let newObject = {...view.clipboard.item}
                const currentFrame = project.pages.flatMap((page) => page.frames).find((frame) => frame.id === view.clipboard.item.frame);
                const descendants = getDescendants(view.clipboard.item.id, currentFrame.objects)
  
                handleAction({ 
                  type: 'INSERT_OBJECT', 
                  object: newObject, 
                  index: selector.object?.parent == newObject.parent ? newObject.index + 1 : null,
                  descendants: descendants,
                  newSelect: selector.object
                })  
              
              
              
        } else if (view.clipboard.type == 'frame') {
            // COPY_PASTE FRAME
            const clipboardFrame = view.clipboard.item
            const newId = uuidv1(); // will use for frame.id and object.frame
            let newObjects = [...clipboardFrame.objects]; // copy existing objects
            newObjects = createNewIds(newObjects, clipboardFrame.id, newId); // create new Ids for these objects
            newObjects = newObjects.map(obj => ({...obj,frame: newId})); // update frame property

            let newFrame = {
                ...clipboardFrame,
                id: newId,
                objects: newObjects
            };
            
            const currentSelectFrame = selector.frame
            const currentSelectPage = selector.page

            if (!currentSelectFrame && !currentSelectPage) return
            if (currentSelectFrame) { // if a frame is selected -> copy next to it
              newFrame.index = currentSelectFrame.index + 1
              newFrame.page_id = currentSelectFrame.page_id
            } else if (currentSelectPage) { // if a page is selected -> copy at the end of the page
              const newIndex = currentSelectPage.frames?.length + 1 || 1
              newFrame.index = newIndex
              newFrame.page_id = currentSelectPage.id
            }
          
            handleAction({type: 'INSERT_FRAME', frame: newFrame });

          }
          
        } 
      } 
    };
    
    // Register copy, cut, and paste shortcuts
    registerShortcut('Ctrl+C', copyToClipboard);
    registerShortcut('Cmd+C', copyToClipboard);
    registerShortcut('Ctrl+X', cutFunction);
    registerShortcut('Cmd+X', cutFunction);
    registerShortcut('Ctrl+V', pasteFunction);
    registerShortcut('Cmd+V', pasteFunction);

  }, [handleAction, selector, view]);

  
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    // document.addEventListener('keyup', handleKeyUp); // Add this line
  
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      // document.removeEventListener('keyup', handleKeyUp); // Add this line
    };
  }, [handleKeyDown])//, handleKeyUp]); // Include handleKeyUp in the dependency array
  

  

  
  return (
    <KeyboardShortcutsContext.Provider value={{ shortcuts, lastShortcut }}>
      {props.children}
    </KeyboardShortcutsContext.Provider>
  );
}
