import TypingDots from "./TypingDots";

export default function Messages({ messages, streaming, clearChat }) {
  // Reverse and filter messages
  const reversedFilteredMessages = [...messages].reverse().filter(
    msg => msg.role !== 'tool' && msg.role !== 'system' && msg.content != null && msg.content !== ''
  )//.slice(0, 10);

  return (
      <div className="flex flex-col-reverse justify-start px-1 bg-white rounded-t-lg gap-2 flex-grow font-medium transition-all ease-in-out duration-200 w-full relative"
           style={{ height: '100%', overflowY: 'auto' }}> 
           
          {(streaming && messages.length > 0) &&
              <div className={`relative text-sm rounded-xl px-3 py-2 mr-auto items-center flex flex-row`}
              style={{animation: 'fadeIn 200ms ease forwards', backgroundColor: '#E2E8F0'}}>
                  <div 
                  className="flex flex-row items-center h-full w-auto"
                  style={{minHeight: '1em'}}><TypingDots /></div>
                  <svg className="absolute bottom-0 -left-[5px]" width="17" height="17" viewBox="0 0 17 17">    
                  <path d="M5 10.5C4.49857 13.5086 1.66667 16.3333 0 17C6.4 17 10.5 14.8333 11.5 13.5L16.5 15L16 0H5.5V2V4V4.5C5.5 5.5 5.5 7.5 5 10.5Z" fill='#E2E8F0'/>
                  </svg>
              </div>
          }
          {reversedFilteredMessages.map((msg, index) => (
              <MessageBubble 
                  key={index}
                  message={msg.content}
                  role={msg.role}
                  index={index}
              />
          ))}
          {/*messages.length > 2 &&
          <div className="absolute top-0 flex justify-end bg-gradient-to-b from-white via-transparent to-transparent w-full">
            <button 
            className="px-2 py-1 bg-slate-100 hover:bg-slate-200 items-center font-medium transition-all rounded-md flex flex-row gap-1 text-xs cursor-pointer"
            onClick={clearChat}
          >
            <Restart height={12} width={12} />
            Clear</button>
          </div>*/}
      </div>
  );
}

function MessageBubble({ message, role, index }) {
  const userMessageColor = '#E04C18';
  const assistantMessageColor = '#E2E8F0';

  const isUser = role === 'user';
  const opacity = Math.round(Math.max((1 - (index * 0.1)) * 100, 0) / 10) * 10
  const formattedMessage = formatMessage(message);
  return (
    <div className={`relative text-sm rounded-xl px-3 py-2 w-auto message-bubble
        ${isUser ? `text-white ml-auto`
        : `mr-auto`}`}
        style={{maxWidth: '75%', backgroundColor: isUser ? userMessageColor : assistantMessageColor,}}
        id='message-bubble'
        >
      {/* Bubble Tail SVG */}
      {isUser ? (
        <svg className="absolute bottom-0 -right-[5px]" width="17" height="17" viewBox="0 0 17 17">  
          <path d="M11.5 10.5C12.0014 13.5086 14.8333 16.3333 16.5 17C10.1 17 6 14.8333 5 13.5L0 15L0.5 0H11V2V4V4.5C11 5.5 11 7.5 11.5 10.5Z" fill={userMessageColor}/>
        </svg>
      ) : (
        <svg className="absolute bottom-0 -left-[5px]" width="17" height="17" viewBox="0 0 17 17">    
        <path d="M5 10.5C4.49857 13.5086 1.66667 16.3333 0 17C6.4 17 10.5 14.8333 11.5 13.5L16.5 15L16 0H5.5V2V4V4.5C5.5 5.5 5.5 7.5 5 10.5Z" fill={assistantMessageColor}/>
        </svg>
      )}
      <span className="break-words select-text message-bubble" dangerouslySetInnerHTML={{ __html: formattedMessage }}>
        
      </span>
    </div>
  );
}

function escapeHtml(text) {
  var element = document.createElement('div');
  if (text) {
    element.innerText = text;
    element.textContent = text;
  }
  return element.innerHTML;
}


function formatMessage(message) {
  // First escape all HTML characters to prevent XSS and misrendering
  message = escapeHtml(message);

  // Format hex color codes
  const hexColorRegex = /#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})/g;
  message = message.replace(hexColorRegex, (match) => {
    return `<span style="display: inline-flex; align-items: center;">
              <span style="background-color: ${match}; width: 1em; height: 1em; display: inline-block; margin-right: 0.5em; border: 0.5px solid #111; border-radius: 4px;"></span>
              ${match}
            </span>`;
  });

  // Format bold text
  const boldRegex = /\*\*(.*?)\*\*/g;
  message = message.replace(boldRegex, '<strong>$1</strong>');

  // Additional formatting can be added here using a similar pattern

  return message;
}