import { useMemo } from "react";
import { rectSortingStrategy, horizontalListSortingStrategy, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { canPaste } from "../../../utilities/pasteRules";


export function kebabToCamel(str) {
  return str.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
}

export function useSortingStrategy(css) {
  return useMemo(() => {
    let direction = "column"; // Default direction
    // console.log(css)
    // Check if the css object exists
    if (css && css.display) {
      // Check for flex display
      if (css.display.includes("flex") && css.flexDirection) {
        direction = css.flexDirection || 'row'
      } 
      // Check for grid display
      else if (css.display.includes("grid")) {
        direction = css.gridAutoFlow?.includes("column") ? "column" : "row";
      } 
      // Check for block display
      else if (css.display === "block") {
        direction = "column";
      } 
      // Check for inline or inline-block display
      else if (css.display === "inline" || css.display === "inline-block") {
        direction = "row";
      }
    }
    // console.log(direction)
    // Determine the sorting strategy based on the direction
    switch (direction) {
      case "column":
      case "column-reverse":
        return verticalListSortingStrategy;
      case "row":
      case "row-reverse":
        return horizontalListSortingStrategy;
      default:
        return rectSortingStrategy;
    }
  }, [css]);
}

export function translateObjectFitToBackground(fit) {
  // Translate CSS object-fit values to equivalent background properties
  switch (fit) {
      case 'contain':
          return {
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
          };
      case 'cover':
          return {
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
          };
      // Add other cases if needed
      default:
          return {
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
          };
  }
}

export function findTableObject(objects, currentId) {
  const obj = objects.find(o => o.id === currentId);
  return obj.componentAPIName === 'Table' ? obj : obj.parent ? findTableObject(objects, obj.parent) : null;
}

export function createPasteObject(name, frameId, parentId, objectProps = {}, index=1) {
  return {
      id: 'new',
      frame: frameId,
      APIName: 'div',
      componentAPIName: name,
      styles: [],
      parent: parentId,
      acceptsChildren: true,
      draggable: true,
      text: '',
      index: 1, // This will be set when you call the function
      type: 'object',
      object_props: {
          ...{}, // Default properties (if any)
          ...objectProps // Merge with provided properties
      }
  };
}


export function getOutlineStyle({self, view, dndState, selector, isHovered, isAppShell, isSelected, streaming}) {
  const isDraggingItem = dndState?.draggingItem?.id === self.id;
  const isFutureParent = dndState?.draggingOverItem?.id === self.id;
  const isFutureSibling = dndState?.draggingOverItem?.id === self.parent;
  const isDraggingOverMe = !isDraggingItem && dndState?.draggingOverItem?.id === self.id;

  const isFocusedSection = selector?.section?.id === self.id;
  
  const dropAllowed = canPaste(self, dndState?.draggingItem);
  let isDraggingOverMetToReplace = (dndState?.draggingDialog && self.componentAPIName === 'AppShell') ||
    (isDraggingOverMe && dndState?.draggingSection && dndState?.draggingItem?.componentAPIName === self.componentAPIName);

  if (view?.mode === 'eye') return ''
  // none of the outlines are shown in preview mode

  const outlineStyle = 
        
        /* STREAMING */ 
        streaming ? 'relative dotted-outline' : 
        // during streaming we highlight everything to visually show what's happening

        /* DRAG & DROP */
        dndState?.draggingItem ? 
        isDraggingItem ? 'relative dragging-item' : 
        dndState?.draggingLayout ? '' :

        (dndState?.draggingSection || dndState?.draggingDialog) ? 
            dndState.offFrame ? '' 
            : isAppShell ? 'relative dragging-parent' 
            : isDraggingOverMetToReplace ? 'relative dragging-to-replace' :
            ''
        :
        
        isDraggingOverMe ? `relative ${dropAllowed ? 'dragging-over-allowed' : ''} ${isDraggingOverMetToReplace && 'dragging-to-replace'}` : 
        dndState?.isDragging && !dndState?.draggingDialog && !dndState?.draggingSection && !dndState.draggingModule ? `relative dotted-outline` : ""
        
        /* CANVAS INTERACTIONS: HOVER, SELECT */
        : isSelected ? `relative selected-outline` :  isHovered ? `relative hovered-outline ` : view?.showOutlines ? 'relative dotted-outline' : ''

  return outlineStyle;
}

export function convertThemeVariablesToStyles(themeVariables) {
  const styleObject = {};
  themeVariables?.forEach(varItem => {
      styleObject[`--${varItem.api_name}`] = varItem.value;
  });
  return styleObject;
}