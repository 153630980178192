import { Plus } from 'iconoir-react';
import { prepareProject } from './helpers';

const emptySet = {
  name: "empty",
  folders: [
  {
      "name": "Sample Folder",
      "flows": [
          {
              "name": "User Flow",
              "frames": [
                  {
                      "name": "Sample Page",
                      "notes": "",
                      "jsx": `<AppShell pageBackground="base-50">
                      <Main gap="24px" width="1200" corners="md" marginX="12px" marginY="12px" paddingX="24px" paddingY="24px" textSize="base" direction="flex-col" selfAlign="center" alignItems="start" background="base-0" justifyContent="start" />
                      </AppShell>`
                  }
              ]
          }]
          }
        ]
      }

export default function AddProject({sets, addProject, userId, }) {
    function addSet() {
      const setName = 'empty'
      const projectName = "New Project"
      const payload = prepareProject(userId, emptySet, setName, projectName)
      
      payload && addProject(payload);
    }
  
    return (
      <div className='flex flex-row py-1 px-3 gap-1 rounded-lg text-white hover:scale-105 
      transition-all duration-150 group hover:brightness-110 active:brightness-100
      font-medium bg-primary items-center text-xs select-none relative'
      onClick={addSet}
      >
         <span className='drop-shadow-sm'>
         New App</span>
         <Plus height={20} width={20} className='-mr-1 group-hover:stroke-[1.6px] transition-all'/>
      </div>
    )
  }
  