import { useContext, useEffect, useState } from "react";
import { EditorContext } from "../../../EditorContext";

export default function LongString({ propValue, onSave, propKey }) {
    const {selector } = useContext(EditorContext)

    const [value, setValue] = useState(propValue);
    const [originalValue, setOriginalValue] = useState(propValue);
    useEffect(() => {
        setValue(propValue);
        setOriginalValue(propValue);
    }, [selector.object, propValue]);

    function handleBlur(e) {
        onSave(propKey, e.target.value);
    }

    function handleInputChange(e) {
        setValue(e.target.value);
    }

    function handleSave() {
        onSave(propKey, value);
        setOriginalValue(value);
    }

    function handleCancel() {
        setValue(originalValue);
    }

    function handleKeyDown(e) {
        // Check if Shift + S is pressed
        if (e.shiftKey && e.key === 'S') {
            e.preventDefault(); // Prevent the default browser action
            handleSave();
        }
    }

    return (
        <div className="w-full flex flex-col items-end gap-2 text-xs">
            <textarea
                className='resize-y bg-base-100 w-full rounded px-2.5 py-0.5 border focus:ring-1 focus:ring-primary focus:bg-white'
                value={value || ''}
                rows={5}
                onChange={handleInputChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown} // Handle keydown here
            />
        </div>
    );
}
