import { useContext, useEffect, useRef, useState } from "react";
import * as IconoirIcons from 'iconoir-react';
import Library from "../../minnav/library/Library";
import { createNewFrame, createNewIds } from "../../../../utilities/helpers";
import { EditorContext } from "../../EditorContext";
import { v1 as uuidv1 } from 'uuid';

export default function AddFromLibrary({addFeature, set, selector, handleAction}) {
    
    const {setNotifications, notifications } = useContext(EditorContext)
    function insertNewFrame({objects=[]}) {
      
        const page = selector.page
        const frames = page?.frames
        const insertIndex = frames?.length > 0 
          ? Math.max(...frames.map(frame => frame.index)) + 1 // add as last to the page
          : 1;
      
        let newFrame = createNewFrame({ ...page, type: 'page' }, [], 'Untitled frame', { width: 800, height: 800 });
        newFrame = { ...newFrame, index: insertIndex };
  
        let newObjects = objects.map(obj => ({...obj, frame: newFrame.id}));
        newObjects = newObjects.map(obj => obj.parent == 'rootObject' ? {...obj, parent: newFrame.id} : obj); 
        
        const action = {type: 'INSERT_FRAME', frame: {...newFrame, objects: newObjects}}
        handleAction(action);
        setShowLibrary(false)
      }
      
      function addFrame() {
        const page = selector.page
        const frames = selector.page.frames
          const insertIndex = frames?.length > 0 
          ? Math.max(...frames.map(frame => frame.index)) + 1 // add as last to the page
          : 1;
      
          let newFrame = createNewFrame({ ...page, type: 'page' }, [], 'Untitled frame', { width: 800, height: 800 });
          newFrame = { ...newFrame, index: insertIndex };;
          
          handleAction({type: 'INSERT_FRAME', frame: newFrame });
        
        
        const newNotification = {
          id: uuidv1(), 
          message: 'Page added', 
          timeout: 2000
        };
      
        // Update the notifications array
        setNotifications([...notifications, newNotification]);
  
      };
  
  
      const duplicateFrame = (frame) => {
        const newId = uuidv1(); // will use for frame.id and object.frame
        
        let newObjects = [...frame.objects]; // copy existing objects
        
        newObjects = createNewIds(newObjects, frame.id, newId); // create new Ids for these objects
        newObjects = newObjects.map(obj => ({...obj,frame: newId})); // update frame property
      
        const newFrame = {
            ...frame,
            id: newId,
            index: frame.index + 1,
            objects: newObjects
        };
        
        handleAction({type: 'INSERT_FRAME', frame: newFrame });
      }
  
      function handleClick() {
        /*let frames = selector.page?.frames || []
        frames = frames.sort((a, b) => a.index - b.index)
        const lastFrame = frames[frames.length - 1]
        if (lastFrame) duplicateFrame(lastFrame)
        else addFrame()*/
        addFrame()
      }
      
    const [showLibrary, setShowLibrary] = useState(false)
    const myRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (myRef.current && !myRef.current.contains(event.target)) {
                event.stopPropagation();  // Prevent the event from propagating
                setShowLibrary(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [myRef]);

    return (
        <div className='flex flex-row items-center bg-slate-50 rounded-md ring-1 ring-slate-300 text-xs font-medium relative'
        ref={myRef}
        >
            <div className="flex flex-row gap-1 items-center px-4 py-1 justify-center items-center rounded-l-md
            hover:bg-white transition-all duration-75 cursor-default"
            onClick={() => setShowLibrary(!showLibrary)}
            >
                New Page 
                <IconoirIcons.NavArrowDown height={12} width={12} style={{strokeWidth:2}} />
            </div>
            <div className="flex flex-row gap-1 items-center px-1 py-1 border-l justify-center items-center rounded-r-md
            hover:bg-white transition-all duration-75 cursor-default"
            onClick={handleClick}
            >
                <IconoirIcons.Plus height={16} width={16} style={{strokeWidth: 1.2}} />
            </div>




            {/* Dropdown */}
            {showLibrary &&
            <div className={`absolute right-0 -bottom-2  bg-gray-50 translate-y-full z-50 rounded-xl border-[0.5px] border-gray-400 shadow-xl
            flex flex-col pt-2 overflow-hidden`} style={{ width: 480,  height: 580, }}>
                <IconoirIcons.Xmark
                    width={20} 
                    height={20} 
                    className="absolute top-2 right-2 hover:scale-110 transition-all duration-75 opacity-40 hover:opacity-80 cursor-pointer"
                    onClick={() => setShowLibrary(false)}
                    
            />
            <Library 
            onSelect={insertNewFrame} set={set}
            />
            </div>}
        </div>
        
    );
  }