import PropTypes from 'prop-types';
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import React, { useState } from 'react';
import { Icon } from '../index'; /* replace to 'import { Icon } from './index'' */
import { iconMap } from '../media/iconMap'; /* replace to 'import { iconMap } from './iconMap'' */

const allIconNames = Object.keys(iconMap) || []

export default function InputText(props) {
    
    const {
        state = 'default',
        placeholder = 'placeholder text',
        bgColor = 'base-0',
        size = 'medium',
        label = '',
        helperText = '',
        rightIcon = 'none',
        leftIcon = 'none',
        value = '',
        prefix = '',
        suffix = '',
        textAlign = 'left',
        width = 'auto',
        hasOutline = true,
        attributes,
        listeners,
        name,
        type,
        onChange = () => {}
      } = props;

    const { junoAttributes, showTag, outlineStyle, onPropertyUpdate=()=>{}, preview} = props.junoProps || {} /* remove line */
    
    const [isFocused, setIsFocused] = useState(false);

    const sizeStyles = size == 'small' ? `py-1 px-2 gap-1.5 text-xs` : size == 'large' ? `py-2 px-3 gap-3 text-base` : `py-1.5 px-2 gap-3 text-sm`;
    const cornerStyles = size == "small" ? "rounded" : size == "large" ? "rounded-lg" : "rounded-md"
    
    let stateStyles = hasOutline ? isFocused ? `border border-accent` : `border border-base-300` : 'border border-transparent'
    switch (state) {
        case 'disabled':
            stateStyles = `bg-base-100 opacity-70 cursor-not-allowed ${hasOutline ? 'border border-base-300' : ''}`
            break;
        case 'error':
            stateStyles = `text-warning-content ${hasOutline ? 'border border-warning-content' : ''}`
            break;
        case 'success':
            stateStyles = `text-success-content ${hasOutline ? 'border border-success-content' : ''}`
            break;
    }
    
    const bgStyles = (bgColor && bgColor !== 'none') ? `bg-${bgColor} ${!hasOutline && isFocused && 'brightness-95'}` : '';
    
    let classes = `w-full flex items-center justify-between truncate ellipsis box-border font-medium ${sizeStyles} ${cornerStyles} ${bgStyles} ${stateStyles}`
    
    const labelTextSize = size == 'small' ? `text-xs` :  size == 'large' ? `text-lg`: `text-sm`;
    const labelClasses = `text-base-content ${labelTextSize} font-medium`

    const messageTextColor = state == 'error' ? stateStyles = 'text-warning-content' : state == 'success' ? stateStyles = 'text-success-content' : ''
    const messageClasses = size == 'large' ? `text-sm  ${messageTextColor}` : `text-xs ${messageTextColor}`
    const widthStyle = width != 'auto' ? `w-${width}` : size == 'small' ? 'min-w-[120px]' : size == 'large' ? 'min-w-[200px]' : 'min-w-[160px]'
    
    const iconWidth = size == 'small' ? 'w-4' : size == 'large' ? 'w-6' : 'w-5'
    const LeftIconComponent = leftIcon !== 'none' ? <Icon icon={leftIcon?.toLowerCase()} className={`flex-shrink-0 scale-90 ${iconWidth}`}/> : null;
    const RightIconComponent = rightIcon !== 'none' ? <Icon icon={rightIcon?.toLowerCase()} className='flex-shrink-0 flex-grow-0'/> : null;

    const gapStyles = size == 'small' ? 'gap-0.5' : size == 'large' ? 'gap-1.5' : 'gap-1'
    let wrapperClasses = `flex flex-col ${widthStyle} ${gapStyles}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */

    return (
        <div
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
            className={wrapperClasses}
        >
            {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Input</div> /* remove line */}
            {label && ( 
                <label className={labelClasses}>
                    <EditableOnDoubleClick text={label} onBlur={onPropertyUpdate('label')} whiteSpace='nowrap'  /* replace to '{label}' */ />
                </label>
            )}
            <div className={classes} style={{boxSizing: 'border-box'}}>
            {LeftIconComponent}
            {prefix}
            {/* start remove block */
            <div className={`flex-grow text-${textAlign}`}>
            {value ? 
            <EditableOnDoubleClick text={value} onBlur={onPropertyUpdate('value')} whiteSpace='nowrap' /* replace to '{value}' */ /> 
            : <span className={'text-base-500 font-medium'}> 
            <EditableOnDoubleClick text={placeholder} onBlur={onPropertyUpdate('placeholder')} whiteSpace='nowrap' /* replace to '{placeholder}' */ />
            </span>}
            </div>
            /* end remove block */} 
            {/* remove line */ /*
            <input
            type={type}
            name={name}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={(e) => onChange(e)}
            disabled={state == 'disabled'}
            className={`flex-grow text-${textAlign} border-transparent focus:outline-none focus:ring-0 font-medium placeholder:font-normal
            placeholder-base-400 text-base-content bg-transparent w-full truncate ellipsis ${state == 'disabled' && 'cursor-not-allowed'}`}
            value={value}
            placeholder={placeholder}
            />  
            */ /* remove line */}
            {suffix}
            {RightIconComponent}
            </div>
            {helperText && <span
            className={messageClasses}
            >
                <EditableOnDoubleClick text={helperText} onBlur={onPropertyUpdate('helperText')} whiteSpace='pre-wrap' /* replace to '{helperText}' */ />
            </span>}    
        </div>
        
    );
}

InputText.propTypes = {
    width: PropTypes.oneOf(["auto", "1/2", "full"]),
    state: PropTypes.oneOf(['default', 'disabled', 'error', 'success']),
    bgColor: PropTypes.oneOf(['none', 'base-0', 'base-50', 'base-100']),
    label: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    helperText: PropTypes.string,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    textAlign: PropTypes.oneOf(['left', 'right', 'center']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    leftIcon: PropTypes.oneOf(['none', ...allIconNames]),
    rightIcon: PropTypes.oneOf(['none', ...allIconNames]),
    hasOutline: PropTypes.bool,
    type: PropTypes.oneOf(['text', 'password', 'email', 'number', 'tel', 'url']),
    name: PropTypes.string,
};

/* ignore rest */

InputText.definitions = {
    apiName: 'InputText',
    displayName: 'Text Input',
    description: 'A text input field with customizable features such as label, placeholder, icons, and background color. Supports various states like placeholder, filled, disabled, warning, and success, and includes options for text alignment and size.',
    ai_instructions: 'text input field with label, helperText (use for info, hints or errors) and optional icons.',
    type: 'input',
    relativeSize: 'small',
    acceptedChildren: 'none',
    propDefinitions: {
        state: {
            type: 'oneOf',
            options: ['default', 'disabled', 'error', 'success'],
            default: 'default', 
            displayName: 'State',
            tile: '1/2',
        },
        bgColor: {
            type: 'colors',
            options: ['none', 'base-0', 'base-50', 'base-100'],
            default: 'base-100', 
            displayName: 'Background',
            tile: '1/2',
        },
        width: {
            type: "width",
            options: ["auto", "1/2", "full"],
            displayName: "Width",
            default: "auto", 
            defaultOnMobile: 'full', 
            tile: '1/2',
        },
        
        size: {
            type: 'size',
            options: ['small', 'medium', 'large'],
            default: 'medium', 
            displayName: 'Size',
            tile: '1/2',
        },        
        label: {
            type: 'string',
            default: '', 
            displayName: 'Label',
            tile: '1/2',
        },

        textAlign: {
            type: 'oneOf',
            options: ['left', 'right', 'center'],
            default: 'left', 
            displayName: 'Align Text',
            tile: '1/2',
        },
        value: {
            type: 'string',
            default: '', 
            displayName: 'Value',
        },
        placeholder: {
            type: 'string',
            default: 'placeholder text', 
            displayName: 'Placeholder Text',
            required: true,
            ai_instructions: 'value example, e.g. "email@example.com" or "Acme Corp Inc". do not write placeholder like action "Enter text". limit to 3 words.'
        },
        helperText: {
            type: 'string',
            default: '', 
            displayName: 'Help Text (hint/error)',
            ai_instructions: 'the text under the field'
        },
        name: {
            type: 'string',
            default: '', 
            displayName: 'Name',
            tile: '1/2',
        },
        type: {
            type: 'oneOf',
            options: ['text', 'password', 'email', 'number', 'tel', 'url'],
            default: 'text',
            displayName: 'Type',
            tile: '1/2',
        },
        prefix: {
            type: 'string',
            default: '',
            displayName: 'Prefix',
            ai_instructions: 'can be used for things https://', 
            tile: '1/2',
            
        },
        suffix: {
            type: 'string',
            default: '', 
            displayName: 'Suffix',
            ai_instructions: 'can be used for things like @gmail.com', 
            tile: '1/2',
        },
        leftIcon: {
            type: 'icon',
            options: ['none', ...allIconNames], 
            displayName: 'Left Icon',
            default: 'none'
        },
        rightIcon: {
            type: 'icon',
            options: ['none', ...allIconNames],
            displayName: 'Right Icon',
            default: 'none' 
        },
        hasOutline: {
            type: "bool",
            displayName: "Outline",
            default: false, 
            ai_instructions: 'adds 1px outline',
        }, 
    }
};
