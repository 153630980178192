import React, { useState, useEffect, useContext, useRef } from "react";
import * as UIKit from "../../../../../ui-kit/local";
import * as ExportedKit from "../../../../../ui-kit/exports/text";
import FormattedSnippet from "./FormattedSnippet";
import * as IconoirIcons from "iconoir-react";
import { EditorContext } from "../../../EditorContext";

// Extract component names and sort them
const allcomponents = Object.keys(UIKit).sort();

// Extract exportable component names from ExportedKit
const exportableComponentNames = Object.keys(ExportedKit).sort();
const exportableComponents = allcomponents.filter(component =>
  exportableComponentNames.includes(component)
);

const availableComponents = allcomponents.filter(component =>
  !exportableComponentNames.includes(component)
);

export default function Exports() {
  const codeBlock = useRef(null);
  const { selector } = useContext(EditorContext);
  const [jsx, setJsx] = useState("");
  const [componentDisplay, setComponentDisplay] = useState("useSelector");

  const handleSelectChange = (event) => {
    const selectedComponent = event.target.value;
    setComponentDisplay(selectedComponent);
    if (selectedComponent === "useSelector") {
      if (selector.object?.componentAPIName) {
        fetchComponentContent(selector.object.componentAPIName);
      } else {
        setJsx("No component selected.");
      }
    } else {
      fetchComponentContent(selectedComponent);
    }
  };

  const fetchComponentContent = (componentName) => {
    try {
      const content = ExportedKit[componentName];
      fetch(content)
        .then(response => response.text())
        .then(text => {
          if (text.includes("<!DOCTYPE html>") || text.includes("<html")) {
            setJsx(`Export for component '${componentName}' not available.`);
          } else {
            setJsx(text);
          }
        })
        .catch(error => {
          setJsx(`Export for component '${componentName}' not available.`);
        });
    } catch (error) {
      setJsx(`Export for component '${componentName}' not available.`);
    }
  };

  useEffect(() => {
    if (componentDisplay === "useSelector" && selector.object?.componentAPIName) {
      fetchComponentContent(selector.object.componentAPIName);
    }
  }, [selector.object?.componentAPIName, componentDisplay]);

  return (
    <div className={`flex flex-col text-sm rounded-t-lg`} style={{ maxHeight: "100%" }}>
      <div className={`gap-2 w-full flex flex-row items-center justify-between text-xs py-2`}>
        <SelectComponent onSelectChange={handleSelectChange} />
        <CopyToClipboardButton codeBlockRef={codeBlock} />
      </div>
      <div className="overflow-y-auto select-text text-xs rounded-lg">
        <div id="code-block" ref={codeBlock}>
          <FormattedSnippet jsx={jsx} />
        </div>
      </div>
    </div>
  );
}

// SelectComponent to list options
function SelectComponent({ onSelectChange }) {
  return (
    <select
      defaultValue="useSelector"
      className="bg-gray-600 px-2 py-1 rounded-md border border-transparent hover:border-gray-500 transition-all cursor-pointer flex flex-row gap-1 items-center"
      onChange={onSelectChange}
    >
      <option value="useSelector">Use Selector</option>
      {exportableComponents.map(component => (
        <option key={component} value={component}>
          {component}
        </option>
      ))}
      {availableComponents.map(component => (
        <option key={component} value={component} disabled>
          {component}
        </option>
      ))}
    </select>
  );
}


function CopyToClipboardButton({ codeBlockRef }) {
    const [copied, setCopied] = useState(false);
  
    const copyToClipboard = () => {
      const text = codeBlockRef.current.innerText;
      navigator.clipboard.writeText(text).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000); // Reset 'copied' state after 3 seconds
      })
      .catch(err => {
        console.log('Something went wrong', err);
      });
    }
  
    return (
      <div
        className="bg-gray-600 px-2 py-1 rounded-md border border-transparent hover:border-gray-500 transition-all cursor-pointer flex flex-row gap-1 items-center"
        onClick={copyToClipboard}
        style={{width: 80}}
      >
        {copied ? <IconoirIcons.Check width={16} height={16} /> : <IconoirIcons.Copy width={16} height={16} />}
        {copied ? "Copied!" : "Copy"}
      </div>
    );
  }