import { useEffect, useState } from "react";

export default function FolderName({ folder, handleAction }) {
    
    const [editable, setEditable] = useState(false);
    const [name, setName] = useState(folder?.name);

    useEffect(() => {
      // Update the name state when the folder prop changes
      setName(folder?.name);
    }, [folder?.name]);

    function handleDoubleClick(e) {
      e.stopPropagation();
      setEditable(true);
    }

    function handleBlur() {
      setEditable(false);
      updateFolderName(folder, name);
    }

    function handleInputChange(e) {
      setName(e.target.value);
    }

    const updateFolderName = (currentFolder, newName) => {
      const action = {
        type: 'UPDATE_FOLDER',
        folder: { ...currentFolder, name: newName }
      };
      handleAction(action);
    };

    return (
      <input 
        type="text"
        value={name}
        onChange={handleInputChange}
        onBlur={(e) => editable && handleBlur(e)}
        onClick={handleDoubleClick}
        onKeyDown={(e) => e.key === 'Enter' && e.target.blur()}
        readOnly={!editable}
        className={`bg-transparent focus:bg-white placeholder-gray-700 text-gray-800 focus:text-gray-900
        py-1 overflow-hidden whitespace-nowrap ellipsis truncate transition-all`}
        style={{
          userSelect: !editable ? 'none' : '',
          minWidth: 200,
          textAlign: 'left'
        }}
      />
    )
  }
