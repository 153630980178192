import React from 'react'
import PropTypes from 'prop-types';

export default function Loader(props) {

    const { size='small', type='spinner', color='primary', opacity='70', attributes, listeners } = props
    const { junoAttributes, outlineStyle, preview} = props.junoProps || {} /* remove line */

    const spinnerSizeClasses = {
        small: 'w-4 h-4 border-2',
        medium: 'w-6 h-6 border-4',
        large: 'w-8 h-8 border-4',
    };

    const pulseSizeClasses = {
        small: 'w-3 h-3',
        medium: 'w-5 h-5',
        large: 'w-7 h-7',
    };
    
    const typeClasses = type == 'spinner' ? `${spinnerSizeClasses[size]} border-solid border-${color} border-t-transparent` : 
    `${pulseSizeClasses[size]} bg-${color} `
    
    const animation = 
        /* remove line */  preview ? 
        type == 'spinner' ? 'animate-spin' : 'pulsate-125' 
        /* remove line */  : ''
    let loaderClasses = `${typeClasses} rounded-full opacity-${opacity} ${animation}`

    return (
        <div {...junoAttributes} className={outlineStyle}  /* remove line */ >   
            <div className={loaderClasses} {...attributes} {...listeners} />
        { /* remove line */}</div> 
    );
}

Loader.propTypes = {
    type: PropTypes.oneOf(['spinner', 'pulse']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    color: PropTypes.oneOfType([
    PropTypes.oneOf(['primary', 'accent', 'base-content', 'error-content', 'warning-content', 'success-content', 'base-0', 'base-100', 'info-content']),
    PropTypes.string
    ]),
    opacity: PropTypes.oneOf(['100', '70', '50']),
    children: PropTypes.node
};

/* ignore rest */

Loader.definitions = {
    apiName: 'Loader',
    displayName: 'Loader',
    description: 'A loader component for indicating loading states, with options for type, size, color, and opacity. Supports different loader styles like spinner, pulse, and dots.',
    ai_instructions: 'animated loader',
    type: 'indicators',
    relativeSize: 'small',
    acceptedChildren: 'none',
    propDefinitions: {
        type: {
            type: 'oneOf',
            options: ['spinner', 'pulse'],
            displayName: 'Type',
            default: 'spinner'
        },

        color: {
            type: 'colors',
            options: ['primary', 'accent', 'base-content', 'error-content', 'warning-content', 'success-content', 'base-0', 'base-100', 'info-content'],
            displayName: 'Color',
            default: 'primary'
        },
        size: {
            type: 'size',
            options: ['small', 'medium', 'large'],
            displayName: 'Size',
            default: 'small', 
            ai_instructions: 'small 12px, medium 20px, large 28px', 
            tile: '1/2'
        },
        opacity: {
            type: 'oneOf',
            options: ['100', '75', '50'],
            displayName: 'Opacity',
            default: '75', 
            tile: '1/2'
        }
    }
};

