import Examples from "./Examples";
import ContactForm from "./ContactForm";
import Hero from "./Hero";
import Who from "./Who";
import Why from "./Why";
import Footer from "./Footer";
import Header from "./Header";
import { useEffect, useRef, useState } from "react";
import { NavArrowDown } from "iconoir-react";

export default function StudioPage() {
    const scrollbarHeight = 64;
    const mainWidth = 1320;
    return (
        <div className='w-screen flex flex-col relative h-dvh relative bg-stone-100' 
        style={{ minHeight: '100dvh', 
          fontFamily: 'Geist, Inter, system-ui, -apple-system, BlinkMacSystemFont, Roboto, sans-serif',

        }}>
                <Header mainWidth={mainWidth}/>   


                <div id='hero'
                className={`flex flex-col items-center justify-center p-5 h-[calc(60dvh_-_128px)] md:h-[40vh] md:max-h-[720px]`}>
                  <Hero mainWidth={mainWidth}/>
                </div>


                <ScrollArrow  scrollTo='examples' scrollbarHeight={scrollbarHeight} />
                
                
                <div className="flex flex-col items-center justify-center md:max-h-[1440px]" id='examples'
                >
                  <Examples mainWidth={mainWidth}/>
                </div>

                <ScrollArrow  scrollTo='about' scrollbarHeight={scrollbarHeight} />
                <div className="flex flex-col items-center justify-center h-auto md:min-h-[calc(100vh_-_128px)] md:max-h-[1320px]"
                style={{ minHeight: 720}}
                id='about'
                >
                  <Why />
                </div>

                <ScrollArrow  scrollTo='contact-form' scrollbarHeight={scrollbarHeight} />

                <div className="flex flex-col items-center justify-center h-auto md:min-h-[calc(100vh_-_64px)] md:max-h-[1320px]
                bg-gradient-to-b from-transparent to-white
                "
                
                id='contact-form'
                >
                  <ContactForm />
                </div>
                {/*
                <Footer />
                */}
                
        </div>
    )
}

function ScrollArrow({ scrollFrom = null, scrollTo = 'self', scrollbarHeight }) {
  const [hasPassedScrollBar, setHasPassedScrollBar] = useState(false);
  const myRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!scrollFrom) return;
      if (myRef.current) {
        const rect = myRef.current.getBoundingClientRect();
        if (rect.top <= 0 && !hasPassedScrollBar) {
          setHasPassedScrollBar(true);
        } else if (rect.top > 0 && hasPassedScrollBar) {
          setHasPassedScrollBar(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasPassedScrollBar, scrollFrom]);

  const headerOffset = 32
  const handleScrollClick = () => {
    if (hasPassedScrollBar && scrollFrom) {
      const scrollFromElement = document.getElementById(scrollFrom);
      if (scrollFromElement) {
        window.scrollTo({
          top: scrollFromElement.offsetTop - headerOffset, // header height. it's sticky
          behavior: 'smooth',
        });
      }
    } else if (scrollTo === 'self' && myRef.current) {
      window.scrollTo({
        top: myRef.current.offsetTop - headerOffset, // header height. it's sticky
        behavior: 'smooth',
      });
    } else if (scrollTo !== 'self') {
      const scrollToElement = document.getElementById(scrollTo);
      if (scrollToElement) {
        window.scrollTo({
          top: scrollToElement.offsetTop - headerOffset, // header height. it's sticky
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <div
      ref={myRef}
      className={`hidden md:flex md:flex-col items-center justify-center 
            text-left text-gray-300 hover:text-gray-400 cursor-pointer 
            hover:scale-110 transition-all text-2xl md:text-2xl font-medium 
            ${hasPassedScrollBar ? 'rotate-180' : ''}
            rounded-2xl`}
      style={{ height: scrollbarHeight || 80 }}
      onClick={handleScrollClick}
    >
      <NavArrowDown className="stroke-[1.5px]" />
    </div>
  );
}