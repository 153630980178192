import { ArrowLeft, ArrowRight, Minus, MinusCircle, Plus, PlusCircle, Xmark } from 'iconoir-react';
import React, { useEffect, useState } from 'react';

export default function SelectSpacing({ propKey, options, currentOption, label, onChange }) {
  // Creating an ordered list of options based on optionsMap keys in defined order
  const optionsOrder = [
    'none',
    '3xs', '0.5', '2px',
    '2xs', '1', '4px',
    'xs', '1.5', '6px',
    'sm', '2', '8px',
    'base', '3', '12px',
    'md', '4',  '16px',
    'lg', '6',  '24px',
    'xl', '8',  '32px',
    '2xl', '12', '48px',
    '3xl', '16',  '64px',
  ];

  const optionsMap = {
    'none': '0',
    '3xs': '3XS', '0.5': '2px', '2px': '2px',
    '2xs': '2XS', '1': '4px', '4px': '4px',
    'xs': 'XS', '1.5': '6px', '6px': '6px',
    'sm': 'S', '2': '8px', '8px': '8px',
    'base': 'base', '3': '12px',
    '12px': '12px',
    'md': 'M',
    '4': '16px',
    '16px': '16px',
    'lg': 'L',
    '6': '24px',
    '24px': '24px',
    'xl': 'XL', '8': '32px', '32px': '32px',
    '2xl': '2XL',
    '12': '48px',
    '48px': '48px',
    '3xl': '3XL',
    '16': '64px',
  };
  
  const orderedOptions = optionsOrder.filter(option => options.includes(option));
  
  const hasNone = options.includes('none');
  // Finding the index of the current option in the ordered list
  const [selectedIndex, setSelectedIndex] = useState(orderedOptions.indexOf(currentOption));
  
  useEffect(() => {
    setSelectedIndex(orderedOptions.indexOf(currentOption));
  }, [currentOption]);

  // Handler to change the selected index
  const handleSelect = (index) => {
    onChange(propKey, orderedOptions[index]);
    setSelectedIndex(index);
  };

  // Handlers for < and > buttons
  const nextOption = () => {
    const nextIndex = (selectedIndex + 1) % orderedOptions.length;
    if (selectedIndex === orderedOptions.length - 1) return;
    handleSelect(nextIndex);
  };

  const prevOption = () => {
    if (selectedIndex == -1) {
      console.log('selectedIndex:', selectedIndex);
      return;
    }

    if (selectedIndex === 0 && hasNone) { return; }
      
    if (!hasNone && selectedIndex === 0) {
        onChange(propKey, null)
        return;
    }

    const prevIndex = (selectedIndex - 1 + orderedOptions.length) % orderedOptions.length;
    handleSelect(prevIndex);
  };

  return (
    <div className='flex flex-col w-full gap-1 items-end text-xs'>
    <div className='flex flex-row gap-1 justify-between items-end w-full'>
      {label}
      <button className='hover:underline opacity-70 hover:opacity-100 transition-all duration-75 scale-75'
      onClick={() => onChange(propKey, hasNone ? 'none' : null)}
      ><Xmark /></button>
    </div>
    <div 
      className='items-center flex flex-row flex-grow w-full rounded-lg py-1 gap-2 px-1.5 bg-base-100 hover:bg-base-200 text-sm transition-all duration-150'
      style={{userSelect: 'none'}}
    >
      <Minus
        width={16} height={16}
        className="opacity-70 hover:opacity-100 cursor-pointer"
        onClick={prevOption} />
      
      <div className="flex-grow flex justify-center items-center lowercase">
        <button
          className="w-full pointer bg-base-0 flex items-center justify-center rounded-md p-0.5"
          style={{ zIndex: 2 }}
        >
          {optionsMap[orderedOptions[selectedIndex]] || 'n/a'}
        </button>
      </div>
      
      <Plus
      width={16} height={16}
      className="opacity-70 hover:opacity-100 cursor-pointer"
      onClick={nextOption}
      />
    </div>
    </div>
  );
};