import Fuse from 'fuse.js';
import * as IconoirIcons from 'iconoir-react';
import { useEffect, useMemo, useState } from 'react';
import SearchResults from './SearchResults';

export default function Search({
    searchResults,
    setSearchResults,
    showSearch,
    setShowSearch,
    items,
}) {
    const [searchQuery, setSearchQuery] = useState('');

    const fuse = useMemo(() => new Fuse(items, {
        keys: ['name'],
        includeScore: true,
        threshold: 0.2,
        tokenize: true
    }), [items]);

    useEffect(() => {
        if (searchQuery?.length > 0) {
            // Perform search and map results to the items
            const results = fuse.search(searchQuery).map(result => result.item);
            setSearchResults(results);
            setShowSearch(true);
        } else {
            setSearchResults([]);
            setShowSearch(false);
        }
    }, [searchQuery]);

    function handleClearSearch() {
        setSearchQuery('');
        setSearchResults([]);
        setShowSearch(false);
    }

    return (
        <div className={`w-full flex-shrink-0 flex flex-col gap-2`}>
            {/* Input Area  */}
            <div className={`flex flex-row gap-2 px-3 py-2 bg-gray-100 w-full rounded-lg text-xs items-center ring-1 ring-inset ${showSearch ? 'ring-[1.5px] ring-primary bg-white' : 'ring-base-300'}`}>
                <IconoirIcons.Search width={16} style={{ strokeWidth: 2 }} className='flex-shrink-0' />
                <input
                    type="text"
                    placeholder="Search..."
                    className='bg-transparent w-full placeholder:text-gray-500'
                    value={searchQuery}
                    onFocus={() => setShowSearch(true)}
                    onBlur={() => (!searchQuery || searchQuery.length === 0) && setShowSearch(false)}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                {showSearch && (
                    <div className='rounded-full hover:scale-110 cursor-pointer' onClick={handleClearSearch}>
                        <IconoirIcons.Xmark width={16} height={16} style={{ strokeWidth: 1.2 }} />
                    </div>
                )}
            </div>

            {/* Display Search Results */}
            {showSearch && searchResults.length > 0 && (
                <SearchResults results={searchResults} />
            )}
        </div>
    );
}