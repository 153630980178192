import { useState, useEffect } from "react";
import axios from "axios";
import { Reduce } from "iconoir-react";
import { convertObjectsToJSX } from "../inspect/components/FormattedSnippet";

const templateCategories = ['inputs', 'buttons']

export default function MakeTemplate({selector, templates}) {
    
    const currentTemplate = templates?.find((template) => template?.root_object_id === selector?.object?.id) || null
    
    const emptyTemplate = {
        name: '', 
        width: 120,
        height: 120,
        root_object_id: selector?.object?.id,
    }
    // Initial states for width and height
    const [ template, setTemplate ] = useState(null);
    const [background, setBackground] = useState('transparent');
    
    useEffect(() => {
        if (currentTemplate) {
            setTemplate(currentTemplate)
        } else {
            setTemplate(emptyTemplate)
        }
    }, [selector.object]);

    // Updating the payload with the state values
    const objectId = selector.object?.id
    const frameId = selector.frame?.id

    // Function to handle logging the payload
    const handleMakeComponentClick = () => {

        takeScreenshot();

        if (currentTemplate) {
            // Update the template
            console.log('Update template')
        } else {
            // Create the template
            console.log('Create template')
        }
    }

    function setSize() {
        if (!selector.objectRef) return;
        const rect = selector.objectRef.getBoundingClientRect();
        setTemplate({
                ...template,
                width: Math.round(rect?.width),
                height: Math.round(rect?.height)
        })
    }
    const takeScreenshot = async () => {
        // Prepare the payload
        const payload = {
            name: template.name,
            frameId: 'bc044f40-4ede-11ef-a151-3b04cc463b83',
            objectId,
            width: template.width,
            height: template.height,
            background,
            jsx: convertObjectsToJSX(selector)
        };
    
        try {
            const response = await axios.post('/take-screenshot', payload);
            const imageUrl = response.data.thumbnail;

            console.log(response.data)
            /*if (imageUrl) {
                window.open(imageUrl, '_blank');
            }*/
        } catch (error) {
            console.error('Error taking screenshot:', error);
        }
    };
    
    
    

    if (template) return (
        <div className={`w-full p-1 flex flex-col gap-2 rounded-md
        ${currentTemplate ? 'bg-amber-200 ring-1 ring-amber-300' : 'bg-base-100 ring-1 ring-base-200 '}
        `}>
            
            {currentTemplate ? <>
                Edit template
                <div className="w-full h-16 bg-amber-100 rounded"
                style={{
                    backgroundImage: `url(${template?.thumbnail})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }} 
                
            />
            </> : 'Make Template'}
            
            <input
                type="text"
                className='p-1 bg-base-0 w-full'
                value={template.name}
                onChange={(e) => setTemplate({...template, name: e.target.value})}
                placeholder="Name"
            />
            <div className='w-full flex flex-row gap-2'>
             <input
                className='p-1 bg-base-0 w-1/2'
                type="text"
                value={background}
                onChange={(e) => setBackground(e.target.value)}
                placeholder="Background"
            />
            
            </div>
            <div className='w-full flex flex-row gap-2'>
            <input
                className='p-1 bg-base-0  w-1/2'
                type="number"
                value={template.width}
                onChange={(e) => setTemplate({...template, width: e.target.value})}
                placeholder="Width"
            />
            <input
                type="number"
                className='p-1 bg-base-0 0 w-1/2'
                value={template.height}
                onChange={(e) => setTemplate({...template, height: e.target.value})}
                placeholder="Height"
            />
            <Reduce onClick={setSize} />
            </div>
            
            <button
                className='px-2 py-1 bg-amber-400 text-white rounded-md'
                onClick={handleMakeComponentClick}
            >
                {currentTemplate ? 'Update' : 'Create'}
            </button>
        </div>
    )
}
