export const iconbars = [
    {
      apiName: 'iconbar_sample',
      displayName: 'Sample Iconbar',
      rootComponent: 'IconBar',
      description: 'Displays a vertical navigation iconbar with icons. can be used instead of sidebar to add more space to main content. can be also used with sidebar if nav is complex.',
      width: 64, 
      height: 480, 
      ai_template: true,
      thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714065011486.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=aw0lUaRVtRWP8ASzhQ2wVdY3EzE9Zda97%2FoZV2O9tXcCv3z24gqyM5iOrRGafOoYZEjy6hI5rxOS5NF%2FX%2FHGViXBJGCh3KmPZoq%2BL74mwd6JjdM81ZRTsjDVIbg%2BG4CNkmNvKcNXudRADYqjSWitHoDphkvgZP6NyqKrPihWkaCCtyvpYSueZCv4uR2ai%2FjhpZIr4fnms9pq9%2F%2FNOu0TNnWkfplM8Bi53g40hphPhPPOrq%2BSShaAnxKjhDGYCYnLXPiGHA3UqKTcvGaccHLhN7NzikJZAHLudoowKlcamPihIA7NMAkeO4wbbVk2noFpVbE9MAgGj0m%2BAIandAdCNg%3D%3D",
      jsx: `<IconBar paddingY="md" alignItems="center" background="accent">
      <Module paddingY="0" module_role="Logo leading to homepage" module_name="Home Logo" alignItems="center">
        <Logo size="24px" type="symbol" color="inverted" />
      </Module>
      <Module paddingY="0" module_role="Logo leading to homepage" module_name="Home Logo" alignItems="center">
        <Avatar size="small" type="icon" bgColor="accent" />
      </Module>
      <Divider direction="horizontal" width="1/2" />
      <Module paddingY="sm" module_role="contains icon buttons" module_name="icons" alignItems="center" height="h-full" gap="lg">
        <IcoNoirIcon name="HomeAlt" color={null} size="24" thickness="normal" />
        <IcoNoirIcon name="Dashboard" color={null} size="24" thickness="normal" />
        <IcoNoirIcon name="Reports" color={null} size="24" thickness="normal" />
        <IcoNoirIcon name="Activity" color={null} size="24" thickness="normal" />
        <IcoNoirIcon name="Settings" color={null} size="24" thickness="normal" />
      </Module>
    </IconBar>`  
    }

  ]