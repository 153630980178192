import React from 'react';
import PropTypes from 'prop-types';
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import { Icon } from '../index'; /* replace to 'import { Icon } from './index'' */
import { iconMap } from '../media/iconMap'; /* replace to 'import { iconMap } from './iconMap'' */

const allIconNames = Object.keys(iconMap) || []

export default function DataCard(props) {

    
    const {
        title = "Metric",
        value = "$10,500",
        changeValue = "+12%",
        changeColor = 'none',
        textSize = 'small',
        icon = 'chart-up',
        helpText = null,
        attributes,
        listeners,
        defaultIconSet
      } = props;
      
    const { junoAttributes, showTag, outlineStyle, onPropertyUpdate=()=>{}} = props.junoProps || {} /* remove line */

    const sizeStyles = `w-full h-auto ${textSize == 'small' ? 'text-sm' : 'text-base'}`
    const truncateStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}

    let wrapperClasses = `flex flex-row items-stretch justify-start gap-3 ${sizeStyles}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */

    const titleFont = textSize == 'small' ? 'text-base' : 'text-lg';
    const smallerFont = textSize == 'small' ? 'text-sm' : 'text-base';
    
    const IconComponent = icon && icon !== 'none' ? <Icon icon={icon?.toLowerCase()} defaultIconSet={defaultIconSet} /> : null;

    return (
        <div 
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
        className={wrapperClasses}>
        {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Data Card</div> /* remove line */ }
        
        {IconComponent}

        {/* CONTENT BLOCK */}
        <div className={`flex flex-col flex-grow items-start ${smallerFont} gap-1.5`} style={truncateStyle}>
            
            {title && <div className={`flex-shrink-0 flex flex-row items-center relative group`}>
                {<EditableOnDoubleClick text={title} onBlur={onPropertyUpdate('title')} whiteSpace="nowrap"/> /* replace to '{title}' */}
            </div>}

            {/* Value */}
            <div className='flex flex-row items-baseline gap-2' >
                    <h3 className={`${titleFont} flex flex-row font-semibold `} style={truncateStyle}>
                        {<EditableOnDoubleClick text={value} onBlur={onPropertyUpdate('value')} whiteSpace="nowrap"/> /* replace to '{value}' */}
                    </h3>
                    <span className={`${smallerFont} font-medium text-${changeColor}`} style={truncateStyle}>
                        {<EditableOnDoubleClick text={changeValue} onBlur={onPropertyUpdate('changeValue')} whiteSpace="nowrap"/> /* replace to '{changeValue}' */}
                    </span>
            </div>
            

            {/* Description */}
            {helpText && <div className={`${smallerFont} -mt-1`} style={truncateStyle}>
                {<EditableOnDoubleClick text={helpText} onBlur={onPropertyUpdate('helpText')} whiteSpace="nowrap"/> /* replace to '{helpText}' */}
            </div>}
        </div>
        </div>
    );
}


DataCard.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    changeValue: PropTypes.string,
    hasOutline: PropTypes.bool,
    changeColor: PropTypes.oneOf(['none', 'primary', 'primary-content', 'accent', 'accent-content', 'base-content', 'base-500', 'base-700', 'base-900'
    ,'success-content', 'warning-content', 'warning-content', 'error-content', 'info-content']),
    textSize: PropTypes.oneOf(['small', 'medium']),
    icon: PropTypes.oneOf(['none', ...allIconNames]),
    iconColor: PropTypes.oneOf(['none', 'primary', 'primary-content', 'accent', 'accent-content', 'base-content', 'base-500', 'base-700', 'base-900'
    ,'success-content', 'warning-content', 'warning-content', 'error-content', 'info-content']),
    helpText: PropTypes.string,
};

/* ignore rest */


DataCard.definitions = {
    apiName: 'DataCard',
    displayName: 'DataCard',
    description: `Small card to visualize one piece of important data, useful for KPIs and other important metrics`,
    ai_instructions: `Small horizontal to visualize one piece of important data, useful for KPIs and other important metrics`,
    type: 'cards',
    relativeSize: 'medium',
    acceptedChildren: 'none',
    propDefinitions: {
        title: {
            type: 'string',
            default: "Metric",
            displayName: 'Title'
        }, 
        value: {
            type: 'string',
            default: "$10,500", 
            displayName: 'Value',
            tile: '1/2',
            required: true
        }, 
        changeValue: {
            type: 'string',
            default: "+12%", 
            displayName: 'Change Value',
            tile: '1/2',
            ai_instructions: 'optional short stirng, can represent change in value'
        }, 
        changeColor: {
            type: 'colors',
            options: ['none', 'primary', 'primary-content', 'accent', 'accent-content', 'base-content', 'base-500', 'base-700', 'base-900'
            ,'success-content', 'warning-content', 'warning-content', 'error-content', 'info-content'],
            displayName: 'Change Color',
            default: 'none'
        },
        helpText: {
            type: 'string',
            displayName: 'Help Text',
            default: null,
            ai_instructions: 'Helpful text no more than 40 characters', 
        },
        
        
        textSize: {
            type: 'size',
            options: ['small', 'medium'],
            displayName: 'Text Size',
            ai_instructions: 'small sets title, price to text-sm and description to text-xs, medium sets title and price to text-base and description to text-sm',
            default: 'small',
            tile: '1/2'
        },
        icon: {
            type: 'icon',
            options: ['none', ...allIconNames],
            displayName: 'left icon',
            ai_instructions: 'icon that appears on the left side of the card to illustrate',
            default: 'chart-up'
        },
        iconColor: {
            type: 'colors',
            options: ['none', 'primary', 'primary-content', 'accent', 'accent-content', 'base-content', 'base-500', 'base-700', 'base-900'
            ,'success-content', 'warning-content', 'warning-content', 'error-content', 'info-content'],
            
            displayName: 'Icon Color',
            default: 'none'
        },
        

    }
};
