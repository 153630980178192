import React, { useContext, useState, useEffect, useRef } from 'react';
import { EditorContext } from '../../EditorContext';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import * as IconoirIcons from 'iconoir-react';
import FlowTab from './FlowTab';
import { deleteFolder, duplicateFolder } from '../folderActions';



export default function FolderTab({ folder, pages, onAddPage, isOnlyFolder, sidebarOpen, tooltipStyle }) {
  
  const { setSelector, selector, handleAction, view, setView } = useContext(EditorContext);
  const isSelected = selector.folder?.id == folder.id && view.inOverview == 'folders';

  // isSelected && console.log('pages in folder', pages.map(p=>p.name))

  
  const [folderOpen, setFolderOpen] = useState(isSelected);

  function handleSelect(e) {
    e.stopPropagation();
    // Find the page with the lowest index in the folder
    const selectPage = pages.length > 0 
      ? pages.reduce((lowest, page) => (!lowest || page.index < lowest.index) ? page : lowest) 
      : null;
  
    // Assuming you have a method to get frames from a page
    let selectFrame = null;
    if (selectPage) {
      // This is placeholder logic. Replace it with your actual method of retrieving frames
      const frames = selectPage.frames || []
      selectFrame = frames && frames.length > 0 
        ? frames.reduce((lowest, frame) => (!lowest || frame.index < lowest.index) ? frame : lowest) 
        : null;
    }
  
    // Update the selector with the selected folder, page, and frame
    setSelector({ 
      ...selector, 
      folder: folder, 
      page: selectPage, 
      frame: selectFrame, 
      object: null
    });
    
    setView({...view, showAllFolders: true, inOverview: 'folders'})
  }

const {
  attributes,
  listeners,
  setNodeRef,
  transform,
  transition,
} = useSortable({ id: folder.id });

const style = {
  transform: CSS.Transform.toString(transform),
  transition,
};

  function updateName(name) {
    const action = {
      type: 'UPDATE_FOLDER',
      folder: { ...folder, name },
      
    };
    handleAction(action);
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  )

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      // Logic to reorder frames
      const movingPage = pages.find(f => f.id == active.id)
      const overPage = pages.find(f => f.id == over.id)
      console.log(movingPage.index, 'to', overPage.index)
      if (movingPage && overPage && movingPage != overPage) {
        const action = {
          type: 'RELOCATE_PAGE', 
          page: movingPage, 
          newIndex: overPage.index
        }
        handleAction(action)
      } 
    }
    
  }
  function toggleOpen(e) {
    e.stopPropagation();
    setFolderOpen(!folderOpen)
  }

  const [isEditingName, setIsEditingName] = useState(false);
  return (
    <div  ref={setNodeRef} id={folder.id} style={{...style}}  {...attributes} {...listeners}
    
    >
        
        <div className={`flex flex-row items-center relative group text-xs gap-1 justify-start w-full 
        px-1 transition-all duration-75  rounded py-0.5 select-none cursor-default overflow-visible
        ${isSelected && view.showAllFolders ? 'font-medium bg-slate-100' : 'font-normal  hover:bg-slate-100'}
        `}
        
        onClick={(e)=>handleSelect(e)} 
        > 
          <div className={`p-0.5 rounded  flex-shrink-0 ${isSelected && 'text-gray-800'}`} >
            <IconoirIcons.Folder height={16} width={16} className={`scale-[0.8] stroke-[2px]
            ${isSelected ? 'opacity-100' : 'opacity-70 group-hover:opacity-100'}`}/>
          </div> 
          {<span className={`${tooltipStyle} `}>
                {folder.name}
          </span>}
          <div className={`${sidebarOpen ? 'hidden md:flex md:flex-row' : 'hidden'}`}>
          <FolderName 
            currentName={folder.name} 
            onBlur={updateName} 
            isEditingName={isEditingName}
            setIsEditingName={setIsEditingName}
          />
          </div>
          {!isEditingName &&
          <>
          <div className={`${sidebarOpen ? 'hidden md:flex md:flex-row  p-0.5' : 'hidden'}`} onClick={toggleOpen} >
            <IconoirIcons.NavArrowRight height={12} width={12} className={`stroke-[2px] -ml-1.5 opacity-60 ${folderOpen && pages?.length > 0 ? 'rotate-90' : ''}`}/>
          </div>
          
          <Options 
            onDelete={isOnlyFolder ? () => {} : ()=>deleteFolder({folder, handleAction})}
            onDuplicate={()=> duplicateFolder({folder, pagesToCopy: pages, handleAction})}
            onRename={()=> setIsEditingName(true)}
            isOnlyFolder={isOnlyFolder}
            sidebarOpen={sidebarOpen}
            onAddPage={(folderId)=>{onAddPage(folderId); setFolderOpen(true)}}
            folder={folder}
          />
          </>
          }

          
        </div>
      
        {folderOpen && pages?.length > 0 && 
      <div className={`${sidebarOpen ? 'hidden md:flex md:flex-col pl-0 gap-1 py-1 cursor-default' : 'hidden'} `}>
      <DndContext
      
      onDragEnd={handleDragEnd}
      collisionDetection={closestCenter}
      sensors={sensors}
      
    >
      <SortableContext
        items={pages}
        strategy={verticalListSortingStrategy}
      >
      {pages
        .sort((a, b) => a.index - b.index)
        .map((page) => (
        <FlowTab 
          key={page.id} 
          page={page} 
          folder={folder}
          isOnlyFlow={pages.length === 1}
          />
      ))}
      </SortableContext></DndContext>
      </div>
      }
      
      
    </div>
  );

}


function Options({
  isOnlyFolder, 
  onDelete,
  onRename, 
  onDuplicate,
  onAddPage,
  sidebarOpen,
  folder
}) {

  const tooltipStyle = `absolute top-1/2 -right-2 bg-gray-800 text-white transition-all duration-75 whitespace-nowrap text-2xs font-nomrmal
  translate-x-3/4 -translate-y-1/2 px-2 py-0.5 rounded opacity-0 group-hover:opacity-100 group-hover:translate-x-full`

  const [ showSubmenu, setShowSubmenu ] = useState(false);

  const myRef = useRef();
  // close on outside click
  useEffect(() => {
    function handleClickOutside(event) {
      if (myRef.current && !myRef.current.contains(event.target)) {
        setShowSubmenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [myRef]);

  const buttonStyle = 'w-full py-1 px-2 hover:bg-slate-100 rounded active:bg-slate-200 transition-all duration-75 whitespace-nowrap text-left'

  const buttons = [
    // {text: 'Rename', action: ()=>onRename()},
    // {text: 'Add Flow Inside', action: ()=>{}},
    {text: 'Duplicate', action: onDuplicate},
    {text: 'Rename', action: onRename},
    {text: 'Delete', action: onDelete}
  ]

if (sidebarOpen) return (
<div className={`absolute right-1 top-1/2 -translate-y-1/2  hidden md:flex md:flex-row gap-2 items-center transition-all duration-150
${showSubmenu ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'} `}
ref={myRef}
>
<button className={`p-0.5 rounded hover:scale-110 transition-all duration-75
${showSubmenu ? 'bg-slate-200' : 'hover:bg-slate-200 bg-transparent'}`}
title='More options'
onClick={(e)=> {e.stopPropagation(); setShowSubmenu(!showSubmenu)}}  >
  <IconoirIcons.MoreHoriz height={16} width={16} />
</button>

<button className={`p-0.5 rounded hover:scale-110 transition-all duration-75 hover:bg-slate-200 bg-transparent`}
title='Add flow inside'
>
  <IconoirIcons.Plus onClick={()=>onAddPage(folder.id)} height={16} width={16} className='hover:scale-110' />
</button>
  {showSubmenu &&
    <div className='absolute top-1/2 -translate-y-1/2 -right-2 translate-x-full
    p-1 rounded ring-[0.5px] ring-slate-300 min-w-24 flex flex-col
    bg-white transition-all duration-75 text-2xs font-normal' 
    
    >
      {buttons.map((button, index) =>
        <button key={index} className={`${buttonStyle} 
        ${button.text === 'Delete' && isOnlyFolder && 'opacity-50 cursor-not-allowed'}
        `} 
        onClick={button.action}>
          {button.text}
        </button>
      )}
      
    </div>
  }
</div>
)
}



function FolderName({ currentName, onBlur, isEditingName, setIsEditingName }) {

  const [name, setName] = useState(currentName);

  useEffect(() => {
    setName(currentName);
  }, [currentName]);

  const handleBlur = () => {
    if (name.trim().length === 0) {
      setName(currentName); // Resetting to the original name if empty
    } else {
      setIsEditingName(false);
      onBlur(name);
    }
  };

  return isEditingName ? (
    <input
      type="text"
      value={name}
      onChange={(e) => setName(e.target.value)}
      onBlur={handleBlur}
      autoFocus
      onKeyDown={(e) => e.key === 'Enter' && handleBlur()}
      className="bg-transparent focus:bg-white placeholder-gray-700 text-gray-800 focus:text-gray-900
                 select-none cursor-default max-w-full px-1
                 py-0.5 overflow-hidden whitespace-nowrap ellipsis truncate transition-all"
      style={{
        userSelect: 'auto',
        textAlign: 'left'
      }}
    />
  ) : (
    <div
      onDoubleClick={(e) => { e.stopPropagation(); setIsEditingName(true); }}
      className="bg-transparent focus:bg-white placeholder-gray-700 focus:text-gray-900
                 select-none cursor-default max-w-full px-1
                 py-0.5 overflow-hidden whitespace-nowrap ellipsis truncate transition-all"
      style={{ userSelect: 'none' }}
    >
      {name}
    </div>
  );
}
