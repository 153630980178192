import PropTypes from 'prop-types';

export default function Module(props) {

    const { module_name, background, corners, hasOutline, flexDirection, gap, alignItems, justifyContent, width, height, paddingX, paddingY, maxWidth, minWidth, maxHeight, minHeight, children, attributes, listeners} = props
    const { junoAttributes, junoListeners, dndProps, showTag, setRefs, self, outlineStyle, eventListeners} = props.junoProps || {} /* remove line */

    
    const bgStyles = background && background != 'none' ? `bg-${background} ` : ' ';
    const paddingStyles = `${paddingX ? `px-${paddingX}` : ''} ${paddingY ? `py-${paddingY}` : ''}`;
    const widthStyles = `w-${width}`;
    const cornerStyles = corners === 'none' ? '' : `rounded-${corners}`;
    const heightStyles = `${height}`;
    const gapStyles = gap === 'none' ? '' : `gap-${gap}`;
    const alignItemsStyles = alignItems ? `items-${alignItems}` : '';
    const justifyContentStyles = justifyContent ? `justify-${justifyContent}` : '';
    const borderStyles = hasOutline ? 'border border-base-300 ' : ' ';

    let classes = `flex ${bgStyles}${flexDirection} ${borderStyles} ${cornerStyles} ${paddingStyles} ${widthStyles} ${gapStyles} ${alignItemsStyles} ${justifyContentStyles} ${heightStyles}`
    classes += ` ${outlineStyle}` /* remove line */

    const inLineStyles = {
        maxWidth: maxWidth, 
        minWidth: minWidth,
        maxHeight: maxHeight,
        minHeight: minHeight
    };

    return (
        <div 
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
        className={classes} style={inLineStyles}>
        {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>module: {module_name}</div> /* remove line */}
        {children}
        </div>
    );
}


Module.propTypes = {
    background: PropTypes.oneOfType([
        PropTypes.oneOf(["base-0", 'base-50', "base-100", "base-200", "primary", "accent", "base-900", "none"]),
        PropTypes.string]),
    paddingX: PropTypes.oneOf(["0", "sm", "base", "md", "lg", 'xl', '2xl']),
    paddingY: PropTypes.oneOf(["0", "sm", "base", "md", "lg", 'xl', '2xl']),
    flexDirection: PropTypes.oneOf(["flex-col", "flex-row"]),
    gap: PropTypes.oneOf(["none", "xs", "sm", "base", "md", "lg", "xl", '2xl']),
    alignItems: PropTypes.oneOf(["start", "center", "end", "stretch"]),
    justifyContent: PropTypes.oneOf(["start", "center", "end", "between", "around", "evenly"]),
    width: PropTypes.oneOf(["full", "auto", "1/2", "1/3", "1/4", "2/3", '3/4']),
    height: PropTypes.oneOf(['h-full', 'h-auto', 'h-1/2', 'h-1/3', 'h-1/4', 'h-2/3', 'h-[integer]px']),
    maxWidth: PropTypes.number,
    minWidth: PropTypes.number,
    maxHeight: PropTypes.number,
    minHeight: PropTypes.number,
    corners: PropTypes.oneOf(["none", "sm", "md", "lg", 'xl', '2xl', "full"]),
    hasOutline: PropTypes.bool,
    module_name: PropTypes.string,
    module_role: PropTypes.string,
    children: PropTypes.node
};

Module.defaultProps = {
    module_name: 'module name',
    background: 'none',
    module_role: 'module purpose',
    paddingX: 'base',
    paddingY: 'sm',
    flexDirection: 'flex-col',
    gap: 'base',
    alignItems: 'start',
    justifyContent: 'start',
    width: 'full',
    height: 'h-auto',
    maxWidth: null,
    minWidth: null,
    maxHeight: null,
    minHeight: null,
    corners: 'none',
    hasOutline: false
};

/* ignore rest */

Module.definitions = {
    apiName: "Module",
    displayName: "Module",
    description: "Modules contain groups of primitives and serve a function on the page. The",
    ai_instructions: 'modules can only be pasted into sections. modules serve purpose on the page',
    type: 'container',
    relativeSize: 'various',
    acceptedChildren: 'everything except layouts, sections, dialogs',
    propDefinitions: {
        background: {
            type: "colors",
            options: ["base-0", 'base-50', "base-100", "base-200", "primary", "accent", "base-900", "none"],
            displayName: "Background",
            default: "none"
        },
        paddingX: {
            type: "padding",
            options: ["0", "sm", "base", "md", "lg", 'xl', '2xl'],
            displayName: "Padding X",
            ai_instructions: 'adds padding on left and right. in addition to overall padding property',
            default: 'base'
        },
        paddingY: {
            type: "padding",
            options: ["0", "sm", "base", "md", "lg", 'xl', '2xl'],
            displayName: "Padding Y",
            ai_instructions: 'adds padding on top and bottom. in addition to overall padding property',
            default: 'sm'
        },
        flexDirection: {
            type: "direction",
            options: ["flex-col", "flex-row"],
            displayName: "Direction",
            default: "flex-col", 
            required: true
        },
        gap: {
            type: "gap",
            options: ["none", "xs", "sm", "base", "md", "lg", "xl", '2xl'],
            displayName: "Gap",
            default: "base"
        },
        corners: {
            type: "corners",
            options: ["none", "sm", "md", "lg", 'xl', '2xl', "full"],
            displayName: "Corners",
            default: "none"
        },
        alignItems: {
            type: "alignItems",
            options: ["start", "center", "end", "stretch"],
            default: "start", 
            required: true
        },
        justifyContent: {
            type: "justifyContent",
            options: ["start", "center", "end", "between", "around", "evenly"],
            default: "start",
            required: true
        },
        width: {
            type: "width",
            options: ["full", "auto", "1/2", "1/3", "1/4", "2/3", '3/4'],
            displayName: "Width",
            default: "full"
        },
        height: {
            type: 'oneOf',
            options: ['h-full', 'h-auto', 'h-1/2', 'h-1/3', 'h-1/4', 'h-2/3', 'h-[integer]px'],
            default: 'h-auto', 
            ai_instructions: 'for custom height use "h-[integer]px" syntax but replace integer with pixel height, e.g. "h-[200px]", i.e. follow tailwindcss utilities approach'
        },
        maxWidth: {
            type: "number",
            default: null
        },
        minWidth: {
            type: "number",
            default: null
        },
        maxHeight: {
            type: "number",
            default: null
        },
        minHeight: {
            type: "number",
            default: null
        },
        
        hasOutline: {
            type: "bool",
            displayName: "Outline",
            default: false, 
            ai_instructions: 'adds base-300 1px outline',
        },
        
    }
};
