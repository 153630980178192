import React from 'react'
import PropTypes from 'prop-types'
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */

export default function ProgressSemiCircle(props) {
    
    const {
        label = 'label',
        circleRadius = '48px',
        color = 'info-content',
        progress = '50%',
        showProgressValue = true,
        attributes,
        listeners
      } = props;
      
    const { junoAttributes, dndProps, showTag, setRefs, self, outlineStyle, eventListeners, onPropertyUpdate=()=>{}, preview } = props.junoProps || {} /* remove line */
    
    const radius = parseInt(circleRadius, 10);
    const strokeWidth = {
        '32px': 8,
        '48px': 10,
        '60px': 12,
        '80px': 14
    }[circleRadius] || 10;
    
    const normalizedRadius = radius - strokeWidth / 2;
    const circumference = normalizedRadius * Math.PI;

    const progressValue = parseInt(progress, 10);

    const fontSizeMap = {
        '32px': 'text-base',
        '48px': 'text-lg',
        '60px': 'text-xl',
        '80px': 'text-2xl'
    }
    const labelFontSizeMap = {
        '32px': 'text-sm',
        '48px': 'text-sm',
        '60px': 'text-sm',
        '80px': 'text-base'
    }

    let wrapperClasses = `flex flex-col items-center gap-1`
    wrapperClasses += ` ${outlineStyle}` /* remove line */

    return (
        <div   
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
                className={wrapperClasses}
             >
            {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Semi Circle</div> /* remove line */}
            <div style={{ position: 'relative', width: radius * 2, height: radius + strokeWidth / 2, overflow: 'hidden' }}>
            
            {/* Semicircle */}
            <svg height={radius * 2} width={radius * 2} style={{ transform: 'rotate(180deg)' }}>
                    <circle 
                            stroke="currentColor"
                            style={{ strokeOpacity: 0.08 }}
                            fill="transparent"
                            strokeWidth={strokeWidth}
                            strokeDasharray={`${circumference} ${circumference}`}
                            r={normalizedRadius}
                            cx={radius}
                            cy={radius}
                            strokeLinecap="round" />
                     <circle 
                        stroke={`var(--${color})`}
                        fill="transparent"
                        strokeWidth={strokeWidth}
                        strokeDasharray={`${((progressValue)/ 100) * circumference} ${circumference - ((progressValue - strokeWidth) / 100) * circumference}`}
                        r={normalizedRadius}
                        cx={radius}
                        cy={radius}
                        strokeLinecap="round" />
                </svg>

            {/* Label */}
            <div className={`flex flex-col items-center justify-center leading-tight font-semibold absolute bottom-0 left-1/2 -translate-x-1/2`}>
                {radius >= 60 && label ? <h3 className={`whitespace-nowrap text-center font-normal ${labelFontSizeMap[circleRadius]}`} style={{ maxWidth: radius * 1.2 }}>
                {<EditableOnDoubleClick text={label} preview={preview} whiteSpace='nowrap' classes='overflow-hidden' onBlur={onPropertyUpdate('label')}/> /* replace to '{label}' */}
                </h3> 
            : null}
                {showProgressValue && <span className={`${fontSizeMap[circleRadius]}`}>{progress}</span>}
            </div>
            </div>
            {radius < 60 && label ? <h3 className={`whitespace-nowrap font-normal ${labelFontSizeMap[circleRadius]}`}>
                {<EditableOnDoubleClick text={label} preview={preview} whiteSpace='nowrap' onBlur={onPropertyUpdate('label')}/> /* replace to '{label}' */}
            </h3> : null}
        </div>
    );
}

ProgressSemiCircle.propTypes = {
    label: PropTypes.string,
    circleRadius: PropTypes.oneOf(['32px', '48px', '60px', '80px']),
    color: PropTypes.oneOf(['info-content', 'primary', 'accent', 'success-content', 'base-content', 'warning-content', 'error-content']),
    progress: PropTypes.oneOf(['0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%']),
    showProgressValue: PropTypes.bool,
};

/* ignore rest */

ProgressSemiCircle.defaultProps = {
    label: 'label',
    circleRadius: '48px',
    color: 'info-content',
    progress: '50%', 
    showProgressValue: true
};

ProgressSemiCircle.definitions = {
    apiName: 'ProgressSemiCircle',
    displayName: 'Progress Semi Circle',
    description: '',
    ai_instructions: '',
    type: 'indicators',
    relativeSize: 'small',
    acceptedChildren: 'none',
    propDefinitions: {
        label: {
            type: 'string',
            displayName: 'Text',
            default: 'label'
        },
        circleRadius: {
            type: 'oneOf',
            options: ['32px', '48px', '60px', '80px'],
            default: '48px', 
            displayName: 'Radius', 
            tile: '1/2'
        },

        progress: {
            type: 'oneOf',
            displayName: 'Progress',
            options: ['0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'],
            default: '50%', 
            tile: '1/2'
        }, 
        color: {
            type: 'colors',
            options: ['info-content', 'primary', 'accent', 'success-content', 'base-content', 'warning-content', 'error-content'],
            displayName: 'Color',
            default: 'info-content'
        },
        showProgressValue: {
            type: 'bool',
            displayName: 'Show Progress Value',
            default: true
        }
    }
};
