import { TabGroup } from "../../../../ui-kit/exports/react";

export default function Controls({
  currentTab, setCurrentTab, tabs
}) {

    const handleTabSelect = (tabName) => {
        setCurrentTab(tabName);
    }

    return (
        <>
            <div className={`flex flex-col pl-5 px-2 w-full justify-between items-start transition-all -mt-2 mb-2`}>
                <div className="flex flex-row gap-2 h-full items-end text-xl font-semibold mb-3">
                    Design System
                </div>
                <div className="flex flex-row gap-2 items-center w-full ">
                    <TabGroup
                        tabs={tabs}
                        selectedTab={currentTab}
                        onSelect={handleTabSelect}
                        size='medium'
                        underlineAll={false}
                    />
                </div>
            </div>   
        </>
    )
}
