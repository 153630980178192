import React, { useContext, useRef, useEffect, useState } from 'react';
import { EditorContext } from '../EditorContext';
import Viewport from './Viewport';
// import ViewportPan from './ViewportPan';
import DnDContext from '../dragNdrop/DnDContext';
import { usePanning } from './helpers/PanningFunctions';
import "../../../styles/highlighters.css";


export default function Canvas() {
  const { selector, view, setSelector, panState, setPanState } = useContext(EditorContext);
  
  const canvasRef = useRef(null);
  

  const mode = view.mode
  usePanning({ref: canvasRef, panState, setPanState, mode});

  const [currentWidth, setCurrentWidth] = useState(0); 
  const [currentHeight, setCurrentHeight] = useState(0);
  const [currentFit, setCurrentFit] = useState(false)

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      let { width, height } = entries[0].contentRect;
      
      const roundedWidth = Math.round((width) * 10) / 10;
      const roundedHeight = Math.round(height * 10) / 10;
      
      //console.log('resizeObserver', roundedWidth, roundedHeight);
      if (currentWidth === roundedWidth && currentHeight === roundedHeight && currentFit === panState.fitCanvas) return;
  
      currentFit !== panState.fitCanvas && setCurrentFit(panState.fitCanvas);
        
      setCurrentWidth(roundedWidth); 
      setCurrentHeight(roundedHeight);
        setPanState({
          ...panState,
          canvasDimensions: { width: roundedWidth, height: roundedHeight }
        });
    });
  
    const currentRef = canvasRef.current;
    if (currentRef) {
      resizeObserver.observe(currentRef);
    }
    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
      resizeObserver.disconnect();
    };
  }, [currentWidth, currentHeight, currentFit, panState.fitCanvas]); 
  
  const {handleDragStart, handleDragOver, handleDragEnd} = useContext(DnDContext)
  
  const dndOverObject = {onCanvas: true}
  const dndListeners = {
      onDragStart: (event) => {event.stopPropagation(); handleDragStart(event, dndOverObject, canvasRef)},
      onDragOver: (event) => {event.stopPropagation(); handleDragOver(event, dndOverObject, canvasRef)},
      onDragEnd: (event) => {event.stopPropagation(); handleDragEnd(event, dndOverObject, canvasRef)},
  };
  
  
  
  return (
    
    
            <div 
              onClick={()=>setSelector({...selector, object: null, section: null})}
              className={`flex flex-col relative  w-full justify-start overflow-hidden
              flex-grow py-2 pl-3 pr-1 ${!panState.fitCanvas ? 'bg-slate-200 rounded-md' : 'bg-transparent'} `}
              ref={canvasRef}
              {...dndListeners}
              id='juno-canvas'
              style={{ 
                height: `100%`,
                boxSizing: 'border-box',
                maxWidth: '100%',
                maxHeight: '100%',
                zIndex: 0,
                animation: 'fadeInGrow 150ms ease'
                }}>
              
              {<Viewport panState={panState} />}
              
            </div>
            
    
  );
  }

  //rounded-lg overflow-hidden 