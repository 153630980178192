import Color from 'color';
import { 
    baseColorPresets, 
    functionColorsPresets, 
    primaryColorDefault, 
    accentColorDefault,
    cornersPresets, 
} from './presets';
import { v1 as uuidv1 } from 'uuid';

// functions to convert colors between rgba and HSL
export function changeColorSaturation(hsla, newSaturation) {
    const color = Color(hsla).hsl()
    const updatedColor = color.saturationl(newSaturation);
    const colorString = `hsla(${updatedColor.hue()}, ${updatedColor.saturationl()}%, ${updatedColor.lightness()}%, ${updatedColor.alpha()})`;
    
    // Return the updated HSLA string
    return colorString
}
export function makeDefaultTheme() {
    const defaultTheme = {
    id: uuidv1(),
    display_name: 'Theme Name',
    isDark: false,
    font: 'Inter',
    iconSet: 'iconoir',
    baseColorSet: 'default',
    functionalColorSet: 'default',
    fontPreference: 'default',
    colors: {
        ...primaryColorDefault,
        ...accentColorDefault, 
        ...functionColorsPresets.info, 
        ...functionColorsPresets.success,
        ...functionColorsPresets.warning,
        ...functionColorsPresets.error,
        ...baseColorPresets.default
    }, 
    cornersSet: 'default',
    corners: {
        ...cornersPresets.default
        }
    } 

    return defaultTheme;
}

export function buildThemeObject(theme) {
    const colorVars = Object.entries(theme.colors).map(([key, value]) => ({
        id: uuidv1(),
        api_name: key,
        value: value,
        theme_id: theme.id, 
        type: 'color'
    }))
    const cornerVars = Object.entries(theme.corners).map(([key, value]) => ({
        id: uuidv1(),
        api_name: key,
        value: value,
        theme_id: theme.id, 
        type: 'borderRadius'
    }))

    let themeObject = {
        id: theme.id,
        display_name: theme.display_name,
        is_dark: theme.isDark,
        iconSet: theme.iconSet,
        fontPreference: theme.fontPreference,
        variables: [
            ...colorVars,
            ...cornerVars,
            {   id: uuidv1(),
                api_name: 'mainFont', 
                type: 'fontFamily',
                value: theme.font,
                theme_id: theme.id},

        ]}
    return themeObject;
}

export function convertTheme(theme) {
    const themeObject = {
        id: theme.id,
        display_name: theme.display_name,
        isDark: theme.is_dark,
        font: theme.variables.find(v => v.api_name == 'mainFont').value,
        fontPreference: theme.fontPreference,
        iconSet: theme.iconSet,
        baseColorSet: matchBaseColorSet(theme.variables),
        functionalColorSet: 'default',
        colors: theme.variables.filter(v => v.type == 'color').reduce((acc, cur) => {
            acc[cur.api_name] = cur.value;
            return acc;
        }, {}),
        corners: theme.variables.filter(v => v.type == 'borderRadius').reduce((acc, cur) => {
            acc[cur.api_name] = cur.value;
            return acc;
        }, {}),
        cornersSet: matchCornersSet(theme.variables), 
    }
    return themeObject;
}



function matchBaseColorSet(variables) {
    // Create an object of base color variables
    const baseColorVariables = variables.reduce((acc, variable) => {
        if (variable.type === 'color' && variable.api_name?.includes('base')) {
            acc[variable.api_name] = variable.value;
        }
        return acc;
    }, {});

    // Compare with presets to find a match
    for (const [presetName, colorSet] of Object.entries(baseColorPresets)) {
        const isMatch = Object.entries(baseColorVariables).every(([key, value]) => 
            colorSet[key] === value
        );

        if (isMatch && Object.keys(baseColorVariables).length === Object.keys(colorSet).length) {
            return presetName; // Return the name of the matching color set
        }
    }

    return 'custom'; // Return 'custom' if no match is found
}

function matchFunctionalColorSet(variables, isDark=false) {
    const functionalColors = ['info', 'success', 'warning', 'error'];
    const colorVars = variables.reduce((acc, variable) => {
        if (variable.type === 'color' && functionalColors.includes(variable.api_name)) {
            acc[variable.api_name] = variable.value;
        }
        return acc;
    }, {});

    const presets = isDark ? functionColorsPresets.dark : functionColorsPresets
    for (const [presetName, colorSet] of Object.entries(presets)) {
        const isMatch = Object.entries(colorVars).every(([key, value]) =>
            colorSet[key] === value
        );

        if (isMatch && Object.keys(colorVars).length === Object.keys(colorSet).length) {
            return presetName;
        }
    }

    return 'custom';
}



function matchCornersSet(variables) {
    // Filter to find borderRadius type variables
    const borderRadiusVariables = variables.reduce((acc, variable) => {
        if (variable.type === 'borderRadius') {
            acc[variable.api_name] = variable.value;
        }
        return acc;
    }, {});

    // Compare with presets to find a match
    for (const [presetName, cornerSet] of Object.entries(cornersPresets)) {
        // Check if every key in the preset exists in borderRadiusVariables and has the same value
        const isMatch = Object.keys(cornerSet).every(key =>
            borderRadiusVariables.hasOwnProperty(key) && cornerSet[key] === borderRadiusVariables[key]
        );

        // Ensure all keys in borderRadiusVariables are present in the preset
        const isCompleteMatch = Object.keys(borderRadiusVariables).every(key =>
            cornerSet.hasOwnProperty(key)
        );

        if (isMatch && isCompleteMatch) {
            return presetName;  // Matching preset found
        }
    }

    return 'custom';  // No match found, return 'custom'
}