import React from 'react';

export default function SelectSize({ propKey, options, currentOption, onSelect }) {
    
    const handleSelect = (value) => {
      onSelect(propKey, value);
  };

  const sizeMapping = {
    'auto': 'auto',
    'xsmall': 'XS',
    'small': 'S',
    'medium': 'M',
    'large': 'L',
  };
  

  return (
    <div 
        className='items-center flex flex-row flex-grow w-full rounded-md p-0.5 bg-base-100 hover:bg-base-200 text-sm transition-all duration-150
        ring-1 ring-base-200
        '
        style={{userSelect: 'none'}}
      >
        {options.map((option) => (
          <button
            key={option}
            className={`w-full pointer text-sm flex items-center justify-center rounded-md py-1  transition-all duration-150 lowercase
            ${currentOption === option ? `bg-base-0 ring-1 ring-base-200 `
            : `opacity-80 hover:opacity-100`}`}
            style={{
              fontWeight: 500,
              flexGrow: 1,
              zIndex: 2
            }}
            onClick={() => handleSelect(option)}
          >
            {sizeMapping[option]}
          </button>
        ))}
      </div>
  );
};
