import { useContext } from "react";
import Template from "../templates/Template";
import DnDContext from "../../../dragNdrop/DnDContext";
import { EditorContext } from "../../../EditorContext";

export default function Results({items=[]}) {

    const { selector, view, effectiveVariables } = useContext(EditorContext);
  const { handleDragStart, handleDragEnd } = useContext(DnDContext);
const frameId = selector.frame?.id

// console.log('results')
  return (
        <div className='w-full flex flex-col gap-2'>
      {items.map(template => (
            <div key={template.id} className='flex flex-col w-full gap-1'>
              <div className='flex flex-col gap-2 w-full bg-slate-50 text-center rounded-md p-2 text-xs items-center justify-center
              border border-slate-100 hover:border-primary transition-all duration-75 text-gray-500'
              >
              <Template 
              
                template={template} 
                effectiveVariables={effectiveVariables} 
                frameId={frameId} 
                view={view} 
                handleDragEnd={handleDragEnd}
                handleDragStart={handleDragStart}
                />
              </div>
  
              <div className='mb-1 flex flex-row gap-1 justify-between w-full font-normal text-xs  opacity-50'>
              <h4 className='capitalize flex-grow truncate'>
                {template.name}
              </h4>
              
              </div>
            </div>
          ))}
        
      </div>
  )
}