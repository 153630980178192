const misc = [

  {
    apiName: 'module_sidebar_search',
    displayName: 'Sidebar Search',
    allowedSections: ['Sidebar'],
    width: 280,
    height: 64,
    ai_template: true,
    ai_instructions: 'module in the sidebar that allows users to search for items within the app',
    thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714840464787.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=oLeX7jvmSyL%2BfPiukrJPAKxHw2mq69%2BjPRdRsINdQF014t0ZwK72zJrq%2Ff1uCSr2Bz4ZUYjynF8YbggQtFM%2BWZcZWbTQq%2B9WtoSS4yX3nTpt8L4l5W86A6oXCoCdezKsffmLrP9Qfg0IX4nH7eReoflpWn%2F4MOWVq%2BnIcuap8ljcK5BCYThr%2FwZTRsO%2FKvNB83X6weEL7HH9mIjOuN4XFso2PJtJieW3Qz3ukYykOxWNzAqm5%2B1srvX5EXbYZtQzwSO3MqJy1yyrNcmilUo7tt0Oyh%2FNNgLucdNPkYrPbkqwaAQ3V4%2FnWCugGuEvgqJ%2Fe4EuiinjA8DqBxR3e1IdOg%3D%3D",
    tileWidth: '100%',
    jsx: `<Module  paddingX="sm">
      <Search size="medium" bgColor="base-0" width="full" hasOutline />
      </Module>
    `
  },
    {
      apiName: 'module_sidebar_home_logo',
      displayName: 'Sidebar Logo',
      allowedSections: ['Sidebar'],
      ai_template: true,
      ai_instructions: 'small module in the sidebar that displays the logo that links to the homepage. usually used on top of the sidebar',
      width: 280, 
      height: 40, 
      thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714840057581.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=IVNxBBOwAmDlEGsz8fUIvcpveD6NRQNHFPeoP9cCitL7rPcJ%2FCC7aZI%2FuzAe4yULZ2EzrArWS%2FXk7jRbvefBbE%2FXgz6%2BwBU%2BaipGVDlhywqWAV%2FU8BEq9dT%2BNZkm80xSFvnUSVAnr69PfT0IAcB5nBaV1ZIt3P9f6yc2QagpghV4kEm%2BMlwXCZhVpKUqa4UXe%2F5c65hlP700QAQsBqB8L3xJIlGqJeo6BP9l07hYHFKQ5hQQy4jYNloJjfLqpG3pIt2j8TAR2%2F1G1gXy56TiqBMsEYaZPfX%2B32q2HTGHnucm1RRScKAPKu0K8RCjkaRTsgH3QzgMuf2VHVOKQY3j%2BA%3D%3D",
      tileWidth: '100%',
      jsx: `<Module >
        <Logo size="24px" type="logo" />
      </Module>`
    },
    {
      apiName: 'module_sidebar_user_info_display',
      displayName: 'User / Profile Menu',
      allowedSections: ['Sidebar'],
      ai_template: true,
      ai_instructions: 'module in the sidebar that shows user avatar and links to profile settings. can be used on top or bottom of the sidebar',
      width: 280,
      height: 52, 
      thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714840110379.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=ir5M50DM%2Fk9SHKdufNTg%2BTQWnJqHexTIrJPisSHygiLBObAJWCLMIUkhgRKZH4tUOq4cskh7S7fS%2FKyKavf7%2BOzo4FOh5o0XuB05QwAdOyfoaMdDYcnIFy9tgHu53121B%2BD9%2BSjxlqecJ6ryEp9Kn1FWIdKJJQVyaYNohe%2FRgNMR0yYO3mwPYYocgRDTHQ95TUzUUZ%2B1fUd%2BIul3N8TQwZuoYUvuu6Ec5AAS0FcO74DAlCqjvXQFg4PvRBpC6Z%2B%2BokCXnB%2Brm0yFtrGQhqj6So1Sly7%2BOf7jSgrQCu8Uam5WxKhDtuSad%2BFU3fIjw6rS%2BS2jcFy56Arxv%2Bo8wBj9Fw%3D%3D",
      tileWidth: '100%',
      jsx: `
      <Module >
      <AvatarCard secondaryText="username@company.com" imageSize="32px" imageSrc="https://images.unsplash.com/photo-1633332755192-727a05c4013d?crop=entropy&cs=srgb&fm=jpg&ixid=M3w0OTQ5NTB8MHwxfHNlYXJjaHwyfHxoZWFkc2hvdHxlbnwwfHx8fDE3MTM2MzQ0MTB8MA&ixlib=rb-4.0.3&q=85" />
      </Module>  `
    },
    {
      apiName: 'module_sidebar_button',
      displayName: 'Action Button',
      allowedSections: ['Sidebar'],
      ai_template: false,
      width: 280,
      height: 52, 
      thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714840169616.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=DjTB%2FtRF1JB9lwzA9Z8Oe3RWZ1jJloXX%2BUUh3wqTwmFfRNLYASrBE%2FyXR%2FOvYPcIhOLrA1EKfH6iod%2B%2ByMRdYNEFlKoXhFp3uATFbE7iUzYuUK%2BPDjXsuADPxm9pXzxL3K1FXFyod1ekuTLjoAh9MwSJp6PRcJoVr6giu31LITeZKg6td8wsZCSntx%2BImXpx700Yz7eZlK%2BGW3niBLzXSXtTSbaocjJkM7tX9FYHIZsjhtC4Y%2Fm3lUIbMsx3EHijPDYmYGH0dm7IOUicoQeEnMWjBq3icVr7hphLjFQVnKrrBlPtmTkUvjT7Wy5B%2FV7ojxJrHxj8Kk8P%2FldvwoHZvg%3D%3D",
      tileWidth: '100%',
      jsx: `
        <Module >
          <Button size="small" text="Add Record" width="full" leftIcon="Plus" />
        </Module>`
    }
]

const nav = [
    {
      apiName: 'module_sidebar_navigation_links',
      displayName: 'Navigation Links Module',
      allowedSections: ['Sidebar'],
      ai_template: true,
      ai_instructions: 'primary module in the sidebar that displays navigation links to different parts of the app',
      width: 280,
      height: 280, 
      tileWidth: '100%',
      thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714839951257.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=Rz3fFl50p3qU%2Bqu592rchuko0%2FBXSv%2Fh%2BkY5%2Fr%2ByqSviTv6CZI%2FCbb82BKs%2FilO8EgmL%2FcEEosezX3gXg25tFmpfuBMg967CU0HmirrHsoSRUuvlJAt5OdeO7bncLPrmwIohInRjFocfbA%2BM7C0PfR2cADq%2F2%2BUsJDBdB7WHXNELBZgGROr4w2i6sih9IqzBtszMJGaVcRBlUPLpYcvXdA1wDBD9%2Bbo%2F4ztnJ%2BMrWhvjrJBGibsEplyOOAt0R0jNBI2ap22CjEc%2Fhpn3Iw5CQQaw4SBQkeFEwYfoeaWbnzQ7UFAzx5lIbjhWWu2%2FHmjbh8KBrOyId%2FWCK1xlGm05pw%3D%3D",
      jsx: `
        <Module paddingY="sm" gap="sm"  height="h-full">
          <SidebarLink text="Home" fontWeight="normal" size="medium" leftIcon="Home" rightIcon="none" width="full" indentLevel="none" />
          <SidebarLink text="Dashboard" fontWeight="normal" size="medium" leftIcon="Dashboard" rightIcon="none" width="full" indentLevel="none" />
          <SidebarLink text="Report" leftIcon="Reports" width="full" rightIcon="none" fontWeight="normal" />
          <SidebarLink text="Monitoring" leftIcon="Activity" rightIcon="none" width="full" fontWeight="normal" />
          <SidebarLink text="Members" fontWeight="normal" size="medium" leftIcon="PeopleTag" rightIcon="none" width="full" indentLevel="none" />
          <SidebarLink text="Settings" fontWeight="normal" size="medium" leftIcon="Settings" rightIcon="none" width="full" indentLevel="none" />
        </Module>
      `
    },
    {
      apiName: 'module_sidebar_folder',
      displayName: 'Sidebar Folders',
      allowedSections: ['Sidebar'],
      width: 280,
      height: 120,
      ai_template: true,
      ai_instructions: 'secondary navigation module in the sidebar that can display nested navigation links such as folders and subfolders',
      tileWidth: '100%',
      thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714840406537.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=FVPUSP6vmZoGAeP0U5OIIM8nSR3gaTd4H4gbk%2FlGJYBszPCUHcekZ9eG%2BtmouPZk7ZrWA8p8SZKthFhH8aLeas7xsHXEq0Z7%2Ba522GCCqf0IMdwUnhlThjKbTVSRtAq0fHYiUlTMzpZQsShzkpJeclyq1fMTT3pKW4BmPVLJYaK1L8Rkx2ce1Xfq%2BXuD0qs4Wk5vte%2FXG0QnUfrGC0k%2BPXY9EU4UI9K6wErhssGxqgQjGMyRowSa2ys7GOHVOFMbELmdn3AChWSqaQxZcixcpTievgOYIJGTnwl22pJs5pZIiZ6LkVgBYILBmW1tEuYgcnJZLZToIzpnybXkAtjMBg%3D%3D",
      jsx: `
        <Module paddingY="sm" gap="sm" >
          <SidebarLink text="FOLDERS" fontWeight="normal" size="small" leftIcon="none" rightIcon="Plus" width="full" indentLevel="none" />
          <SidebarLink text="Folder" fontWeight="normal" size="medium" leftIcon="Folder" rightIcon="none" width="full" indentLevel="none" />
          <SidebarLink text="Folder" fontWeight="normal" size="medium" leftIcon="Folder" rightIcon="none" width="full" indentLevel="none" />
        </Module>
      `
    },
    {
      apiName: 'module_sidebar_settigs_support_combo',
      displayName: 'Settings & Support',
      allowedSections: ['Sidebar'],
      width: 280,
      height: 82,
      ai_template: true,
      ai_instructions: 'secondary navigation module in the sidebar that can display nested navigation links such as folders and subfolders',
      tileWidth: '100%',
      thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714840313071.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=yopbOE%2FMVI2QoS%2B2HYvzbgUrKvnDuGYFjZp7gthicMx%2FiXdrL%2Fj5IKu9RsaKrYpfTcD0NZmqU%2Fpip7BxyuPnQlRoG23tCQzWRteqHNS5C7oiU2gSZIPjqbrAdgjSBGKZT6V9f%2FtT8jwiTMsSJJuJK1nX4Qffv6YFUBu5Z22xtdWie75G59L3sZbqaPsB4jQcuG8V6R53Tihw5DZV5iB4U9EHUN8eBmXd0Ts1a%2B7pcgxJ3SBhrBOe9eVqQtBKS0lgrYozBspK%2BmdEgOIIxoaYK3448tW6bMA%2FOq0r08mV9ah0IAOuS%2FGmPpGOghDEPkL3%2BY85fx387j34DbxzYYBSYQ%3D%3D",
      jsx: `
      <Module paddingY="sm" gap="sm" height="h-auto" alignItems="start" justifyContent="end">
        <SidebarLink text="Settings" fontWeight="normal" size="medium" leftIcon="Settings" rightIcon="none" width="full" indentLevel="none" />
        <SidebarLink text="Support" fontWeight="normal" size="medium" leftIcon="Lifebelt" rightIcon="none" width="full" indentLevel="none" />
      </Module>`
    },
    {
      apiName: 'module_sidebar_filters',
      displayName: 'Search Filters',
      allowedSections: ['Sidebar'],
      width: 280,
      height: 208,
      ai_template: true,
      ai_instructions: 'module in the sidebar that allows users to filter search results',
      thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1715191016899.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=vhRlBvXCsPVsYsT%2Fwb1D9TvGPPMd8Akd0XtfmnHb1n6zh%2F6e1oSOHPKX4Ul4pvxOF%2F41ahYS7tNOacV%2B4cg2LkaLaKEI5SB9k5Qf7JB0%2BNCEFj8BE78XuuTzmnnzjW1LO3%2FMqdovrPyuxb9L6ijlG2dBokzlfngweZNDRUmctc9ezQ7Nl2gsyEC0yKEHL1nwW3t7eplnp0JeDBwLjgDYwditMHw6ZEoGhT4JJRl4dBa5qN7nR9%2Bg89h8rt8JqvV195HElEE8ELpP4wM791jj6TUJ8gQt2E0NNG7trA2Ov1lIvcbq4%2F%2FfjhHaGpYDaLh2Fi3pQ1IR5%2BMCrZMoyUVSqQ%3D%3D", 
      jsx: `<Module paddingY="sm" gap="md" height="h-auto">
      <Heading text="Filters" textSize="md" />
      <FlexBox direction="flex-col" padding="none" paddingY="none" paddingX="md">
        <Checkbox type="standard" label="Filter 1" />
        <Checkbox type="standard" label="Filter 2" />
        <Checkbox type="standard" label="Filter 3" />
        <Checkbox type="standard" label="Filter 4" />
        <Checkbox type="standard" label="Filter 5" />
      </FlexBox>
    </Module>`
    }]
const info =  [ 
    {
      apiName: 'module_sidebar_info_card',
      displayName: 'Info Card',
      allowedSections: ['Sidebar'],
      width: 280,
      height: 264,
      ai_template: true,
      ai_instructions: 'module in the sidebar that displays info card with header, description and action',
      thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1715533339531.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=4gSy29qY1rQ6lK9zw2a7p77gJ%2Bdxzg19vCBMMsqI6haKv8%2BH%2BLPrYEh6ljBvt4RaXQ4FQEnYaugaM3%2BB91vtG2RjVszS7TDV3u2eEpmn0cHq%2FY6XiybUteHMRW1khgmXb048i%2Ba64NWp3oxztC4%2Bl6K9hdblJNu6YnV3kxB0b%2Fw9N14123XJk8UbOVLDRm2GX%2FlyVcE1xWhlDPGJOC%2BDEO%2BHKXcVp90PIrIqqsEN8kPfoovO%2Bmw7x9Ob7aEPR2e60lCKzTc8iWK4ow2b5B6mNzHeAu9BNLvIPwyc3v8og7b9%2F%2BkABuksCkrxSML7IhNe35dj%2B4pkb%2BhIGgWHd6mALQ%3D%3D",
      jsx: `<Module >
            <FlexBox direction="flex-col" hasOutline>
              <IcoNoirIcon name="Presentation" size="32" />
              <Heading text="Title" textSize="md" />
              <Paragraph text="This is a short paragraph with around 120 characters, ideal for brief descriptions or introductory text." />
              <Button type="secondary" text="Primary" size="small" width="full" />
            </FlexBox>
            </Module>`
    }
  ];
  
  
  export const sidebarModules = [
    ...nav.map(m => ({ ...m, category: 'sidebarModules', displayName: 'Navigation' })),
    ...info.map(m => ({ ...m, category: 'sidebarModules', displayName: 'Info' })),
    ...misc.map(m => ({ ...m, category: 'sidebarModules', displayName: 'Miscellaneous' }))
  ]