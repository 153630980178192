export const sidebars = [
    {
      apiName: 'sidebar_sample_left',
      displayName: 'Sample Sidebar',
      rootComponent: 'Sidebar',
      category: 'navigation',
      subcategory: 'sidebar',
      description: 'Displays a sidebar with a logo, user avatar, and navigation links.',
      width: 280, // Adjust the width as per your layout requirements
      height: 632, // Height will depend on the content
      ai_template: true,
      thumbnail: "https://firebasestorage.googleapis.com/v0/b/juno-v1.appspot.com/o/Screenshot%202024-04-25%20at%201.17%201.jpg?alt=media&token=b02f29b9-7b0f-4085-8c12-0e9c7748e340",
      jsx: `<Sidebar background="base-100" position="left" hasOutline gap="base" alignItems="stretch" justifyContent="start" display width="280" textSize="base" paddingY="md" paddingX="sm">
      <Module module_name="Navigation Links" module_role="app navigation">
        <Logo size="24px" type="logo" />
      </Module>
      <Module module_name="Navigation Links" module_role="app navigation" paddingX="sm">
        <Search size="medium" bgColor="base-0" width="full" hasOutline />
      </Module>
      <Module paddingY="sm" gap="sm" module_name="Navigation Links" module_role="app navigation" height="h-full">
        <SidebarLink text="Home" fontWeight="normal" size="medium" leftIcon="Home" rightIcon="none" width="full" indentLevel="none" />
        <SidebarLink text="Link" fontWeight="normal" size="medium" leftIcon="Reports" rightIcon="none" width="full" indentLevel="none" />
        <SidebarLink text="Link" leftIcon="Activity" width="full" rightIcon="none" fontWeight="normal" />
        <SidebarLink text="Link" leftIcon="PeopleTag" rightIcon="none" width="full" fontWeight="normal" />
      </Module>
      <Module paddingY="sm" gap="sm" module_name="Navigation Links" module_role="app navigation" height="h-full" alignItems="start" justifyContent="end">
        <SidebarLink text="Settings" fontWeight="normal" size="medium" leftIcon="Settings" rightIcon="none" width="full" indentLevel="none" />
        <SidebarLink text="Support" fontWeight="normal" size="medium" leftIcon="Lifebelt" rightIcon="none" width="full" indentLevel="none" />
      </Module>
      <Module module_name="User Info Display" module_role="Displays user info and email">
        <AvatarCard secondaryText="username@company.com" imageSize="32px" imageSrc="https://images.unsplash.com/photo-1633332755192-727a05c4013d?crop=entropy&cs=srgb&fm=jpg&ixid=M3w0OTQ5NTB8MHwxfHNlYXJjaHwyfHxoZWFkc2hvdHxlbnwwfHx8fDE3MTM2MzQ0MTB8MA&ixlib=rb-4.0.3&q=85" />
      </Module>
    </Sidebar>`
    }, 
    {
      apiName: 'sidebar_search_filters',
      displayName: 'Search Filters Sidebar',
      rootComponent: 'Sidebar',
      category: 'navigation',
      subcategory: 'sidebar',
      description: 'Displays a sidebar with search filters for a folder management system.',
      width: 280, // Adjust the width as per your layout requirements
      height: 632, // Height will depend on the content
      ai_template: true,
      thumbnail: "https://firebasestorage.googleapis.com/v0/b/juno-v1.appspot.com/o/screenshot-1715195362301%201.jpg?alt=media&token=bbc712c6-97f0-40ee-b9c3-000cfdd8530b", 
      jsx: `<Sidebar background="none" position="left" hasOutline gap="base" alignItems="stretch" justifyContent="start" display width="280" textSize="base" paddingY="md" paddingX="sm">
      <Module paddingY="sm" gap="sm" module_name="Navigation Links" module_role="app navigation" height="h-auto">
        <SidebarLink text="Dashboard" fontWeight="normal" size="medium" leftIcon="Reports" rightIcon="none" width="full" indentLevel="none" />
        <SidebarLink text="Data" leftIcon="Activity" width="full" rightIcon="none" fontWeight="normal" />
        <SidebarLink text="Overview" leftIcon="PeopleTag" rightIcon="none" width="full" fontWeight="normal" />
      </Module>
      <Module paddingY="sm" gap="md" module_role="Folder management and navigation" module_name="Folder Management" height="h-full">
        <Heading text="Search filters" textSize="md" />
        <FlexBox direction="flex-col" padding="none" paddingY="none" paddingX="md">
          <Checkbox type="standard" label="Filter 1" />
          <Checkbox type="standard" label="Filter 2" />
          <Checkbox type="standard" label="Filter 3" />
          <Checkbox type="standard" label="Filter 4" />
          <Checkbox type="standard" label="Filter 5" />
        </FlexBox>
      </Module>
    </Sidebar>`
    },
    {
      apiName: 'sidebar_minimalistic',
      displayName: 'Minimalistic Sidebar',
      rootComponent: 'Sidebar',
      category: 'navigation',
      subcategory: 'sidebar',
      description: 'Displays a minimalistic sidebar with a logo and navigation links.',
      width: 280, 
      height: 632, 
      ai_template: true,
      thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1715652269554.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=Hld3XwuNr%2BpaJIgUmplFDaJ6XuDfuQjJ0B0aHPLV8BiWrCbkctW%2F4UOaBZEgT7iWXEFUJtHTFACzELXdpygB%2FpOOo4K3%2BXMmvKJWeNwkyeBV2APlvcgBFJfos3Z3xkEzeJ9L%2FO%2BFj9c0m5k4MBkoFG7oLO0lbMdfdDlG2PztGGtQTr0pspfxRwww9HOQM9ZjtOKvPpG0z8kEx203SHTPliB5ekJMDFd1AM17j8Sv4L57fShWr1ziNP9SUDYj3WGPUQ3zchvQSqZLtM8iI1pYC1EsxOrchna%2BYKT9I4tOeBimj1PfB04oo5M%2FSFq0%2FuvqSHEGKyoWZDhj0chc6ySrYQ%3D%3D",
      jsx: `<Sidebar position="left" hasOutline gap="sm" alignItems="stretch" justifyContent="start" display width="280" textSize="base" paddingY="md" paddingX="sm">
      <Module >
        <Logo size="24px" type="symbol" />
      </Module>
      <Module paddingY="sm" gap="base" module_name="Navigation Links" module_role="app navigation" height="h-full">
        <SidebarLink text="home" fontWeight="normal" size="small" leftIcon="Reports" rightIcon="none" width="full" indentLevel="none" />
        <SidebarLink text="starred" leftIcon="Activity" width="full" rightIcon="none" fontWeight="normal" size="small" />
        <SidebarLink text="to-do" leftIcon="Hashtag" rightIcon="none" width="full" fontWeight="normal" size="small" />
      </Module>
      <Module module_name="User Info Display" module_role="Displays user info and email" flexDirection="flex-row" justifyContent="between" alignItems="center">
        <AvatarCard secondaryText="" imageSize="20px" imageSrc="https://images.unsplash.com/photo-1633332755192-727a05c4013d?crop=entropy&cs=srgb&fm=jpg&ixid=M3w0OTQ5NTB8MHwxfHNlYXJjaHwyfHxoZWFkc2hvdHxlbnwwfHx8fDE3MTM2MzQ0MTB8MA&ixlib=rb-4.0.3&q=85" />
        <IcoNoirIcon name="Bell" size="16" />
      </Module>
    </Sidebar>`, 
    apiName: 'sidebar_collapsible',
    displayName: 'Collapsible Sidebar',
      rootComponent: 'Sidebar',
      category: 'navigation',
      subcategory: 'sidebar',
      description: 'Displays a minimalistic sidebar with a logo and navigation links.',
      width: 280, 
      height: 632, 
      ai_template: true,
      thumbnail: null,
      jsx: `<CollapsibleSidebar>
      </CollapsibleSidebar>`
    }
  ]