import PropTypes from 'prop-types'
import React, { useState } from 'react'
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick' /* remove line */

export default function SegmentedSwitch(props) {
    
    const {
        size = 'medium',
        defaultOption = 0,
        width = 'auto',
        bgColor = 'base-200',
        selectedOptionColor = 'base-0',
        options = ['Creativity', 'Uniqueness', 'Nerve', 'Talent'],
        hasOutline = false,
        attributes,
        listeners
      } = props;

    const { junoAttributes, dndProps, showTag, preview, self, outlineStyle, eventListeners, onPropertyUpdate=()=>{} } = props.junoProps || {} /* remove line */

    const [selectedOption, setSelectedOption] = useState(defaultOption)
    function handleSelect(index) {
        setSelectedOption(index)
    }

    const widthStyle = `w-${width}`
    const sizeStyles = size == 'small' ? `gap-0.5 text-xs` : size == 'large' ? `gap-1.5 text-base` : `gap-1 text-sm`;
    const cornerStyles = size == "small" ? "rounded" : size == "large" ? "rounded-lg" : "rounded-md"
    const textColor = bgColor.startsWith('base-') ? 'text-base-content' : `text-${bgColor}-content`
    
    const bgStyles = bgColor != 'none' ? `bg-${bgColor} text-${bgColor}-content` : ''
    const borderStyles = hasOutline ? 'border border-base-300' : 'border border-transparent';
    const classes = `flex flex-row items-center justify-between font-medium whitespace-nowrap flex-shrink-0 relative ${bgStyles} ${borderStyles} ${sizeStyles} ${cornerStyles}`
    
    const optionSizeStyles = size == 'small' ? `py-3xs px-xs border-2 rounded` : size == 'large' ? `py-xs px-sm border-2 rounded-lg` : `py-3xs px-sm border-2 rounded-md`;
    const optionClasses = `cursor-pointer  flex flex-row items-center justify-center text-center border-${bgColor} ${optionSizeStyles} ${textColor}`
    
    const selectedTextColor = 
        selectedOptionColor.startsWith('success') || selectedOptionColor.startsWith('info') ? `text-${selectedOptionColor.replace('-content', '')}`
        : `text-${selectedOptionColor}-content`

    const selectedOptionClasses = `cursor-pointer text-center bg-${selectedOptionColor} border-${bgColor} ${selectedTextColor} ${optionSizeStyles}`

    const gapStyles = size == 'small' ? 'gap-0.5' : size == 'large' ? 'gap-1.5' : 'gap-1'
    let wrapperClasses = `flex flex-col ${widthStyle} ${gapStyles} select-none`
    wrapperClasses += ` ${outlineStyle}` /* remove line */

    const truncateStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',}


    function updateOption(e, index) { const newOptions = [...options]; /* remove line */
        newOptions[index] = e.target.textContent; /* remove line */
        onPropertyUpdate('options')({target: { innerText: newOptions }});} /* remove line */
    
    return (
        <div
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
            className={wrapperClasses}
        >
        {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Switch</div> /* remove line */}
        <div className={classes}>
                {options
                .slice(0, 5) // up to 5 options
                .map((option, index) => (
                    <div className={selectedOption == index ? selectedOptionClasses : optionClasses}
                    style={{...truncateStyle, width: `${100/options.length}%`}}
                    key={index}
                    /* replace to 'onClick={()=> handleSelect(index)}' */
                    >
                        <span className='text-center' style={truncateStyle}>
                        <EditableOnDoubleClick text={option} onBlur={(e) => updateOption(e, index)} preview={preview} /* replace to '{option}' */ />
                        </span>
                    </div>
                ))}
        </div>
        
    </div>
);  
}

SegmentedSwitch.propTypes = {
    width: PropTypes.oneOf(['auto', '1/2', 'full']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    bgColor: PropTypes.oneOf(['base-0', 'base-50', 'base-100', 'base-200', 'base-300']),
    selectedOptionColor: PropTypes.oneOf(['base-0', 'accent', 'primary', 'success-content', 'info-content']),
    defaultOption: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.string),
    hasOutline: PropTypes.bool,
};

/* ignore rest */


SegmentedSwitch.definitions = {
    apiName: 'SegmentedSwitch',
    displayName: 'SegmentedSwitch',
    description: `A segmented switch component that allows users to select from a list of options represented as strings`,
    ai_instructions: `horizontal row of options as strings. user can select only one by clicking on it. The selected option is highlighted with a different background color.`,
    type: 'input',
    relativeSize: 'small',
    acceptedChildren: 'none',
    propDefinitions: {
        width: {
            type: "width",
            options: ["auto", "1/2", "full"],
            displayName: "Width",
            default: "auto", 
            defaultOnMobile: 'full', 
            tile: '1/2'
        },
        size: {
            type: 'size',
            options: ['small', 'medium', 'large'],
            displayName: 'Size',
            default: 'medium', 
            tile: '1/2'
        },
        bgColor: {
            type: 'colors',
            options: ['base-0', 'base-50', 'base-100', 'base-200', 'base-300'],
            displayName: 'Background',
            tile: '1/2',
            default: 'base-100'
        },
        selectedOptionColor: {
            type: 'colors',
            options: ['base-0', 'accent', 'primary', 'success-content', 'info-content'],
            displayName: 'Selected Color',
            tile: '1/2',
            default: 'base-0'
        },
        options: {
            type: 'arrayOfStrings',
            displayName: 'Options',
            default: ['Option A', 'Option B', 'Option C']
        },
        hasOutline: {
            type: "bool",
            displayName: "Outline",
            default: false, 
            ai_instructions: 'adds base-300 1px outline',
        }, 
    }
}


