import { emails } from './sections/emails';
import { mains } from './sections/mains';
import { headers } from './sections/headers';
import { sidebars } from './sections/sidebars';
import { featurePanels } from './sections/features';
import { iconbars } from './sections/iconbars';
import { sidePanels } from './sections/sidepanels';


export const sections = [
  { name: 'Main', description: 'Main Content Templates', rootComponent: 'Main', templates: mains },
  { name: 'Sidebar', description: 'Components for navigating through the application', rootComponent: 'Sidebar', templates: sidebars },
  { name: 'Header', description: 'Header Templates', rootComponent: 'Header', templates: headers },
  { name: 'SidePanel', description: 'Side Panel Templates', rootComponent: 'SidePanel', templates: sidePanels},
  { name: 'Feature', description: 'Feature Panel Templates', rootComponent: 'FeaturePanel', templates: featurePanels },
  { name: 'Emails', description: 'Email Templates', rootComponent: 'Email', templates: emails },
  { name: 'Iconbar', description: 'Iconbar Templates', rootComponent: 'IconBar', templates: iconbars}
];