export const safelist = `safelist: [
    // Colors
    'bg-primary', 'bg-primary-content', 'bg-primary-focus',
    'bg-secondary', 'bg-secondary-content', 'bg-secondary-focus',
    'bg-accent', 'bg-accent-content', 'bg-accent-focus',
    'bg-base-0', 'bg-base-100', 'bg-base-200', 'bg-base-300', 'bg-base-400', 'bg-base-500', 'bg-base-600', 'bg-base-700', 'bg-base-900', 'bg-base-content',
    'bg-error', 'bg-error-content', 'bg-error-focus',
    'bg-info', 'bg-info-content', 'bg-info-focus',
    'bg-success', 'bg-success-content', 'bg-success-focus',
    'bg-warning', 'bg-warning-content', 'bg-warning-focus',

    'text-primary', 'text-primary-content', 'text-primary-focus',
    'text-secondary', 'text-secondary-content', 'text-secondary-focus',
    'text-accent', 'text-accent-content', 'text-accent-focus',
    'text-base-0', 'text-base-100', 'text-base-200', 'text-base-300', 'text-base-400', 'text-base-500', 'text-base-600', 'text-base-700', 'text-base-900',
    'text-error', 'text-error-content', 'text-error-focus',
    'text-info', 'text-info-content', 'text-info-focus',
    'text-success', 'text-success-content', 'text-success-focus',
    'text-warning', 'text-warning-content', 'text-warning-focus',

    'border-primary', 'border-primary-content', 'border-primary-focus',
    'border-secondary', 'border-secondary-content', 'border-secondary-focus',
    'border-accent', 'border-accent-content', 'border-accent-focus',
    'border-base-0', 'border-base-100', 'border-base-200', 'border-base-300', 'border-base-400', 'border-base-500', 'border-base-600', 'border-base-700', 'border-base-900',
    'border-error', 'border-error-content', 'border-error-focus',
    'border-info', 'border-info-content', 'border-info-focus',
    'border-success', 'border-success-content', 'border-success-focus',
    'border-warning', 'border-warning-content', 'border-warning-focus',
    
    'outline-primary', 'outline-primary-content', 'outline-primary-focus',
    'outline-secondary', 'outline-secondary-content', 'outline-secondary-focus',
    'outline-accent', 'outline-accent-content', 'outline-accent-focus',
    'outline-base-0', 'outline-base-100', 'outline-base-200', 'outline-base-300', 'outline-base-400', 'outline-base-500', 'outline-base-600', 'outline-base-700', 'outline-base-900',
    'outline-error', 'outline-error-content', 'outline-error-focus',
    'outline-info', 'outline-info-content', 'outline-info-focus',
    'outline-success', 'outline-success-content', 'outline-success-focus',
    'outline-warning', 'outline-warning-content', 'outline-warning-focus',

    'ring-primary', 'ring-primary-content', 'ring-primary-focus',
    'ring-secondary', 'ring-secondary-content', 'ring-secondary-focus',
    'ring-accent', 'ring-accent-content', 'ring-accent-focus',
    'ring-base-0', 'ring-base-100', 'ring-base-200', 'ring-base-300', 'ring-base-400', 'ring-base-500', 'ring-base-600', 'ring-base-700', 'ring-base-900',
    'ring-error', 'ring-error-content', 'ring-error-focus',
    'ring-info', 'ring-info-content', 'ring-info-focus',
    'ring-success', 'ring-success-content', 'ring-success-focus',
    'ring-warning', 'ring-warning-content', 'ring-warning-focus',
    
    // Gaps
    'gap-3xs', 'gap-2xs', 'gap-xs', 'gap-sm', 'gap-md', 'gap-lg', 'gap-xl', 'gap-2xl', 'gap-3xl', 'gap-base',

    // Padding
    'p-3xs', 'p-2xs', 'p-xs', 'p-sm', 'p-md', 'p-lg', 'p-xl', 'p-2xl', 'p-3xl', 'p-base', 
    'px-3xs', 'px-2xs', 'px-xs', 'px-sm', 'px-md', 'px-lg', 'px-xl', 'px-2xl', 'px-3xl', 'px-base',
    'py-3xs', 'py-2xs', 'py-xs', 'py-sm', 'py-md', 'py-lg', 'py-xl', 'py-2xl', 'py-3xl', 'py-base',

    // Margins
    'm-3xs', 'm-2xs', 'm-xs', 'm-sm', 'm-md', 'm-lg', 'm-xl', 'm-2xl', 'm-3xl', 'm-base',
    'mx-3xs', 'mx-2xs', 'mx-xs', 'mx-sm', 'mx-md', 'mx-lg', 'mx-xl', 'mx-2xl', 'mx-3xl', 'mx-base',
    'my-3xs', 'my-2xs', 'my-xs', 'my-sm', 'my-md', 'my-lg', 'my-xl', 'my-2xl', 'my-3xl', 'my-base',
    'mt-3xs', 'mt-2xs', 'mt-xs', 'mt-sm', 'mt-md', 'mt-lg', 'mt-xl', 'mt-2xl', 'mt-3xl', 'mt-base',
    'mb-3xs', 'mb-2xs', 'mb-xs', 'mb-sm', 'mb-md', 'mb-lg', 'mb-xl', 'mb-2xl', 'mb-3xl', 'mb-base',

    // Text
    'text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl', 'text-5xl', 'text-6xl', 'text-7xl', 'text-8xl', 'text-9xl',

    // Radius
    'rounded-2xs', 'rounded-xs', 'rounded-sm', 'rounded-md', 'rounded-lg', 'rounded-xl', 'rounded-2xl', 'rounded-3xl', 'rounded-base', 
]`.trim();