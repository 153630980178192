import React, { useEffect, useRef } from "react";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import beautify from 'js-beautify';

export default function FormattedSnippet ({ jsx }){
  const codeRef = useRef(null);
  // Beautify the JSX string
  const formattedJSX = beautify.html(jsx || '', {
    indent_size: 2,
    space_in_empty_paren: true,
    wrap_line_length: 120,
    unformatted: [], // Keep all tags formatted
  });

  useEffect(() => {
    if (codeRef.current) {
      codeRef.current.removeAttribute("data-highlighted"); // Remove the attribute
      hljs.highlightElement(codeRef.current);
    }
  }, [formattedJSX]);

  return (
    <pre className="relative">
      <code
        id="code-block"
        ref={codeRef}
        className="text-xs language-javascript" 
        style={{ padding: 8, minHeight: '8em' }}
      >
        {formattedJSX}
      </code>
    </pre>
  );
};


export function convertObjectsToJSX(selector) {
  const frameObjects = selector.frame.objects || [];
  
  const selectedObject = selector.object ? selector.object : frameObjects.length > 0 ? frameObjects.find(obj => obj.parent == obj.frame) : null;
  
  function generateProps(objectProps, mobileProps) {
    let propsArray = Object.entries(objectProps).map(([key, value]) => {
      if (typeof value === "string") {
        return `${key}="${value}"`;
      } else if (typeof value === "boolean") {
        return value ? key : '';
      } else if (typeof value === "number") {
        return `${key}={${value}}`;
      } else if (typeof value === "object") {
        const jsonString = JSON.stringify(value);
        const escapedString = jsonString
            .replace(/\\/g, '\\\\') // Escape backslashes first
            .replace(/"/g, '\\"'); // Escape double quotes
        return `${key}={${escapedString}}`;
      } else {
        return `${key}={${JSON.stringify(value)}}`;
      }
    }).filter(Boolean);

    if (mobileProps) {
      const mobilePropsArray = Object.entries(mobileProps).map(([key, value]) => {
        if (typeof value === "string") {
          return `mob_${key}="${value}"`;
        } else if (typeof value === "boolean") {
          return value ? `mob_${key}` : '';
        } else if (typeof value === "number") {
          return `mob_${key}={${value}}`;
        } else {
          return `mob_${key}={${JSON.stringify(value)}}`;
        }
      });
      propsArray = propsArray.concat(mobilePropsArray);
    }

    if (propsArray.length > 2) {
      return propsArray.join('\n  ');
    } else {
      return propsArray.join(' ');
    }
  }

  function generateNode(object, indentation = 0) {
    const tag = object.componentAPIName;
    const propsString = generateProps(object.object_props || {}, object.mobile_props);
    const indent = '  '.repeat(indentation);

    let childObjects = getChildren(object.id, frameObjects).sort((a, b) => a.index - b.index);

    if (childObjects.length === 0) {
      return `${indent}<${tag}${propsString ? ` ${propsString}` : ''} />\n`;
    } else {
      let nodeHTML = `${indent}<${tag}${propsString ? ` ${propsString}` : ''}>\n`;

      for (let child of childObjects) {
        nodeHTML += generateNode(child, indentation + 1);
      }

      nodeHTML += `${indent}</${tag}>\n`;
      return nodeHTML;
    }
  }

  if (!selectedObject) {
    return '';
  } else {
    return generateNode(selectedObject);
  }
}

function getChildren(parentId, frameObjects) {
  return frameObjects.filter(obj => obj.parent === parentId);
}
