import React from 'react'
import PropTypes from 'prop-types';
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import { spacingMap } from '../helpers'; /* replace to 'import { spacingMap } from './helpers.js';' */

const dummyText = `This medium-length paragraph provides more detail, suitable for sections that require a bit more explanation. It's perfect for content areas where you need to elaborate on a topic without overwhelming the reader with text.`

export default function Paragraph(props) {
   
    const {
        text = dummyText,
        textSize = 'auto',
        textColor = 'auto',
        marginBottom = null, 
        marginTop = null, 
        lineHeight = 'auto',
        textAlign = 'left',
        
        fontWeight,
        attributes,
        listeners
      } = props;
    const { junoAttributes, self, dndProps, preview=false, showTag, setRefs, outlineStyle, eventListeners, onPropertyUpdate=()=>{} } = props.junoProps || {} /* remove line */

    const textSizeStyles = textSize !== 'auto' && `text-${textSize}`
    const textColorStyles = textColor !== 'auto' &&  `text-${textColor}`
    const marginBottomStyles = marginBottom ? `mb-${spacingMap[marginBottom]}` : '';
    const marginTopStyles = marginTop ? `mt-${spacingMap[marginTop]}` : '';
    
    const lineHeightStyles = lineHeight !== 'auto' && `leading-${lineHeight}`
    const fontWeightStyles = fontWeight !== 'auto' && `font-${fontWeight}`
    const textAlignStyles = textAlign ? `text-${textAlign}` : ''; 

    let classes = `whitespace-pre-wrap ${textSizeStyles} ${textColorStyles} ${marginBottomStyles} ${marginTopStyles} ${lineHeightStyles} ${fontWeightStyles} ${textAlignStyles}`
    classes += ` ${outlineStyle}` /* remove line */

    return (
        <span
        className={classes}
        {...junoAttributes} /* remove line */
        {...attributes} {...listeners} 
        >
        {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Paragraph</div> /* remove line */}
        { 
        !preview ? <EditableOnDoubleClick text={text} onBlur={onPropertyUpdate('text')} /> : /* remove line */
        text}
        </span>
    );
}
Paragraph.propTypes = {
    text: PropTypes.string,
    textSize: PropTypes.oneOf(['auto', 'xs', 'sm', 'base', 'md', 'lg', 'xl', '2xl', '3xl', '4xl']),
    marginTop: PropTypes.oneOf(['4px', '6px', '8px', '12px', '16px', '24px', '32px']),
    marginBottom: PropTypes.oneOf(['4px', '6px', '8px', '12px', '16px', '24px', '32px']),
    lineHeight: PropTypes.oneOf(['auto', 'none', 'tight', 'normal', 'relaxed', 'loose']),
    textAlign: PropTypes.oneOf(['auto', 'left', 'right', 'center', 'justify']),
    textColor: PropTypes.oneOfType([
        PropTypes.oneOf(['auto', 'primary', 'primary-content', 'accent', 'accent-content', 'base-content', 'base-500', 'base-700', 'base-900', 'success-content', 'warning-content', 'error-content', 'info-content']),
        PropTypes.string]),
    children: PropTypes.node
};

/* ignore rest */


Paragraph.stories = [
    {
        name: 'Default', 
        wrapperClasses: 'flex flex-col max-w-[480px] h-auto p-4 bg-white !bg-base-0',
        props: {

        }
    }
]


Paragraph.definitions = {
    apiName: 'Paragraph',
    displayName: 'Paragraph',
    description: 'A paragraph component with editable text content and customizable text size, color, margins, line height, font weight, and text alignment. Supports dynamic styling and interactive editing.',
    ai_instructions: 'represents <p> element. useful for pieces of copy. for short text use Text component',
    type: 'typography',
    relativeSize: 'medium',
    acceptedChildren: 'none',
    propDefinitions: {
        text: {
            type: 'longString',
            default: dummyText,
            displayName: 'Text',
        },
        textColor: {
            type: 'colors',
            options: ['auto', 'primary', 'primary-content', 'accent', 'accent-content', 'base-content', 'base-500', 'base-700', 'base-900', 'success-content', 'warning-content', 'error-content', 'info-content'],
            displayName: 'Color',
            default: 'auto',
            miniEditor: true,
        },
        textSize: {
            type: 'oneOf',
            options: ['auto', 'xs', 'sm', 'base', 'lg', 'xl', '2xl', '3xl', '4xl'],
            default: 'auto', 
            displayName: 'Text Size',
            tile: '1/2'
        },
        textAlign: {
            type: 'oneOf',
            options: ['auto', 'left', 'right', 'center', 'justify'],
            default: 'left', 
            displayName: 'Text Alignment',
            tile: '1/2'
        }, 
        marginTop: {
            type: 'spacing',
            options: ['4px', '6px', '8px', '12px', '16px', '24px', '32px'],
            displayName: 'Top Margin',
            default: null,
            tile: '1/2'
        },
        marginBottom: {
            type: 'spacing',
            options: ['4px', '6px', '8px', '12px', '16px', '24px', '32px'],
            displayName: 'Bottom Margin',
            default: null,
            tile: '1/2'
        },
        lineHeight: {
            type: 'oneOf',
            options: ['auto', 'none', 'tight', 'normal', 'relaxed', 'loose'],
            displayName: 'Line Height',
            default: 'auto', 
            tile: '1/2'
        },
        
        
    }
};

