import React from 'react'
import PropTypes from 'prop-types'
import { ButtonIcon } from '../index'; /* replace to 'import { ButtonIcon } from './index';' */
import { spacingMap } from '../helpers'; /* replace to 'import { spacingMap } from './helpers.js';' */

export default function Modal(props) {
    
    const {
        paddingX = '16px',
        paddingY = '16px',
        gap = '12px',

        modalBackground = 'base-100',
        width = '640px',
        corners = 'base',
        backdrop = 'dark',
        children,
        onClose,
        closeButton,
        attributes,
        listeners
      } = props;
    const { junoAttributes, dndProps, showTag, setRefs, outlineStyle, eventListeners } = props.junoProps || {} /* remove line */
    
    // OVERLAY STYLES
    const darkBackground = `color-mix(in srgb, var(--base-content) 24%, transparent)`
    const lightBackground = `color-mix(in srgb, var(--base-content) 12%, transparent)`
    const overlayClasses = `absolute top-0 left-0 flex flex-col w-full h-full`


    // MODAL STYLES
    const paddingStyles = `${paddingX ? `px-${spacingMap[paddingX]}` : ''} ${paddingY ? `py-${spacingMap[paddingY]}` : ''}`;
    const gapStyles = gap ? `gap-${spacingMap[gap]}` : '';

    const cornerStyles = corners === 'none' ? '' : `rounded-${corners}`;
    const modalBg = modalBackground ? `bg-${modalBackground} mx-auto` : `mx-auto`;
    const borderStyles = `border border-base-200`;
    
    const shadowStyle = {
        '480px': 'shadow-md',
        '640px': 'shadow-md', 
        '780px': 'shadow-lg',
        '960px': 'shadow-lg',
        '1200px': 'shadow-xl',
        '1440px': 'shadow-xl',
    }[width]
    let modalClasses = `flex flex-col min-h-[120px] relative items-stretch justify-start mx-auto ${shadowStyle} ${modalBg} ${gapStyles} ${borderStyles} ${paddingStyles} ${cornerStyles}`
    modalClasses += ` ${outlineStyle}` /* remove line */

    return (
        /* Overlay */
        <div  className={overlayClasses} 
        {...attributes} {...listeners} 
        id={junoAttributes?.id} {...eventListeners} /* remove line */
        style={{
            backgroundColor: backdrop == 'none' ? 'transparent' : backdrop == 'dark' ? darkBackground : lightBackground,
            zIndex: 100, 
            backdropFilter: backdrop == 'blurred' && 'blur(2px)',
            WebkitBackdropFilter: backdrop == 'blurred' && 'blur(2px)', /* For Safari compatibility */
        }}>
        {showTag && <div className='selected-element-inside' style={{zIndex: 2000}}>Modal Overlay</div> /* remove line */}
            {/* Modal */}
            <div className={modalClasses}
            ref={setRefs} {...dndProps?.dndAttributes} {...dndProps?.dndListeners} /* remove line */
            style={{width: '100%', maxWidth: width, marginTop: 80, 
            animation: 'fadeInUp 100ms ease-in-out',
             }}>
            {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Modal Window</div> /* remove line */}
            
            {closeButton && <div className='absolute right-1 top-1 transition-all rounded-lg'><ButtonIcon icon='close'
            size="small"
            type={"ghost"}
            /* replace to 'onClick={onClose}' */
          /></div>}
            {children}
            </div>
        </div>
    );
}


Modal.propTypes = {
    modalBackground: PropTypes.oneOf(['base-100', 'base-0']),
    backdrop: PropTypes.oneOf(['dark', 'blurred', 'none']),
    width: PropTypes.oneOf(['480px', '640px', '780px', '960px', '1200px']),
    paddingX: PropTypes.oneOf(['0px', '8px', '12px', '16px', '24px', '32px', '48px']),
    paddingY: PropTypes.oneOf(['0px', '8px', '12px', '16px', '24px', '32px', '48px']),
    gap: PropTypes.oneOf(['0px', '8px', '12px', '16px', '24px', '32px'],),
    corners: PropTypes.oneOf(['none', 'sm', 'base', 'md', 'lg', 'xl', '2xl', '3xl']),
    closeButton: PropTypes.bool,
    children: PropTypes.node
};

/* ignore rest */


Modal.stories = [
    {
        name: 'Default',
        wrapperClasses: 'flex flex-col w-full max-w-[640px] h-full max-h-[480px] bg-base-0',
        props: {
            width: '480px', 
            backdrop: 'dark', 
        }
    }

]

Modal.definitions = {
    apiName: 'Modal',
    displayName: 'Modal',
    description: 'A customizable modal component with various options for background, size, padding, gap, corners, and interactive elements. Features editable title and supports a close button.',
    ai_instructions: 'modal that overlays app shell to present dynamic content.',
    type: 'dialogs',
    acceptedChildren: 'everything except table components, layouts, sections, dialogs',
    relativeSize: 'medium to large',
    propDefinitions: {
        modalBackground: {
            type: 'colors',
            options: ['base-100', 'base-0'],
            displayName: 'Modal Background',
            default: 'base-100', 
        },
        width: {
            type: 'oneOf',
            options: ['480px', '640px', '780px', '960px', '1200px'],
            displayName: 'Width',
            default: '640px', 
            tile: '1/2'
        },
        backdrop: {
            type: 'oneOf',
            options: ['dark', 'blurred', 'none'],
            displayName: 'Backdrop',
            default: 'dark', 
            tile: '1/2'
        },
        
        corners: {
            type: 'corners',
            options: ["none", "sm", "md", "lg", 'xl'],
            displayName: 'Corners',
            default: 'base', 
            tile: '1/2'
        },
        paddingX: {
            type: 'spacing',
            options: ["none", "sm", "base", "md", "lg", 'xl', '2xl'],
            displayName: 'Padding X',
            default: 'md', 
            tile: '1/2'
        },
        paddingY: {
            type: 'spacing',
            options: ["none", "sm", "base", "md", "lg", 'xl', '2xl'],
            displayName: 'Padding Y',
            default: 'md', 
            tile: '1/2'
        },
        gap: {
            type: 'gap',
            options: ['none', 'sm', 'base', 'md', 'lg'],
            displayName: 'Gap',
            default: 'base', 
            tile: '1/2'
            
        },
        closeButton: {
            type: 'bool',
            displayName: 'Close Button',
            default: true, 
            
        },
    }
};