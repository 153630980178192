import React, { useState, useEffect, useContext, useRef } from "react";
import { EditorContext } from "../../../EditorContext";
import * as IconoirIcons from "iconoir-react";
import { FormattedSnippet, FormattedImport} from "./FormattedSnippet";
import ShowHTML from "./ShowHTML";

export default function ShowCode({ selector, view, setView }) {

    const codeBlock = useRef(null);
    const [useSelector, setUseSelector] = useState(view.mode == 'editor' ? true : false);
    useEffect(() => {
      if(view.mode == 'eye') {
        setUseSelector(false)
      }
    }, [view.mode])

    const formatOptions = [
      { label: "JSX", value: "jsx", note: "Only React components in JSX." },
      { label: "Hybrid", value: "hybrid", note: "Simple elements are HTML, complex are React." },
    ]

    const [selectedFormat, setSelectedFormat] = useState("hybrid");
    
    return (
      <div
        className={`flex flex-col text-sm gap-2 rounded-l-xl overflow-hidden`}
        style={{ maxHeight: "100%" }}
      >
        <div className="px-2.5 pt-3 pb-2 bg-gray-50 border-b border-gray-200 flex flex-col w-full gap-2">
        
        <div className={`gap-2 w-full flex flex-row items-center justify-between text-xs`}>
        <div className="w-auto flex flex-row items-center gap-2">
          {/*<div className="w-[136px]">
          <SwitchInput        
              
              options={formatOptions}
              currentValue={selectedFormat}
              setCurrentValue={setSelectedFormat}
          
          />
          </div>*/}
          
          { view.mode == 'editor' &&
            <SelectMode useSelector={useSelector} setUseSelector={setUseSelector} />
          }
          </div>
          {
            <div className="w-auto flex flex-row items-center gap-2 relative">
            <span className="font-medium text-gray-500  transition-all duration-150 flex flex-row gap-2 items-center
            cursor-pointer border border-base-200 hover:border-base-300 rounded-lg px-2 py-0.5"
            onClick={()=> setView({...view, overview: true, inOverview: 'designSystem'})}
            >
              View Docs
              <IconoirIcons.Page width={16} height={16} />
            </span>
            {/*<div className="absolute -bottom-5 translate-y-full right-0 z-50 drop-shadow-sm hover:scale-110 transition-all duration-150">
            <CopyToClipboardButton codeBlockRef={codeBlock} />
          </div>*/}
            </div>
          }
        
        
        </div>
        </div>
        <div className="overflow-y-auto select-text text-xs rounded-lg flex flex-col gap-4 px-2">
          <FormattedImport
                selector={useSelector ? selector : {...selector, object: null}} 
                />
             
            <div id="code-block" ref={codeBlock}>
            <FormattedSnippet
                selector={useSelector ? selector : {...selector, object: null}} 
                />
          </div>
        </div>
        </div>
    );
  };
  
  
  function CopyToClipboardButton({ codeBlockRef }) {
    const [copied, setCopied] = useState(false);
  
    const copyToClipboard = () => {
      const text = codeBlockRef.current.innerText;
      navigator.clipboard.writeText(text).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000); // Reset 'copied' state after 3 seconds
      })
      .catch(err => {
        console.log('Something went wrong', err);
      });
    }
  
    return (
      <div
        className="p-1 rounded-md bg-base-0 ring-1 ring-base-200 transition-all cursor-pointer flex flex-row gap-1 items-center"
        onClick={copyToClipboard}
        
      >
        {copied ? <IconoirIcons.Check width={16} height={16} /> : <IconoirIcons.Copy width={16} height={16} />}
        
      </div>
    );
  }
  


  function SelectMode({useSelector, setUseSelector}) {
    
    return (
      <div className={`relative group px-2 py-0.5 rounded-md bg-base-0 border border-base-200 transition-all cursor-pointer flex flex-row gap-2 items-center
      ${useSelector ? 'bg-primary text-base-0 border-primary' : 'bg-base-0 hover:border-base-300'}
      `}
      onClick={() => setUseSelector(!useSelector)}
      style={{width: 120}}
      >
        <IconoirIcons.MapsArrowDiagonal width={16} height={16} style={{strokeWidth: useSelector ? 2 : 1.5}} 
        className="-scale-x-100"
        />
        {useSelector ? 'Click & Select' : 'Entire page'}
        
      </div>
    )
  } 

  {/*<span className={`absolute opacity-0 group-hover:opacity-100 invisible group-hover:visible whitespace-nowrap
    w-auto font-medium rounded px-2 py-1 text-[12px] bg-base-0 border border-base-200 z-50 text-base-700
    top-1/2 transform -translate-y-1/2 -right-2 translate-x-full transition-all`}>
        {useSelector ? 'Use Selector' : 'Entire page'}
    </span>*/}