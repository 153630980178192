import { EmojiSatisfied, Flare, Group, Running, SparksSolid, Xmark } from "iconoir-react";
import { useContext } from "react";
import UserSettings from "./UserSettings";
import UsageSettings from "./Usage";
import UserContext from "../../UserContext";

export default function SettingsModal({view, setView}) {
    
    const { user, setUser, handleLogout } = useContext(UserContext);

    // Function to handle clicks on the overlay
    const handleOverlayClick = () => {
        setView({ ...view, settings: null });
    };

    // OVERLAY STYLES
    const lightBackground = `color-mix(in srgb, #111 12%, transparent)`;
    const overlayClasses = `absolute top-0 flex flex-col w-full h-full`;
    

    const contentMap = {
        'user': <UserSettings user={user} setUser={setUser} />,
        'usage': <UsageSettings />
    }
    return (
        /* Overlay */
        <div  
            className={overlayClasses} 
            style={{
                backgroundColor: lightBackground,
                zIndex: 2000, 
                // backdropFilter: 'blur(1px)',
                // WebkitBackdropFilter: 'blur(1px)',
            }}
            onClick={handleOverlayClick} //
        >
            {/* Modal */}
            <div 
                className={`flex flex-row relative items-stretch justify-start mx-auto shadow-lg bg-white border border-slate-100 rounded-2xl`}
                style={{width: '100%', maxWidth: 720, marginTop: 80, minHeight: 420 }}
                onClick={e => e.stopPropagation()}
                
            >
                {/* Tabs */}
                <div className="max-w-[188px] w-1/3 border-r border-slate-200 p-3"
                ><Tabs view={view} setView={setView} handleLogout={handleLogout} 
                firstName={user.firstname}
                />
                </div>

                {/* Content */}
                <div className="flex-grow p-3">
                    {contentMap[view.settings]}
                </div>
                
                {/* Close Button */}
                <div className="absolute top-0 right-0 p-3 cursor-pointer opacity-30 hover:opacity-60 hover:scale-110 transition-all duration-150"
                onClick={handleOverlayClick}
                >
                    <Xmark className="w-5 h-5" />
                </div>
            </div>

            
        </div>
    );
}

function Tabs({view, setView, handleLogout, firstName=''}) {
    const tabStyles = `flex flex-row items-center gap-2 px-2 -ml-1 py-1 cursor-pointer transition-all duration-150 text-sm font-normal
    hover:bg-slate-100 transition-all duration-150 rounded-lg hover:text-slate-800
    `
    const iconStyles = {
        width: 18,
        height: 18,
        style: {strokeWidth: 1.5}
    }


    return (
        <div className="flex flex-col gap-2 h-full justify-between w-full">
            <div className="flex flex-col gap-2 w-full">
            <div className={`${tabStyles} ${view.settings == 'user' ? 'bg-slate-100 text-gray-800' : ''}`}
            onClick={() => setView({...view, settings: 'user'})}
            >
                
                <div className="w-[18px] h-[18px] bg-accent rounded-full flex-shrink-0 flex text-xs items-center justify-center font-medium text-primary" >
                
                    {firstName ? firstName[0].toUpperCase() : 'U'}
                
                </div>
                <div>Profile</div>
            </div>
            {/*
            <div className={`${tabStyles} ${view.settings == 'access' ? 'bg-slate-100 text-gray-800' : ''}`}
            onClick={() => setView({...view, settings: 'access'})}
            >
                <Group {...iconStyles} />
                <div>Access</div>
            </div>*/}
            <div className={`${tabStyles} ${view.settings == 'usage' ? 'bg-slate-100 text-gray-800' : ''}`}
            onClick={() => setView({...view, settings: 'usage'})}
            >
                <Flare {...iconStyles} />
                <div>AI Usage</div>
            </div>
            </div>
            
            <div className={tabStyles}
            onClick={handleLogout}>
                <Running {...iconStyles} className="-scale-x-100"/>
                <div>Log Out</div>
            </div>
            
        </div>
    );
    
}