import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import logo from "./logo_text.svg";

export default function Header({mainWidth,}) {

  const navigate = useNavigate();
  const headerRef = useRef(null);
  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 120) {
        headerRef.current.style.borderBottom = '0.5px solid #eee'; 
        if (window.innerWidth < 768) { headerRef.current.style.height = '48px'; }
        headerRef.current.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.02)';
        headerRef.current.style.backgroundColor = 'rgba(255, 255, 255, 0.50)';
      } else {
        headerRef.current.style.borderBottom = 'none'; 
        headerRef.current.style.height = '64px'; 
        headerRef.current.style.boxShadow = 'none'; 
        headerRef.current.style.backgroundColor = 'transparent';
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  
  return (
    <div 
    ref={headerRef}
    className="transition-all duration-150 
    sticky md:flex md:flex-col top-0 backdrop-blur 
    w-full h-[64px] relative justify-between items-center gap-2" 
    style={{zIndex: 50}}>
      
        <div className='flex flex-row h-full items-stretch gap-2 w-full  mx-auto justify-between items-center px-5'
        style={{maxWidth: mainWidth}}
        >
        <div className='flex flex-row h-full items-stretch gap-2 '>
          
          {/* Logo */}
          <div className='flex flex-row py-1 gap-2  rounded items-center text-sm select-none transition-all relative flex-shrink-0'onClick={() => navigate('/')}>
            <img src={logo} alt="Logo" width={80} onClick={() => navigate(`/`)} className='flex-shrink-0'/>
          </div>
        </div>

      
      <div className="flex flex-row h-full w-1/2 items-center justify-end gap-3" >
      
            {<div 
            onClick={() => navigate('/login')}
            className='py-2 px-3 whitespace-nowrap rounded-xl bg-slate-100 md:bg-transparent
            opacity-70 hover:opacity-100 hidden lg:flex
            font-medium text-slate-700 hover:text-slate-800 text-sm transition-all cursor-pointer'>
              login
            </div>}
            <ContactLink />
          
      </div>
      </div>
    </div>
  );
}    


function ContactLink() {

  const handleScrollToForm = () => {
    const element = document.getElementById('contact-form');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };


  return (
    <div 
      onClick={handleScrollToForm}
      className='py-2 px-3 whitespace-nowrap rounded-xl
      bg-white/25 lg:bg-slate-200/50 text-slate-600 hover:text-slate-800
      lg:hover:bg-slate-100/50  hover:ring-[0.5px] ring-slate-300
      font-medium  text-sm transition-all duration-150 cursor-pointer'>
        contact
      </div>

    
  )
}
/*
<div 
      style={{zIndex: 5}}
      className='py-2 px-3 whitespace-nowrap font-medium hover:brightness-110 text-sm transition-all cursor-pointer
      bg-primary duration-150 hover:scale-105 relative group rounded-xl text-white
      shadow-sm hover:shadow-lg
      hidden md:flex md:flex-row'
      
      >
         contact
         {/*<div className='w-5 transition-all duration-75 overflow-hidden ml-2'>
          <EmojiSatisfied className='w-5 h-5'/>
        </div>
        </div>*/