const headers = [
    {
      apiName: 'module_email_logo_symbol',
      displayName: 'Email Logo',
      allowedSections: ['Email'],
      ai_template: true,
      environments: ['email_marketing'],
      ai_instructions: 'module that displays company logo',
      width: 480,
      height: 60,
      tileWidth: '100%',
      thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714139521911.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=o5UriqjLf4kw8qP6%2FjFWT8mfcYOyoo%2F2yUg%2F5Ht8qZvQ2kl852LHomAL8i1tEQsey1hVgGuZmzC4EgCVJcZHhnfvxekzAvzPMqU%2FBu09tS66PIaUOiYfX2aBKoOxv6Z5bICYmybeQfCPCfrcnAH%2FX1sCWTPSWHpnUtShiA5VN1ReR%2F7V5uRIRFv%2Btk0mWcNW3fZZjKh7W%2BuDwPYj%2BaHmWlk6jSvxaLA7evn6rEeA05zD0XTXKKZWpKhJvfb717UtQsyNaERwa3MbneU7rp7yzDh9oW0tSXoV9BnMUis%2FUQSWMDDlKzYNR5447exINeaTLDse2cnEzGQyAove%2Bf3snQ%3D%3D",
      jsx: `<Module module_name="Logo" module_role="Company logo display">
            <Logo size="28px" type="symbol" />
            </Module>`
    },
    {
      apiName: 'module_email_header',
      displayName: 'Email Header',
      allowedSections: ['Email'],
      ai_template: true,
      environments: ['email_marketing'],
      ai_instructions: 'module that displays the email subject line',
      width: 480,
      height: 60,
      tileWidth: '100%',
      thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714139579243.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=4V258NQCDj%2B4fRAvGJsnfcRJRFixTbtEjljF%2BDpP1%2F%2FJUHODP293o5t9TYc%2FtaipkJSpl4rd%2Bc6X6dUpyx6Yq62do4CKQnZiAZrt8Cw9hYYGF8eQVS7EIHea5Asea7y9N%2BOhGDTz8NigalUV2ZwM%2F9J7ghNP9FjQqIY%2BNQk0Nx7foG%2FisOrsC%2FctA%2BENblwX%2FqWajHp%2BQmYXvfcVD3dKnrC2LRC8dMRaXmt2plqdNTntdSeag%2F070ZeDDzLxjR0u6RwMdIHUFA%2FgOmgUbg5woyK7RDFzU9LTaQ7iHm0kJ%2FvRaR6FsEcRKMfYaiS6ZaRFoeY%2FuFTyg2JpybVsNZQG9w%3D%3D",
      jsx: `
      <Module module_name="Email Header" module_role="Email subject line">
        <Heading text="Email Subject Line " marginBottom="none" marginTop="sm" />
      </Module>`
    }
  ]
    
  
  const contentBlocks = [{
      apiName: 'module_email_content_block',
      displayName: 'Call To Action',
      allowedSections: ['Email'],
      ai_template: true,
      environments: ['email_marketing'],
      ai_instructions: 'module that includes a call to action button',
      width: 480,
      height: 200,
      tileWidth: '100%',
      thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714140016789.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=xWYIEi4MWsUgcETsHUqHScJouxBVMWQHjWvVPQhDp%2FLr%2FOdFLsn4d0ZFknVoGvssdpk1K%2F5dNOjg3eoHPj5z9eSpkE5qsh%2FVd4OLPaISojpoawTp6A6LAvGcvU2oQ9KsAP%2BXcseVmSK1yv9yHNdNIg0Dfmh0JVx3xxTnQSpSgITt2TXfcqaIVZssL%2FFAXWDMS%2FF0wX7JDREvZobUWbUTuPDVLlnNrwvh2%2Fu9tR5lA67nG2YBFQXRQHHhHTfOFYPYaGxg5q0cupbQNmobjC5nVxExZ1uGJnC68XCu9byBeJu3XqBaIA6vwHwwloxvjglU9s9z3avQpmo0xYI92ShFSg%3D%3D",
      jsx: `<Module module_name="Call to Action" module_role="Primary action for user">
              <Paragraph text="This medium-length paragraph provides more detail, suitable for sections that require a bit more explanation. " />
              <Paragraph  />
              <Button text="Sign In" />
            </Module>`
    }, 
    {
      apiName: 'module_email_body_hero_image',
      displayName: 'Hero Image',
      allowedSections: ['Email'],
      ai_template: true,
      environments: ['email_marketing'],
      ai_instructions: 'module that has large image',
      width: 480,
      height: 240,
      tileWidth: '100%',
      jsx: `<Module module_name="Logo" module_role="Company logo display" alignItems="center" paddingY="0" paddingX="0">
              <Image objectFit="cover" altText="image 240px height" width="w-full" height="h-[240px]" corners="base" selfAlign="center" />
            </Module>`
    }
  ]
    

const footers = [
  {
    apiName: 'module_email_disclaimer',
    displayName: 'Email Disclaimer',
    allowedSections: ['Email'],
    ai_template: true,
    environments: ['email_marketing'],
    ai_instructions: 'module that displays the email disclaimer information',
    width: 480,
    height: 60,
    tileWidth: '100%',
    thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714140150537.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=C70tkkQDuWlVMVi8IdmicIFL8%2BO4kAQXRieYgxz%2FoLPhDkmAOT%2BMBYUtXBmop91GluJ%2Fz4fn8%2Fz6uAXcix9gnX5mxPDK%2BLfNNH318VF0FBFaXEiVskZee3wtqqEqYElzXL%2Fgq1T1NBTHSlIoNy6WNjrGcVqijq1ykAPCdekxfBV7HrQq%2Fmu6NZ6NJEBHx7fkil2Vge6NAgptXge9f0j3aYhx%2BII%2BSDvOVSZqIWdwtomO4DtWyD16p%2Fibf5vmsYolRAD%2Fp97bnwNvZTjBCS%2BSZbBnRTpbJbuJjHQS7hio7jT8xHYpGsokQ%2BpsBchS8gfWj3ROqQ9aGbBx8391vKQ8iw%3D%3D",
    jsx: `
    <Module module_name="Disclaimer" module_role="Email disclaimer information">
      <Text text="If you did not request this, please ignore the email." />
    </Module>`
  },
  {
    apiName: 'module_email_social_links',
    displayName: 'Social Links',
    allowedSections: ['Email'],
    ai_template: true,
    environments: ['email_marketing'],
    ai_instructions: 'module that displays links to social media profiles',
    width: 480,
    height: 60,
    tileWidth: '100%',
    thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714140196571.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=dMUh0wnWfKEToM6NKHyXvVmzfgdsLFwEkaMuS2Nl%2FSxasia8FnMAMF2NrNrcXzNJ81aUGBv28jY%2FZVV320G2CYcX3r3dtzEZ8mSfG3C8lwZHPcsYpPmE1z%2FXpoIkS1PXIcRVOwYnX5X5xbzUL%2FzzyeEknK6km1RdtCYuGCBLHYbNGTgNTLwo0ks7zawP4n2erBbEOPv1z%2FwjBaVnetdg46Ur9v8wYEdeB%2Foy2lt7C%2F3KIWFGVQRQCDpUFcw0X0PEZA46lgqIdw1Ql4N9dz7G%2Fxq1SURE54%2FvBaEAcgSP3rUPa6Xp0rvgtcJOFo1f8oCL6vpm4U7speighYUowILirA%3D%3D",
    jsx: `
    <Module module_name="Social Links" module_role="Links to social media profiles" flexDirection="flex-row">
      <IcoNoirIcon name="X" size="20" />
      <IcoNoirIcon name="Linkedin" size="20" />
      <IcoNoirIcon name="Tiktok" size="20" />
      <IcoNoirIcon name="Instagram" size="20" />
    </Module>`
  },
  {
    apiName: 'module_email_legal_links',
    displayName: 'Legal Links',
    allowedSections: ['Email'],
    ai_template: true,
    environments: ['email_marketing'],
    ai_instructions: 'module that displays links to legal information',
    width: 480,
    height: 60,
    tileWidth: '100%',
    thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714140778848.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=aunEcwX3EidP0gMBMPpNkt4929EAmE17ycYSclvwFgM1sw4IM%2Bb%2Fdq%2BoG2AUln15Zst2FMNsaQy1fi%2FRNORGmRY2BsypAOnnL34ZXSQ44E2m9vULucJWrCrXpAPf3Et5q1ZnuZvTezwRxTKKRWZOeyUOr2BNTIGdtQTGykM8MIj6fsGw3k4sRJDHXG1bdpd4WlcM1smdXhL%2B22pV8xcmORT6erQ9vabvTZJgX3eGMDf73iQTSZQP%2Fs41rzjPwswU%2BejsQyLeNP8J6oXhbO9aY4zAfUuO%2BDel3Bg%2F84K7VMnR74tv11WZvXDcfrCNyfXToAhQpTVdz%2FJYLiOs2cPRWg%3D%3D",
    jsx: `
    <Module module_name="Legal Links" module_role="Links to legal information" flexDirection="flex-row" alignItems="center">
      <Link text="Terms of Service" textColor="accent" textSize="sm" />
      <Dot color="base-300" size="4px" />
      <Link text="Privacy Policy" textColor="accent" textSize="sm" />
    </Module>`
  }
]
  

export const emailModules = [
    ...headers.map(m => ({ ...m, category: 'headers' })),
    ...contentBlocks.map(m => ({ ...m, category: 'body' })),
    ...footers.map(m => ({ ...m, category: 'footers' })),
]
