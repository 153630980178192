import { useContext, useRef } from "react";
import UserContext from "../../../../../UserContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from '../../../../../utilities/firebaseConfig';
import { v1 as uuidv1 } from "uuid";
import { uploadImage } from "../../../../../utilities/files";
import { UploadSquare } from "iconoir-react";
import { EditorContext } from "../../../EditorContext";

export default function UploadLogo({color='#111111', api_name='', currentAsset={}}){
    // ${isInverted ? 'bg-base-700 hover:bg-base-600' : 'bg-base-100 hover:bg-base-200'}`
    const { user } = useContext(UserContext);
    const [firebaseUser] = useAuthState(auth);
    const { project, setProject, assets } = useContext(EditorContext)
    const projectId = project.project.id
    
    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const uploadedImageUrl = await uploadImage(file, file.name);

                const assetData = {
                    id: uuidv1(),
                    file_url: uploadedImageUrl,
                    file_type: "image",
                    owner_id: user.id,
                    project_id: projectId, // replace with the correct project id
                    asset_source: "user_upload",
                    api_name: api_name,
                };
                console.log("Asset:", assetData);
                await addAsset(assetData);

                onImageUpload(uploadedImageUrl, assetData);

            } catch (err) {
                console.error("Failed to upload image:", err);
            }
        }
    };

    const addAsset = async (assetData) => {
        const userToken = await firebaseUser?.getIdToken();

        if (userToken) {
            try {
                const response = await fetch("/assets/add", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                    body: JSON.stringify(assetData),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                } else {
                    currentAsset?.id ?
                    setProject({...project, assets: assets.map(asset => asset.id === currentAsset.id ? assetData : asset) }) :
                    setProject({...project, assets: [...assets, assetData] })
                }
            } catch (error) {
                console.error("Failed to add asset:", error);
            }
        } else {
            console.error("Failed to get user token");
        }
    };

    function onImageUpload(uploadedImageUrl, assetData) {
        console.log('Image uploaded:', uploadedImageUrl);
        console.log('Asset data:', assetData);
    }
    const uploadInputRef = useRef(null);
    const handleClick = () => {
        if (uploadInputRef.current) {
            uploadInputRef.current.click();
        }
    };

    return (
        <div className='flex flex-row items-center font-medium transition-all duration-75 hover:scale-110 text-xs gap-2
                    opacity-50 hover:opacity-100 cursor-pointer px-[6px] py-1 rounded-md'
                    onClick={handleClick}
                    style={{backgroundColor: `color-mix(in srgb, var(--${color}-content) 12%, transparent)`}}
                    >
                    <UploadSquare className='scale-75'/>
                    {currentAsset?.id ? 'New' : 'Upload'}
                    <input
                        type="file"
                        className=''
                        ref={uploadInputRef}
                        style={{ display: 'none' }}
                        onChange={handleImageUpload}
                    />
                    </div>
    )
}