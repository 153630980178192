import React from 'react';
import PropTypes from 'prop-types'

// Import the SVG files
import sample_symbol from '../assets/brand/symbol.svg'; /* replace to 'import sample_symbol from './assets/brand/symbol.svg';' */
import sample_logo from '../assets/brand/logo.svg'; /* replace to 'import sample_logo from './assets/brand/logo.svg';' */
import sample_symbol_inverted from '../assets/brand/symbol.svg'; /* replace to 'import sample_symbol_inverted from './assets/brand/symbol.svg';' */
import sample_logo_inverted from '../assets/brand/logo_inverted.svg'; /* replace to 'import sample_logo_inverted from './assets/brand/logo_inverted.svg';' */


export default function Logo(props) {
    
    const {
        type = 'symbol',
        size = '28px',
        customWidth = null,
        customHeight = null,
        selfAlign = 'auto',
        color = 'normal',
        assets,
        attributes,
        listeners
      } = props;

    const { junoAttributes, self, dndProps, showTag, setRefs, outlineStyle, eventListeners } = props.junoProps || {} /* remove line */
    
    const symbolAsset = assets?.find(asset => asset.api_name == 'symbol')?.file_url || sample_symbol
    const symbolInvertedAsset = assets?.find(asset => asset.api_name == 'symbol_inverted')?.file_url || sample_symbol_inverted
    const logoAsset = assets?.find(asset => asset.api_name == 'logo')?.file_url || sample_logo
    const logoInvertedAsset = assets?.find(asset => asset.api_name == 'logo_inverted')?.file_url || sample_logo_inverted
    
    const imageOptions = [
        {option: 'symbol', url: color != 'inverted' ? symbolAsset : symbolInvertedAsset}, 
        {option: 'logo', url: color != 'inverted' ? logoAsset : logoInvertedAsset}
    ]
    
    const imageURL = imageOptions.find(option => option.option === type)?.url || null;
    
    let classes = `flex-shrink-0 self-${selfAlign}` 
    classes += ` ${outlineStyle}` /* remove line */
    
    const wrapperInlineStyles = {
        width: customWidth ? customWidth : type == 'symbol' && size,
        height: customHeight || size,
        objectFit: 'contain'
    }
    return (
        <div
            {...attributes} {...listeners} 
            {...junoAttributes} /* remove line */
            className={classes} style={wrapperInlineStyles}
            > 
            {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Logo</div> /* remove line */}
            <img src={imageURL} style={wrapperInlineStyles} draggable={false} />
        </div>   
    )
}

Logo.propTypes = {
    type: PropTypes.oneOf(['logo', 'symbol']),
    size: PropTypes.oneOf(['20px', '24px', '28px', '36px', '40px', '48px', '60px', '96px']),
    color: PropTypes.oneOf(['normal', 'inverted']),
    customWidth: PropTypes.number,
    customHeight: PropTypes.number,
    selfAlign: PropTypes.oneOf(['auto', 'start', 'center', 'end']),
};

/* ignore rest */


Logo.definitions = {
    apiName: 'Logo',
    displayName: 'Logo',
    description: 'A component for displaying a logo with options to choose between a symbol and a full logo. It supports customization of size, width, height, and alignment. The logo automatically adjusts for dark or light themes.',
    ai_instructions: 'can be symbol or full logo. symbol is square, logo is elongated horizontally. automaticlaly adapts to dark and light themes ',
    type: 'media',
    relativeSize: 'small',
    acceptedChildren: 'none',
    propDefinitions: {
        type: {
            type: 'oneOf',
            options: ['logo', 'symbol'],
            displayName: 'Type',
            default: 'symbol',
            tile: '1/2'
            
        },

        selfAlign: {
            type: 'oneOf',
            options: ['auto', 'start', 'center', 'end'],
            displayName: 'Self Align',
            default: 'auto', 
            tile: '1/2'
        },
        size: {
            type: 'oneOf',
            options: ['20px', '24px', '28px', '36px', '40px', '48px', '60px', '96px'],
            displayName: 'Size (height)',
            default: '28px', 
            tile: '1/2'
        },
        color: {
            type: 'oneOf',
            options: ['normal', 'inverted'],
            displayName: 'Color',
            ai_instructions: 'normal is usually dark on light background and inverted is used on dark background',
            default: 'normal', 
            tile: '1/2'
        },
        customWidth: {
            type: 'number',
            displayName: 'Custom Width',
            default: null, 
            tile: '1/2'
        },
        customHeight: {
            type: 'number',
            displayName: 'Custom Height',
            default: null, 
            tile: '1/2'
        },
    }
}


