import React from 'react'
import PropTypes from 'prop-types'
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */

export default function ProgressBar(props) {
    
    const {
        label = null,
        color = 'info-content',
        progress = '50%', 
        showProgress = false,
        barHeight = '8px',
        fontSize = 'sm',
        attributes,
        listeners
      } = props;
    const { junoAttributes, showTag,  outlineStyle, onPropertyUpdate=()=>{}, preview } = props.junoProps || {} /* remove line */
    
    const noLabel = !label || label === ''

    let wrapperClasses = `flex flex-col w-full items-start justify-start whitespace-nowrap text-${fontSize}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */
    
    const truncateStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}

    return (
        <div 
            {...attributes} {...listeners} 
            {...junoAttributes} /* remove line */
            className={wrapperClasses} 
        >   
        {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Progress Bar</div> /* remove line */}
        {label && <div className='flex flex-row items-center justify-between w-full mb-1'>
            <span style={truncateStyle} className='font-semibold'>
            {<EditableOnDoubleClick text={label} preview={preview} whiteSpace='nowrap' onBlur={onPropertyUpdate('label')}/> /* replace to '{label}' */}
            </span>
            <div className='flex-shrink-0 leading-none'>{progress}</div>
        </div>
        }
        <div className='flex flex-row gap-2 w-full items-center'>
        <div className='relative flex-grow w-full rounded-full'>
            <div className='rounded-full w-full h-full' style={{ backgroundColor: 'currentColor', opacity: 0.08, height: barHeight }}/>
            <div className={`absolute top-0 left-0 rounded-full bg-${color}`} style={{width: progress, height: barHeight}}/>
        </div>
        {(showProgress && noLabel) && <div className='flex-shrink-0 leading-none'>{progress}</div>}
        </div></div>
    ); 
}

ProgressBar.propTypes = {
    label: PropTypes.string,
    color: PropTypes.oneOf(['info-content', 'primary', 'accent', 'success-content', 'base-content', 'warning-content', 'error-content']),
    progress: PropTypes.oneOf(['0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%']),
    showProgress: PropTypes.bool,
    barHeight: PropTypes.oneOf(['8px', '12px', '16px']),
    fontSize: PropTypes.oneOf(['xs', 'sm', 'base'])
};

/* ignore rest */

ProgressBar.definitions = {
    apiName: 'ProgressBar',
    displayName: 'ProgressBar',
    description: '',
    ai_instructions: '',
    type: 'indicators',
    relativeSize: 'small',
    acceptedChildren: 'none',
    propDefinitions: {
        label: {
            type: 'string',
            displayName: 'Label',
            default: null
        },
        barHeight: {
            type: 'oneOf',
            options: ['8px', '12px', '16px'],
            default: '8px', 
            displayName: 'Bar Height',
            tile: '1/2'
        },
        fontSize: {
            type: 'oneOf',
            options: ['xs', 'sm', 'base'],
            default: 'small', 
            displayName: 'Font Size', 
            tile: '1/2'
        },
        progress: {
            type: 'oneOf',
            displayName: 'Progress',
            options: ['0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'],
            default: '50%', 
            tile: '1/2'
        },

        color: {
            type: 'colors',
            options: ['info-content', 'primary', 'accent', 'success-content', 'base-content', 'warning-content', 'error-content'],
            displayName: 'Bar color',
            default: 'info-content'
        },
        showProgress: {
            type: 'bool',
            displayName: 'Show Progress Value',
            ai_instructions: 'shows progress as text on the right side if true',
            default: false
        },
        
    }
};
