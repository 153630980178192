import React, { useState, useRef, useContext, useEffect } from 'react';
import { EditorContext } from '../EditorContext';

import ZoomCanvas from './canvas/ZoomCanvas';
import ShareButton from './ShareButton';
import ModeSwitch from './canvas/ModeSwitch';
import ViewSwitch from './ViewSwitch';
import UserMenu from './UserMenu';
import ZoomOverview from './overview/ZoomOverview';
import Notifications from './Notifications';
import FrameName from './canvas/FrameName';
import FeatureName from './canvas/FeatureName';
import RevealFlow from './canvas/RevealFlow';


export default function Header() {
  const { project, view, selector, setView, handleAction } = useContext(EditorContext);
  
  const enabledUsers = [
    '994ce318-d3fb-11ed-a933-ea12fce6e513', // sam 
    '16f58728-1071-11ee-a171-ea12fce6e513', // alena
    '6f590740-bb99-11ee-93c1-7b9311e5f7d9', // mike
    // 'ac386de0-535c-11ee-8977-11102f6a0664', // asel - design partner
  ]
  
  const tooltipStyle = `absolute opacity-0 group-hover:opacity-100 invisible group-hover:visible whitespace-nowrap
  bg-gray-800 text-gray-200 font-normal rounded-sm px-2 py-0.5 text-2xs z-50 
  transition-all duration-75`
  
  const currentFrame = selector?.frame || {}
  return (
    <div className={`h-full transition-all flex-shrink-0 flex flex-row items-center justify-between w-full text-gray-800 `}
    style={{
      width: `100%`, 
      zIndex: 1000,   
    }}
    >
          <div className={`flex flex-row w-1/2 flex-shrink-0 gap-2 items-center pl-[2px]`} >
            {/*<Home tooltipStyle={tooltipStyle}/>*/}
            
            {view.overview ? 
             null
            :
            <>
              {/*<ViewSwitch tooltipStyle={tooltipStyle}/>*/}
              <RevealFlow 
                tooltipStyle={tooltipStyle}
                featureName={selector?.page?.name}
              />
              
              <FrameName 
                currentName={currentFrame?.name} 
                onBlur={(newName) =>
                  handleAction({
                    type: 'UPDATE_FRAME',
                    currentFrame: currentFrame,
                    newFrame: { ...currentFrame, name: newName }
                  })} />
            </>
            }
    
    </div>
    
    <div className='flex flex-row w-1/2 flex-shrink-0 items-center justify-end gap-3 pointer-events-auto'>
    
    {view.overview ?
     renderFolderViewControls(tooltipStyle) : 
      renderCanvasControls(tooltipStyle)
    }
    
    </div>
    
    </div>
  );
}


function renderFolderViewControls(tooltipStyle) {
  return (
    <>
    <Notifications />
    <ZoomOverview />
    {/*<ShowNotesButton tooltipStyle={tooltipStyle} />*/}
    {/*<AddFeatureButton />*/}
    <UserMenu />
    <ShareButton tooltipStyle={tooltipStyle}/>
    </>
  )
}

function renderCanvasControls(tooltipStyle) {
  return (
    <>
    <Notifications />
    <ZoomCanvas tooltipStyle={tooltipStyle}/>
    <ModeSwitch tooltipStyle={tooltipStyle}/>
    
    <ShareButton tooltipStyle={tooltipStyle}/>
    <UserMenu /></>
  )
}


/*
<div className="flex flex-col gap-0">
              <FeatureName 
                feature={selector?.page} 
                onBlur={handleAction} />
              <FrameName 
                currentName={currentFrame?.name} 
                onBlur={(newName) =>
                  handleAction({
                    type: 'UPDATE_FRAME',
                    currentFrame: currentFrame,
                    newFrame: { ...currentFrame, name: newName }
                  })} />
                  
                  </div>
                  */