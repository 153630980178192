import React from 'react';
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react';
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import { Icon } from '../index'; /* replace to 'import { Icon } from './index'' */
import { iconMap } from '../media/iconMap'; /* replace to 'import { iconMap } from './iconMap'' */

const allIconNames = Object.keys(iconMap) || []

export default function HeaderLink(props) {
    
    const {
        text = 'Item',
        fontWeight = 'medium',
        leftIcon = 'none',
        rightIcon = null,
        size = 'medium',
        width = 'auto',
        dropdownBgColor = 'base-0',
        background = null,
        showDropdown = false,
        onClick = () => {},
        defaultIconSet,
        children,
        attributes,
        listeners,
        openDirection = 'downward-right'
        // openBehavior = 'onClick', // Commented until hover is also implemented
      } = props;
    const { junoAttributes, preview, outlineStyle, onPropertyUpdate=()=>{} } = props.junoProps || {} /* remove line */

    const fontWeightStyles = fontWeight !== 'inherit' ? `font-${fontWeight}` : '';
    const sizeStyles = size == 'small' ? `py-1 px-1.5 gap-1.5 text-xs` : `py-1.5 px-3 gap-2 text-sm`;
    const widthStyle = width == 'full' ? `w-full self-stretch` : `w-${width}`
    const cornerStyles = size == "small" ? "rounded" : size == "large" ? "rounded-lg" : "rounded-md"
    const borderStyles = `border border-transparent`
    const bgStyles = (!background || background == 'none') ? '' : `bg-${background} transition-all hover:brightness-95` 
    const fontColor = (!background || background == 'none') ? 'text-inherit' : background?.startsWith('base') ? 'text-base-content' : `text-${background}-content`;

    let linkClasses = `flex flex-row items-center justify-between relative cursor-default ${widthStyle} ${fontWeightStyles} ${sizeStyles} ${bgStyles} ${cornerStyles} ${fontColor} ${borderStyles}`
    
    const iconStrokeWidth = fontWeight === 'light' ? 1 : (fontWeight === 'medium' || fontWeight === 'semibold') ? 2 : 1.5
    const iconWidth = size == 'small' ? 'w-4' : size == 'large' ? 'w-6' : 'w-5'
    
    const LeftIcon = leftIcon !== 'none' ? <Icon icon={leftIcon?.toLowerCase()} defaultIconSet={defaultIconSet} className={`flex-shrink-0 scale-90 ${iconWidth}`}/> : null;
    const RightIcon = rightIcon ? <Icon icon={rightIcon?.toLowerCase()} defaultIconSet={defaultIconSet} className={`flex-shrink-0 opacity-60 scale-[0.8]`} /> : null;
    
    

    const shadowStyles = size == 'small' ? 'shadow-sm' : 'shadow'
    const dropdownSizeStyles = size == 'small' ? 'py-1.5 px-1.5 rounded-md gap-1' : 'p-2 rounded-lg gap-1.5'
    const dropwdownClasses = `absolute -bottom-2 left-1/2 -translate-x-1/2 text-base-content translate-y-full border flex flex-col min-w-full bg-${dropdownBgColor} ${dropdownSizeStyles} ${shadowStyles}` 
    
    const [ open, setOpen ] = useState(showDropdown)
    useEffect(() => {
        setOpen(showDropdown);
    }, [showDropdown]);

    let wrapperClasses = `relative flex-shrink-0 flex items-center justify-center`
    wrapperClasses += ` ${outlineStyle}` /* remove line */

    return (
        <div
            {...attributes} {...listeners} 
            {...junoAttributes} /* remove line */
            className={wrapperClasses}
            /* replace to '        onClick={() => children && children?.length > 0 ? setOpen(!open) : onClick()}' */
            >
            <div className={linkClasses}>
                {LeftIcon}
                <div className='flex-grow w-full'>
                    <EditableOnDoubleClick text={text} preview={preview} whiteSpace='nowrap' onBlur={onPropertyUpdate('text')} /* replace to '                {text}' */ />
                </div>
                {RightIcon}
            </div>
            {open && <div className={dropwdownClasses} 
            style={{ minWidth: size == 'small' ? 120 : 180, minHeight: size == 'small' ? 28 : 36}}>
                    {children}
            </div>}
        </div>
    );
}

HeaderLink.propTypes = {
    background: PropTypes.oneOfType([
        PropTypes.oneOf(["base-0", "base-100", "base-200", "primary", "accent", "base-900"]),
        PropTypes.string]),
    text: PropTypes.string,
    fontWeight: PropTypes.oneOf(['auto', 'light', 'normal', 'medium', 'semibold']),
    size: PropTypes.oneOf(['small', 'medium']),
    leftIcon: PropTypes.oneOf(['none', ...allIconNames]),
    rightIcon: PropTypes.oneOf(['none', 'chevron-down', 'plus']),
    width: PropTypes.oneOf(['auto', 'full']),
    showDropdown: PropTypes.bool,
    openDirection: PropTypes.oneOf(['downward-right', 'downward-left']),
    dropdownBgColor: PropTypes.oneOf(['base-0', 'base-100', 'base-200', 'none',]),
    // openBehavior: PropTypes.oneOf(['onHover', 'onClick']),
};

/* ignore rest */

HeaderLink.definitions = {
    apiName: 'HeaderLink',
    displayName: 'Header Link',
    description: 'Navigation link that goes into header. Shows options on hover or click.',
    ai_instructions: '...',
    type: 'navigation',
    relativeSize: 'small',
    acceptedChildren: 'any',
    propDefinitions: {
        text: {
            type: 'string',
            default: 'Item', 
            displayName: 'Text',
        },
        background: {
            type: 'colors',
            options: ['base-0', 'base-100', 'base-200', 'primary', 'accent', 'base-900'],
            displayName: 'Link Background',
            default: null
        },
        width: {
            type: "width",
            options: ["auto", "full"],
            displayName: "Width",
            default: "auto", 
            tile: '1/2'
        }, 
        size: {
            type: 'size',
            options: ['small', 'medium'],
            displayName: 'Size',
            default: 'medium', 
            tile: '1/2'
        },

        rightIcon: {
            type: 'oneOf',
            options: ['none', 'chevron-down', 'plus'],
            displayName: 'Right Icon',
            default: 'NavArrowDown', 
            tile: '1/2'
        },
        fontWeight: {
            type: 'oneOf',
            options: ['auto', 'light', 'normal', 'medium', 'semibold'],
            displayName: 'Font Weight',
            default: 'medium', 
            tile: '1/2'
        },
        leftIcon: {
            type: 'icon',
            options: ['none', ...allIconNames],
            ai_instructions: 'Should be rarely used, only for special cases.',
            displayName: 'Left Icon',
            default: 'none' 
        },
        openDirection: {
            type: 'oneOf',
            options: ['downward-right', 'downward-left', 'upward-right', 'upward-left'],
            displayName: 'Dropdown Direction',
            default: 'downward-right', 
        },
        dropdownBgColor: {
            type: 'colors',
            options: ['base-0', 'base-100', 'base-200', 'base-700', 'base-900'],
            displayName: 'Dropdown Background',
            default: 'base-0', 
        },
        showDropdown: {
            type: 'bool',
            displayName: 'Show Dropdown',
            default: false,

        }, 
    }
};


/*
        openBehavior: {
            type: 'oneOf',
            options: ['onHover', 'onClick'],
            displayName: 'Open Behavior',
            default: 'onClick'
        },*/