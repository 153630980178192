import React, { useState, useRef, useContext, useEffect } from 'react';
import * as IconoirIcons from 'iconoir-react';
import { EditorContext } from "../../EditorContext";
import ViewSwitch from '../ViewSwitch';



export default function RevealFlow({tooltipStyle, featureName}) {
    const { view, setView } = useContext(EditorContext);
    
    return (
      <div className={`w-auto gap-1 select-none bg-white pl-1.5 h-7 rounded-md items-center transition-all justify-center flex relative group cursor-pointer
      ring-1 ring-inset ring-base-300 font-normal`}
              
              >
                <ViewSwitch tooltipStyle={tooltipStyle}/>
                <div className='flex flex-row gap-1 border-l border-slate-300 h-full items-center px-2'
                onClick={()=> setView({...view, miniNav: !view.miniNav})}
                >
                <span className='flex-grow max-w-[100px] truncate'
                > {featureName}
                </span>
                {!view.miniNav ? <IconoirIcons.FastArrowDown width={14} height={14} className="group-hover:scale-110 flex-shrink-0 -mr-1" /> :
                <IconoirIcons.FastArrowUp width={14} height={14} className="group-hover:scale-110 flex-shrink-0 -mr-1" />}
                </div>
                
                

              </div>
      
    );
  }
  