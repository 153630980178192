import React from 'react';
import PropTypes from 'prop-types'

export default function NotFound(props) {

    const { self, junoAttributes, setRefs, outlineStyle, eventListeners} = props.junoProps || {}
    let classes = `flex flex-row gap-1 bg-accent-100 text-primary rounded px-2 py-1 self-start`
    classes += ` ${outlineStyle}`
    return (
        <div 
            {...junoAttributes} /* remove line */    
            className={classes}
            
        >
            {self?.componentAPIName} not found
        </div>
    );
}

NotFound.definitions = {
    apiName: 'NotFound',
    displayName: 'Not Found',
    description: 'used in Juno UI to mark error',
    ai_instructions: 'used in Juno UI to mark error', // is not passed to ai, but probably should be
    propDefinitions: {}
}

NotFound.propTypes = {};

NotFound.defaultProps = {};

NotFound.junoDefinitions = {
    apiName: 'NotFound',
    displayName: 'Not Found',
    description: 'used in Juno UI to mark error',
    ai_instructions: 'used in Juno UI to mark error', // is not passed to ai, but probably should be
    propDefinitions: {}
};
