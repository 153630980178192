import { useContext, useMemo, useRef } from "react";
import { convertJSXToObjectsSync } from "../../../../../utilities/AIhelpers";
import { createNewFrame } from "../../../../../utilities/helpers";
import DnDContext from "../../../dragNdrop/DnDContext";
import { EditorContext } from "../../../EditorContext";
import { canPaste } from "../helpers";
import LayoutThumbnail from "./layout_thumbnails"
import { Plus } from "iconoir-react";

export const layouts = [
  {name: 'Main and Sidebar', 
  apiname: 'appshell_main_sidebar',
    description: 'Sidebar, Main',
    jsx: '<AppShell><Main /><Sidebar /></AppShell>',
    image: 'main_sidebar'
  },
  {name: 'Header, Main', 
    apiname: 'appshell_header_main',
     description: 'Header and Main', 
     jsx: '<AppShell><Header /><Main /></AppShell>',
     image: 'header_main'
    },
    
    {name: 'Iconbar, Sidebar, Main', 
    apiname: 'appshell_iconbar_sidebar_main',
     description: 'Same as above but with skinny icon bar', 
     jsx: '<AppShell><IconBar /><Sidebar /><Main /></AppShell>',
     image: 'iconbar_sidebar_main'
    },
    
    {name: 'Main + Feature', 
    apiname: 'appshell_main_and_feature',
     description: 'Half page is Main + the other half is FeaturePanel. Good for onboarding, auth screens.', 
     jsx: '<AppShell><Main /><FeaturePanel /></AppShell>', 
     image: 'main_feature'
    },  
    
    {name: 'Main', 
    apiname: 'appshell_main',
     description: 'Just Main that takes over entire screen', 
     jsx: '<AppShell><Main /></AppShell>', image: 'main'
    },
    
    {name: 'Email',
      apiname: 'email', 
     description: 'Email', 
     jsx: `<Email corners="md" gap="none" mob_padding="md">
     <Module module_name="Logo" module_role="Company logo display">
       <Logo size="28px" type="symbol" />
     </Module>
     <Module module_name="Email Header" module_role="Email subject line">
       <Heading text="Email Subject Line " marginBottom="none" marginTop="sm" />
     </Module>
     <Module module_name="Content" module_role="Content and CTA">
       <Paragraph  />
       <Button text="Sign In" />
     </Module>
     <Module module_name="Disclaimer" module_role="Email disclaimer information">
       <Text text="If you did not request this, please ignore the email." />
     </Module>
     <Module module_name="Social Links" module_role="Links to social media profiles" flexDirection="flex-row">
       <IcoNoirIcon name="X" size="20" />
       <IcoNoirIcon name="Linkedin" size="20" />
       <IcoNoirIcon name="Tiktok" size="20" />
       <IcoNoirIcon name="Instagram" size="20" />
     </Module>
   </Email>`, 
     image: 'email'
    }/*,
    {name: 'Hero, Sidebar, Main',
      apiname: 'appshell_hero_sidebar_main', 
     description: 'Email', 
     jsx: '<AppShell><Hero /><Sidebar /><Main /></AppShell>',
     image: 'hero_sidebar_main'
    },*/
    
]



export default function TypicalLayouts() {


  return (
    <div className="w-full flex flex-col items-start text-xs font-semibold">
        <div className="grid grid-cols-2 w-full gap-3">
            {layouts.map(layout =>
                <Layout layout={layout} key={layout.name}/>
            )}
        </div>
    </div>
);
            }


function Layout({layout}) {
    const {selector, view, handleAction } = useContext(EditorContext)
    const {handleDragStart, handleDragEnd} = useContext(DnDContext)

      const { jsx } = layout

      const { objects, rootObject, action, descendants } = useMemo(() => {
        const objs = convertJSXToObjectsSync(jsx) || [];
        const rootObj = objs.find((obj) => obj.parent === "rootObject");
        const descendants = objs.filter((obj) => obj.id !== rootObj.id) || [];
    
        const action = {
          type: "INSERT_OBJECT",
          object: rootObj,
          descendants,
        };
    
        return { objects: objs, rootObject: rootObj, action, descendants };
      }, [jsx]);

      
      
      const self = {
        source: 'library', 
        componentAPIName: rootObject?.componentAPIName,
        jsx, 
      }
      const myRef = useRef(null)
      
      const selectedItem = selector.object || selector.frame;
      
      function handleClick() {
        // if (canPaste(selectedItem, rootObject)) {
          handleAction(action);
        // }
      }

      return (
        <div 
        ref={myRef} 
        className="flex flex-col gap-2 cursor-grab text-xs "
        
        draggable={view.disableDnD ? false : true}
        onDragStart={(event) => {event.stopPropagation(); handleDragStart(event, self, myRef)}}
        onDragEnd={(event) => {event.stopPropagation(); handleDragEnd(event, self, myRef)}}
        
                        >
                            <div className="w-full text-gray-300 rounded overflow-hidden hover:scale-105 hover:text-primary transition-all duration-150
                            relative group
                            ">
                            <LayoutThumbnail width={'100%'} name={layout.image} />
                            

                            <div className="absolute bottom-2 right-2 p-1 bg-base-0 rounded-full ring-2 ring-base-300
                            hover:scale-110 transition-all duration-75 opacity-0 group-hover:opacity-100
                            cursor-default"
                            // onClick={() => handleClick()}
                            >
                              <Plus width={16} height={16} style={{strokeWidth: 2}} className="scale-125"/>
                            </div>
                            </div>
                            <span className="truncate font-medium">{layout.name}</span>
                    </div>
      )
}