import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../index'; /* replace to 'import { Icon } from './index'' */
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import { MediaImage } from 'iconoir-react';
import { useMemo } from 'react';


export default function ProductCard(props) {

    const {
        title = "Product Name",
        descriptionFirstLine = "Description line 1",
        descriptionSecondLine = null,
        width = '100%',
        corners = 'md',
        textSize = 'small',
        
        price = "$50",
        imageSrc = null,
        rating = "4.5",
        tag = null,
        priceNote = "total",
        attributes,
        listeners
      } = props;
    const { junoAttributes, showTag, outlineStyle, onPropertyUpdate=()=>{} } = props.junoProps || {} /* remove line */

    const sizeStyles = `w-full h-auto ${textSize == 'small' ? 'text-sm' : 'text-base'}`
    const truncateStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}

    let wrapperClasses = `flex flex-col items-stretch justify-start gap-3 ${sizeStyles}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */
    
    const contentClasses = `flex flex-col flex-grow`
    
    const noImage = !imageSrc;
    const imageStyles = useMemo(() => ({
        background: !noImage && `linear-gradient(rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.12)), url(${imageSrc}) no-repeat center center / cover`, 
        backgroundSize: 'cover', 
        backgroundColor: `color-mix(in srgb, var(--base-content) 20%, transparent)`
    }), [imageSrc, noImage]);

    
    const cornerStyles = corners === 'none' ? '' : `rounded-${corners}`;
    const titleFont = textSize == 'small' ? 'text-base' : 'text-lg';
    const smallerFont = textSize == 'small' ? 'text-xs' : 'text-sm';


    return (
        <div 
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
        className={wrapperClasses} style={{maxWidth: width}}
        >
        {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Product Card</div> /* remove line */}
        {/* IMAGE */}
        <div className={`relative w-full aspect-square flex items-center justify-center ${cornerStyles}`} style={imageStyles}>
            
            {/* TAG */}
            {tag && 
                <div className='absolute top-1.5 left-2 rounded-full px-3 py-1.5 bg-base-0 shadow font-medium max-w-[160px] truncate overflow-ellipsis whitespace-nowrap'>
                {<EditableOnDoubleClick text={tag} onBlur={onPropertyUpdate('tag')} whiteSpace="nowrap" classes='truncate whitespace-nowrap'/> /* replace to '{tag}' */}
            </div>}
                
            {/* ICON */}
            {<div className='absolute top-1.5 right-2 rounded-full transition-all cursor-pointer p-2 hover:bg-base-0'>
                <Icon icon={'heart'} />
            </div>}
        {noImage && <MediaImage width={60} height={60} style={{opacity: '0.3'}} />}
        </div>
        
        {/* Description */}
        <div className={contentClasses}>
        
        {/* Title */}
        <div className={`mb-sm flex flex-row gap-2 justify-between items-center ${titleFont}`}>
            <h3 className={`font-semibold`} style={truncateStyle}>
                {<EditableOnDoubleClick text={title} onBlur={onPropertyUpdate('title')} whiteSpace="nowrap"/> /* replace to '{title}' */}
            </h3>
            {rating && <div className='flex-shrink-0 flex flex-row items-center gap-0.5'>
                <Icon icon='star' className='flex-shrink-0 scale-75'/>
                {<EditableOnDoubleClick text={rating?.slice(0, 5)} onBlur={onPropertyUpdate('rating')} whiteSpace="nowrap"/> /* replace to '{rating}' */}
            </div>}
        </div>

        {/* Description Lines */}
        {descriptionFirstLine && <span className={`${smallerFont} font-normal truncate overflow-ellipsis`} style={truncateStyle}>
            {<EditableOnDoubleClick text={descriptionFirstLine} onBlur={onPropertyUpdate('descriptionFirstLine')} whiteSpace="nowrap"/> /* replace to '{descriptionFirstLine}' */}
        </span>}
        {descriptionSecondLine && <span className={`${smallerFont} font-light`} style={truncateStyle}>
            {<EditableOnDoubleClick text={descriptionSecondLine} onBlur={onPropertyUpdate('descriptionSecondLine')} whiteSpace="nowrap"/> /* replace to '{descriptionSecondLine}' */}
        </span>}

        {/* Price and Price Note */}
        {price && 
            <div className={`mt-2 flex flex-row gap-1 items-end items-baseline ${smallerFont}`}>
                <span style={truncateStyle} className={`font-semibold ${titleFont}`}>
                    {<EditableOnDoubleClick text={price} onBlur={onPropertyUpdate('price')} whiteSpace="nowrap"/> /* replace to '{price}' */}
                </span>
                <span style={truncateStyle}>
                    {<EditableOnDoubleClick text={priceNote} onBlur={onPropertyUpdate('priceNote')} whiteSpace="nowrap"/> /* replace to '{priceNote}' */}
                </span>
            </div>
        }
        </div>
        </div>
    );
}

ProductCard.propTypes = {
    imageSrc: PropTypes.string,
    textSize: PropTypes.oneOf(['small', 'medium']),
    width: PropTypes.oneOf(['100%', '200px', '320px']),
    title: PropTypes.string.isRequired,
    tag: PropTypes.string,
    rating: PropTypes.string,
    descriptionFirstLine: PropTypes.string,
    descriptionSecondLine: PropTypes.string,
    price: PropTypes.string,
    priceNote: PropTypes.string,
    corners: PropTypes.oneOf(["none", "sm", "md", "lg", 'xl']),
};

/* ignore rest */

ProductCard.definitions = {
    apiName: 'ProductCard',
    displayName: 'ProductCard',
    description: 'Product card with image, title, description, price and rating. Can be used in product listings, product pages.',
    ai_instructions: 'use for product listings especially in search scenarios. can be used both for physical products and services, e.g. hotels, flights, etc.',
    type: 'cards',
    relativeSize: 'medium',
    acceptedChildren: 'none',
    propDefinitions: {
        title: {
            type: 'string',
            default: "Product Name", 
            displayName: 'Title',
            required: true
        }, 
        descriptionFirstLine: {
            type: 'string',
            default: "Description line 1",
            ai_instructions: 'First line of product description', 
            displayName: 'Description'
        },
        descriptionSecondLine: {
            type: 'string',
            default: null,
            ai_instructions: 'Second line of product description. Not recommended', 
            displayName: 'Second Description'
        },
        price: {
            type: 'string',
            default: "$50", 
            displayName: 'Price', 
            tile: '1/2'
        },
        priceNote: {
            type: 'string',
            default: "total", 
            displayName: 'Note',
            tile: '1/2'
        },
        width: {
            type: 'oneOf',
            options: ['100%', '200px', '320px'],
            default: '100%', 
            displayName: 'Width',
            tile: '1/2'
        },
        textSize: {
            type: 'size',
            options: ['small', 'medium'],
            ai_instructions: 'small sets title, price to text-sm and description to text-xs, medium sets title and price to text-base and description to text-sm',
            default: 'small', 
            displayName: 'Text Size',
            tile: '1/2'

        },
        corners: {
            type: 'corners',
            options: ["none", "sm", "md", "lg", 'xl'],
            default: 'md',
            displayName: 'Corners',
            ai_instructions: 'rounded corners for the thumbnail image', 
            tile: '1/2'
        },
        rating: {
            type: 'string',
            default: "4.5",
            displayName: 'Rating',
            ai_instructions: 'short, will be truncated to 5 characters', 
            tile: '1/2'
        }, 
        tag: {
            type: 'string',
            default: null,
            displayName: 'Tag',
            ai_instructions: 'tag appears in top left corner. typically used to highlight a card, so if there are many cards, use tag only on few',
        },
        
        imageSrc: {
            type: 'imageURL',
            displayName: 'Image',
            default: null
        },

    }
};
