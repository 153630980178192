import React from 'react';
import Icon from '../../../../../components/icons/Icon';

export default function SelectJustify({ propKey, options, currentOption, onSelect, currentDirection }) {
    const direction = currentDirection || 'flex-col'
    const handleSelect = (value) => {
      onSelect(propKey, value);
  };

  const optionsIcons = {
    'start': direction === 'flex-row' ? 'justify-left' : 'justify-top',
    'end': direction === 'flex-row' ? 'justify-right' : 'justify-bottom',
    'center': direction === 'flex-row' ? 'justify-horizontal-center' : 'justify-vertical-center',
    'between': direction === 'flex-row' ? 'justify-horizontal-space-between' : 'justify-vertical-space-between',
    'around': direction === 'flex-row' ? 'justify-horizontal-space-around' : 'justify-vertical-space-around',
    'evenly': direction === 'flex-row' ? 'justify-horizontal-space-evenly' : 'justify-vertical-space-evenly',
  };

  

  return (
    <div 
        className='items-center flex flex-row flex-grow w-full rounded-lg p-1 bg-base-100 hover:bg-base-200 text-sm transition-all duration-150'
        style={{userSelect: 'none'}}
      >
        {options.map((option) => (
          <button
            key={option}
            className={`w-full pointer text-sm flex items-center justify-center rounded-md py-1  transition-all duration-150 lowercase
            ${currentOption === option ? `bg-base-0 ring-1 ring-base-100 `
            : `opacity-80 hover:opacity-100`}`}
            style={{
              fontWeight: 500,
              flexGrow: 1,
              zIndex: 2
            }}
            onClick={() => handleSelect(option)}
          >
            <Icon name={optionsIcons[option]} width={20} height={20} fill={'currentColor'}/>
          </button>
        ))}
      </div>
  );
};
