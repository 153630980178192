import React, { useState, useRef, useContext, useEffect } from 'react';
import * as IconoirIcons from 'iconoir-react';
import { EditorContext } from "../EditorContext";

export default function ViewSwitch({tooltipStyle}) {
    const {view, setView } = useContext(EditorContext);
    
    const iconAttributes = {
      height: 16,
      width: 16,
      style: {strokeWidth: 1.5}
    }
    return (
      <button
      className={`py-1 h-7 px-0.5 rounded-md cursor-pointer flex-shrink-0 flex items-center justify-center hover:scale-105 transition-all relative group duration-75
      `}
      onClick={() => setView({...view, overview: !view.overview, inOverview: 'folders'})}
        
      >
        {view.overview ? <IconoirIcons.EditPencil {...iconAttributes} /> : <IconoirIcons.ViewGrid {...iconAttributes} />}
        {/*<span className={`${tooltipStyle} -bottom-1 transform translate-y-full left-0`}
        onClick={e => e.stopPropagation()}
        >
          !view.overview ? 'See project' : 'Edit page'
        </span>*/}
          </button>
    );
  }
