import { nanoid } from 'nanoid';
import { AlignLeft, ArrowDown, ArrowDownTag, ArrowUpTag, Calendar, Cell2x2, CheckCircle, CheckSquare, Cube, Dialpad, Emoji, Flower, Iconoir, InputField, LayoutLeft, Link, MediaImage, Menu, MouseButtonLeft, NavArrowDown, NavArrowRight, Page, PanoramaEnlarge, Plus, Reports, RubikCube, Table2Columns, Text, TextBox, ViewColumns3, ViewGrid } from 'iconoir-react';
import { useRef } from 'react';
import { canPasteSimplified } from '../../../../utilities/pasteRules';

const gridDescendants = [
    {
      id: nanoid(10),
      APIName: 'div',
      componentAPIName: 'FlexBox',
      index: 1, 
      parent: null,
      frame: null,
      mobile_props: {},
      text: '',
      object_props: {background: 'base-0', corners: 'base', paddingX: '12px', paddingY: '12px', gap: '8px'}
    }, 
    {
      id: nanoid(10),
      APIName: 'div',
      componentAPIName: 'FlexBox',
      index: 2, 
      parent: null,
      frame: null,
      mobile_props: {},
      text: '',
      object_props: {background: 'base-0', corners: 'base', paddingX: '12px', paddingY: '12px', gap: '8px'}
    },
    {
        id: nanoid(10),
        APIName: 'div',
        componentAPIName: 'FlexBox',
        index: 3, 
        parent: null,
        frame: null,
        mobile_props: {},
        text: '',
        object_props: {background: 'base-0', corners: 'base', paddingX: '12px', paddingY: '12px', gap: '8px'}
      }
  ]
  
export default function Keypad({handleDragEnd, handleDragStart, frameId, selector, handleAction}) {

const iconAttributes = {
    width: 20, 
    height: 20,
    className: 'stroke-[1.6px] group-hover:stroke-[1.8px] flex-shrink-0 group-hover:scale-125 transition-all duration-75'
}

const list = [
    { icon: <ViewColumns3 {...iconAttributes} className='stroke-[1.6px] group-hover:stroke-[1.8px] flex-shrink-0 group-hover:scale-125 transition-all duration-75 rotate-90' />, title: 'v stack', componentAPIName: 'FlexBox', props: {direction: 'flex-col', background: 'base-50', paddingX: '12px', paddingY: '12px', gap: '12px'} },
    { icon: <ViewColumns3 {...iconAttributes} />, title: 'h stack', componentAPIName: 'FlexBox', props: {direction: 'flex-row', background: 'base-50', paddingX: '12px', paddingY: '12px', gap: '12px'} },
    { icon: <RubikCube {...iconAttributes} />, title: 'grid', componentAPIName: 'Grid', props: {background: 'base-50', paddingX: '12px', paddingY: '12px', gap: '12px'}, descendants: gridDescendants },
    { icon: <Text {...iconAttributes} />, title: 'heading', componentAPIName: 'Heading', props: {} },
    { icon: <AlignLeft {...iconAttributes} />, title: 'paragraph', componentAPIName: 'Paragraph', props: {} },
    { icon: <TextBox {...iconAttributes} />, title: 'text', componentAPIName: 'Text', props: {} },
    { icon: <PanoramaEnlarge {...iconAttributes} />, title: 'button', componentAPIName: 'Button', props: {} },
    { icon: <Iconoir {...iconAttributes} />, title: 'icon', componentAPIName: 'ButtonIcon', props: {} },
    { icon: <Link {...iconAttributes} />, title: 'link', componentAPIName: 'Link', props: {} },
    
    { icon: <InputField {...iconAttributes} />, title: 'input', componentAPIName: 'InputText', props: {} },
    { icon: <ArrowDownTag {...iconAttributes} />, title: 'select', componentAPIName: 'Select', props: {} },
    { icon: <CheckSquare  {...iconAttributes} />, title: 'checkbox', componentAPIName: 'Checkbox', props: {} },
    

    { icon: <MediaImage {...iconAttributes} />, title: 'image', componentAPIName: 'Image', props: {} },
    { icon: <Emoji  {...iconAttributes} />, title: 'avatar', componentAPIName: 'Avatar', props: {} },
    { icon: <Flower  {...iconAttributes} />, title: 'logo', componentAPIName: 'Logo', props: {} },
    
    
    
    
    { icon: <Table2Columns  {...iconAttributes} />, title: 'table', componentAPIName: 'TableWidget', props: {} },
    { icon: <Reports  {...iconAttributes} />, title: 'barchart', componentAPIName: 'BarChart', props: {} },
    { icon: <Calendar  {...iconAttributes} />, title: 'datepicker', componentAPIName: 'MiniCalendar', props: {} },
]

    

return (
    <div className="grid  w-full bg-base-200 dark:bg-base-0 gap-1.5 p-1.5 h-auto rounded-lg ring-1 ring-base-300 dark:ring-base-100 transition-all duration-75" 
    style={{alignItems: 'start', gridTemplateColumns: 'repeat(3, minmax(0px, 1fr))'}}>
    
    {list.map((item, index) => (
        <Wrapper key={index} item={item} frameId={frameId} selectedObject={selector?.object} frame={selector?.frame}
        handleAction={handleAction}
        handleDragEnd={handleDragEnd} handleDragStart={handleDragStart}>
            
        </Wrapper>
    ))}
</div>
)

}

function Wrapper({ item, children, selectedObject, frameId, frame,  
    handleAction,
    handleDragStart, handleDragEnd }) {
const myRef = useRef(null);

const rootObject = {
    id: nanoid(10),
    APIName: 'div',
    componentAPIName: item.componentAPIName,
    index: 1, 
    parent: 'rootObject',
    frame: frameId,
    mobile_props: {},
    text: '',
    object_props: item.props || {},
}

const descendants = item?.descendants && item.descendants.length > 0 
    ? item.descendants.map(descendant => ({...descendant, frame: frameId, parent: rootObject.id}))
    : [];

const self = {
    source: 'library', 
    componentAPIName: rootObject?.componentAPIName,
    objects: [...descendants, rootObject],
}

const canDrag = true // !view.disableDnD && view.mode == 'editor'

// const paste = canPasteSimplified(parentAPIName, childAPIName) || false
let canPaste = !selectedObject ? false : canPasteSimplified(selectedObject?.componentAPIName, item.componentAPIName) || false

function paste() {

    if (!canPaste) return

    const parent = selectedObject
    const siblings = frame.objects.filter((o) => o.parent == parent.id);
    
    const action = {
      type: "INSERT_OBJECT",
      object: rootObject,
      parent: parent,
      index: siblings.length + 1,
      descendants,
    };
    handleAction(action);
  }

return (
    <div
    className={`flex flex-col flex-nowrap p-1 hover:pt-3 hover:scale-100 w-full rounded max-w-full bg-base-0 dark:bg-base-50 items-start justify-start h-16
    cursor-grab items-center justify-center group relative text-base-700 hover:text-base-content
    ring-1 ring-base-300 hover:ring-base-400 
    dark:ring-base-100 dark:hover:ring-base-200
    transition-all duration-75
    `}
    ref={myRef}
    draggable={canDrag}
    onClick={()=>canPaste && paste()}
    onDragStart={(event) => {canDrag && event.stopPropagation(); handleDragStart(event, self, myRef)}}
    onDragEnd={(event) => {canDrag && event.stopPropagation(); handleDragEnd(event, self, myRef)}}
    >
    {item.icon}
    <span className='opacity-0 h-0 mt-0 group-hover:h-3 group-hover:opacity-50 group-hover:mt-2 transition-all duration-75
    whitespace-nowrap  text-2xs flex flex-row gap-1 items-center justify-between'>
            {item.title}
    </span>
    { canPaste &&
                  
                  
                  <Plus width={16} height={16} className="absolute top-1 right-1 opacity-0 
                  group-hover:opacity-70 hover:opacity-100 hover:scale-125 hover:stroke-[2px]
                  transition-all duration-75 cursor-copy" 
                  
                  />
                }
    </div>
);
}


