/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Color from 'color'; // Import the color library
import { useRef } from 'react';

export default function MainColorPicker({ theme, onColorChange, colorName, isDarkTheme = false }) {
    const colorSet = theme.colors

    function updateColors(e, colorName, isDark=false) {
        const luminosityLimit = isDark ? 10 : 90
        
        const newColorHex = e.target.value;
        const newColorHSLA = Color(newColorHex).hsl();

        const hue = Math.round(newColorHSLA.hue());
        const saturation = Math.round(newColorHSLA.saturationl());
        let luminosity = Math.round(newColorHSLA.lightness());
        luminosity = isDark ? Math.max(luminosity, luminosityLimit) : Math.min(luminosity, luminosityLimit);
        const alpha = newColorHSLA.alpha().toFixed(2);

        
        // Adust luminosity based on whether theme is dark or light
        const focusLuminosity = isDark ? Math.min(luminosity + 5, luminosityLimit) : Math.max(luminosity - 5, 0);
        const contentLuminosity = luminosity > 50 ? 10 : 90

        // Construct new HSLA values for focus and content colors
        const color = `hsla(${hue}, ${saturation}%, ${luminosity}%, ${alpha})`
        const focusColor = `hsla(${hue}, ${saturation}%, ${focusLuminosity}%, ${alpha})`
        const contentColor = `hsla(${hue}, ${saturation}%, ${contentLuminosity}%, ${alpha})`

        const colors = {
            [colorName]: color,
            [`${colorName}-focus`]: focusColor,
            [`${colorName}-content`]: contentColor,
        };
        
        onColorChange(colors);
    }

    const inputRef = useRef(null);
    const handleClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const circleSize = 72
    return (
        
        <div
            className={`group relative flex flex-row`}            
            onClick={handleClick}
            
        >

<div className="bg-gray-100 rounded-full z-10 hover:scale-105 transition-all flex items-center justify-center text-lg" 
                style={{   
                        width: circleSize, height: circleSize,
                        backgroundColor: theme.colors[colorName], 
                        color: theme.colors[colorName+'-content']
                }} >{colorName.slice(0,1).toUpperCase()}</div>
            <div className="flex items-center justify-center text-lg bg-slate-200 rounded-full transition-all" 
                style={{
                    marginLeft: `-${circleSize/2}px`, width: circleSize, height: circleSize, transform: 'scale(0.8) translateY(10%)',
                    backgroundColor: theme.colors[colorName+'-focus'], color: theme.colors[colorName+'-content']
                    }}></div>
            
            <input
                 ref={inputRef}
                    type="color"
                    name={colorName}
                    value={Color(colorSet[colorName]).hex()} // Convert HSLA to Hex for the input value
                    onChange={(e) => updateColors(e, colorName, isDarkTheme)}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 0,
                    }}
                />
        </div>
    );
}