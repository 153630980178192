import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react';
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import { Icon } from '../index'; /* replace to 'import { Icon } from './index'' */

export default function Select(props) {
    
    const {
        size = 'medium',
        value = '',
        placeholder = 'Select',
        showOptions = false,
        bgColor = 'base-0',
        label = '',
        helperText = '',
        state = 'default',
        rightIcon = 'chevron-down',
        width = 'auto',
        hasOutline = false,
        onSelect = () => {},
        defaultIconSet = 'heroicons',
        options = ['Option A', 'Option B', 'Option C'],
        attributes,
        listeners
      } = props;
    const { junoAttributes,  showTag, setRefs, self, outlineStyle, eventListeners, onPropertyUpdate=() => {}, preview } = props.junoProps || {} /* remove line */

    const [ open, setOpen ] = useState(showOptions)
    const selectedOption = value || ''

    useEffect(() => {
        setOpen(showOptions);
    }, [showOptions]);
    
    const sizeStyles = size == 'small' ? `py-1 px-2 gap-1.5 text-xs` : size == 'large' ? `py-2 px-3 gap-3 text-base` : `py-1.5 px-2 gap-3 text-sm`;
        
    const cornerStyles = size == "small" ? "rounded" : size == "large" ? "rounded-lg" : "rounded-md"
    let stateStyles = '';
    switch (state) {
        case 'default':
            stateStyles = hasOutline ? open ? `border border-accent` : `border border-base-300` : 'border border-transparent'
            break;
        case 'disabled':
            stateStyles = `bg-base-100 opacity-70 cursor-not-allowed ${hasOutline ? 'border border-base-300' : ''}`
            break;
        case 'error':
            stateStyles = `text-warning-content ${hasOutline ? 'border border-warning-content' : ''}`
            break;
        case 'success':
            stateStyles = `${hasOutline ? 'border border-success-content' : ''}`
            break;
    }

    const bgStyles = (bgColor && bgColor !== 'none') ? `bg-${bgColor} text-base-content` : '';
    let classes = `w-full flex items-center justify-between truncate ellipsis box-border font-medium select-none ${sizeStyles} ${cornerStyles} ${bgStyles} ${stateStyles}`
    const labelTextSize = size == 'small' ? `text-xs` :  size == 'large' ? `text-lg`: `text-sm`;
    const labelClasses = `${bgColor == 'none' ? '': 'text-base-content'} ${labelTextSize} font-medium`

    const messageTextColor = state == 'error' ? stateStyles = 'text-error-content' : state == 'warning' ? stateStyles = 'text-warning-content' : state == 'success' ? stateStyles = 'text-success-content' : 'text-base-content'
    const messageClasses = `text-sm ${messageTextColor}`
    const widthStyle = width != 'auto' ? `w-${width}` : size == 'small' ? 'min-w-[120px]' : size == 'large' ? 'min-w-[200px]' : 'min-w-[160px]'
    const gapStyles = size == 'small' ? 'gap-0.5' : size == 'large' ? 'gap-1.5' : 'gap-1'
    let wrapperClasses = `flex flex-col ${widthStyle} ${gapStyles} relative`
    wrapperClasses += ` ${outlineStyle} ` /* remove line */

    const RightIconComponent = rightIcon !== 'none' ? 
        <Icon 
            icon={rightIcon?.toLowerCase()} 
            defaultIconSet={defaultIconSet} 
            className={`flex-shrink-0 flex-grow-0 opacity-80 scale-75`}
            /> : null;

    /* OPTIONS STYLING */
    const shadowStyles = size == 'small' ? 'shadow-sm' : size == 'large' ? 'shadow-md' : 'shadow'
    const optionsBorderRadius = (size === 'small' ? 'rounded' : size === 'large' ? 'rounded-lg' : 'rounded-md');
    const optionsClasses = `w-full absolute mt-2 bg-white overflow-hidden ${optionsBorderRadius} ${shadowStyles} border border-base-100`
    const optionSizeStyles = size == 'small' ? `py-0.5 px-2 gap-1.5 text-xs min-w-[120px]` :  size == 'large' ? `py-2 px-3 gap-3 text-base min-w-[200px]`: `py-1.5 px-2 gap-3 text-sm min-w-[160px]`;
    const optionClasses = `${optionSizeStyles} hover:bg-base-100 transition-all duration-100 ease-in-out cursor-default`

    return (
        <div
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
            className={wrapperClasses}
        
    >
        {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Select</div> /* remove line */}
        {label && <label className={labelClasses}>
                    <EditableOnDoubleClick text={label} onBlur={onPropertyUpdate('label')} whiteSpace='nowrap'  /* replace to '{label}' */ /> 
                </label>}
        <div className={classes}
        /* replace to 'onClick={() => setOpen(!open)}' */
        >
        {value ? /* replace to '{selectedOption ?' */
            <EditableOnDoubleClick text={value} onBlur={onPropertyUpdate('value')} whiteSpace='nowrap' /* replace to 'selectedOption :' */ /> :
            <span className={'text-base-500'}> 
            <EditableOnDoubleClick text={placeholder} onBlur={onPropertyUpdate('placeholder')} whiteSpace='nowrap' placeholder /* replace to '{placeholder}' */ />
            </span>} 
        
        {RightIconComponent}
        {open && (
            <div className={optionsClasses}
             style={{ position: 'absolute', top: '100%', left: 0, zIndex: 1 }}>
                {options.map((option, index) => (
                    <div 
                        key={index}
                        className={optionClasses}
                        /* replace to '                        onClick={(e) => {
                            e.stopPropagation();
                            onSelect(option);
                        }' */
                        >
                    {option}
                    </div>
                ))}
            </div>
        )}
        </div>
        {helperText && <span className={messageClasses}>
            <EditableOnDoubleClick text={helperText} onBlur={onPropertyUpdate('helperText')} whiteSpace='pre-wrap' /* replace to '{helperText}' */ />
        </span>}
    </div>
);  
}

Select.propTypes = {
    width: PropTypes.oneOf(['auto', '1/2', 'full']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    label: PropTypes.string,
    state: PropTypes.oneOf(['default', 'disabled', 'error', 'success']),
    bgColor: PropTypes.oneOf(['base-0', 'base-50', 'base-100', 'none']),
    value: PropTypes.string,
    placeholder: PropTypes.string,
    showOptions: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.string),
    rightIcon: PropTypes.oneOf(['chevron-down','none']),
    hasOutline: PropTypes.bool,
    onSelect: PropTypes.func,
    helperText: PropTypes.string,
};

/* ignore rest */

Select.definitions = {
    apiName: 'Select',
    displayName: 'Select Dropdown',
    description: 'A dropdown select component allowing users to choose from a list of options. Features include customizable size, background color, and the ability to show or hide options.',
    ai_instructions: 'dropdown list. show/hides options on click',
    type: 'input',
    relativeSize: 'small',
    acceptedChildren: 'none',
    propDefinitions: {
        
            
        state: {
            type: 'oneOf',
            options: ['default', 'disabled', 'error', 'success'],
            default: 'default', 
            displayName: 'State',
            tile: '1/2'
        },
        bgColor: {
            type: 'colors',
            options: ['base-0', 'base-100', 'base-50', 'none'],
            displayName: 'Background',
            default: 'base-100', 
            tile: '1/2',
        },
        width: {
            type: "width",
            options: ["auto", "1/2", "full"],
            displayName: "Width",
            default: "auto", 
            tile: '1/2',
            defaultOnMobile: 'full'
        },
        size: {
            type: 'size',
            options: ['small', 'medium', 'large'],
            displayName: 'Size',
            default: 'medium', 
            tile: '1/2'
        },
        
        label: {
            type: 'string',
            default: '', 
            displayName: 'Label',
        },

        value: {
            type: 'string',
            displayName: 'Value',
            default: '', 
        },
        options: {
            type: 'arrayOfStrings',
            displayName: 'Options',
            default: ['Option A', 'Option B', 'Option C'], 
        },
        placeholder: {
            type: 'string',
            default: 'placeholder text', 
            ai_instructions: 'Placeholder',
            required: true,
            ai_instructions: 'value example, e.g. "email@example.com" or "Acme Corp Inc". do not write placeholder like action "Enter text". limit to 3 words.'
        },
        helperText: {
            type: 'string',
            default: '', 
            displayName: 'Help Text (hint/error)',
            ai_instructions: 'the text under the field'
        },
        
        rightIcon: {
            type: "oneOf",
            options: ['chevron-down','none'],
            displayName: "Right Icon",
            default: 'chevron-down', 
            ai_instructions: 'icon',
            tile: '1/2'
        },

        
        showOptions: {
            type: 'bool',
            displayName: 'Show Options',
            default: false, 
            
        },
        hasOutline: {
            type: "bool",
            displayName: "Outline",
            default: false, 
            ai_instructions: 'adds base-300 1px outline',
        }, 
    }
}


