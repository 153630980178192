// BASE IMPORTS
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { getChildren, getDescendants } from "../../utilities/helpers";
import { mergeThemeVariables } from "../editor/sidebar/theme-editor/helpers";
import Icon from "../../components/icons/Icon";
import ShareProjectHeader from "./ShareProjectHeader";
import { useNavigate } from 'react-router-dom'
import ShowNotes from "./ShowNotes";
import { convertThemeVariablesToStyles } from "../editor/canvas/helpers";
import LightRouter from "../../components/LightRouter";

// ADD DROPDOWN FOR SELECTING FOLDER / PAGE / FRAME FROM SUBLINGS
// ADD MOBILE VIEW

export default function ShareProject({
  id=null,
  showBadge=false
}) {
  const params = useParams();
  const projectID = id || params.projectID; 

  // APP & USER
  const [ showCode, setShowCode ]= useState(false)
  const [ showNotes, setShowNotes ]= useState(false)

  const [project, setProject] = useState(null);
  const [designSystem, setDesignSystem] = useState(null);
  const [selectedFrame, setSelectedFrame] = useState(null);
  const selectedPage = selectedFrame && project?.pages?.find(p => p.id == selectedFrame.page_id)

  
  let selectedFolder = selectedPage && project?.folders?.find(f => f.id == selectedPage.folder_id)
  selectedFolder = selectedFolder && {...selectedFolder, pages: project?.pages?.filter(p=>p.folder_id == selectedFolder.id)}
  const [ isMobile, setIsMobile ] = useState(false)
  const [ narrowViewport, setNarrowViewport] = useState(false)
  const navigate = useNavigate();
  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const response = await axios.get(`/api/share/${projectID}`);
        
        setProject(response.data)

        // Fetch design system data
        fetchDesignSystemData(response.data?.designSystem?.id, projectID);

        // Set selectedFrame here after project data is loaded
        const initialFolder = response.data?.folders?.[0];
        const folderPages = response.data?.pages?.filter(p => p.folder_id === initialFolder?.id) || [];
        const initialPage = folderPages[0];
        const firstFrame = initialPage?.frames?.reduce((prev, current) => {
          return (prev.index < current.index) ? prev : current;
        }, initialPage?.frames[0]);
        
        setSelectedFrame(firstFrame); // Set the first frame of the first page as the selectedFrame
      } catch (error) {
        console.error("Error fetching page data:", error);
      }
    };

    const fetchDesignSystemData = async (designSystemID, projectID) => {
      try {
        const response = await axios.post(`/design-system/${designSystemID}`, {
          userId: null, 
          projectId: projectID || null
        })
        
        setDesignSystem(response.data);
      } catch (error) {
        console.error("Error fetching design system data:", error);
      }
    };

    fetchPageData();
  }, [projectID]);

  
  const children = getChildren(selectedFrame?.id, selectedFrame?.objects) || [];
  
  const defaultTheme = designSystem?.themes?.find(t => t.id == designSystem.designSystem.default_theme_id)
  const currentTheme = designSystem?.themes?.find(t => t.id == project.project?.current_theme_id)
  const effectiveVariables = currentTheme && defaultTheme ? mergeThemeVariables(defaultTheme, currentTheme) : defaultTheme?.variables
  

  const canvasRef = useRef(null);
  // Resize observer to track changes in the frame's size
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      if (width < 600) {
        setNarrowViewport(true)
      } else {setNarrowViewport(false)}
      
    });
  
    const currentRef = canvasRef.current;
  
    if (currentRef) {
      resizeObserver.observe(currentRef);
    }
  
    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
      resizeObserver.disconnect();
    };
  }, [canvasRef]); 
  
  const [message, setMessage] = useState('');
  const clickCountRef = useRef(0);
  const timeoutRef = useRef(null);

  const handleClick = () => {
    clickCountRef.current += 1;

    // Start a new timer if it's the first click
    if (clickCountRef.current === 1) {
        timeoutRef.current = setTimeout(() => {
            clickCountRef.current = 0; // Reset click count after 1 second
        }, 10000);
    }

    // If three clicks are detected within 1 second
    if (clickCountRef.current === 2) {
        setMessage('This is not yet a clickable prototype, but will be in the future');

        // Hide message after 3 seconds
        setTimeout(() => {
            setMessage('');
        }, 5000);

        // Clear the click count and the timeout
        clickCountRef.current = 0;
        clearTimeout(timeoutRef.current);
    }
};

  // Clear the timeout on unmount
  useEffect(() => {
      return () => {
          if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
          }
      };
  }, []);

  const themeStyles = convertThemeVariablesToStyles(effectiveVariables);
  const assets = project?.assets;

  return (
    <div className='flex relative flex-col w-screen items-center justify-stretch overflow-y-scroll bg-slate-50 pb-2 pt-1 px-2 gap-1'
      ref={canvasRef}
      style={{overflow: 'scroll', 
        fontFamily: 'Inter', 
        height: '100dvh'
      }}
      >
        {/* HEADER */}
        <ShareProjectHeader
          showCode={showCode} 
          setShowCode={setShowCode}
          isMobile={isMobile}
          setIsMobile={setIsMobile}
          project={project}
          selectedFrame={selectedFrame}
          setSelectedFrame={setSelectedFrame}
          narrowViewport={narrowViewport}
          selectedPage={selectedPage}
          selectedFolder={selectedFolder}
          setShowNotes={setShowNotes}
          showNotes={showNotes}

          />
        {/* FRAME */}
        <div className="flex flex-col overflow-hidden rounded relative"
        style={{
          ...themeStyles,
          width: '100%',
          height: '100%',
          boxShadow: '0 0 0 1px var(--base-200)',
        }}>
        <div className="flex flex-col w-full bg-blue-100 relative items-center justify-start overflow-y-scroll relative" 
                style={{
                  color: 'var(--base-content)',
                  height: '100%',
                  fontFamily: `var(--mainFont), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
                
              }}>
              {children.length != 0 &&
                  children
                      .sort((a, b) => a.index - b.index)
                      .map((object) => (
                          
                          <LightRouter
                              key={object.id} 
                              self={object} 
                              assets={assets} 
                              descendants={getDescendants(object.id, selectedFrame.objects)}
                          />
                              
                      ))}
        </div>
        </div> 
      
      {narrowViewport && <Footer project={project} selectedFrame={selectedFrame} setSelectedFrame={setSelectedFrame}/>}
      {/*!narrowViewport && <CodePreview showCode={showCode} selectedFrame={selectedFrame} setShowCode={setShowCode}/>*/}
      {!narrowViewport && <ShowNotes showNotes={showNotes} selectedFrame={selectedFrame} setShowNotes={setShowNotes}/>}
            
    </div>
  );
}

function Footer({project, selectedFrame, setSelectedFrame}) {
  const allFolders = project?.folders.sort((a, b) => a.index - b.index);
  const allFlows = project?.pages.filter(p => allFolders.map(f=>f.id).includes(p.folder_id)).sort((a, b) => a.index - b.index);
  const allFrames = allFlows?.flatMap(f => f.frames).filter(frame => !frame.isArchived).sort((a, b) => a.index - b.index);
  const framesByOrder = allFolders?.flatMap(folder => allFlows.filter(flow => flow.folder_id === folder.id).flatMap(flow => allFrames.filter(frame => frame.page_id === flow.id))) || []
  const currentIndex = framesByOrder.findIndex(frame => frame.id === selectedFrame?.id);

  const previousFrame = framesByOrder[currentIndex - 1];
  const nextFrame = framesByOrder[currentIndex + 1];

  function selectNextFrame() {if (nextFrame) {setSelectedFrame(nextFrame)}}
  function selectPreviousFrame() {if (previousFrame) {setSelectedFrame(previousFrame)}}

  return (
    <div className="fixed bottom-0 flex-row w-full shadow-lg justify-between bg-white" 
    style={{zIndex: 100, borderTop: '1px solid #AAA', height: 48}}>
        <button className={`transition-all ${previousFrame ? 'bg-white' : 'bg-slate-100'} w-1/2 h-full items-center flex justify-center`}
        onClick={selectPreviousFrame}
        ><Icon name='chevron-back' width={10} height={32} fill={`${!previousFrame ? '#CCC' : '#505366'}`} /></button>
        <button className={`transition-all ${nextFrame ? 'bg-white' : 'bg-slate-100'} w-1/2 h-full items-center flex justify-center`}
        onClick={selectNextFrame} style={{borderLeft: '1px solid #CCC'}}
        ><Icon name='chevron-right' width={10} height={32} fill={`${!nextFrame ? '#CCC' : '#505366'}`} /></button>
        
        </div>
  )
}
