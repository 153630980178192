import { useState, useEffect } from "react";
import Icon from "../../components/icons/Icon";
import AceEditor from 'react-ace';
import { convertObjectsToJSX } from "../../utilities/AIhelpers";

export default function ShowNotes({showNotes, selectedFrame, setShowNotes}) {

    return (
  <div 
              className={`absolute right-2 bg-white rounded-md shadow-lg border border-slate-300
              overflow-hidden flex flex-col transition-all duration-100 ease-in-out
              ${showNotes ? "opacity-100" : "opacity-0"} 
              `}
              style={{height: 'auto', minHeight: 240, top: 44,
              visibility: showNotes ? 'flex' : 'none', 
              width: showNotes ? 360 : 0,
            }}
              >
                <div className='flex flex-row justify-between items-center px-3 py-1 pr-2 font-medium border-b border-slate-300 ' >
                <h2 className="text-md font-semibold text-slate-900 truncate whitespace-nowrap"
                  >{selectedFrame?.name}</h2>
                  <button
                      className='hover:scale-110 transition-all cursor-pointer opacity-80 hover:opacity-90 mr-1'
                      onClick={() => setShowNotes(false)}>
                      <Icon width={12} height={12} fill="#333" name="xmark" />
                    </button>
                </div>
                <div className="px-3 py-2 flex flex-col gap-2">
                  <p className="text-sm text-slate-700" style={{ whiteSpace: 'pre-line' }}>
                    {selectedFrame?.notes}
                  </p>
                </div>
                
                
              </div>
    )
  }