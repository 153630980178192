import React, { useEffect, useRef, useState } from "react";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import { makeImportString, prepareHybridJSX, preparePureJSX } from "./helpers";
import pretty from 'pretty';
import { Check, Copy } from "iconoir-react";

export function FormattedSnippet({ selector}) {

    const codeRef = useRef(null);
    
    let jsx = prepareHybridJSX(selector)
    
    jsx = pretty(jsx, {ocd: true});

    useEffect(() => {
      if (codeRef.current) {
        const codeBlock = codeRef.current;
        setTimeout(() => {
          hljs.highlightElement(codeBlock);
        }, 0); // Timeout set to 0 to allow the stack to clear and DOM to update
      }
    }, [jsx]);
  
    const [copied, setCopied] = useState(false)
  
      const copyToClipboard = () => {
        navigator.clipboard.writeText(jsx).then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 3000); 
        })
        .catch(err => {
          console.log('Something went wrong', err);
        });
      }
  
    return (
      <div className="w-full flex flex-col gap-1">
          Snippet
        <div className="w-full flex flex-col relative group overflow-scroll py-1 border border-base-200 rounded">
      <pre 
      
      className="relative" style={{
        overflowX: 'auto',
        maxWidth: '100%',
        width: '100%',
        boxSizing: 'border-box',
        // whiteSpace: 'normal',
        whiteSpace: 'pre',
        // wordWrap: 'break-word',
        userSelect: 'text',
      }}>
        
        <code
          key={jsx} // Force rerender of this element when jsx changes
          ref={codeRef}
          id="code-block"
          className="language-javascript text-xs"
          style={{ padding: '0px 12px',}}
        >
          {jsx}
        </code>
      </pre>
      <div className={`absolute top-0 right-0 w-6 max-h-7 h-full ring-1
        rounded ${copied ? 'bg-lime-100 ring-lime-200' : 'bg-base-0 ring-base-200'}
        flex items-center justify-center`}>
          { copied ? <Check className="w-4 h-4 text-lime-700 stroke-[2px]" /> :
            <Copy onClick={copyToClipboard} className="cursor-pointer w-4 h-4 hover:scale-110 transition-all duration-75 "/>
          }
        </div>
      </div>
      </div>
    );}

export function FormattedImport({ selector}) {

      const codeRef = useRef(null);
      
      
      let importString = makeImportString(selector, true);
      
      let jsx = importString
  
      useEffect(() => {
        if (codeRef.current) {
          const codeBlock = codeRef.current;
          setTimeout(() => {
            hljs.highlightElement(codeBlock);
          }, 0); // Timeout set to 0 to allow the stack to clear and DOM to update
        }
      }, [jsx]);
    
      const [copied, setCopied] = useState(false)
    
        const copyToClipboard = () => {
          navigator.clipboard.writeText(jsx).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 3000); 
          })
          .catch(err => {
            console.log('Something went wrong', err);
          });
        }
    
      if (jsx && jsx.length > 0) return (
        <div className="w-full flex flex-col gap-1">
          Import String
        <div className="w-full flex flex-col relative group overflow-scroll py-1 border border-base-200 rounded">
        
        <pre 
        
        className="relative" style={{
          overflowX: 'auto',
          maxWidth: '100%',
          width: '100%',
          boxSizing: 'border-box',
          // whiteSpace: 'normal',
          whiteSpace: 'pre',
          // wordWrap: 'break-word',
          userSelect: 'text',
        }}>
          
          <code
            key={jsx} // Force rerender of this element when jsx changes
            ref={codeRef}
            id="code-block"
            className="language-javascript text-xs"
            style={{ padding: '0px 12px',}}
          >
            {jsx}
          </code>
        </pre>
        <div className={`absolute top-0 right-0 w-6 max-h-7 h-full ring-1
        rounded ${copied ? 'bg-lime-100 ring-lime-200' : 'bg-base-0 ring-base-200'}
        flex items-center justify-center`}>
          { copied ? <Check className="w-4 h-4 text-lime-700 stroke-[2px]" /> :
            <Copy onClick={copyToClipboard} className="cursor-pointer w-4 h-4 hover:scale-110 transition-all duration-75 "/>
          }
        </div>
        </div>
        </div>
      );}