export const sidePanels = [
    {
      apiName: 'sidepanel_chat_ui',
      displayName: 'Chat UI',
      rootComponent: 'SidePanel',
      category: 'content',
      subcategory: 'sidepanel',
      description: '',
      width: 360,
      height: 704,
      ai_template: true,
      thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1715652023477.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=tQfKGxwdwwdNXa%2Bth0N0h4AvpZSVlQlx4wSzGlbt%2BePfb0shXto6Fzs06Hr6YS%2BMriaMYKZEKYPtaswqCA%2FHD0DpAN9dXMQbY7GsT6WT0HAm5bkAZGs%2FvHNzMRNeaKE4uslgKS61xSM3w7cVpz5WQtyW9%2FCLNIoIhWItUSCY3lt88C2a6cZzLCj1lM%2FFcxm3JA6IwiwC%2FzEvMh9in31d6pP%2FLsL92lL2Z%2BuEJdw0hLUBJ4JjsR0ZV8hlTEDrEBu%2Bq8bOUVu1qaZ%2Fg8%2F2LrUEM613rlai0beU9BrRPGPS%2BasRZbXFZnpBTJKxvVNmVB13UcP3gosIcd%2Fv2H6Ukixsbg%3D%3D",
      jsx: `<SidePanel width="360" position="right">
      <Module height="h-full" paddingY="md" paddingX="0">
  <FlexBox direction="flex-col" alignItems="start" paddingY="base" corners="lg" padding="base" paddingX="base" height="h-full" justifyContent="end">
    <FlexBox direction="flex-row" width="2/3" padding="none">
      <Avatar size="small" type="image" />
      <FlexBox direction="flex-col" width="full" background="base-100" corners="md" padding="sm" paddingX="base" paddingY="sm">
        <Paragraph text="Lorem ipsum dolor sit amet, consectetur adipiscing elit." textAlign="left" />
      </FlexBox>
    </FlexBox>
    <FlexBox direction="flex-row" width="2/3" padding="none" selfAlign="end">
      <FlexBox direction="flex-col" width="full" corners="md" selfAlign="end" background="accent" padding="sm" paddingX="base" paddingY="sm">
        <Paragraph text="Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." textAlign="right" />
      </FlexBox>
      <Avatar  />
    </FlexBox>
    <FlexBox direction="flex-row" width="2/3" padding="none" selfAlign="start">
      <Avatar  />
      <FlexBox direction="flex-col" width="full" background="base-100" corners="md" padding="sm">
        <Paragraph text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." textAlign="left" />
      </FlexBox>
    </FlexBox>
    <FlexBox direction="flex-row" width="2/3" padding="none" selfAlign="end">
      <FlexBox direction="flex-col" width="full" background="accent" corners="md" padding="sm" selfAlign="end">
        <Paragraph text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." textAlign="right" />
      </FlexBox>
      <Avatar  />
    </FlexBox>
  </FlexBox>
  <FlexBox direction="flex-row" justifyContent="start" padding="none" paddingX="base" paddingY="none">
    <InputText size="medium" placeholder="Start typing..." width="full" bgColor="base-0" hasOutline rightIcon="PeopleTag" />
    <ButtonIcon type="secondary" alignSelf="center" isPill size="small" icon="Voice" />
    <ButtonIcon type="primary" alignSelf="center" isPill size="small" icon="Send" />
  </FlexBox>
  </Module>
    </SidePanel>`
    }, 
    {
      apiName: 'sidepanel_empty',
      displayName: 'Empty Sidepanel',
      rootComponent: 'SidePanel',
      category: 'content',
      subcategory: 'sidepanel',
      description: '',
      width: 360,
      height: 720,
      ai_template: true,
      thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1715652130460.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=FeXcyDQA8lHACcPpAOboZ1oIy8Gi8x0BK0wZ3Btknak3aZnqqXZuppGpHXndaNkQQ4Nyn8CC5taHOSFjBJzZMTNRbgET7NH%2BNo2NJd0gX%2B8hNgv14QGRg%2BEhmkZmlYYoV9VzDQEJCYt4KQFSlNdhpp9KtXTziWDfgHAvI3m5bK%2BeFpLgvNfvhfZmdnZFYO84vcesravkkoZLOfpDFRpr2sh6JmuhwOwDiFfD82TsVhD5jCtU5VW9DFOX1RqGRSf%2B8a%2B4dEKDaHf1I8SpiHaK42HtAMRGKXJKKuSUW3rH6t1ICH%2FO2KVRhK20v%2FoQbeT8nJMl4aGlC9bV1yHHajeZbg%3D%3D",
      jsx: `<SidePanel />`
    }
  ]