import React, { useContext, useState } from 'react';
import { EditorContext } from '../../EditorContext';
import PageThumbnail from '../thumbnail/PageThumbnail';
import { createNewFrame, createNewIds } from '../../../../utilities/helpers';
import { v1 as uuidv1 } from 'uuid';
import { useDroppable } from '@dnd-kit/core';
import { horizontalListSortingStrategy, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';

import FlowDescription from './FlowDescription';
import AddPlus from './AddPlus';


export default function UserFlow({
  page,
  refreshKey,
  frames,
  isMobile,
  folder,
  draggedFrame, 
  columnWidth
}) {
    const { selector, setSelector, view, setView, handleAction, dndState, assets, effectiveVariables } = useContext(EditorContext);
  const isSelected = selector.page?.id == page.id
  function handlePageSelect() {
    setSelector({ ...selector, folder, page, frame: page.frames[0], object: null })
  }
  function addLastFrame() {
    const newId = uuidv1(); // will use for frame.id and object.frame
    const lastFrame = frames.reduce((prev, current) => {
      return (prev.index > current.index) ? prev : current;
    });
    let newObjects = [...lastFrame.objects]; // copy existing objects
    
    newObjects = createNewIds(newObjects, lastFrame.id, newId); // create new Ids for these objects
    newObjects = newObjects.map(obj => ({...obj,frame: newId})); // update frame property

    const newFrame = {
        ...lastFrame,
        id: newId,
        index: lastFrame.index + 1,
        objects: newObjects
    };
    
    handleAction({type: 'INSERT_FRAME', frame: newFrame });
};

function addNextFrame(frame) {
  if (frame) {
    const newFrame = {
      ...frame,
      id: uuidv1(),
      index: frame.index + 1,
      name: 'Untitled frame',
      objects: []
    };
    
    handleAction({type: 'INSERT_FRAME', frame: newFrame});
  } else { // add last
    const insertIndex = frames?.length > 0 
    ? Math.max(...frames.map(frame => frame.index)) + 1 // add as last to the page
    : 1;

    let newFrame = createNewFrame({ ...page, type: 'page' }, [], 'Untitled frame', { width: 800, height: 800 });
    newFrame = { ...newFrame, index: insertIndex };;
    
    handleAction({type: 'INSERT_FRAME', frame: newFrame });
  }
  
};
  
const handleDuplicateFrame = (frame) => {
  const newId = uuidv1(); // will use for frame.id and object.frame
  
  let newObjects = [...frame.objects]; // copy existing objects
  
  newObjects = createNewIds(newObjects, frame.id, newId); // create new Ids for these objects
  newObjects = newObjects.map(obj => ({...obj,frame: newId})); // update frame property

  const newFrame = {
      ...frame,
      id: newId,
      index: frame.index + 1,
      objects: newObjects
  };
  
  handleAction({type: 'INSERT_FRAME', frame: newFrame });
}
  const dragingInProgress = dndState?.isDragging
  const { setNodeRef } = useDroppable({ id: page.id })
  const pageNum = page.frames?.length || 0
  const isEmpty = pageNum === 0
  
  return (
    <div className={`relative flex flex-col items-start max-w-full transition-all pt-2 w-full group pl-4 pr-3
    
    `}
    // onClick={()=>handlePageSelect()} disabled becuase it has weird conflict with frame clicks
    
    >
      <SortableContext 
        id={page.id}
        items={frames} 
        strategy={rectSortingStrategy} >
      <div
        className="flex flex-row items-end h-full pb-3 pt-1 px-2 gap-5 transition-all justify-start relative"
        style={{ width: '100%', maxWidth: '100%', flexWrap: 'wrap'}}
        ref={setNodeRef}  
      >
        {frames
        .sort((a, b) => a.index - b.index)
        .map((frame) => (
          
          <PageThumbnail
            key={frame.id + refreshKey}
            frame={frame}
            isDraggedFrame={draggedFrame?.id == frame.id || false}
            columnWidth={columnWidth}
            isMobile={isMobile}
            isSelected={selector.frame?.id === frame.id}
            folder={folder}
            page={page}
            addNextFrame={addNextFrame}
            showNotes={view.showNotes}
            assets={assets}
            effectiveVariables={effectiveVariables}
          /> 
        
        ))}
        
        <AddPlus
          addNextFrame={addNextFrame} 
          isEmpty={isEmpty} 
          columnWidth={columnWidth - 4 } 
          isSelectedFlow={isSelected}
          />
      </div></SortableContext>
      
      
      {}
    </div>
  );
}


