import * as Icons from 'ionicons/icons';
import * as IconoirIcons from 'iconoir-react';



export function preparePrimitives(UIKit) {
    const ionicIconNames = Object.keys(Icons);
    const ionicIconNamesString = JSON.stringify(ionicIconNames).replace(/[[\]]/g, '');

    const iconoirIconNames = Object.keys(IconoirIcons);
    const iconoirIconNamesString = JSON.stringify(iconoirIconNames).replace(/[[\]]/g, '');
    
    const containers = ['Modal', 'Drawer', 'Main', 'Footer', 'IconBar', 'Sidebar', 'Header', 'FeaturePanel', 'FlexBox', 'Grid', 'Accordion', 'Form', 'TableCell', 'div'];
    const excludeComponents = ['Vimeo', 'Video', 'Slide', 'RichText', 'NotFound'];


    const transformedComponents = Object.entries(UIKit)
      .filter(([componentName, Component]) => !excludeComponents.includes(Component.definitions?.apiName))
      .map(([componentName, Component]) => {
        
        const { apiName, displayName, type, ai_instructions, propDefinitions } = Component.definitions;
        let propDefinitionsString = JSON.stringify(propDefinitions);

        // Detect and replace the specific parts in the string
        propDefinitionsString = propDefinitionsString.replace(new RegExp(`\\["none","auto",${ionicIconNamesString}\\]`, 'g'), '"none, auto or select icon name from Ionic Icon library (use camelCase)"');
        propDefinitionsString = propDefinitionsString.replace(new RegExp(`\\["none",${ionicIconNamesString}\\]`, 'g'), '"none or select icon name from Ionic Icon library (use camelCase)"');
        propDefinitionsString = propDefinitionsString.replace(new RegExp(`\\[${ionicIconNamesString}\\]`, 'g'), '"select icon name from Ionic Icon library (use camelCase)"');

        // Similarly, replace for iconoirIconNames
        propDefinitionsString = propDefinitionsString.replace(new RegExp(`\\["none","auto",${iconoirIconNamesString}\\]`, 'g'), '"none, auto or select icon name from Iconoir Icons library (use camelCase)"');
        propDefinitionsString = propDefinitionsString.replace(new RegExp(`\\["none",${iconoirIconNamesString}\\]`, 'g'), '"none or select icon name from Iconoir Icons library (use camelCase)"');
        propDefinitionsString = propDefinitionsString.replace(new RegExp(`\\[${iconoirIconNamesString}\\]`, 'g'), '"select icon name from Iconoir Icons library (use camelCase)"');

        const acceptsChildren = containers.includes(apiName);

        return {
          apiName,
          displayName,
          type,
          ai_instructions,
          propDefinitions: JSON.parse(propDefinitionsString), 
          acceptsChildren
        };
      });

    
    return transformedComponents;
}
