import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import { spacingMap } from '../helpers'; /* replace to 'import { spacingMap } from './helpers.js';' */

export default function AppShell(props) {
    const { 
        pageBackground='base-0', 
        maxWidth='stretch',
        justifyContent='center',
        paddingX=null,
        paddingY=null,
        children, attributes, listeners } = props
    
    const { junoAttributes, showTag, outlineStyle} = props.junoProps || {} /* remove line */ 

    const paddingStyles = `${paddingX ? ` px-${spacingMap[paddingX]}` : ''}${paddingY ? ` py-${spacingMap[paddingY]}` : ''}`;
    const fontColor = pageBackground?.startsWith('base') ? 'base-content' : `${pageBackground}-content`;
    const pageBgColor = `bg-${pageBackground}`;
    const fontColorValue = `text-${fontColor}`;
    

    // Ensure that parent has h-screen or replace h-full to h-screen in the classes below
    let outerClasses = `relative flex flex-col w-full h-full flex-grow ${pageBgColor} ${fontColorValue}`;
    outerClasses += ` min-h-full ${outlineStyle}` /* remove line */ 

    let innerClasses = `relative flex flex-row w-full items-stretch flex-grow min-h-full self-${justifyContent} ${paddingStyles}`
    innerClasses += ` ${outlineStyle}` /* remove line */ 

    let mainClasses = `relative flex flex-col w-full items-stretch flex-grow min-h-full`

    const groupedChildren = {
        Header: [],
        Hero: [],
        IconBar: [],
        Sidebar: [],
        FeaturePanel: [],
        MainArea: [], // sidepanel + main
        Footer: []
    };

    React.Children.forEach(children, child => {
        if (isValidElement(child)) {
            const { type } = child;
            let typeName = type?.displayName || type?.name;
            typeName = child?.props?.self?.componentAPIName; // comment out
            if (groupedChildren[typeName]) {
                groupedChildren[typeName].push(child);
            } else {
                groupedChildren.MainArea.push(child);
            }
        }
    });

    const { Header, Hero, Footer, MainArea, IconBar, Sidebar, FeaturePanel } = groupedChildren;
    
    return (
        <div
        {...attributes} {...listeners}
        {...junoAttributes} /* remove line */
        className={outerClasses} 
        >
        <div 
        className={innerClasses} 
        style={{ width: '100%', maxWidth: maxWidth != 'stretch' ? `${maxWidth}px` : '100%'}}
        >
            {showTag && <div className='selected-element-inside' style={{ zIndex: 1000 }}>App Shell</div> /* remove line */}
            {IconBar}
            {Sidebar}
            {FeaturePanel}
            
            {/* Main Block */}
            <div className={mainClasses} 
            style={{minHeight: '100%'}}
             /* remove line */
                >
                {Header}
                {Hero}
                <div className={`flex flex-row flex-grow w-full h-full  justify-${justifyContent}`}>
                    {MainArea}
                </div>
                {Footer}
            </div>
        </div>
        </div>
    );
}

AppShell.propTypes = {
    pageBackground: PropTypes.oneOfType([
        PropTypes.oneOf(['base-0',  'base-50', 'base-100', 'base-200', 'base-300', 'primary', 'secondary', 'accent']),
        PropTypes.string]),
    maxWidth: PropTypes.oneOf(['stretch', '960', '1200', '1440', '1920']),
    justifyContent: PropTypes.oneOf(['center', 'start', 'end']), 
    paddingX: PropTypes.oneOf(["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"]),
    paddingY: PropTypes.oneOf(["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"]),
    children: PropTypes.node
};

/* ignore rest */


AppShell.definitions = {
    apiName: 'AppShell',
    displayName: 'Application Shell',
    description: 'A flexible and foundational component of an application layout, offering customizable options for background color and maximum width. It provides a structure for arranging header, footer, and main content areas.',
    ai_instructions: 'app shell contains page sections. cannot be deleted or moved.',
    type: 'layout',
    acceptedChildren: 'only sections: Main, Header, Footer, FeaturePanel, Sidebar, Iconbar',
    relativeSize: 'large',
    propDefinitions: {
        
        maxWidth: {
            type: 'oneOf',
            options: ['stretch', '960', '1200', '1440', '1920'],
            displayName: 'Page Width',
            default: 'stretch'
        }, 
        pageBackground: {
            type: 'colors',
            options: ['base-0', 'base-50', 'base-100', 'base-200', 'base-300', 'primary', 'secondary', 'accent'],
            displayName: 'Background',
            default: 'base-0', 
            ai_instructions: 'updating pageBackground will also update default font colors on the page'
        },
        justifyContent: {
            type: 'justifyContent',
            options: ['center', 'start', 'end'],
            displayName: 'Inner Justify',
            default: 'center', 
            ai_instructions: 'app shell is flex-row so justify content works horizontally'
        }, 
        paddingX: {
            type: "spacing",
            options: ["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Padding X",
            ai_instructions: 'adds padding on left and right. in addition to overall padding property',
            default: 'base', 
            tile: '1/2',
        },
        paddingY: {
            type: "spacing",
            options: ["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Padding Y",
            ai_instructions: 'adds padding on top and bottom. in addition to overall padding property',
            default: null,
            tile: '1/2',
        },
    }
};