
import { useEffect, useState } from 'react';
import * as projectsDB from '../../../../api/projectsAPI'

export default function ProjectName({project, setProject}) {

    const [editable, setEditable] = useState(false);
    const [name, setName] = useState(project?.project?.name);
  
    
    useEffect(() => {
      // Update the name state when the frame prop changes
      setName(project?.project?.name);
    }, [project?.project?.name]);
  
    const handleBlur = () => {
      setEditable(false);
      const updatedProject = { ...project, project: { ...project.project, name } };
      setProject(updatedProject);
      projectsDB.update(updatedProject.project);
    };
  
    
    return (
        <input 
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={(e) => editable && handleBlur(e)}
          onClick={(e) => {e.stopPropagation(); setEditable(true)}}
          onKeyDown={(e) => e.key === 'Enter' && e.target.blur()}
          readOnly={!editable}
          className={`bg-transparent focus:bg-white placeholder-gray-700 text-gray-800 focus:text-gray-900
          select-none cursor-default overflow-hidden whitespace-nowrap ellipsis truncate transition-all`}
          style={{
            userSelect: !editable ? 'none' : '',
            minWidth: 200,
            textAlign: 'left'
          }}
        />
    
      )
    }
    
    