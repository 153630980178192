import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import { Icon } from '../index'; /* replace to 'import { Icon } from './index'' */
import { iconMap } from '../media/iconMap'; /* replace to 'import { iconMap } from './iconMap'' */

const allIconNames = Object.keys(iconMap) || []

export default function TextArea(props) {
    
    const {
        state = 'default',
        bgColor = 'base-0',
        size = 'medium',
        maxRows = 5,
        defaultRows = 3,
        placeholder = 'placeholder text',
        label = 'Label',
        helperText = '',
        value = '',
        maxCharacters = 200,
        onChange = () => {},
        prefix = '',
        hasCharacterCount = true,
        textAlign = 'left',
        width = 'auto',
        rightIcon = 'none',
        hasOutline = true,
        defaultIconSet,
        attributes,
        listeners
      } = props;
    const { junoAttributes, dndProps, showTag, setRefs, self, outlineStyle, eventListeners, onPropertyUpdate=()=>{}, preview} = props.junoProps || {} /* remove line */
    
    const textAreaRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);


    const sizeStyles = size == 'small' ? `py-0.5 px-2 gap-1.5 text-xs` : size == 'large' ? `py-2 px-3 gap-3 text-base` : `py-1.5 px-2 gap-3 text-sm`;
    const cornerStyles = size == "small" ? "rounded" : size == "large" ? "rounded-lg" : "rounded-md"
    

    let stateStyles = '';
    switch (state) {
        case 'default':
            stateStyles = hasOutline ? isFocused ? `border border-accent` : `border border-base-300` : 'border border-transparent'
            break;
        case 'disabled':
            stateStyles = `bg-base-100 opacity-70 cursor-not-allowed ${hasOutline ? 'border border-base-300' : ''}`
            break;
        case 'error':
            stateStyles = `text-warning-content ${hasOutline ? 'border border-warning-content' : ''}`
            break;
        case 'success':
            stateStyles = `${hasOutline ? 'border border-success-content' : ''}`
            break;
    }

    
    
    const bgStyles = (bgColor && bgColor !== 'none') ? `bg-${bgColor}` : '';
    let classes = `w-full flex items-center justify-between truncate ellipsis box-border font-medium ${sizeStyles} ${cornerStyles} ${bgStyles} ${stateStyles}`
    
    const labelTextSize = size == 'small' ? `text-sm` :  size == 'large' ? `text-lg`: `text-md`;
    const labelClasses = `text-base-content ${labelTextSize} font-medium`
    
    const messageTextColor = state == 'error' ? stateStyles = 'text-warning-content' : state == 'success' ? stateStyles = 'text-success-content' : ''
    const messageClasses = `text-sm font-sm ${messageTextColor}`
    const widthStyle = width != 'auto' ? `w-${width}` : size == 'small' ? 'min-w-[120px]' : size == 'large' ? 'min-w-[200px]' : 'min-w-[160px]'

    let wrapperClasses = `flex flex-col gap-1 ${widthStyle}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */

    const RightIconComponent = rightIcon !== 'none' ? <Icon icon={rightIcon?.toLowerCase()} defaultIconSet={defaultIconSet} className='flex-shrink-0 flex-grow-0 -mr-1 self-start'/> : null;

    // Code to auto-resize the textarea
    const lineHeight = size == 'small' ? 1 : size == 'large' ? 1.5 : 1.25
    const textAreaStyles = {
        lineHeight,
        height: 'auto',
        minHeight: Math.min(defaultRows, maxRows) * lineHeight + 'em',
        maxHeight: maxRows * lineHeight + 'em',
        overflow: 'auto'
    }
    
    const adjustTextAreaHeight = (lineHeight, maxRows) => {
        const textAreaElement = textAreaRef.current;
        if (!textAreaElement) return;
        textAreaElement.style.height = 'auto';
        const newHeight = textAreaElement.scrollHeight;
        const maxHeight = lineHeight * maxRows * 16; // Assuming lineHeight is in rem
        textAreaElement.style.height = `${Math.min(newHeight, maxHeight)}px`;
    };

    useEffect(() => {
        adjustTextAreaHeight(parseFloat(textAreaStyles.lineHeight), maxRows);
        // Optional: If the value is part of the state and could change externally,
        // you might want to observe changes and adjust the height accordingly.
    }, [maxRows, value]); // Add other dependencies as necessary

    // Code to count characters
    const countCharacters = (text) => { return text.length || 0 }
    const [ charCount, setCharCount ] = useState(countCharacters(value.length));

    function handleChange(e) {
        adjustTextAreaHeight(parseFloat(textAreaStyles.lineHeight), maxRows);
        setCharCount(countCharacters(e.target.value)); 
        onChange(e.target.value);
    }
    

    return (
        <div
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
            className={wrapperClasses}
        >
            {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>TextArea</div> /* remove line */}
            {label && <label className={labelClasses}>
                    <EditableOnDoubleClick text={label} onBlur={onPropertyUpdate('label')} whiteSpace='nowrap'  /* replace to '{label}' */ />
                </label>}
            <div style={{boxSizing: 'border-box'}} className={classes}>
            {prefix}
            {/* start remove block */
            <div className={`flex-grow font-medium text-${textAlign}`}
            style={textAreaStyles}
            >
            {value ?  <EditableOnDoubleClick text={value} onBlur={onPropertyUpdate('value')} whiteSpace='normal'/>
            :  <span className={'text-base-500'}> 
                <EditableOnDoubleClick text={placeholder} onBlur={onPropertyUpdate('placeholder')} whiteSpace='normal'/> 
                </span>}
            </div>
            /* end remove block */}
            {/* remove line */ /*
            <textarea
            type='text'
            ref={textAreaRef}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            disabled={state == 'disabled'}
            className={`flex-grow text-${textAlign} border-transparent focus:outline-none focus:ring-0 font-medium resize-none placeholder:font-normal
            placeholder-base-500 text-base-content bg-transparent w-full ${state == 'disabled' && 'cursor-not-allowed'}`}
            value={value}
            style={textAreaStyles}
            placeholder={placeholder}
            onChange={e => handleChange(e)}
            />  
            */ /* remove line */}
            {RightIconComponent}
            </div>
            {(helperText || hasCharacterCount) &&
            <div className={`flex flex-row gap-2 items-start justify-between w-full ${messageClasses}`}>
            <span>
            <EditableOnDoubleClick text={helperText} onBlur={onPropertyUpdate('helperText')} whiteSpace='pre-wrap'  /* replace to '{helperText}' */ />
            </span>
            <span className={`opacity-70 flex-shrink-0 text-xs ${charCount > maxCharacters ? 'text-warning' : '' }`}>{charCount}/{maxCharacters}</span>
            </div>
            }            
        </div>
        
    );
}

TextArea.propTypes = {
    width: PropTypes.oneOf(["auto", "1/2", "full"]),
    textAlign: PropTypes.oneOf(['left', 'center', 'right']),
    defaultRows: PropTypes.number,
    maxRows: PropTypes.number,
    state: PropTypes.oneOf(['default', 'disabled', 'error', 'success']),
    bgColor: PropTypes.oneOf(['none', 'base-0', 'base-50', 'base-100']),
    label: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    helperText: PropTypes.string,
    hasCharacterCount: PropTypes.bool,
    prefix: PropTypes.string,
    rightIcon: PropTypes.oneOf(['none', ...allIconNames]),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    maxCharacters: PropTypes.number,
    hasOutline: PropTypes.bool,
};

/* ignore rest */

TextArea.definitions = {
    apiName: 'TextArea',
    displayName: 'Textarea',
    description: 'A text input field with label, hint (used for info or errors) and optional icons.',
    ai_instructions: 'a text input field with label, hint (used for info or errors) and optional icons. can be used for email, password, name, etc.',
    type: 'input',
    relativeSize: 'small',
    acceptedChildren: 'none',
    propDefinitions: {
        width: {
            type: "width",
            options: ["auto", "1/2", "full"],
            displayName: "Width",
            default: "auto", 
            defaultOnMobile: 'full', 
            tile: '1/2'
        },
        size: {
            type: 'size',
            displayName: 'Size',
            options: ['small', 'medium', 'large'],
            default: 'medium', 
            tile: '1/2'
        },

        state: {
            type: 'oneOf',
            displayName: 'State',
            options: ['default', 'disabled', 'error', 'success'],
            default: 'default', 
            tile: '1/2'
        },
        bgColor: {
            type: 'colors',
            options: ['none', 'base-0', 'base-50', 'base-100'],
            displayName: 'Background',
            default: 'base-0', 
            tile: '1/2'
        },
        
        defaultRows: {
            type: 'number',
            default: 3,
            ai_instructions: 'default number of rows', 
            tile: '1/2'
        },
        maxRows: {
            type: 'number',
            default: 5,
            ai_instructions: 'maximum number of rows', 
            tile: '1/2'
        },



        label: {
            type: 'string',
            default: 'Label', 
            tile: '1/2'
        },

        textAlign: {
            type: 'oneOf',
            options: ['left', 'center', 'right'],
            default: 'left', 
            tile: '1/2'
        },
        value: {
            type: 'longString',
            default: ''
        },
        
        placeholder: {
            type: 'string',
            default: 'placeholder text', 
            required: true,
            ai_instructions: 'value example, e.g. "email@example.com" or "Acme Corp Inc". do not write placeholder like action "Enter text". limit to 3 words.'
        },
        helperText: {
            type: 'string',
            default: '', 
            ai_instructions: 'the text under the field. can be used for info and error messages'
        },
        rightIcon: {
            type: 'icon',
            options: ['none', ...allIconNames],
            displayName: 'Right Icon',
            default: 'none'
        },

        maxCharacters: {
            type: 'number',
            default: 200,
            ai_instructions: 'maximum number of characters allowed in the input field', 
            tile: '1/2'
        },
        hasCharacterCount: {
            type: 'bool',
            default: true,
            ai_instructions: 'includes small character count at the top right corner of the input field',
        },
        /*
        prefix: {
            type: 'string',
            default: '',
            ai_instructions: '', 
            tile: '1/2'  
        },*/
        hasOutline: {
            type: "bool",
            displayName: "Outline",
            default: false, 
            ai_instructions: 'adds base-300 1px outline',
        }, 
        
    }
};
