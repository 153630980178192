import { Main, 
    Text,
    Heading, 
    FlexBox, 
    InputText, 
    Select, 
    Checkbox, 
    Button, 
    Alert, 
    Grid, 
    Icon
    
 } from '../../../../../ui-kit/exports/react'


export function SampleForm() {
  
    return (
      <Main selfAlign="center" alignItems="center" justifyContent="center" background="base-50">
  <form className="flex flex-col flex-nowrap  px-6 py-6 w-full max-w-full max-w-[400px]  self-auto text-base-content  bg-base-0  gap-6 rounded-lg items-start justify-start h-auto  " style={{ backgroundColor: 'color-mix(in srgb, var(--base-0) 100%, transparent)', maxWidth: '400px' }}>
    <div className="flex flex-col flex-nowrap w-full max-w-full   self-auto text-inherit    gap-2   items-start justify-start h-auto  ">
      <h1 className="text-ellipsis text-2xl     font-semibold  " style={{ whiteSpace: 'pre-wrap' }}>
        Form Sample
      </h1>
      <div className="flex flex-row flex-nowrap w-full max-w-full   self-auto text-inherit    gap-2   items-center justify-start h-auto  ">
        <span className="inline-flex text-sm      text-left" style={{ whiteSpace: 'pre-wrap' }}>
        Fill out the form below
        </span>
        <a className="inline-flex text-sm text-base-content   underline    cursor-pointer" href="#" target="_self" rel="">
          Learn more
        </a>
      </div>
    </div>
    <div className="flex flex-col flex-nowrap w-full max-w-full   self-auto text-inherit    gap-4   items-start justify-start h-auto  ">
      <InputText label="" placeholder="email@company.com" rightIcon="none" width="full" leftIcon="mail" />
      <InputText label="" placeholder="(123) 456 7898" width="full" leftIcon="none" prefix="+1" />
      <Checkbox label="I simply agree to everything" isChecked width="full" style="standard" size="medium" />
    </div>
    <div className="flex flex-col flex-nowrap w-full max-w-full   self-auto text-inherit    gap-2   items-stretch justify-start h-auto  ">
      <Button text="Submit" type="accent" rightIcon="send" />
      <Button text="Go Back" type="ghost" leftIcon="none" />
    </div>
  </form>
</Main>
    )
}