const tables = [
    {
        apiName: 'module_main_table_default',
        displayName: 'Table',
        allowedSections: ['Main'],
        ai_template: true,
        enviroments: ['data_display', 'file_management', 'analytics', 'dashboards'],
        ai_instructions: 'module that displays tabular data',
        width: 760,
        height: 320,
        tileWidth: '100%',
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714427294454.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=nrRDnzR0xfTWv13wJq3TxVA8qeh7GOIrGjbQ%2BZWSVtjqOLtib2X%2FMdoNZWzAuxAYrjyQzkLKHPOxfcex3ht470Q5zXilQsnhxNdZfwaUQ4vs7pPkmEmwVY1qMPcnoVn%2B9zb4gNxYyS4sZml5w5s2jkaTMPG5kL%2FLMRM%2FF82Rb8kifW6RJlz1iszPU2L%2F1xExfmLxp%2FGFwm8sPxIizZJaZYw9Oz6pmp73iK8O8ziqimcsT6QVSTjO8eEaoTRI3Z9aXp5sanpaEhyQIeL44JlrUnuu5c5r4utJoaBVaVUuMKtOUi76r%2FcX4wWCAjwWsxrt1Xah6wFhjM3n%2FXGqR8VuIw%3D%3D",
        jsx: `
        <Module module_name="Table" module_role="Displays tabular data" width="full">
          <TableWidget background="base-100" corners="md" cellPaddingY="sm" cellPaddingX="base" textSize="sm" />
        </Module>`
      }, 
      {
        apiName: 'module_main_table_text',
        displayName: 'Text Table',
        allowedSections: ['Main'],
        enviroments: ['data_display', 'file_management', 'analytics', 'dashboards'],
        ai_template: true,
        ai_instructions: 'module that displays tabular data with text only',
        width: 780,
        height: 240,
        tileWidth: '100%',
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1715048729965.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=KopBxt5JCcOxqLQcPmE5tFW48Y8xuIgeudgqhWzKr2CU%2FYXFAUxCE4SlvSFQroA4hOE%2Bg9BGwLh1M4jsNhzSxeuhHoVrOl1JdeyqKJl1x0%2Bn13AHm9DwT3QjPZQ27YAjb%2BK4MtpS6BpjF%2BJdr42ASogTP1ZzRfc5I62Z5F34oge9jlo6iSehz2NX5GOjg7SK3GqHWynoSzFXxTVfZP7BauQOYOsE4SHqiT%2BflvX%2FBy2tlpUI9o7cxfioxvpwok8%2BoW9DwTk%2BKfSTqIYST4Z7c5jn7xVVi%2BBitMOnAnqbaBeboK8f9J9rjcb6k9ICtegRIvX5EnRhhqjpfNGrrCcOBw%3D%3D", 
        jsx: `<Module >
        <TableWidget pagination="standard" columns={[{\"accessor\":\"selected\",\"header\":[\"<Checkbox />\"],\"width\":\"5%\",\"alignItems\":\"start\",\"justifyContent\":\"start\"},{\"accessor\":\"name\",\"header\":[\"Name\"],\"width\":\"20%\",\"alignItems\":\"start\",\"justifyContent\":\"start\"},{\"accessor\":\"company\",\"header\":[\"Company\"],\"width\":\"20%\",\"alignItems\":\"start\",\"justifyContent\":\"start\"},{\"accessor\":\"team\",\"header\":[\"Team\"],\"width\":\"20%\",\"alignItems\":\"start\",\"justifyContent\":\"start\"},{\"accessor\":\"date\",\"header\":[\"Date\"],\"width\":\"20%\",\"alignItems\":\"start\",\"justifyContent\":\"start\"},{\"accessor\":\"actions\",\"header\":[\"\"],\"width\":\"15%\",\"alignItems\":\"end\",\"justifyContent\":\"end\"}]} data={[{\"selected\":[\"<Checkbox />\"],\"name\":[\"Alice Smith\"],\"company\":[\"ABC Corp\"],\"team\":[\"Marketing\"],\"date\":[\"2023-01-01\"],\"actions\":[\"<IcoNoirIcon name='EditPencil' size='auto' />\"]},{\"selected\":[\"<Checkbox />\"],\"name\":[\"Bob Johnson\"],\"company\":[\"XYZ Inc\"],\"team\":[\"Sales\"],\"date\":[\"2023-01-02\"],\"actions\":[\"<IcoNoirIcon name='EditPencil' size='auto' />\"]},{\"selected\":[\"<Checkbox />\"],\"name\":[\"Carol Taylor\"],\"company\":[\"Tech Solutions\"],\"team\":[\"Development\"],\"date\":[\"2023-01-03\"],\"actions\":[\"<IcoNoirIcon name='EditPencil' size='auto' />\"]},{\"selected\":[\"<Checkbox />\"],\"name\":[\"Dave Brown\"],\"company\":[\"HealthPlus Inc\"],\"team\":[\"HR\"],\"date\":[\"2023-01-04\"],\"actions\":[\"<IcoNoirIcon name='EditPencil' size='auto' />\"]},{\"selected\":[\"<Checkbox />\"],\"name\":[\"Eve White\"],\"company\":[\"Global Tech\"],\"team\":[\"Support\"],\"date\":[\"2023-01-05\"],\"actions\":[\"<IcoNoirIcon name='EditPencil' size='auto' />\"]}]} />
      </Module>
      `
      }
]

const navigation = [
  {
    apiName: 'module_main_header_search_filter',
    displayName: 'Header for Data Display',
    allowedSections: ['Main'],
    enviroments: ['data_display', 'file_management', 'analytics', 'dashboards'],
    ai_template: true,
    ai_instructions: 'module that displays a header for data display pages. includes search bar and filter options',
    width: 780,
    height: 86,
    tileWidth: '100%',
    thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1715486320592.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=YiLW1en8Vq4zmay2o4ulID3SeD80NUtsFpTUzAF7KPUmm3xkPqXPvGn1PsMlVmxzsG6wi%2Faa2sIKxYo2iqE7yXmEmIK5%2BbA1wzUPKNpzYYZCNp1RjZutvtgoQwGiwrB4AK7w%2FQ9qjHasgum0m%2BfGpUjbs3IIzNccU9FLTSaZdycHI2USH8c2QPVTqJJjtXqdSPuCMjISUUyOBeqMsRYWb65w1l2Awen4w192OvvKt%2FrqXhmzCTkayE%2BUQGlXQ9mElB7OJBZlmcvfSiTARutX1Et4Xa0AKevK1faW3Z5kFGwNx%2BK5Vj4ff%2FuRMO%2FTPjlnf8u%2BIFStQ0v3DOk13A6kQw%3D%3D",
    jsx: `<Module flexDirection="flex-col" paddingX="base" paddingY="sm" gap="base" alignItems="start" justifyContent="start" width="full">
    <Heading text="Title" textSize="2xl" />
    <FlexBox direction="flex-row" padding="none" paddingX="none" justifyContent="between" paddingY="none" alignItems="center">
      <Heading text="Help text that explains possible actions on this page." textSize="md" fontWeight="normal" />
      <FlexBox direction="flex-row" width="auto" padding="none">
        <Search size="small" bgColor="base-0" hasOutline />
        <InputText size="small" placeholder="Sort By" rightIcon="NavArrowDown" bgColor="base-0" hasOutline />
      </FlexBox>
    </FlexBox>
  </Module>`
  } ,
    {
        apiName: 'module_main_heading',
        displayName: 'Heading',
        allowedSections: ['Main'],
        enviroments: ['any'],
        ai_template: true,
        ai_instructions: 'module that displays a heading',
        width: 760,
        height: 60,
        tileWidth: '100%',    
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714067175031.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=MJ6UbfB3bulvBBLM2VDWqCteRNCncqLoy2IGQ6rhnWBW7IgtxafZEjAdK5GxrzX1bdmGB7NtQRdwt5QNCQqGMPI4%2FpGsmrmFW7EKE4h6tmaC7EDlNtNGVPcd6kwIglnR1bky3HRupOxJCne2%2FzustJ2cb3BqyVPq046CTrnWZTqDOJc2BYyg7Pc3%2BMrzUZcdiFXRognbb75o05997v2UMlI83z3rfKgZAlDogfqj2xUJhBtCWmN5JsQy4c9kTv0E0LLP%2F8VVgtWdLzCr4Q0VCRltj3%2FZcY8L4YFoIPURUArnYvl3SOBPSstHePBaXO52d3OWkvb6aSOIbbVWZReyMA%3D%3D",
        jsx: `<Module module_name="Heading" module_role="tell the user what page they are on">
                <Heading  />
              </Module>`
      }, 
      {
        apiName: 'module_main_pagination',
        displayName: 'Pagination',
        allowedSections: ['Main'],
        enviroments: ['data_display', 'file_management', 'analytics', 'dashboards'],
        ai_template: true,
        ai_instructions: 'module that displays pagination controls. usually goes below tables or data grids',
        width: 760,
        height: 60,
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714067099087.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=nqPaTLgTgnzdG8HkI7wc6HsHFXCBWayXo8B095loRMF5%2BHZNOCLAVlj2623V1vX2hop57jIwr2%2BJw7W%2FB1M3%2B6GVDVpAFxNzGUW7eNgqbar29%2FlYpq85ldPT3JDonNasDe96D5JgDuX9EalrS4zDeeiqksZHeqnyLIZ9wlBg4EzoeIHDTIQJ6NP6uZdkL2A8aGYGWZoeMvikyNCJ7H%2FPs%2Fd7I%2FANC3vA%2FtOZM1%2FgrUpOVdY0R%2FOFeYS6HzoG03Fm9JXRjemz1Hjximl8dgv%2B%2BBM6dPkc2krMMK%2BgrGrx5bbexklmovLX27wmFNG8v%2F1ZnCLp6QEMWIeISTLjw2UkUQ%3D%3D",
        tileWidth: '100%',

        jsx: `<Module module_name="pagination" module_role="Pagination controls for navigating through search results pages" paddingX="md" paddingY="sm" flexDirection="flex-row" gap="base" alignItems="center" justifyContent="between" width="full" height="h-auto">
          <Pagination type="standard" size="medium" />
          </Module>`
      }
]

const dataGrids = [
      {
        apiName: 'module_main_files_and_folders_grid',
        displayName: 'File Grid',
        enviroments: ['file_management'],
        allowedSections: ['Main'],
        ai_template: true,
        ai_instructions: 'shows a grid of folder and file cards. can be used in file management pages for navigation or quick discovery. alternative view would show file/folder list view.',
        width: 760,
        height: 320,
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714066168482.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=F6rhJsp%2FVPwbA9IGUmPvKEx1iL7sJLhD9nI1pkv%2FMCoItfEAIeLRkdey40K%2Fa3EisBtDItjG%2FS%2Fp6w6NKuI%2F0ReiIUBWYMPzsmpuHePmEKxqUTDsZsVyMFNAf3ECxyrJ12fF9DxJnMdQJ7HuXQpinxSrHPSmRB6vPrmvyQBTUlYGjyRe2Xdga89ynAGqEOG3E92NAhMygTLYLELi8QEdUEJk2mxbF7f6%2Fw9R1nUjlqWifyNgvKS%2BgXPKQcC8W5OQg0Rre5EtMhi3FIrOEHCl5RTcN%2FEa65oT12baeWPs35J%2F8lDF6MZICdJGeSZfmyTFUsae1cOb7hTL%2FNjpOJCXJQ%3D%3D",
        tileWidth: '100%',
        jsx: `<Module module_name="Folders Files Preview" module_role="display folder cards for navigation or discovery" flexDirection="flex-col" paddingX="base" paddingY="sm" gap="base" alignItems="center" justifyContent="center" width="full">
        <Grid columnCount={5} height="auto" width="full" padding="none">
          <FolderCard  />
          <FolderCard  />
          <FolderCard  />
          <FileCard  />
          <FileCard  />
          <FileCard  />
          <FileCard  />
        </Grid>
      </Module>`
      }, 
      {
        apiName: 'module_main_media_grid',
        displayName: 'Media Grid',
        allowedSections: ['Main'],
        enviroments: ['data_display', 'file_management', 'analytics', 'dashboards'],
        ai_template: true,
        ai_instructions: 'module that displays a grid of media cards. can be used to show images, videos, or other media',
        width: 760,
        height: 320,
        tileWidth: '100%',
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714066211154.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=g16HxhL1tXEmhs1K0ifprMIy9qx2Fw0NBu34RMflIQpecWudh8cDmYdOr874bw0KnxdagaNO2cZZHs%2BHBXw1o%2B7XaJnbB3mWs3%2FfKZZH2PVhobWVJhE5Szc%2FxUgCn3fP%2B6d1gNKl2yGX0t3ujTmG%2BAPGUAqGQGaXdrqMA5255Ro9%2F2wZGXIMWk8JcEA%2Fz%2FqWSXI%2Fx31ZM2vqG6nwPywPJiZQ%2Br5uteFICVJNrkCVlmPGv%2F141K8%2FS8wS8UIcsqRn1WStXJQr8QnLzWd3YOcz2NCSAcTRpRe9kRSOFu09wI8gALw4dzLa1A4CtUp%2BV3c70i6Q06zJbaNj84NKRmOxzQ%3D%3D",
        jsx: `<Module module_name="results" module_role="Module for displaying search results including tags, sort, and filter options" paddingX="md" paddingY="sm" flexDirection="flex-col" gap="base" alignItems="center" justifyContent="start" width="full" height="h-full">
        <Grid columnCount={4} height="auto" padding="none">
          <MediaCard  />
          <MediaCard  />
          <MediaCard  />
          <MediaCard  />
          <MediaCard  />
          <MediaCard  />
          <MediaCard  />
          <MediaCard  />
        </Grid>
      </Module>`
      },
      {
        apiName: 'module_main_products_grid',
        displayName: 'Products Grid',
        allowedSections: ['Main'],
        enviroments: ['data_display', 'file_management', 'analytics', 'dashboards'],
        ai_template: true,
        ai_instructions: 'module that displays a grid of product cards. can be used to show products or services',
        width: 760,
        height: 320,
        tileWidth: '100%',
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714066253021.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=bC1L%2Frj3GUSfdi3Ruf61jQZuzSYyonKYUySychF9GvXUMgsZbBWLmJsGtF5I1fBiQkC6NE6aeZAEjgC7GIccGM7b6A5F8dpPg0qfkHoD09n7wXn6ZT1ODX%2FlMfIFpTDiFjZ9G7a9S%2F4sAPETyMZf9kY78zGvowiSWtPTEk3dl5WAH0r3PTKblcf%2F3Zr%2BFDPvFwFDkrtDw59K2d365tweX0BdwgsxhV7bk4J9PdHjSteUerRRVJT8Wk3ad8ygGASqhvP6U9qEaOWuTQjznsxsMqdvp1Y3Bbpje77cc1URTqrutPYcvz5hohvsdtVVRXsOK6UOty6GZCfVV59zkrVeDw%3D%3D",
        jsx: `<Module paddingX="md" paddingY="sm" flexDirection="flex-col" gap="base" alignItems="center" justifyContent="start" width="full" height="h-full">
        <Grid columnCount={5} height="auto" padding="none" gap="md">
          <ProductCard  />
          <ProductCard  />
          <ProductCard  />
          <ProductCard  />
          <ProductCard  />
        </Grid>
      </Module>`
      },
      {
        apiName: 'module_main_horizontal_cards_2cols',
        displayName: 'Cards Grid',
        allowedSections: ['Main'],
        enviroments: ['data_display', 'file_management', 'analytics', 'dashboards'],
        ai_template: true,
        ai_instructions: 'module that displays 2 columns of horizontal cards. can be used to show blog posts, news articles, or other content',
        width: 760,
        height: 320,
        tileWidth: '100%',
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714066349715.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=Y5PaMCxolX9Ztfa978NTQPdoR2heHyXkaITk%2FwKTd3gSxeMT499IMUZsn4UY8ekXVHY8lr8Is%2BM4VIqKzhIcOw9PWap6Sz4NTsfKmqaYxpZAKwlJ7CkTzuEIRbzfuxb7aX6xTL7gdQspz8xADz1PLkovEAuN8VZXlCewCegE9u10yokycbtvZAvIvTCeDjyVUBThwkgw0dhqlcTIvbBf1MDxOmFr%2B6GqU0C6URnvoy5tGS3pgNxcgU5bz9rsq7Ycs4xTW8TtVeQY9E8LkYEUO3yPBy6lDo%2F88C3vd%2FNhuYQ%2Fb1cmpE59uNTgZq5Km5wLyYPxo4NYYMSkvswqjVOjDA%3D%3D",
        jsx: `<Module flexDirection="flex-col" >
        <Grid columnCount={2} height="full" width="full" padding="none">
          <FlexBox direction="flex-col" height="h-full" padding="none">
            <MiniCardHorizontal  />
            <MiniCardHorizontal  />
            <MiniCardHorizontal  />
            <MiniCardHorizontal  />
          </FlexBox>
          <FlexBox direction="flex-col" height="h-full" padding="none">
            <MiniCardHorizontal  />
            <MiniCardHorizontal  />
            <MiniCardHorizontal  />
            <MiniCardHorizontal  />
          </FlexBox>
        </Grid>
      </Module>`
      }
      ,
    {
        apiName: 'module_main_kpi_grid',
        displayName: 'Metrics Cards',
        allowedSections: ['Main'],
        enviroments: ['analytics','dashboards'],
        ai_template: true,
        ai_instructions: 'module that displays data and status cards to communicate KPIs. can be used in dashboard scenarios',
        width: 760,
        height: 120,
        tileWidth: '100%',
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714066596155.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=is4zH%2BVpLjUAW13dSqbkJ30f3gStYVf3t%2BmWChJjzfT8TKllSlzSo4OzCjwBsemG15dPAflyomLIHT1gQce0ZZHzDNtZCxgSIvNbRC4%2B2D8mW6aKEIZue%2B77iTmMTMzHvRfxu8SfJUAaoHCnsC%2B%2BAm14R9dhGvTmq03RgAUqCsy4lMwSa7J7s1AfKKS2wx%2FvyMK1PEQ%2BBrgypSrJM35cDvJnd9%2FKTrlOIvJB6JVjuFN80bR2Op1CMdryh623tK5KFaKSaEHInPgaEhyZTflqJaLm1BSMWddAyCaPAQcMPWDffOxXB6Eamb%2BWJwCfrFlrhlcqOjLU%2BVr%2B4SxNXCqyBg%3D%3D",
        jsx: `<Module module_name="KPI overview" module_role="Quickly show KPIs to the user">
        <Grid columnCount={3} height="auto" alignItems="stretch" padding="none">
          <StatusCard hasOutline />
          <DataCard changeValue="+12%" hasOutline />
          <DataCard hasOutline />
        </Grid>
      </Module>`
      }
]

const charts = [
  {
    apiName: 'module_main_keychart',
    displayName: 'Chart',
    allowedSections: ['Main'],
    enviroments: ['analytics','dashboards'],
    ai_template: true,
    ai_instructions: 'module that displays one area chart. should be used to showscase trends for main metric. chart can be edited to be line or bar chart',
    width: 760,
    height: 320,
    tileWidth: '100%',
    thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714066710699.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=CGGL%2BUXpCwze6jtj0tIvZbL0300qZ0rj0lbsjOs6Zpk856%2FEs9%2B7501bJX9FQMZQuHxv7i0UMZMNuRYe739WgrX%2BPlrsHCIHalhcgWQh3GDxBFumM5tVMSbVWk8HmDfhor%2B9%2BHuTNr1n2tnciWr2Yk837lFrU0RW%2FEF4jt855carRZoCZ3zLkcYTrIvB65YZIjc42ZH48PdEDVIZnBFRDa8I28GSaMikcaTzw2edWJ5JNLRr8G%2Bog%2Bc2eSEb9s6pl1lf90KDss5NZFcxbkQ7A4OS8mxDZCWgIeFZE14jjIXRythyAuiLZbje7Y9p1p8pUwx2pGYYFOP13M%2BC2kLvKQ%3D%3D",
    jsx: `<Module module_name="Main Chart" module_role="shows data trends for a key metric">
        <AreaChart lineType="linear" />
      </Module>`
  }, 
  {
    apiName: 'module_main_chart_gridx3',
    displayName: 'Charts Grid',
    allowedSections: ['Main'],
    enviroments: ['analytics','dashboards'],
    ai_template: true,
    ai_instructions: 'module that displays 3 different data charts. can be used to show data trends for different metrics',
    width: 760,
    height: 320,
    tileWidth: '100%',
    thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714066872538.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=7OJwLnBG9IMChw89AqLM7%2FNlVzOUEFoxkHtrNKYNV7Vb5MMpxbeTJnA1IOyvChCXvPj%2BeF493zXbcritA16HPyb9P%2FkrNSGY2jBjJ%2FepnVZPBjIDPpYTI29nPZdIZW48r8J0%2FZYv4Y%2Bdpe8nr8%2FuvpTFuX%2B%2BhXEsO8%2FvaR4Mls7a73ZRE6jBlyTQUn4w6tYhSEDierLrGwjVmPg43pD3eKDmd0ut0byP9Feeco8ZEoGhMEl3UAURcaEvhpFCOTVi6usZSbqfNVwKyBAJO6PwQWzN4CM3l0Ws%2FtQM1aB9nl%2BueRtpbV366kfyDllHlWyGoL84b8l4rgTJo7xPgb7Iwg%3D%3D",
    jsx: `<Module module_name="Three data charts" module_role="Visually represent data trends for key metrics">
    <Grid columnCount={3} height="auto" alignItems="stretch" padding="none">
      <BarChart />
      <LineChart />
      <AreaChart />
    </Grid>
    </Module>`
  }
]

const search = [
    {
      apiName: 'module_main_searchbar',
      displayName: 'Search Bar',
      enviroments: ['data_display', 'file_management', 'analytics', 'dashboards'],
      allowedSections: ['Main'],
      ai_template: true,
      ai_instructions: 'module that shows searchbar and allows user to specify search criteria via filters and tags',
      width: 760,
      height: 160,
      tileWidth: '100%',
      thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714067004037.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=7jcPx8IXFnmLETBWP5mgrpPwGyMvp9bjIP955rM%2BVflwU1ZOps8upD%2FuJ%2B0Tkq8x47H2IAKOuN%2BcRQt%2FBpYjp2hLR0cwbFszk%2BbMLT1NAL1eQpo3q%2FQSY6RXoeFOd%2Bqff9zEx%2FcOGNDcfIrKuOE35nSqSwG9MQV%2F%2BGDG%2Bly8ld%2BD11Zm87SDX3eTzdSz0bwDVLzshfzLgq2rqSxnYgtbu1%2FoitjUoYD1GlT0Xt6qsZEyFQZwKzj4IDMhHr4iaZHr1Cb7GDXOrskybxUrjedNandgJ9IwJQe%2B%2BLyHEkp04U8j6tBvKH9mpxGl9SsSdLT5nWalc5AVvEHnzX49WhASkQ%3D%3D",
      jsx: `<Module module_name="Search Panel" module_role="Allows users to search using keywords">
          <Heading text="Search" />
          <FlexBox direction="flex-row" justifyContent="start" padding="none" paddingX="none" paddingY="none">
            <Search size="large" width="full" text="Search by title or keywords" bgColor="base-0" hasOutline leftIcon="Search" />
            <Button size="large" text="Go" type="primary" leftIcon="none" />
          </FlexBox>
            <FlexBox direction="flex-row" justifyContent="between" padding="none" paddingX="none" alignItems="start">
              <FlexBox direction="flex-row" alignItems="start" padding="none" paddingX="none">
                <Tag text="Tag" color="base-200" size="small" leftIcon="none" rightIcon="Xmark" />
                <Tag text="Tag" color="base-200" size="small" leftIcon="none" rightIcon="Xmark" />
              </FlexBox>
              <FlexBox direction="flex-row" padding="none" justifyContent="end">
                <Button size="small" text="Sort" type="secondary" leftIcon="SortDown" />
                <Button size="small" text="Filter" type="secondary" leftIcon="Filter" />
              </FlexBox>
            </FlexBox>
        </Module>`
    }
  ]

const pricing = [
    {
      apiName: 'module_main_pricing_cards',
      displayName: 'Product Pricing Options',
      allowedSections: ['Main'],
      enviroments: ['e-commerce', 'pricing'],
      ai_template: true,
      ai_instructions: 'module that displays pricing plans for products or services',
      width: 760,
      height: 500,
      tileWidth: '100%',
      thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714067416881.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=qoBIPHFrZtknEnHn4ykWGpk3BhFBPlppWHf%2FC1Z0BAwpHxqgtkFtX9UjzzB02qDuAjkKFWhdFAolwMFqXnG20zuZ8l84SUPEi49ntjmqKVTgAt8gj8KhAyOHu14w3MtPH3py3LzrnS4PS2vidvp2%2FnakLytrjjauwHE6JDowuIv1w3ccQWVAt3tGe8Z3%2BTcaPdInZ%2BnGNmo1DlbUhzJzgK%2FHr1T12z1Ht3M%2BQgPysRx706ihKZ4GaoBvtKZwkqbA0eDLxsNTsZAh7M84LwohdvvjSlaOa0vh6K0BW3aQZnYADw68Mh50GKc%2BeN%2Bb2%2BLViwQaB71qIooAB4c5ZCLG2w%3D%3D",
      jsx: `<Module >
      <Grid columnCount={3} height="full" padding="none" gap="md" alignItems="center">
        <FlexBox direction="flex-col" hasOutline background="base-0" padding="md" minHeight={440} gap="lg" corners="lg" alignItems="center" justifyContent="start" height="full">
          <FlexBox direction="flex-col" padding="none">
            <Badge size="small" text="Standard" type="filled" color="success-content" isPill />
            <Paragraph text="For freelancers and solopreneurs" textSize="auto" marginTop="none" marginBottom="none" />
          </FlexBox>
          <FlexBox direction="flex-row" alignItems="end" padding="none">
            <Heading text="$9" textSize="4xl" textAlign="center" marginTop="none" marginBottom="none" textColor="base-700" lineHeight="none" />
            <Paragraph text="/ mo" textSize="base" marginBottom="none" marginTop="none" lineHeight="normal" />
          </FlexBox>
          <Divider direction="horizontal" />
          <FlexBox direction="flex-col" padding="none" height="full">
            <FlexBox direction="flex-row" alignItems="end" padding="none">
              <IcoNoirIcon name="Check" size="auto" />
              <Paragraph text="1 TB of cloud storage" textSize="base" marginTop="none" marginBottom="none" />
            </FlexBox>
            <FlexBox direction="flex-row" alignItems="end" padding="none">
              <IcoNoirIcon name="Check" size="auto" />
              <Paragraph text="Exclusive access to new features" textSize="base" marginTop="none" marginBottom="none" />
            </FlexBox>
            <FlexBox direction="flex-row" alignItems="end" padding="none">
              <IcoNoirIcon name="Check" size="auto" />
              <Paragraph text="Basic analytics dashboard" textSize="base" marginTop="none" marginBottom="none" />
            </FlexBox>
          </FlexBox>
          <Button type="secondary" text="Start Free Trial" width="auto" />
        </FlexBox>
        <FlexBox direction="flex-col" hasOutline background="base-100" padding="md" gap="lg" corners="lg" alignItems="center" minHeight={480} height="full">
          <FlexBox direction="flex-col" padding="none">
            <Badge size="small" text="Pro" type="filled" color="success-content" isPill />
            <Paragraph text="For small teams" textSize="auto" marginTop="none" marginBottom="none" />
          </FlexBox>
          <FlexBox direction="flex-row" alignItems="end" padding="none">
            <Heading text="$49" textSize="4xl" textAlign="center" marginTop="none" marginBottom="none" textColor="base-700" lineHeight="none" />
            <Paragraph text="/ mo" textSize="base" marginBottom="none" marginTop="none" lineHeight="normal" />
          </FlexBox>
          <Divider direction="horizontal" />
          <FlexBox direction="flex-col" padding="none" height="full" width="full">
            <FlexBox direction="flex-row" alignItems="end" padding="none">
              <IcoNoirIcon name="Check" size="auto" />
              <Paragraph text="5 TB of cloud storage" textSize="base" marginTop="none" marginBottom="none" />
            </FlexBox>
            <FlexBox direction="flex-row" alignItems="end" padding="none">
              <IcoNoirIcon name="Check" size="auto" />
              <Paragraph text="Advanced analytics dashboard" textSize="base" marginTop="none" marginBottom="none" />
            </FlexBox>
            <FlexBox direction="flex-row" alignItems="end" padding="none">
              <IcoNoirIcon name="Check" size="auto" />
              <Paragraph text="Custom integrations support" textSize="base" marginTop="none" marginBottom="none" />
            </FlexBox>
          </FlexBox>
          <Button type="primary" text="Start Free Trial" width="auto" />
        </FlexBox>
        <FlexBox direction="flex-col" hasOutline background="base-0" padding="md" gap="lg" corners="lg" alignItems="center" minHeight={440} height="full">
          <FlexBox direction="flex-col" padding="none">
            <Badge size="small" text="Premium" type="filled" color="success-content" isPill />
            <Paragraph text="For larger organizations" textSize="auto" marginTop="none" marginBottom="none" />
          </FlexBox>
          <FlexBox direction="flex-row" alignItems="end" padding="none">
            <Heading text="$299" textSize="4xl" textAlign="center" marginTop="none" marginBottom="none" textColor="base-700" lineHeight="none" />
            <Paragraph text="/ mo" textSize="base" marginBottom="none" marginTop="none" lineHeight="normal" />
          </FlexBox>
          <Divider direction="horizontal" />
          <FlexBox direction="flex-col" padding="none" height="full">
            <FlexBox direction="flex-row" alignItems="end" padding="none">
              <IcoNoirIcon name="Check" size="auto" />
              <Paragraph text="Unlimited cloud storage" textSize="base" marginTop="none" marginBottom="none" />
            </FlexBox>
            <FlexBox direction="flex-row" alignItems="end" padding="none">
              <IcoNoirIcon name="Check" size="auto" />
              <Paragraph text="24/7 Personalized support" textSize="base" marginTop="none" marginBottom="none" />
            </FlexBox>
            <FlexBox direction="flex-row" alignItems="end" padding="none">
              <IcoNoirIcon name="Check" size="auto" />
              <Paragraph text="Enterprise-level security" textSize="base" marginTop="none" marginBottom="none" />
            </FlexBox>
            <FlexBox direction="flex-row" alignItems="end" padding="none">
              <IcoNoirIcon name="Check" size="auto" />
              <Paragraph text="Customizable usage reports" textSize="base" marginTop="none" marginBottom="none" />
            </FlexBox>
          </FlexBox>
          <Button type="secondary" text="Talk to Sales" width="auto" />
        </FlexBox>
      </Grid>
    </Module>`
    }
]

const forms = [
    {
        apiName: 'module_main_form_sample',
        displayName: 'Sample Form',
        allowedSections: ['Main'],
        enviroments: ['any'],
        ai_template: true,
        ai_instructions: 'module that provides a form for various purposes',
        width: 760,
        height: 480,
        tileWidth: '100%',
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714066003108.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=fXvlS6PGXyOvGNfDW9GN0nFUQ9xt5IG7LWNvpV0DwPEAEjSH69xiOFotnRXAccq127NBU4kSugV61yZ4BmL0JyUeSxgLRukInjhudjUBsFupLdKdbGa90gxW6v%2BrX6RPMTKod34JmaZeKgAZbi2jlF0ndhWWAo9NfumFckNTIKYhBmFU8OlQLWsd%2FVWBosk1GHCS7iVMo6kS8XITHeUY6r%2Bjg85m%2FQm80Uf3%2BAsLZQzGXciKlGH0QOcBWGB3jAshFTgjo8evsbDYMt4SGWiMGC4d1fZdwulQF%2FvY3ISfgzaqd%2BBHLexbQ%2Fk9dbpimPHZzbCGXro83kuM0MT3Jlo0Uw%3D%3D",
        jsx: `<Module alignItems="center">
        <Form gap="md" padding="md" maxWidth={420} alignItems="stretch">
          <Heading text="Form Title" />
          <FlexBox gap="sm" padding="none" alignItems="stretch">
            <InputText label="Field 1" rightIcon="eyeOff" placeholder="Enter details" />
            <InputText label="Field 2" placeholder="Enter more details" />
            <Select size="medium" currentOption="Option 1" label="Choose Option" />
          </FlexBox>
          <Checkbox type="standard" label="I agree to the Terms of Service" />
          <Button text="Submit" type="primary" leftIcon="none" rightIcon="none" width="auto" size="medium" />
        </Form>
      </Module>`
    }, 
    {
        apiName: 'module_main_form_select_options',
        displayName: 'Select Options Form',
        allowedSections: ['Main'],
        enviroments: ['any'],
        ai_template: true,
        ai_instructions: 'module that provides a form for user to select options',
        width: 760,
        height: 480,
        tileWidth: '100%',
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714066096247.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=nD9CyhzZPC2RXt4SY8vWH99hnMam9WdN%2BC5HOFMZqNa8PStG8umQ90VnDwBE7r5Bk47sMWMH%2FPvEnWpdw7CeDYU1rIFtyBt9JF6EboT0u%2Fhuq0UaGicw90EchfMuzVTsHB2cW8iwk%2F408tDOcCtQk8dkang0Y0qZ%2F1sr1JkAWKMubLZ88ULV76OG10WroNUs4ohwZa57AdlIgC19pXIB%2BRGospCyGoDKTiNmOw2CiMhCFOpZX7jsNERfYm8emqLUTlkeFK0GsL%2F8iWwGUSZuPSKkDv3gnk5hLsWUFFRRvC8grPlljwWXALle9qYUNA7MWWk0BXO%2F0h0L62CdYZJPVA%3D%3D",
        jsx: `<Module alignItems="center" justifyContent="center">
        <Form gap="md" padding="md" maxWidth={560} alignItems="start">
          <Heading text="What's your site about?" textAlign="center" marginBottom="none" mob_textAlign="center" />
          <Paragraph text="This will help us find you some starting ideas and examples." marginTop="none" marginBottom="base" />
          <FlexBox gap="sm" padding="none" alignItems="start" direction="flex-row" flexWrap="wrap">
            <Button type="secondary" text="Technology" />
            <Button type="secondary" text="Art" />
            <Button type="secondary" text="Fashion" />
            <Button type="secondary" text="Photography" />
            <Button type="secondary" text="Food" />
            <Button type="secondary" text="Sports" />
            <Button type="secondary" text="Music" />
            <Button type="secondary" text="Travel" />
            <Button type="secondary" text="Health" />
            <Button type="secondary" text="Finance" />
            <Button type="secondary" text="Gaming" />
          </FlexBox>
          <InputText label="Did not find what you were looking for?" placeholder="Describe your site" leftIcon="none" width="full" />
          <FlexBox padding="none" direction="flex-row" justifyContent="end" width="full" alignItems="center">
            <Button text="Back" type="secondary" style="filled" size="medium" width="auto" />
            <Button text="Next" width="auto" size="medium" />
          </FlexBox>
        </Form>
      </Module>`
    }
]

const stackedLists = [
  {
    apiName: 'module_main_stacked_list_users',
    displayName: 'User List',
    allowedSections: ['Main'],
    enviroments: ['data_display', 'file_management', 'analytics', 'dashboards'],
    ai_template: true,
    ai_instructions: 'module that displays a list of users. can be used to show team members, customers, or other users',
    width: 780,
    height: 292,
    tileWidth: '100%',
    thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1715486167437.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=dwU12efCxj0%2FF8Iizc7ewlO%2Fmqx1kngQAWgiUU5BExCS5f%2FKCUnBc2xjV00WIuZtKzMeiJKdXmIZ2I0ixqmBH2xGEA87Huco2ehI27hV89DZWoB7oqiBr9znFhUy1D0hftYF21lmfSmjpPWNaMqkC%2F4rRqKrHnt1dOSpOxYtoBkNxVLvmgY%2Bh%2FgfaiylcxphAgTvgrw64wyyroPSagbfTmxoJNrI33yyvRJKBNTYTjvPvBbER9lz7WexwPcQYq1hjBQALGiRPe4tk3Mbfq5cYRr%2F1%2BVNg8No2LHdyK4g02EYjUPuHdLXoVgDjIfy%2F5AKcmqDs6KP08PijJu%2Bu8O2mA%3D%3D",
    jsx: `<Module flexDirection="flex-col" paddingX="base" paddingY="sm" gap="xs" alignItems="start" justifyContent="start" width="full">
    <FlexBox direction="flex-row" padding="none" paddingX="none" justifyContent="between" paddingY="base" alignItems="center">
      <AvatarCard name="Alice Johnson" secondaryText="alice.johnson@email.com" />
      <FlexBox direction="flex-row" width="auto" padding="none">
        <Text text="Role" />
        <KebabMenu iconName="MoreVert" padding="sm" direction="downward-left" />
      </FlexBox>
    </FlexBox>
    <Divider direction="horizontal" />
    <FlexBox direction="flex-row" padding="none" paddingX="none" justifyContent="between" paddingY="base" alignItems="center">
      <AvatarCard name="Bob Smith" secondaryText="bob.smith@email.com" />
      <FlexBox direction="flex-row" width="auto" padding="none">
        <Text text="Role" />
        <KebabMenu iconName="MoreVert" padding="sm" direction="downward-left" />
      </FlexBox>
    </FlexBox>
    <Divider direction="horizontal" />
    <FlexBox direction="flex-row" padding="none" paddingX="none" justifyContent="between" paddingY="base" alignItems="center">
      <AvatarCard name="Cindy Brown" secondaryText="cindy.brown@email.com" />
      <FlexBox direction="flex-row" width="auto" padding="none">
        <Text text="Role" />
        <KebabMenu iconName="MoreVert" padding="sm" direction="downward-left" />
      </FlexBox>
    </FlexBox>
    <Divider direction="horizontal" />
    <FlexBox direction="flex-row" padding="none" paddingX="none" justifyContent="between" paddingY="base" alignItems="center">
      <AvatarCard name="David Wilson" secondaryText="david.wilson@email.com" />
      <FlexBox direction="flex-row" width="auto" padding="none">
        <Text text="Role" />
        <KebabMenu iconName="MoreVert" padding="sm" direction="downward-left" />
      </FlexBox>
    </FlexBox>
  </Module>`
  }
]

const messagesUI = [
  {
    apiName: 'module_main_chat_ui',
    displayName: 'Chat UI',
    allowedSections: ['Main', 'SidePanel'],
    enviroments: [],
    ai_template: true,
    ai_instructions: 'module that displays a chat interface. can be used to show conversations between users',
    width: 780,
    height: 788,
    tileWidth: '100%',
    thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1715575573487.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=R7qjBdgpZ1VvY2g%2FtNsk%2BuNQVVcqjG6p81Nfbm0cKVO%2F9116dqajZD1gdIvXwkFhT%2BqFF83NoxRTXD77ZtAaVbdc%2BHtl8LtPo4ThZvf4Yl5oHKKy%2FVkddzxNrGqgGTSdSnfr9Z%2Ff73otAdUtehJ7Bn7aT98N6vx0Epnbri24xunL2tzf%2FMRofOrPSF2R2z19kIC8R4ifjIYbMxZyvzQvyj%2B%2FFUMNMCUE28Hm9C0WKZ3KSRVBmAfyzosDyl8diVs3wYGM%2FvlhL4SXdYXgeB%2FAlORBAWMCexUA2NkoWkYAxWDLXCs9Raw8U20OXC%2Ffo1Mn9ojmnTO08DJrQ48Vdakqyg%3D%3D",
    jsx: `<Module  height="h-full" paddingY="md" paddingX="0">
    <FlexBox direction="flex-row" justifyContent="start" padding="none" paddingX="none" paddingY="none">
      <FlexBox direction="flex-row" justifyContent="start" padding="none" paddingX="none" paddingY="none">
        <Heading text="User Name" textSize="xl" />
        <Status text="online" size="small" color="success-content" showIndicator />
      </FlexBox>
      <FlexBox direction="flex-row" padding="none" justifyContent="end">
        <ButtonIcon type="secondary" alignSelf="center" isPill size="xsmall" icon="Phone" />
        <ButtonIcon type="secondary" alignSelf="center" isPill size="xsmall" icon="VideoCamera" />
      </FlexBox>
    </FlexBox>
    <Divider direction="horizontal" margins="sm" color="base-200" />
    <FlexBox direction="flex-col" alignItems="start" paddingY="base" corners="lg" padding="base" paddingX="base" height="h-full" justifyContent="end">
      <FlexBox direction="flex-row" width="2/3" padding="none">
        <Avatar size="small" type="image" />
        <FlexBox direction="flex-col" width="full" background="base-100" corners="md" padding="sm" paddingX="base" paddingY="sm">
          <Paragraph text="Hey there! How can I assist you today?" textAlign="left" />
        </FlexBox>
      </FlexBox>
      <FlexBox direction="flex-row" width="2/3" padding="none" selfAlign="end">
        <FlexBox direction="flex-col" width="full" corners="md" selfAlign="end" background="accent" padding="sm" paddingX="base" paddingY="sm">
          <Paragraph text="I'm looking for information on your latest product updates." textAlign="right" />
        </FlexBox>
        <Avatar size="small" type="image" image="alena" />
      </FlexBox>
      <FlexBox direction="flex-row" width="2/3" padding="none" selfAlign="start">
        <Avatar size="small" type="image" image="sam" />
        <FlexBox direction="flex-col" width="full" background="base-100" corners="md" padding="sm">
          <Paragraph text="Sure, our latest update includes several performance improvements and new features!" textAlign="left" />
        </FlexBox>
      </FlexBox>
      <FlexBox direction="flex-row" width="2/3" padding="none" selfAlign="end">
        <FlexBox direction="flex-col" width="full" background="accent" corners="md" padding="sm" selfAlign="end">
          <Paragraph text="That sounds great! Can you tell me more about the new features?" textAlign="right" />
        </FlexBox>
        <Avatar size="small" type="image" image="alena" />
      </FlexBox>
    </FlexBox>
    <FlexBox direction="flex-row" justifyContent="start" padding="none" paddingX="none" paddingY="none">
      <InputText size="medium" placeholder="Start typing..." width="full" bgColor="base-0" hasOutline rightIcon="PeopleTag" />
      <ButtonIcon type="secondary" alignSelf="center" isPill size="small" icon="Voice" />
      <ButtonIcon type="primary" alignSelf="center" isPill size="small" icon="Send" />
    </FlexBox>
  </Module>`
  }, 
  {
    apiName: 'module_main_chat_ui_compact',
    displayName: 'Chat UI Compact',
    allowedSections: ['Main', 'SidePanel'],
    enviroments: [],
    ai_template: true,
    ai_instructions: 'module that displays a chat interface. more suitable for sidepanel',
    width: 780,
    height: 788,
    tileWidth: '100%',
    thumbnail: null,
    jsx:`<Module height="h-full" paddingY="md" paddingX="0">
    <FlexBox direction="flex-row" justifyContent="between" padding="none" paddingX="base" paddingY="none">
      <AvatarCard  />
      <FlexBox direction="flex-row" hasOutline padding="none" paddingX="none" paddingY="none" justifyContent="end" width="auto" alignItems="center">
        <ButtonIcon type="link" alignSelf="center" isPill size="xsmall" icon="Phone" />
        <KebabMenu iconName="MoreVert" padding="sm" direction="downward-left" size="24">
          <FlexBox direction="flex-col" padding="none" gap="sm" textSize="sm">
            <FlexBox direction="flex-row" padding="none" alignItems="center">
              <IcoNoirIcon size="16" color="base-content" name="OpenNewWindow" />
              <Text text="Open" />
            </FlexBox>
            <FlexBox direction="flex-row" padding="none" alignItems="center">
              <IcoNoirIcon size="16" color="base-content" name="EditPencil" />
              <Text text="Edit" />
            </FlexBox>
            <FlexBox direction="flex-row" padding="none" alignItems="center">
              <IcoNoirIcon size="16" color="base-content" name="Archive" />
              <Text text="Archive" />
            </FlexBox>
          </FlexBox>
        </KebabMenu>
      </FlexBox>
    </FlexBox>
    <FlexBox direction="flex-col" alignItems="start" paddingY="base" corners="lg" padding="base" paddingX="base" height="h-full" justifyContent="end">
      <FlexBox direction="flex-row" width="2/3" padding="none">
        <FlexBox direction="flex-col" width="full" background="base-100" corners="md" padding="sm" paddingX="base" paddingY="sm">
          <Paragraph text="Lorem ipsum dolor sit amet, consectetur adipiscing elit." textAlign="left" />
        </FlexBox>
      </FlexBox>
      <FlexBox direction="flex-row" width="2/3" padding="none" selfAlign="end">
        <FlexBox direction="flex-col" width="full" corners="md" selfAlign="end" background="accent" padding="sm" paddingX="base" paddingY="sm" alignItems="end">
          <Paragraph text="Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." textAlign="right" />
        </FlexBox>
      </FlexBox>
      <FlexBox direction="flex-row" width="2/3" padding="none" selfAlign="start">
        <FlexBox direction="flex-col" width="full" background="base-100" corners="md" padding="sm">
          <Paragraph text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." textAlign="left" />
        </FlexBox>
      </FlexBox>
      <FlexBox direction="flex-row" width="2/3" padding="none" selfAlign="end">
        <FlexBox direction="flex-col" width="full" background="accent" corners="md" padding="sm" selfAlign="end" alignItems="end">
          <Paragraph text="Duis aute irure " textAlign="right" />
        </FlexBox>
      </FlexBox>
      <FlexBox direction="flex-row" width="2/3" padding="none" selfAlign="end">
        <FlexBox direction="flex-col" width="full" background="accent" corners="md" padding="sm" selfAlign="end" alignItems="end">
          <Paragraph text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." textAlign="right" />
        </FlexBox>
      </FlexBox>
    </FlexBox>
    <FlexBox direction="flex-row" justifyContent="start" padding="none" paddingX="base" paddingY="none">
      <InputText size="medium" placeholder="Start typing..." width="full" bgColor="base-0" hasOutline rightIcon="PeopleTag" />
      <ButtonIcon type="secondary" alignSelf="center" isPill size="small" icon="Voice" />
      <ButtonIcon type="primary" alignSelf="center" isPill size="small" icon="Send" />
    </FlexBox>
  </Module>`
  }
]


const todoLists = [
  {
    apiName: 'module_main_todo_list',
    displayName: 'To-Do List',
    allowedSections: ['Main', 'Sidebar'],
    enviroments: [''],
    ai_template: true,
    ai_instructions: 'module that displays a list of tasks. can be used to show tasks, reminders, or other to-do items',
    width: 780,
    height: 400,
    thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1715575454149.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=DhTCCbAbx5GCqzGIQa%2FoA%2Bdx8vqhd7lL%2BIQv5OmpSb2E05BKoUatKsrY0LXoBRYNkOtrH90rsPHw%2FLlYUqghYqvMbwM13cI3wS84z%2B4hfcL%2Fo1DqtwkYxrvFnMcFA%2FfXiC1E2jG1Zqfca%2FORe0kEqrNSEsEAsVW3ImNsk%2FoykYGxhbV%2F7UNXGz2Qr2QD10rOytkK2ukTnOVuM4UGYNFsLeCuwzZluhBP7AGYwtRicFBDDpkVYsOHGisVGoMU9MihLX30OAB7L1goHZ8qbQ772QEZvuQMk3IJmtCDk9BrV9Okuq1YDVDC0t3TixBGeVvTvN8CL7ZGPHyXCugeWZmJ8g%3D%3D", 
    jsx: `<Module flexDirection="flex-col" paddingX="base" paddingY="sm" gap="base" alignItems="start" justifyContent="start" width="full">
    <FlexBox direction="flex-row" padding="none" justifyContent="between">
      <Heading text="Tasks" textSize="2xl" />
      <Button type="accent" text="New Task" size="small" leftIcon="Plus" />
    </FlexBox>
    <FlexBox direction="flex-col" padding="none" justifyContent="between" gap="xs">
      <FlexBox direction="flex-row" padding="none" paddingX="none" justifyContent="start" paddingY="none" alignItems="center" corners="md" gap="sm">
        <IcoNoirIcon name="Calendar" size="auto" />
        <Heading text="Today" textSize="lg" fontWeight="medium" marginTop="sm" marginBottom="sm" />
      </FlexBox>
      <FlexBox direction="flex-row" padding="sm" paddingX="base" justifyContent="between" paddingY="sm" alignItems="start" corners="md">
        <Checkbox type="standard" label="Complete market analysis" />
        <IcoNoirIcon name="StarSolid" size="auto" color="success-content" />
      </FlexBox>
      <FlexBox direction="flex-col" padding="sm" paddingX="base" justifyContent="between" paddingY="sm" alignItems="start" corners="md" background="base-100">
        <Checkbox type="standard" label="Review project requirements" />
      </FlexBox>
      <FlexBox direction="flex-col" padding="sm" paddingX="base" justifyContent="between" paddingY="sm" alignItems="start" corners="md">
        <Checkbox type="standard" label="Design initial wireframes" />
      </FlexBox>
    </FlexBox>
    <FlexBox direction="flex-col" padding="none" justifyContent="between" gap="xs">
      <FlexBox direction="flex-row" padding="none" paddingX="none" justifyContent="start" paddingY="none" alignItems="center" corners="md" gap="sm">
        <IcoNoirIcon name="Cart" size="auto" />
        <Heading text="Groceries" textSize="lg" fontWeight="medium" marginTop="sm" marginBottom="sm" />
      </FlexBox>
      <FlexBox direction="flex-col" padding="sm" paddingX="base" justifyContent="between" paddingY="sm" alignItems="start" corners="md">
        <Checkbox type="standard" label="Buy fresh vegetables" isChecked />
      </FlexBox>
      <FlexBox direction="flex-col" padding="sm" paddingX="base" justifyContent="between" paddingY="sm" alignItems="start" corners="md" background="none">
        <Checkbox type="standard" label="Pick up bread and milk" />
      </FlexBox>
      <FlexBox direction="flex-col" padding="sm" paddingX="base" justifyContent="between" paddingY="sm" alignItems="start" corners="md">
        <Checkbox type="standard" label="Restock kitchen pantry" />
      </FlexBox>
    </FlexBox>
  </Module>`
  }
]

export const mainModules = [
    ...stackedLists.map(m => ({ ...m, category: 'stacked_lists', displayName: 'Stacked Lists' })),  
  ...dataGrids.map(m => ({ ...m, category: 'data_grids', displayName: 'Grids' })),
    ...forms.map(m => ({ ...m, category: 'forms', displayName: 'Forms' })),
    ...navigation.map(m => ({ ...m, category: 'navigation', displayName: 'Page Navigation' })),  
    ...tables.map(m => ({ ...m, category: 'tables', displayName: 'Tables' })),
    
    
    ...charts.map(m => ({ ...m, category: 'charts', displayName: 'Charts' })),
    ...search.map(m => ({ ...m, category: 'search_bars', displayName: 'Search' })),
    ...pricing.map(m => ({ ...m, category: 'pricing', displayName: 'Pricing' })),
    ...messagesUI.map(m => ({ ...m, category: 'messages_ui', displayName: 'Chat UI' })),
    ...todoLists.map(m => ({ ...m, category: 'todo_lists', displayName: 'To-Do Lists' }))

    
    

]
