import React, { useState, useEffect, useContext } from 'react';
import { EditorContext } from '../../../EditorContext';
import FrameTemplate from './FrameTemplate'
import DnDContext from '../../../dragNdrop/DnDContext';
import { NavArrowLeft } from 'iconoir-react';
import FlowGroups from './Groups';
import FramesPerGroup from './FramesPerGroup';

export default function Pages({insertNewFrame }) {
  const { assets, effectiveVariables, set, view } = useContext(EditorContext);
  const { handleDragEnd, handleDragStart } = useContext(DnDContext);
  const [selectedFlow, setSelectedFlow] = useState(null);

  const folders = set?.folders?.sort((a, b) => a.index - b.index).filter(folder => folder.name != 'Layouts') || [];
  const flows = folders.flatMap(folder => {
    const sortedPages = (folder.pages || []).sort((a, b) => a.index - b.index);
    return sortedPages;
  });
  
  return (
    <div className='w-full h-full flex flex-col gap-2' style={{  overflow: 'scroll', userSelect: 'none' }}>
      <div className='w-full h-full flex flex-col gap-2'
        style={{  overflow: 'scroll', userSelect: 'none' }}>
            {flows?.length > 0 && !selectedFlow && 
            <FlowGroups
              flows={flows} 
              onSelect={setSelectedFlow}
              />}
            {selectedFlow && 
            <>
            <div className='flex flex-row gap-2 items-center justify-between text-xs py-2 font-medium px-1.5'>
              <div className='flex flex-row gap-1 items-center justify-start -ml-1'>
                <NavArrowLeft onClick={()=>setSelectedFlow(null)} className='cursor-pointer hover:scale-125 stroke-[1.8px] transition-all duration-75' width={16} height={16}/>
                <h1>{selectedFlow.name}</h1>
              </div>
              {/*<button className='border bg-slate-100 border-slate-300 px-2 rounded-md font-normal mr-1'>
                Add All
              </button>*/}
            </div>
            <FramesPerGroup
              flow={selectedFlow}
              assets={assets}
              view={view}
              insertNewFrame={insertNewFrame}
              variables={effectiveVariables}
              handleDragEnd={handleDragEnd}
              handleDragStart={handleDragStart}
            />
            </>}
          </div>

      
      </div>
  );
}


