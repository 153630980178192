import React from 'react'
import PropTypes from 'prop-types'
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Bar, BarChart, LabelList } from 'recharts';
import { Icon } from '../index'; /* replace to 'import { Icon } from './index'' */
// Run 'npm install recharts' to install the recharts library


const dummyDataData = {
    keyPairs: ['name', 'temperature'],
    valuePairs: [
        ['Aug 12', 45],
        ['Aug 19', 34],
        ['Aug 26', 52],
        ['Sep 2', 51],
        ['Sep 9', 52],
        ['Sep 16', 60],
        ['Sep 23', 80]
    ]
}

export default function BarChartComponent(props) {

    const {
        backgroundColor = null, 
        width = 'full',
        height = '120px',
        barColor = 'primary',
        showDots = true,
        showLabels = true,
        showYAxis = true,
        showXAxis = true,
        showGrid = true,
        data = dummyDataData,
        title = 'Metric Name',
        emptyState = false,
        emptyMessage = 'Data may take up to 24 hrs to show',
        attributes,
        listeners
      } = props;
    const { junoAttributes,  showTag,  outlineStyle, onPropertyUpdate=()=>{}, preview} = props.junoProps || {} /* remove line */

    const widthStyles = `w-${width}`;
    // const paddingStyles = padding === 'none' ? 'p-0' : `p-${padding}`;
    // const cornerStyles = corners === 'none' ? '' : `rounded-${corners}`;
    const bgStyles = backgroundColor && backgroundColor != 'none' ? `bg-${backgroundColor}` : ''
    const fontColorStyles = backgroundColor && backgroundColor != 'none' ? `text-base-content` : 'text-inherit'

    let wrapperClasses = `flex flex-col items-stretch relative ${widthStyles} ${bgStyles} ${fontColorStyles}`
    wrapperClasses += ` ${outlineStyle}`/* remove line */
    
    const emptyStyles = `flex flex-col justify-center items-center px-sm text-sm font-medium gap-2 rounded-md`

    function transformData(keyPairs, valuePairs) {
        return valuePairs.map(values => {
            return values.reduce((obj, val, index) => {
                obj[keyPairs[index]] = val;
                return obj;
            }, {});
        });
    }
    const sampleData = data ? transformData(data?.keyPairs, data?.valuePairs) : []
    const totalValue = sampleData.reduce((acc, curr) => acc + curr[data?.keyPairs[1]], 0)
    
    const sideMargins = (!showYAxis && !showLabels && !showDots && !showXAxis) ? 0 : 20

    return (
        <div
            {...attributes} {...listeners} 
            {...junoAttributes} /* remove line */
            className={wrapperClasses}
        >
            {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Bar Chart</div> /* remove line */}
            
            {/* Title Block & Menu */}
            <div className="flex flex-row items-start justify-between mb-md">
                <div className="flex flex-col text-sm font-medium gap-2 items-start relative justify-between">
                    <EditableOnDoubleClick text={title} onBlur={onPropertyUpdate('title')} preview={preview} /* replace to '{title}' */ />
                    <span className='text-xl font-semibold'>{totalValue}</span>
                </div>
                {/* implement Kebab here
                <KebabMenu>
                     
                </KebabMenu> 
                */}
            </div>

            {/* CHART */}
            {!emptyState ?
            <div className='flex flex-row' style={{ 
                    width: '100%', 
                    pointerEvents: preview ? 'auto' : 'none', /* remove line */
                    height: height,  }}>
                <ResponsiveContainer width={'100%'} height="100%" >
                <BarChart data={sampleData} margin={{ top: 20, right: sideMargins, bottom: 0, left: sideMargins }}>
                    {showGrid && <CartesianGrid strokeDasharray="1 3" />}
                    {showXAxis && <XAxis dataKey={data?.keyPairs[0]} tick={{ fontSize: '12px'}}/>}
                    {showYAxis && <YAxis width={20} tick={{ fontSize: '12px'}}/>}
                    <Tooltip />
                    <Bar dataKey={data?.keyPairs[1]} fill={`var(--${barColor})`}>
                        {showLabels && (<LabelList 
                            dataKey={data?.keyPairs[1]} 
                            position="top" 
                            style={{ fontSize: 10 }} 
                        />
                        )}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
            </div>
        :
        <>
        <div className={emptyStyles} style={{ height: height, backgroundColor: `color-mix(in srgb, currentColor 8%, transparent)`}}>
            <Icon icon='chart-up' />
            <span className='font-normal text-sm'>
                <EditableOnDoubleClick text={emptyMessage} preview={preview} onBlur={onPropertyUpdate('emptyMessage')} /* replace to '                {emptyMessage}' */ />
            </span>
        </div>
        </>
        }
        </div>
    );
}

BarChartComponent.propTypes = {
    title: PropTypes.string,
    width: PropTypes.oneOf(['auto', 'full', '1/2']),
    height: PropTypes.oneOf(['92px', '120px', '240px', '360px']),
    barColor: PropTypes.oneOf(['primary', 'accent', 'base-content']),
    backgroundColor: PropTypes.oneOf(['base-0', 'base-100', 'base-200']),
    emptyState: PropTypes.bool,
    emptyMessage: PropTypes.string,
    showGrid: PropTypes.bool,
    showDots: PropTypes.bool,
    showLabels: PropTypes.bool,
    showYAxis: PropTypes.bool,
    showXAxis: PropTypes.bool,
    data: PropTypes.shape({
        keyPairs: PropTypes.arrayOf(PropTypes.string),
        valuePairs: PropTypes.arrayOf(PropTypes.array)
    })
};

/* ignore rest */

BarChartComponent.definitions = {
    apiName: 'BarChart',
    displayName: 'Bar Chart',
    description: 'Visualizes data as a line chart, fairly customizable.',
    ai_instructions: 'Use to display changes or trends in data across different categories or times.',
    type: 'data_visualization',
    relativeSize: 'medium to large',
    acceptedChildren: 'none',
    propDefinitions: {
        title: {
            type: 'string',
            displayName: 'Title',
            default: 'Metric Name', 
        },
        data: {
            type: 'arrayOfObjects',
            displayName: 'Data',
            ai_instructions: 'Use keyPairs to define the keys for the x and y axis, and valuePairs to define x-axis values and data points. Limit to 30 data points to ensure performance.',
            default: dummyDataData
        },
        width: {
            type: 'width',
            options: ['auto', 'full', '1/2'],
            displayName: 'Width',
            default: 'full', 
            tile: '1/2'
        },
        height: {
            type: 'oneOf',
            options: ['92px', '120px', '240px', '360px'],
            displayName: 'Height',
            default: '120px',
            tile: '1/2',
            ai_instructions: 'small is 92px, medium 148px, large 240px',
        },
        barColor: {
            type: 'colors',
            options: ['primary', 'accent', 'base-content'],
            displayName: 'Line Color',
            default: 'primary', 
            tile: '1/2'
        },
        backgroundColor: {
            type: 'colors',
            options: ['base-0', 'base-100', 'base-200'],
            displayName: 'Background',
            default: null,
            tile: '1/2'
        },
        showGrid: {
            type: 'bool',
            displayName: 'Show Grid',
            default: true
        },
        showDots: {
            type: 'bool',
            displayName: 'Show Dots',
            default: true
        },

        showLabels: {
            type: 'bool',
            displayName: 'Show Labels',
            default: true
        },
        showYAxis: {
            type: 'bool',
            displayName: 'Show Y Axis',
            default: true
        },
        showXAxis: {
            type: 'bool',
            displayName: 'Show X Axis',
            default: true
        },

        emptyState: {
            type: 'bool',
            displayName: 'Empty State',
            default: false, 
            ai_instructions: 'will display empty message instead of bars',
        },
        emptyMessage: {
            type: 'longString',
            displayName: 'Empty Message',
            default: 'Data may take up to 24 hrs to show', 
        },
        
        
    }
};
