export const featurePanels = [
    {
      apiName: 'featurePanel_testimonials',
        displayName: 'Feature Panel with Testimonial',
        rootComponent: 'FeaturePanel',
        description: `Feature panel can be added in onboarding or authentication pages to promote product. This one showcases testimonials`,
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714429649640.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=OjQVKUoVz6PObtGL2duerEh20lPGQJFUxGgMzi51d%2Feo7j84%2FIhfzmu%2BPzKyXS4Uvqq0%2FioH17rZA67M4KavqbNNRHdbhmqejh5jmlxdBJbW9UOG2M1cBshlZf5kw7ZuZloCI0%2BrQvDJOeJKtSCtLVxxeVK3nMk%2F%2Fw5NK4IXb8YAB%2BnBRISKBiWKRbCfHXcaFEFXi%2FBxHv2D5m7XxplGkRu8gTXIgGtxScgBk38QOIY%2F%2BzukN7Z2yBvKewjvJ8fdP2MB6%2BN%2BZLHYNT1GyqE748lIg0DFC4i8z87Qniiq3eyegq0tePcZFffF4HqBXs9oXYBHfxpkKYFCpXFm7vtPow%3D%3D",
        width: 800,
        height: 800,
        jsx: `<FeaturePanel alignItems="center" background="primary" justifyContent="center">
        <Module module_name="testimonial" module_role="Showcasing customer testimonials" paddingX="base" paddingY="sm" flexDirection="flex-col" gap="base" alignItems="center" justifyContent="center" width="full" height="h-auto">
        <FlexBox maxWidth={420} alignItems="center" background="none">
            <StarRatings ratingValue="100%" ratingText="5" ratingPosition="right" starColor="orange-500" starSize="24px" progress="100%" fontSize="medium" />
            <Paragraph text="Testimonial text, around 120 characters, ideal for brief descriptions or introductory text." textSize="xl" textAlign="center" />
            <AvatarCard imageSize="40px" imageSrc={null} imagePosition="left" name="@customer" secondaryText="Company Name" bgColor="none" corners="none" padding="none" />
            <FlexBox padding="none" direction="flex-row" alignItems="center" justifyContent="center" />
        </FlexBox>
        </Module>
        </FeaturePanel>`, 
    }, 
    {
      apiName: 'featurePanel_image',
        displayName: 'Feature Panel with Image',
        description: `Feature panel can be added in onboarding or authentication pages to promote product. This one showcases a sample image. Image can be uploaded separately, can display a product or a service.`,
        rootComponent: 'FeaturePanel',
        width: 800, 
        height: 800,
        ai_template: true,
        jsx: `<FeaturePanel alignItems=\"center\" background=\"accent\" justifyContent=\"center\" backgroundImageSrc=\"https://images.unsplash.com/photo-1464822759023-fed622ff2c3b?crop=entropy&cs=srgb&fm=jpg&ixid=M3w0OTQ5NTB8MHwxfHNlYXJjaHw1fHxtb3VudGFpbnN8ZW58MHx8fHwxNzEwMTg0NjYzfDA&ixlib=rb-4.0.3&q=85\" />\n`,
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1710448296611.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=Si0yCPlR1Btjr8i35Ie7igvhxwH021oFlxWQdW6Qug4ukfbfWpjwHnl55fXr6pcNzrQkT3khNIBhpAnp3yd92J511ms8wOzuOi2VA1nqyPp2PvYPK%2BfuCfK5%2BjysQCYnNWhTHMQC0Mp7V71%2FmsnsWDuXMWUYzeQFO8Buvtp3Kh1IHxFH2P3ipT90XTswUGRv3fHO0xu3FtyGSIZAge690GA%2BISVRrX6kQT%2FrC%2FWV4wt92tOkAnJbt%2FLygL6myB%2FSrrTgfHa3JJpor12cjdVp7%2BCHrYc65XViRuBtdLIIKNS2xH7dKNEy6t6nCek%2FVckDf860%2BwWlvbXRniP0USexzQ%3D%3D"
    },
    {
      apiName: 'featurePanel_logos',
        displayName: 'Feature with Logos',
        description: `Feature panel can be added in onboarding or authentication pages to promote product. This one showcases logos of companies or customers.`,
        rootComponent: 'FeaturePanel',
        width: 800, 
        height: 800,
        ai_template: true,
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714065112966.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=CeHK4gm8%2FyIp6eEUnYhNxm6p%2FfwM6qyiEnMyWPqGS8xvyVNoC3XC6dz%2BDIuClDe5QSkODlJtxLPLpBc1ob3T9oYiebxmQoDoqhyTZIgGukNfTJFs3GhfFIsmRj1u62ctYkHkCwNaG8fA6E2iiF80VEc%2B6NWAPrWjT88klN0t2D3NOoqiCjBL3A%2BN4SZMmcinBE4Ae%2BTQ%2BdUzfsfqAKb5T6N3DYyPKXADZd2jxSJULLCIyimYBEwXrl4lkLZwdugaOoyJC98yRIIrPwh2wSha4qj2jxzcRP9i9MEVX%2F9VBSvTMbAelS8e9X5R7UrsDhF97BiUZueC8CfLM43tq31OiQ%3D%3D",
          jsx: `<FeaturePanel alignItems="center" background="primary" justifyContent="center">
          <Module module_name="testimonial" module_role="Showcasing customer testimonials" paddingX="base" paddingY="sm" flexDirection="flex-col" gap="base" alignItems="center" justifyContent="center" width="full" height="h-auto">
            <FlexBox maxWidth={420} alignItems="center" background="none" gap="md">
              <Heading text="Trusted by over 3,000 teams" textSize="3xl" marginBottom="lg" />
              <Grid columnCount={3} height="full" padding="none" gap="md">
                <Image altText="company logo" width="w-full" height="h-[60px]" />
                <Image altText="company logo" width="w-full" height="h-[60px]" />
                <Image altText="company logo" width="w-full" height="h-[60px]" />
                <Image altText="company logo" width="w-full" height="h-[60px]" />
                <Image altText="company logo" width="w-full" height="h-[60px]" />
                <Image altText="company logo" width="w-full" height="h-[60px]" />
              </Grid>
            </FlexBox>
          </Module>
        </FeaturePanel>`,
          
    }
    ]
    