import React, { useMemo } from 'react';
import { getDescendants } from '../../../utilities/helpers';
import LightRouter from '../../../components/LightRouter';
import { convertThemeVariablesToStyles } from '../canvas/helpers';
import { MediaImage } from 'iconoir-react';

export default function FramePreview({
  frame,
  width = '100%',
  height = '100%',
  scaleFactor = 1,
  assets = [],
  effectiveVariables = []
}) {
  const thumbnail = frame?.thumbnail;
  /*/ If thumbnail is provided, render the Thumbnail component
  if (thumbnail) {
      return <Thumbnail width={width} height={height} frame={frame} />;
  } 
  // Otherwise, render the Miniature component
  else { */
      return (
          <Miniature 
              frameObjects={frame?.objects || []}
              width={width}
              height={height} 
              assets={assets} 
              effectiveVariables={effectiveVariables}
          />
      );

}
  const Thumbnail = ({ width, height, frame }) => {
    const thumbnail = frame?.thumbnail;
    if (thumbnail) {
      return (
        <div
          draggable={false}
          style={{
            width,
            height,
            backgroundImage: `url(${thumbnail})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            overflow: 'hidden'
          }}
        />
      );
    } else {
      return (<div
          draggable={false}
          className='flex flex-col items-center justify-center text-slate-400 gap-10 text-5xl'
          style={{
            width,
            height,
            overflow: 'hidden', 
          }}
        ><MediaImage width={96} height={96}/>{frame.name}</div>)
      }
  };

function Miniature ({ 
  frameObjects, 
  width, 
  height,
  assets, 
  effectiveVariables }){
  const rootObject = frameObjects.find(obj => obj.parent === obj.frame);
  const objects = frameObjects.filter(obj => obj.id !== rootObject?.id) || [];

  const themeStyles = convertThemeVariablesToStyles(effectiveVariables);

  return (
    <div
      className="relative flex flex-grow flex-col items-center justify-center"
      style={{
        ...themeStyles,
        width,
        height,
        color: 'var(--base-content)',
        fontFamily: `var(--mainFont), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
        userSelect: 'none',
        pointerEvents: 'none',
      }}>
      {rootObject &&
        <LightRouter
          key={rootObject.id}
          self={rootObject}
          assets={assets}
          descendants={getDescendants(rootObject.id, objects)}
        />
      }
    </div>
  );
};

