import React from 'react'
import PropTypes from 'prop-types';
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import { spacingMap } from '../helpers'; /* replace to 'import { spacingMap } from './helpers.js';' */

export default function Link(props) {

    const {
        text = 'Link',
        URL = '#',
        openInNewWindow = false,
        onClick=()=>{},
        textSize = 'auto',
        textColor = 'auto',
        lineHeight = 'auto',
        fontWeight = 'auto',
        underline = 'onlyOnHover',
        marginBottom = null, 
        marginTop = null, 
        attributes,
        listeners
      } = props;
    const { junoAttributes, dndProps, showTag, setRefs, self, outlineStyle, eventListeners, onPropertyUpdate=()=>{} , preview} = props.junoProps || {} /* remove line */

    const textSizeStyles = textSize != 'auto' && `text-${textSize}`;
    const textColorStyles = `text-${textColor}`;
    const lineHeightStyles = lineHeight != 'auto' ? `leading-${lineHeight}` : '';
    const fontWeightStyles = fontWeight != 'auto' ? `font-${fontWeight}` : '';
    const marginBottomStyles = marginBottom ? `mb-${spacingMap[marginBottom]}` : '';
    const marginTopStyles = marginTop ? `mt-${spacingMap[marginTop]}` : '';
    
    const underlineStyles = underline == 'always' ? 'underline' : underline == 'onlyOnHover' ? 'hover:underline' : 'no-underline';

    let classes = `inline-flex ${textSizeStyles} ${textColorStyles} ${lineHeightStyles} ${fontWeightStyles} ${underlineStyles}  ${marginBottomStyles} ${marginTopStyles} cursor-pointer`
    classes += ` ${outlineStyle}` /* remove line */

    return (
        <span /* replace to '        <a' */
            className={classes}
            href={URL}
            target={openInNewWindow ? "_blank" : "_self"}
            rel={openInNewWindow ? "noopener noreferrer" : ""}
            {...junoAttributes} /* remove line */
            /* replace to '            onClick={(e)=> onClick(e)}' */
            {...attributes} {...listeners} 
        >
            {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Link</div> /* remove line */ }
            {
            !preview ? <EditableOnDoubleClick text={text} onBlur={onPropertyUpdate('text')} display='inline'/> :  /* remove line */
            text} 
        </span> /* replace to '        </a>' */
    );
}

Link.propTypes = {
    text: PropTypes.string,
    URL: PropTypes.string,
    openInNewWindow: PropTypes.bool,
    textSize: PropTypes.oneOf(['auto', 'xs', 'sm', 'base', 'lg', 'xl', '2xl']),
    textColor: PropTypes.oneOfType([
        PropTypes.oneOf(['auto', 'primary', 'primary-content', 'accent', 'accent-content', 'base-content', 'info-content', 'base-500', 'base-700', 'base-900']),
        PropTypes.string]),
    lineHeight: PropTypes.oneOf(['auto', 'tight', 'normal', 'relaxed', 'loose']),
    fontWeight: PropTypes.oneOf(['auto', 'hairline', 'thin', 'light', 'normal', 'medium', 'semibold', 'bold', 'extrabold', 'black']),
    underline: PropTypes.oneOf(['always', 'onlyOnHover', 'never']),
    marginTop: PropTypes.oneOf(['4px', '6px', '8px', '12px', '16px', '24px', '32px']),
    marginBottom: PropTypes.oneOf(['4px', '6px', '8px', '12px', '16px', '24px', '32px']),
    children: PropTypes.node
};

/* ignore rest */


Link.definitions = {
    apiName: 'Link',
    displayName: 'Link',
    description: 'A link component with editable text, customizable URL, font size, color, line height, font weight, and underline style. Supports opening in a new window and has hover effects.',
    ai_instructions: 'link component represents <a> element',
    type: 'typography',
    relativeSize: 'small',
    acceptedChildren: 'none',
    propDefinitions: {
        text: {
            type: 'string',
            displayName: 'Text',
            default: 'Link'
        },
        URL: {
            type: 'string',
            displayName: 'URL',
            default: '#'
        },
        openInNewWindow: {
            type: 'bool',
            displayName: 'Open in New Window',
            default: false
        },
        
        textColor: {
            type: 'colors',
            options: ['auto', 'primary', 'primary-content', 'accent', 'accent-content', 'base-content', 
            'info-content', 'base-500', 'base-700', 'base-900'],
            displayName: 'Color',
            default: 'auto'
        },
        textSize: {
            type: 'oneOf',
            options: ['auto', 'xs', 'sm', 'base', 'lg', 'xl', '2xl'],
            displayName: 'Font Size',
            default: 'auto', 
            tile: `1/2`
        },
        lineHeight: {
            type: 'oneOf',
            options: ['auto', 'tight', 'normal', 'relaxed', 'loose'],
            displayName: 'Line Height',
            default: 'auto', 
            tile: `1/2`
        },
        fontWeight: {
            type: 'oneOf',
            options: ['hairline', 'thin', 'light', 'normal', 'medium', 'semibold', 'bold', 'extrabold', 'black'],
            displayName: 'Font Weight',
            default: 'auto', 
            tile: `1/2`
        },
        marginTop: {
            type: 'spacing',
            options: ['4px', '6px', '8px', '12px', '16px', '24px', '32px'],
            displayName: 'Top Margin',
            default: null,
            tile: '1/2'
        },
        marginBottom: {
            type: 'spacing',
            options: ['4px', '6px', '8px', '12px', '16px', '24px', '32px'],
            displayName: 'Bottom Margin',
            default: null,
            tile: '1/2'
        },
        underline: {
            type: 'oneOf',
            options: ['always', 'onlyOnHover', 'never'],
            displayName: 'Underline',
            default: 'onlyOnHover', 
            tile: `1/2`
        }
    }
};

