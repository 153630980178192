import React from 'react';
import PropTypes from 'prop-types';
import { spacingMap } from '../helpers'; /* replace to 'import { spacingMap } from './helpers.js';' */

export default function Form(props) {
        
    const {
        direction = "flex-col",
        width = "full",
        height = "h-auto",
        
        paddingX = null,
        paddingY = null,
        gap = null,

        fontColor = "auto",
        bgImageSrc = null,
        background = null,
        bgOpacity = "100",

        hasOutline = false,
        flexWrap = "nowrap",
        corners = "none",
        
        alignItems = "start",
        justifyContent = "start",
        textSize = "auto",
        maxWidth = null,
        minWidth = null,
        maxHeight = null,
        minHeight = null,
        selfAlign = "auto",
        display = true,
        
        
        onSubmit=()=>{},
        children,
        attributes,
        listeners
      } = props;
    const { junoAttributes,  showTag,  outlineStyle } = props.junoProps || {} /* remove line */

    const bgStyles = background ? `bg-${background}` : '';
    const autoFontStyle = (!background || background == 'none') ? 'text-inherit' : background == 'base-900' ? `text-base-0` : background?.startsWith('base') ? 'text-base-content' : `text-${background}-content`
    const fontStyles = fontColor == 'auto' ? autoFontStyle : `text-${fontColor}`
    
    const gapStyles = gap ? `gap-${spacingMap[gap]}` : '';
    const paddingStyles = `${paddingX ? ` px-${spacingMap[paddingX]}` : ''}${paddingY ? ` py-${spacingMap[paddingY]}` : ''}`;
    
    const cornerStyles = corners === 'none' ? '' : `rounded-${corners}`;
    const widthStyles = `w-${width} max-w-full ${maxWidth ? `max-w-[${maxWidth}px]` : ''} ${minWidth ? `min-w-[${minWidth}px]` : ''}`;
    const heightStyles = `${height} ${maxHeight ? `max-h-[${maxHeight}px]` : ''} ${minHeight ? `min-h-[${minHeight}px]` : ''}`;
    const fontSize = textSize != 'auto' ? 'text-'+textSize : '';
    const borderStyles = hasOutline ? 'border border-base-300' : '';
    
    const wrapStyles = `flex-${flexWrap}`;
    const alignItemsStyles = alignItems ? `items-${alignItems}` : '';
    const justifyContentStyles = justifyContent ? `justify-${justifyContent}` : '';
    

    let classes = `flex ${direction} ${wrapStyles} ${paddingStyles} ${widthStyles} self-${selfAlign} ${fontStyles} ${fontSize} ${bgStyles} ${borderStyles} ${gapStyles} ${cornerStyles} ${alignItemsStyles} ${justifyContentStyles} ${heightStyles}`
    classes += ` ${outlineStyle}` /* remove line */
    
    const inLineStyles = {
        background: bgImageSrc && `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url(${bgImageSrc}) no-repeat center center / cover`, 
        backgroundColor: (background && background != 'none') ? bgOpacity ? `color-mix(in srgb, var(--${background}) ${bgOpacity}%, transparent)` : `var(--${background})` : '',
        maxWidth: maxWidth, 
        minWidth: minWidth,
        maxHeight: maxHeight,
        minHeight: minHeight
    };

    const filteredInLineStyles = Object.fromEntries(
        Object.entries(inLineStyles).filter(([_, value]) => value != null)
    );


    if (display) return (
        <form
        /* replace to '' */
        onSubmit={(e) => {e.preventDefault(); onSubmit && onSubmit(e)}}
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
        className={classes}
        style={Object.keys(filteredInLineStyles).length > 0 ? filteredInLineStyles : undefined}>
        {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Form</div> /* remove line */}
        {children}
        </form>
    );
}


Form.propTypes = {
    background: PropTypes.oneOfType([
        PropTypes.oneOf(["base-0", 'base-50', "base-100", "base-200", "primary", "accent", "base-900" ]),
        PropTypes.string]),
    bgOpacity: PropTypes.oneOf(["10", "20", "30", "40", "50", "60", "70", "80", "90", "100"]),
    direction: PropTypes.oneOf(["flex-col", "flex-row"]),
    width: PropTypes.oneOf(["full", "auto", "1/2", "1/3", "1/4", "2/3", '3/4']),
    height: PropTypes.oneOf(['h-full', 'h-1/2', 'h-1/3', 'h-1/4', 'h-2/3', 'h-[integer]px', 'h-auto']),
    
    paddingX: PropTypes.oneOf(["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"]),
    paddingY: PropTypes.oneOf(["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"]),
    gap: PropTypes.oneOf(["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"]),
    
    
    hasOutline: PropTypes.bool,
    flexWrap: PropTypes.oneOf(["nowrap", "wrap", "wrap-reverse"]),
    corners: PropTypes.oneOf(["none", "sm", "md", "lg", 'xl', '2xl', "full"]),
    
    alignItems: PropTypes.oneOf(["start", "center", "end", "stretch"]),
    justifyContent: PropTypes.oneOf(["start", "center", "end", "between", "around", "evenly"]),
    textSize: PropTypes.oneOf(["auto", 'xs', "sm", "base", "md"]),
    maxWidth: PropTypes.number,
    minWidth: PropTypes.number,
    maxHeight: PropTypes.number,
    minHeight: PropTypes.number,
    selfAlign: PropTypes.oneOf(['auto', 'start', 'center', 'end']),
    display: PropTypes.bool,
    onSubmit: PropTypes.func,
    bgImageSrc: PropTypes.string,
    fontColor: PropTypes.oneOf(['base-0', 'base-100', 'base-200', 'primary', 'accent', 'base-900', 'base-content', 'auto', 'success-content', 'error-content', 'warning-content', 'info-content']),
    children: PropTypes.node
};



/* ignore rest */

Form.definitions = {
    apiName: "Form",
    displayName: "Form Component",
    description: "A flexible form component for building various types of forms, supporting a wide range of layouts and styles.",
    ai_instructions: 'flex form container. has no children by default',
    type: 'input',
    relativeSize: 'small',
    acceptedChildren: 'everything except table components, layouts, sections, dialogs',
    propDefinitions: {
        direction: {
            type: "direction",
            options: ["flex-col", "flex-row"],
            displayName: "Direction",
            default: "flex-col", 
            tile: '1/2',
            required: true
        },
        flexWrap: {
            type: "oneOf",
            options: ["nowrap", "wrap", "wrap-reverse"],
            displayName: "Flex Wrap",
            default: "nowrap", 
            tile: '1/2',
            required: true
        },   
        alignItems: {
            type: "alignItems",
            options: ["start", "center", "end", "stretch"],
            default: "start", 
            required: true,
            tile: '1/2',
        },
        justifyContent: {
            type: "justifyContent",
            options: ["start", "center", "end", "between"],
            default: "start",
            required: true, 
            tile: '1/2',
        },
        background: {
            type: "colors",
            options: ["base-0", 'base-50', "base-100", "base-200", "primary", "accent", "base-900", "none"],
            displayName: "Background",
            default: "none", 
            tile: '1/2',
        },
        bgOpacity: {
            type: "oneOf",
            displayName: "BG Opacity",
            options: ["10", "20", "30", "40", "50", "60", "70", "80", "90", "100"],
            default: '100',
            tile: '1/2',
        },
        fontColor: {
            type: 'colors',
            options: ['base-0', 'base-100', 'base-200', 'primary', 'accent', 'base-900', 'base-content', 'auto', 
            'success-content', 'error-content', 'warning-content', 'info-content'],
            displayName: 'Font Color',
            default: 'auto', 
            tile: '1/2',
        },
        textSize: {
            type: "oneOf",
            options: ["auto", 'xs', "sm", "base", "md"],
            default: "auto", 
            tile: '1/2',
        },
        paddingX: {
            type: "spacing",
            options: ["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Padding X",
            ai_instructions: 'adds padding on left and right. in addition to overall padding property',
            default: 'base', 
            tile: '1/2',
        },
        paddingY: {
            type: "spacing",
            options: ["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Padding Y",
            ai_instructions: 'adds padding on top and bottom. in addition to overall padding property',
            default: null,
            tile: '1/2',
        },
             
        corners: {
            type: "corners",
            options: ["none", "sm", "md", "lg", 'xl', '2xl', "full"],
            displayName: "Corners",
            default: null, 
            tile: '1/2',
        },
        gap: {
            type: "gap",
            options: ["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Gap",
            default: null,
            tile: '1/2',
        },
        width: {
            type: "oneOf",
            options: ["full", "auto", "1/2", "1/3", "1/4", "2/3", '3/4'],
            displayName: "Width",
            default: "full", 
            tile: '1/2',
        },
        height: {
            type: 'oneOf',
            options: ['h-full', 'h-1/2', 'h-1/3', 'h-1/4', 'h-2/3', 'h-[integer]px', 'h-auto'],
            default: 'h-auto',
            displayName: "Height",
            tile: '1/2', 
            ai_instructions: 'for custom height use "h-[integer]px" syntax but replace integer with pixel height, e.g. "h-[200px]", i.e. follow tailwindcss utilities approach'
        },
        minWidth: {
            type: "number",
            default: null, 
            displayName: "Min Width",
            tile: '1/2',
        },
        minHeight: {
            type: "number",
            default: null,
            displayName: "Min Height",
            tile: '1/2',
        },

        maxWidth: {
            type: "number",
            default: null, 
            displayName: "Max Width",
            tile: '1/2',
        },

        maxHeight: {
            type: "number",
            default: null, 
            displayName: "Max Height",
            tile: '1/2',
        }
        ,selfAlign: {
            type: 'oneOf',
            options: ['auto', 'start', 'center', 'end'],
            default: 'auto'
        }, 
        hasOutline: {
            type: "bool",
            displayName: "Outline",
            default: false, 
            ai_instructions: 'adds base-300 1px outline',
            tile: '1/2',
        },
        display: {
            type: "bool",
            displayName: "Display",
            default: true
        },
        bgImageSrc: {
            type: 'imageURL',
            displayName: 'Image',
            ai_instructions: 'adds background image as cover, use rarely, make sure font color of the objects inside is readable',
            default: null
        }

    }
};
