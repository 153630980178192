import React, { useContext, useRef, useEffect, useState } from "react";
import { EditorContext } from "../../EditorContext";
import UserFlow from "../flow/UserFlow";
import FlowHeader from "./FlowHeader";
import SearchInput from "../search/OverviewSearch";
import UserMenu from "../../header/UserMenu";
import ShareButton from "../../header/ShareButton";

export default function FlowDisplay({draggedFrame, refreshKey}) {
    const { project, view, setView, selector, setSelector,handleAction } = useContext(EditorContext);
  
    const columnWidth = view.columnWidth
    const folder = selector.folder
    const page = selector.page

    if (folder && page) return (
      <div className={`relative flex flex-col h-full  w-full relative transition-all max-h-full overflow-hidden pl-1
        border-l-[0.5px] border-slate-300 bg-white box-border h-full`} >


        <Controls /> 
        <FlowHeader />
        <div className="overflow-x-auto">
                <UserFlow
                      key={page.id}
                      frames={page.frames}
                      page={page}
                      folder={folder}
                      isMobile={false}
                      columnWidth={columnWidth}
                      draggedFrame={draggedFrame}
                      refreshKey={refreshKey}
                    />
          
        </div>
        
      </div>
    );
    }  

    
    function Controls() {
      const { selector, handleAction, set } = useContext(EditorContext);
      
      return(
        <div className="w-full flex flex-row gap-2 text-lg items-center justify-between px-3 flex-shrink-0"
        style={{ height: 48, maxHeight: 48 }}
        >
          
    
          {/* Search */}
          <SearchInput />
          
          <div className="w-auto justify-end flex flex-row gap-3 items-center flex-shrink-0">
          {/*<ZoomOverview />*/}
          
          <ShareButton />
          <UserMenu />
          
          </div>
        </div>
      )
    }