import React from 'react'
import PropTypes from 'prop-types'
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */

export default function Status(props) {

    const {
        color = 'info',
        size = 'medium',
        text = 'Status',
        isPill = false,
        style = 'subtle',
        attributes,
        listeners
      } = props;
    const { junoAttributes,  dndProps, showTag, setRefs, self, outlineStyle, eventListeners, onPropertyUpdate=() => {}, preview } = props.junoProps || {} /* remove line */
    
    const sizeStyleMap = {
        small: `py-1 px-2 gap-1.5 text-xs max-w-[120px]`,
        medium: `py-1.5 px-2 gap-2 text-sm max-w-[160px]`
    };
    const cornerStyles = isPill ? 'rounded-full' : size === 'small' ? 'rounded' : 'rounded-md';
    const sizeStyles = sizeStyleMap[size]

    const fontColor = `text-base-content`
    const borderStyles = style == 'bright' ? `border border-${color}-focus` : `border border-base-300`
    
    let wrapperClasses = `${borderStyles} ${fontColor} ${sizeStyles} flex flex-row items-center font-medium justify-start leading-tight flex-shrink-0 flex-grow-0 whitespace-nowrap ${cornerStyles}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */
    
    const truncateStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'};
    const circleSize = size == 'small' ? 7 : 9;

    return (
        <div 
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
            className={wrapperClasses}>   
        {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Status</div> /* remove line */}
        
        {/* Status Indicator */}
        <div className={`flex-shrink-0 rounded-full ${color == 'base-content' ? 'bg-base-500' : `bg-${color}-content`} `}
            style={{
            width: circleSize, 
            height: circleSize, 
            boxShadow: style == 'bright' && `0 0 0 ${size === 'small' ? '2px' : '3px'} var(--${color == 'base-content' ? 'base-300' : `${color}`})`
        }} />

        {/* Text */}
        <span style={truncateStyle}>
        <EditableOnDoubleClick text={text} whiteSpace='nowrap' onBlur={onPropertyUpdate('text')} preview={preview} /* replace to '{text}' */ />
        </span>
        </div>
    ); 
}

Status.propTypes = {
    text: PropTypes.string,
    color: PropTypes.oneOf(['info', 'success', 'base', 'warning', 'error']),
    size: PropTypes.oneOf(['small', 'medium']),
    style: PropTypes.oneOf(['bright', 'subtle']),
    isPill: PropTypes.bool,
};

/* ignore rest */


Status.definitions = {
    apiName: 'Status',
    displayName: 'Status',
    description: '',
    ai_instructions: '',
    type: 'indicators',
    relativeSize: 'small',
    acceptedChildren: 'none',
    propDefinitions: {
        text: {
            type: 'string',
            displayName: 'Text',
            default: 'Status'
        },
        color: {
            type: 'colors',
            options: ['info', 'success', 'base-content', 'warning', 'error'],
            displayName: 'Color',
            default: 'info'
        },
        size: {
            type: 'size',
            options: ['small', 'medium'],
            default: 'medium', 
            displayName: 'Size',
            tile: '1/2'
        },
        style: {
            type: 'oneOf',
            options: ['bright', 'subtle'],
            displayName: 'Style',
            tile: '1/2',
            default: 'subtle'
        },
        isPill: {
            type: 'bool',
            displayName: 'Make rounded',
            default: false
        },
    }
};
