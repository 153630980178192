import React, { useContext } from 'react';
import {v1 as uuidv1 } from 'uuid'
import UserContext from '../../../../UserContext';
import { useAuthState } from "react-firebase-hooks/auth";
import { uploadVideo } from "../../../../utilities/files";
import { auth } from '../../../../utilities/firebaseConfig';
import Icon from '../../../../components/icons/Icon';
import { EditorContext } from '../../../../pages/editor/EditorContext';

export default function VideoUpload({ onUpload, currentURL }) {
    const { user } = useContext(UserContext);
    const [firebaseUser] = useAuthState(auth);

    const { project } = useContext(EditorContext)
    const projectId = project.id

    const handleVideoUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const uploadedImageUrl = await uploadVideo(file, file.name);

                const assetData = {
                    id: uuidv1(),
                    file_url: uploadedImageUrl,
                    file_type: "image",
                    owner_id: user.id,
                    project_id: projectId, // replace with the correct project id
                    design_system_id: project.ds, // replace with the correct design system id
                    asset_source: "user_upload",
                };

                await addAsset(assetData);

                onUpload(uploadedImageUrl);

            } catch (err) {
                console.error("Failed to upload image:", err);
            }
        }
    };

    const addAsset = async (assetData) => {
        const userToken = await firebaseUser?.getIdToken();

        if (userToken) {
            try {
                const response = await fetch("/assets/add", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                    body: JSON.stringify(assetData),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                console.error("Failed to add asset:", error);
            }
        } else {
            console.error("Failed to get user token");
        }
    };

    return (
        <div className="relative cursor-pointer select-none flex flex-col max-w-full items-center text-sm justify-start">
        <div className='px-2 py-1 bg-slate-100 text-slate-400 cursor-not-allowed mb-2 w-full truncate'>{currentURL}</div>
        <div 
        className='flex flex-row gap-1 mb-2 bg-slate-100 w-full px-2 py-1 font-medium '
            onClick={() => {
                document.getElementById("uploadInput").click();
            }}
        >
            
            <Icon width={20} height={20} name={"il-img"} fill="#333" />
            Upload
            <input
                type="file"
                id="uploadInput"
                style={{ display: 'none' }}
                onChange={handleVideoUpload}
            />
        </div>
        <div 
        className='flex flex-row gap-1 mb-2 bg-slate-100 w-full px-2 py-1 font-medium '
            onClick={() => onUpload('')}
        >Clear Image</div>
        
        </div>
    );
}