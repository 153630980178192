import { useContext, useRef, useState } from "react";
import { EditorContext } from "../../EditorContext";
import UIKit from "./UIKit";
import Search from "./search/Search";
import SwitchInput from "../Switch";
import { Flash, LayoutLeft, Play, Plus } from "iconoir-react";
import TypicalLayouts from "../library/layouts/TypicalLayouts";
import { makePrimitives, makeTemplates } from "./helpers";
import Results from "./search/Results";
import ElementsTemplates from "./templates/ElementsTemplates";
import ComponentTemplates from "./templates/ComponentTemplates";
import { TemplatesContext } from "../../TemplatesContext";

export default function Library() {
    const { view, setView, selector  } = useContext(EditorContext)
    const { templates } = useContext(TemplatesContext)
    
    const [selectedOption, setSelectedOption] = useState('uikit');
    const switchOptions = 
      [
        { label: '', value: 'uikit', icon: <Flash width={16} height={16} /> },
        { label: 'Elements', value: 'gallery',  },
        { label: 'Templates', value: 'templates' },
      ] 
      
      function handleSelect(value) {
        setSelectedOption(value)
        setView({ ...view, uikit: value })
      }
      const [showSearch, setShowSearch] = useState(false)
      const [searchResults, setSearchResults] = useState([])
    
    const templateCategories = makeTemplates(templates);
    const primitiveCategories = makePrimitives(templates)
    const searchItems = [...templateCategories, ...primitiveCategories].flatMap(cat => cat.items)

    return (
        <div className="w-full h-full flex flex-col gap-3 py-3 px-2.5">
          
          {<SwitchInput        
              currentValue={selectedOption}
              options={switchOptions}
          onChange={handleSelect}/> }
        {<Search
                searchResults={searchResults} 
                setSearchResults={setSearchResults} 
                showSearch={showSearch} 
                setShowSearch={setShowSearch} 
                items={searchItems}
          />}
          {
            showSearch ? (
              <Results items={searchResults} />
            ) : selectedOption === 'uikit' ? (
              <UIKit />
            ) : selectedOption === 'gallery' ? (
              <ElementsTemplates categories={primitiveCategories}/>
            ) : selectedOption === 'templates' ? (
              <ComponentTemplates categories={templateCategories}  />
            ) : null
          }
            
            </div>
            )

}


function NoSections() {
  const { setView, view } = useContext(EditorContext);
  return (
  <div className='bg-base-100 rounded-lg flex flex-col gap-1 p-2 py-3 items-center justify-center w-full text-black font-medium text-center balance text-sm'>
    <LayoutLeft width={24} height={24} style={{strokeWidth: 1.5}}/>
    <h2>Choose layout</h2>
      <p className='px-2 rounded-md text-xs font-medium mb-3'>
      or search <a className='underline cursor-pointer' 
      onClick={() => setView({ ...view, rightSide: 'assets', assets: 'pages' })}>page templates</a>
      </p>
      <TypicalLayouts />  
  </div>
  )
  
}



