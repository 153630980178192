import React, { useContext, useState, useEffect, useRef } from 'react';
import { EditorContext } from '../../EditorContext';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Flower, Folder, Label, LabelSolid, MoreHoriz, MultiplePages, NavArrowDown, Plus, SeaWaves } from 'iconoir-react';
import { deletePage, duplicatePage } from '../pageActions';
import { createNewFrame } from '../../../../utilities/helpers';

export default function FlowTab({ folder, page, isOnlyFlow }) {
  
  const { selector, handleAction, view, setView, setSelector } = useContext(EditorContext);
  // console.log('page', page.name)

  const isSelected = selector && selector.page && selector.page.id === page.id;
  const frames = page.frames || []

  const updatePageName = (newName) => {
    const currentPage = page;
    const newPage = { ...currentPage, name: newName };
    const action = {
      type: 'UPDATE_PAGE',
      currentPage,
      newPage
    };

    handleAction(action);
  };

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: page.id });
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  
  function handleClick(e) {
    e.stopPropagation();
    setSelector({ ...selector, folder, page, frame: frames[0], object: null})
    setView({...view, showAllFolders: false, inOverview: 'folders' })
  }
  
  function addFrame() {
    const page = selector.page
    const frames = selector.page.frames
      const insertIndex = frames?.length > 0 
      ? Math.max(...frames.map(frame => frame.index)) + 1 // add as last to the page
      : 1;
  
      let newFrame = createNewFrame({ ...page, type: 'page' }, [], 'Untitled frame', { width: 800, height: 800 });
      newFrame = { ...newFrame, index: insertIndex };;
      
      handleAction({type: 'INSERT_FRAME', frame: newFrame });
  };
  const [isEditingName, setIsEditingName] = useState(false);
  return (
    <div ref={setNodeRef} id={folder.id} style={style}  {...attributes} {...listeners}
    className='flex flex-row items-center w-full justify-between relative group hover:bg-slate-100 rounded'>
    
      
      <div className={`flex flex-row items-center px-2 pl-7 py-0.5 gap-0 w-full whitespace-nowrap overflow-hidden ellipsis truncate related group rounded
        ${isSelected && !view.showAllFolders ? 'font-medium bg-slate-100' : 'font-normal'}`}
        onClick={(e) => handleClick(e)}
        style={{cursor: 'default'}}
        >
          
          <PageName currentName={page.name} onBlur={updatePageName} 
          isEditingName={isEditingName} setIsEditingName={setIsEditingName} />
          {!isEditingName && 
          <Options  
            isOnlyFlow={isOnlyFlow} 
            onDelete={isOnlyFlow ? () => {} : ()=>deletePage({page, handleAction})}
            onDuplicate={()=>duplicatePage({page, handleAction})}
            onAddFrame={()=>addFrame()}
            onRename={()=>setIsEditingName(true)}
            flow={page}
          />}
      </div>
      
    </div>
  );
}


function PageName({currentName, onBlur, isEditingName, setIsEditingName}) {
    

  
  const [name, setName] = useState(currentName);

  useEffect(() => {
    setName(currentName);
  }, [currentName]);

  const handleBlur = () => {
    if (name.trim().length === 0) {
      setName(currentName); // Resetting to the original name if empty
    } else {
      setIsEditingName(false);
      onBlur(name);
    }
  };
  
  return isEditingName ? (
    <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onBlur={handleBlur}
        autoFocus 
        onKeyDown={(e) => e.key === 'Enter' && handleBlur()}
        className={`bg-transparent focus:bg-white placeholder-gray-700 focus:text-gray-900
        select-none cursor-default max-w-full px-1
        py-0.5 overflow-hidden whitespace-nowrap ellipsis truncate transition-all`}
        style={{
          userSelect: 'auto',
          textAlign: 'left'
        }}
      />
  
    ) : (
    <div
      onDoubleClick={(e) => {e.stopPropagation(); setIsEditingName(true);}}
      className={`bg-transparent focus:bg-white placeholder-gray-700 focus:text-gray-900
        select-none cursor-default max-w-full px-1
        py-0.5 overflow-hidden whitespace-nowrap ellipsis truncate transition-all`}
      style={{ userSelect: 'none' }}
    >
      
      {name}
    </div>
  );
  }
  
  


  function Options({
    isOnlyFlow, 
    onDelete,
    onDuplicate,
    onRename,
    onAddFrame,
    flow
  }) {
  
    const tooltipStyle = `absolute top-1/2 -right-2 bg-gray-800 text-white transition-all duration-75 whitespace-nowrap text-2xs font-nomrmal
    translate-x-3/4 -translate-y-1/2 px-2 py-0.5 rounded opacity-0 group-hover:opacity-100 group-hover:translate-x-full`
  
    const [ showSubmenu, setShowSubmenu ] = useState(false);
  
    const myRef = useRef();
    // close on outside click
    useEffect(() => {
      function handleClickOutside(event) {
        if (myRef.current && !myRef.current.contains(event.target)) {
          setShowSubmenu(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [myRef]);
  
    const buttonStyle = 'w-full py-1 px-2 hover:bg-slate-100 rounded active:bg-slate-200 transition-all duration-75 whitespace-nowrap text-left'
  
    const buttons = [
      {text: 'Duplicate', action: onDuplicate},
      {text: 'Rename', action: onRename},
      {text: 'Delete', action: onDelete}
    ]
  
  
  return (
    <div className={`absolute right-1 top-1/2 -translate-y-1/2  flex flex-row gap-2 items-center transition-all duration-150
    ${showSubmenu ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'} `}
  ref={myRef}
  >

    <button className={`p-0.5 rounded hover:scale-110 transition-all duration-75
  ${showSubmenu ? 'bg-slate-200' : 'hover:bg-slate-200 bg-transparent'}`}
  title='See more options'
    onClick={(e)=> {e.stopPropagation(); setShowSubmenu(!showSubmenu)}}  >
      <MoreHoriz height={16} width={16} />
    </button>
  
  <button className={`p-0.5 rounded hover:scale-110 transition-all duration-75 hover:bg-slate-200 bg-transparent`}
  onClick={onAddFrame}
  title='Add frame inside'
  >
    <Plus onClick={()=>{}} height={16} width={16}  className='hover:scale-110' />
  </button>
    {showSubmenu &&
      <div className='absolute top-1/2 -translate-y-1/2 -right-2 translate-x-full
      p-1 rounded ring-[0.5px] ring-slate-300 min-w-24 flex flex-col
      bg-white transition-all duration-75 text-2xs font-normal' 
      
      >
        {buttons.map((button, index) =>
          <button key={index} className={`${buttonStyle} 
          ${button.text === 'Delete' && isOnlyFlow && 'opacity-50 cursor-not-allowed'}`} 
          onClick={(e) => {
            e.stopPropagation(); 
            button.action(); 
            setShowSubmenu(false)}} 
          >
            {button.text}
          </button>
        )}
        
      </div>
    }
  </div>
  )
  }
  