import React, {  useMemo, useRef } from "react";
import { convertJSXToObjectsSync } from "../../../../../utilities/AIhelpers";
import ComponentPreview from "../../../../../components/ComponentPreview";

export default function SearchResult({ component, effectiveVariables, assets, handleDragStart, handleDragEnd}) {
  const myRef = useRef(null);
  
  const { jsx, width=1440, height=820, thumbnail } = component;
  
  const padding = 0
  const tileSize = 144

  const { objects, rootObject, action, descendants } = useMemo(() => {
    const objs = convertJSXToObjectsSync(jsx) || [];
    const rootObj = objs.find((obj) => obj.parent === "rootObject");
    const descendants = objs.filter((obj) => obj.id !== rootObj.id) || [];

    const action = {
      type: "INSERT_OBJECT",
      object: rootObj,
      descendants,
    };

    return { objects: objs, rootObject: rootObj, action, descendants };
  }, [jsx]);
  
  const scaleFactor = Math.min(1, tileSize / Math.max(width, height))
  
  const self = {
    source: 'library', 
    componentAPIName: rootObject?.componentAPIName,
    jsx, 
    allowedSections: component.allowedSections || []
  }

  const tranformWidth = scaleFactor * width - padding
  const tranformHeight = scaleFactor * height - padding
  
  const canDrag = true // !view.disableDnD && view.mode == 'editor'

  return (
    <div
      className="flex flex-col flex-shrink-0 bg-base-100 relative font-normal select-none rounded-lg items-center justify-center group"
      ref={myRef}
      style={{
        overflow: 'hidden', 
        width: tileSize, 
        height: tileSize,
        padding,   
      }}
    >
      <div 
      className={`overflow-hidden group-hover:scale-105 hover:scale-110 transition-all duration-75 text-black`}
      style={{ width: tranformWidth, height: tranformHeight}}
      >
      <div
        className="flex flex-col items-center justify-center cursor-grab"
        style={{
          width, height,
          transform: `scale(${scaleFactor})`,
          transformOrigin: 'top left',
        }}
        draggable={canDrag}
        onDragStart={(event) => {canDrag && event.stopPropagation(); handleDragStart(event, self, myRef)}}
        onDragEnd={(event) => {canDrag && event.stopPropagation(); handleDragEnd(event, self, myRef)}}
      >
        
        {<ComponentPreview
          jsx={jsx} width={width} height={height}
          assets={assets}
          thumbnail={component.thumbnail}
          effectiveVariables={effectiveVariables} 
      />}
        
      </div>
      </div>
    </div>
  );
}