import React from 'react';
import PropTypes from 'prop-types';
import { spacingMap } from '../helpers'; /* replace to 'import { spacingMap } from './helpers.js';' */


export default function FeaturePanel(props) {
    
    const {
        paddingX = null,
        paddingY = null,
        gap = null,

        background = null, 
        
        alignItems = 'stretch',
        justifyContent = 'start',
        display = true,
        textSize = 'base',
        backgroundImageSrc = null,
        children,
        attributes,
        listeners
      } = props;
    const { junoAttributes, showTag, outlineStyle } = props.junoProps || {} /* remove line */

    const bgStyles = background ?`bg-${background}` : '';
    const fontColor = (!background || background == 'none') ? '' : background == 'base-900' ? `text-base-0` : background?.startsWith('base') ? 'text-base-content' : `text-${background}-content`
    const paddingStyles = `${paddingX ? ` px-${spacingMap[paddingX]}` : ''}${paddingY ? ` py-${spacingMap[paddingY]}` : ''}`;
    const gapStyles = gap ? `gap-${spacingMap[gap]}` : '';

    const fontSize = `text-`+textSize
    const alignItemsStyles = `items-${alignItems}`
    const justifyContentStyles = justifyContent ? `justify-${justifyContent}` : '';

    let classes = `flex flex-col flex-grow w-full ${bgStyles} ${justifyContentStyles} ${paddingStyles} ${gapStyles} ${fontColor} ${fontSize} ${alignItemsStyles}`
    classes += ` ${outlineStyle}` /* remove line */

    const noImage = !backgroundImageSrc;
    const imageStyles = { 
        background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url(${backgroundImageSrc}) no-repeat center center / cover`
    };
    
    if (display) return (
        <div className={classes} style={noImage ? {} :  imageStyles} 
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
        >
        {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Feature Panel</div> /* remove line */ }
        {children}
        </div>
    );
}

FeaturePanel.propTypes = {
    background: PropTypes.oneOfType([
        PropTypes.oneOf(['base-0', 'base-50', 'base-100', 'base-200', 'primary', 'accent', 'base-content']),
        PropTypes.string]),
    backgroundImageSrc: PropTypes.string,
    
    paddingX: PropTypes.oneOf(["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px']),
    paddingY: PropTypes.oneOf(["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px']),
    gap: PropTypes.oneOf(["4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"]),

    alignItems: PropTypes.oneOf(['start', 'center', 'end', 'stretch']),
    justifyContent: PropTypes.oneOf(['start', 'center', 'end', 'between', 'around', 'evenly']),
    display: PropTypes.bool,
    textSize: PropTypes.oneOf(['sm', 'base', 'md', 'lg', 'xl']),
    children: PropTypes.node
    
};

/* ignore rest */

FeaturePanel.definitions = {
    apiName: 'FeaturePanel',
    displayName: 'Feature Panel',
    description: 'A versatile panel designed for promotional content in onboarding and authentication flows. It supports customizable padding, background, gap, alignment, display options, and can include elements like quotes, images, and more.',
    ai_instructions: 'feature panel for promotional material in onboarding and authentication flows. do not delete unless user specifically asks',
    type: 'section',
    relativeSize: 'large',
    acceptedChildren: 'everything except table components, layouts, sections, dialogs',
    propDefinitions: {
        background: {
            type: 'colors',
            options: ['base-0', 'base-50', 'base-100', 'base-200', 'primary', 'accent', 'base-content'],
            displayName: 'Background',
            default: 'base-0'
        },
        paddingX: {
            type: "spacing",
            tile: '1/2',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px'],
            displayName: "Padding X",
            ai_instructions: 'adds padding on left and right. in addition to overall padding property',
            default: null
        },
        paddingY: {
            type: "spacing",
            tile: '1/2',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px'],
            displayName: "Padding Y",
            ai_instructions: 'adds padding on top and bottom. in addition to overall padding property',
            default: null
        },
        gap: {
            type: 'gap',
            options: ["4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Gap",
            default: null,
            tile: '1/2',
        },
        textSize: {
            type: 'oneOf',
            options: ['sm', 'base', 'md', 'lg', 'xl'],
            default: 'base', 
            tile: '1/2'
        }, 
        alignItems: {
            type: 'alignItems',
            options: ['start', 'center', 'end', 'stretch'],
            displayName: 'Align',
            default: 'stretch', 
            tile: '1/2'
        },
        justifyContent: {
            type: 'justifyContent',
            options: ['start', 'center', 'end', 'between'],
            displayName: 'Justify',
            default: 'start', 
            tile: '1/2'
        },
        
        
        backgroundImageSrc: {
            type: 'imageURL',
            displayName: 'Image',
            default: null
        },
        display: {
            type: 'bool',
            default: true,
            defaultOnMobile: false,
        },
        
    }
};
