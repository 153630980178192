import React, { useState, useEffect } from 'react';

const initialImages = [
  "https://firebasestorage.googleapis.com/v0/b/juno-v1.appspot.com/o/juno2-2.webp?alt=media&token=b50c799e-f9c0-4a8e-a08b-a9e311524f2a",
  "https://firebasestorage.googleapis.com/v0/b/juno-v1.appspot.com/o/juno1-2.webp?alt=media&token=796929c5-82c5-4068-9c6f-949e6e979d7a",
  "https://firebasestorage.googleapis.com/v0/b/juno-v1.appspot.com/o/cooklang-2.webp?alt=media&token=fb158370-02f7-42a1-a210-df59632f64e7",
  "https://firebasestorage.googleapis.com/v0/b/juno-v1.appspot.com/o/zoo-2.webp?alt=media&token=71884413-7534-4841-a095-2f7993f1830e",
  "https://firebasestorage.googleapis.com/v0/b/juno-v1.appspot.com/o/lifelines-2.webp?alt=media&token=852238fb-2962-4766-8dbf-7a8866795c4d",
  "https://firebasestorage.googleapis.com/v0/b/juno-v1.appspot.com/o/neofleet-2.webp?alt=media&token=81fc4fe4-7263-4575-83a9-242eec955d4f"
  ]
  
export default function TestPage() {
  
  const [images, setImages] = useState(initialImages);
  const totalImages = images.length;
  const maxIndex = totalImages - 1;
  const [intervalId, setIntervalId] = useState(null); // Store the interval ID

  useEffect(() => {
    // Start the interval to rotate images
    const id = setInterval(() => {
      setImages((prevImages) => {
        const lastElement = prevImages[prevImages.length - 1];
        const rest = prevImages.slice(0, prevImages.length - 1);
        return [lastElement, ...rest];
      });
    }, 5000);

    setIntervalId(id);

    const handleScroll = () => {
      const examplesElement = document.getElementById("examples");
      if (examplesElement) {
        const rect = examplesElement.getBoundingClientRect();
        const isNearView = rect.top - 500 <= window.innerHeight && rect.bottom >= 500;

        if (isNearView) {
          clearInterval(id); // Stop the interval when 200px before "examples" is in view
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      clearInterval(id); // Cleanup on unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  const allStyles = {
    position: 'absolute',
    left: '50%',
    width: '100%',
    height: '100%',
    backgroundSize: '100% auto',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    transition: 'all 750ms ease-out',
  };

  function getStyles(position) {
  
    const topOffset = -position * (6 - position * 0.5)
    const scale = 1 - position * (0.03)
    const brightness = 1 - position * 0.05;

    const styles = {
        transform: `translate3d(-50%, ${topOffset}px, 0) scale(${scale})`,
        transformOrigin: 'center top',
        animation: position === 0 ? 'bringToFront 750ms forwards' : 'none',
        zIndex: maxIndex - position,
        opacity: position < 4 ? 1 : 0,
        filter: `brightness(${brightness})`,
        ...allStyles
      }
    
    return styles
  }

  
  return (
    <div className="relative w-screen h-screen p-3 flex flex-col items-center justify-center">
      <div className="w-[1000px] h-[500px] relative" style={{ zIndex: 0 }}>
        {images.map((image, i) => (
          <div
            key={image}
            className={`absolute rounded-md border-[0.5px] border-slate-300 shadow-lg`}
            style={{
              backgroundImage: `url(${image})`,
              ...getStyles(i),
            }}
          >
           
          </div>
        ))}
        
      </div>
    </div>
  );
}