import React from 'react'
import PropTypes from 'prop-types';
import * as IconoirIcons from 'iconoir-react';
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */

export default function StarRatings(props) {
    
    const {
        label = '4.5',
        ratingValue = '90%', // This should be considered whether it should be a percentage string or a numeric value depending on your implementation.
        labelPosition = 'right',
        color = 'orange-500',
        size = 'sm', // consider making this px value
        attributes,
        listeners
      } = props;
    const { junoAttributes, self, dndProps, showTag, preview, onPropertyUpdate=()=>{}, setRefs, outlineStyle, eventListeners } = props.junoProps || {} /* remove line */
    
    let classes = `flex flex-row gap-2 items-center text-${size}`
    classes += ` ${outlineStyle}` /* remove line */
    
    let starClasses = `flex flex-row gap-1.5 flex-shrink-0 text-${color}`

    /* text-orange-500 */

    return (
        <div 
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
            className={classes}
        >
            {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Star Rating</div> /* remove line */}
            <div className='relative flex flex-row gap-1.5 opacity-100' style={{ width: 'max-content' }}>
                    <div className='flex flex-row gap-1.5 opacity-10'>
                        {Array.from({ length: 5 }, (_, index) => <IconoirIcons.StarSolid key={index} className='flex-shrink-0' />)}
                    </div>
                    <div className={starClasses} style={{ position: 'absolute', width: ratingValue, overflow: 'hidden'}}>
                        {Array.from({ length: 5 }, (_, index) => <IconoirIcons.StarSolid key={index} className='flex-shrink-0' />)}
                    </div>
            </div>
            {label && <div className={`flex-shrink-0 leading-none font-normal`} style={{order: labelPosition === 'left' ? '-1' : 1}}>
            { !preview ? <EditableOnDoubleClick text={label} whiteSpace='nowrap' onBlur={onPropertyUpdate('label')} /> : label /* replace to '            {label}' */}
            </div>}
        </div>
    ); 
}


StarRatings.propTypes = {
    ratingValue: PropTypes.oneOf(['10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%']),
    label: PropTypes.string,
    labelPosition: PropTypes.oneOf(['left', 'right']),
    color: PropTypes.oneOfType([
        PropTypes.oneOf(['info-content', 'primary', 'accent', 'success-content', 'base-content', 'warning-content', 'error-content', 'orange-500']),
        PropTypes.string]),
    size: PropTypes.oneOf(['xs', 'sm', 'base', 'lg', ]),
};
 
/* ignore rest */

  StarRatings.definitions = {
    apiName: 'StarRatings',
    displayName: 'StarRatings',
    description: 'An icon-only button component, utilizing icons from the Ionic Icon library. Supports various sizes and styles, with an optional loading state where the icon is replaced with an animated spinner.',
    ai_instructions: 'icon-only button. icons are from the iconoir library.',
    type: 'indicators',
    acceptedChildren: 'none',
    relativeSize: 'small',
    propDefinitions: {
        ratingValue: {
            type: 'oneOf',
            displayName: 'Rating',
            options: ['10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'],
            default: '90%',
        },
        color: {
            type: 'colors',
            options: ['info-content', 'primary', 'accent', 'success-content', 'base-content', 'warning-content', 'error-content', 'orange-500'],
            displayName: 'Color',
            default: 'orange-500'
        },
        label: {
            type: 'string',
            displayName: 'Label',
            default: '4.5', 
            tile: '1/2'
        },
        labelPosition: {
            type: 'oneOf',
            displayName: 'Label Position',
            options: ['left', 'right'],
            default: 'left', 
            tile: '1/2'
        },
        
        size: {
            type: 'oneOf',
            options: ['xs', 'sm', 'base', 'lg'],
            displayName: 'Size (like font)',
            default: 'sm', 

        },
    }
};
