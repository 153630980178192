import { useState } from "react";

export default function EditableOnDoubleClick({ text, onBlur, preview=false, data=null, whiteSpace='pre-wrap', classes='', display='block' }) {
  const [ editable, setEditable ] = useState(false)
  
  function handleDoubleClick(e) {
    e.stopPropagation()
    setEditable(true)
  }

  function handleBlur(e, data) {
    e.preventDefault()
    setEditable(false)
    onBlur(e, data)
  }

  // console.log(data)
  if (preview) {
    return text
    
  } else {
    return (
      <div
        style={{
          whiteSpace, 
          display,
          outline: editable && '1px dashed #E04C18'
        }}
        className={classes}
        contentEditable={editable}
        onBlur={(e) => {handleBlur(e, data)}}
        onKeyDown={(e) => e.stopPropagation()}
        suppressContentEditableWarning={true}
        onDoubleClick={(e)=> handleDoubleClick(e)}
      >
        {text || `\u00A0`}
      </div>
    );
  }
  
}
