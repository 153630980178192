export const emails = [{
  apiName: 'email_verification_link',
  rootComponent: 'Email',
  displayName: 'Email with Magic Link',
  category: 'authetication',
  subcategory: 'authetication',
  description: `Contains clear call to aciton and magic link for verification purposes.`,
  width: 800, 
  height: 800, 
  thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714139447558.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=xeuTlt2Lj91UY9nFgsjAjMsLT15ILml9UMEIqz0tcIkCd0bOKEQ3Vs0GoQuqwhJFhIPhvNzewUqh6gGQHH1apE5rZ04dPABsFTVZCVm318ORCtNAcKLOHIaCRxR7nEMLGsBBb%2Bekn5AjlXGo%2BiBzrmt3Lj56J75L7l4JisirRSXh2854rTIkQ90NQlLhN7nrmkYYHuXgzxOkkdCDdCiQD1O8WYKOG1bglgBsfBvmQ48lW0AUx48oeiquaaYjj4uhNQT17tczWDnMuWulZjvoaav94xz2spt981GoXlg%2Bj2xqBEjriy6qKZEl8w4Q2K6RdpRFF2ZLv21ukNRoxaU2iA%3D%3D",
  ai_template: true,
    jsx: `<Email corners="md" gap="none" mob_padding="md">
    <Module module_name="Logo" module_role="Company logo display">
      <Logo size="28px" type="symbol" />
    </Module>
    <Module module_name="Email Header" module_role="Email subject line">
      <Heading text="Email Subject Line " marginBottom="none" marginTop="sm" />
    </Module>
    <Module module_name="Content" module_role="Content and CTA">
      <Paragraph  />
      <Button text="Sign In" />
    </Module>
    <Module module_name="Disclaimer" module_role="Email disclaimer information">
      <Text text="If you did not request this, please ignore the email." />
    </Module>
    <Module module_name="Social Links" module_role="Links to social media profiles" flexDirection="flex-row">
      <IcoNoirIcon name="X" size="20" />
      <IcoNoirIcon name="Linkedin" size="20" />
      <IcoNoirIcon name="Tiktok" size="20" />
      <IcoNoirIcon name="Instagram" size="20" />
    </Module>
  </Email>`,
}, 
  {
    apiName: 'email_promo_1', 
    displayName: 'Promo Email',
    category: 'marketing',
    subcategory: 'promo',
    rootComponent: 'Email',
    width: 800, 
    height: 800, 
    description: `A promotional email template with a hero image, heading, short content body and a call-to-action button.`,
    thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1712801728710.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=o8tqWQquSM%2BXrf0ADoGQ2GYSzr0cmt4tasJLA%2BLH4wB9y6djgQB25tvLYd%2FFPWwiv8k2unArwUxzlwEnZyj7%2BhZu68nttBJUvTmx3qGJkOf%2FEUIeCOpAe4JO35Er9U%2Fer5MLsHMH7JTAHz5JXzj8YNcRz1kJuKb9ogLpwfj0S5paxAvirLcwJ%2B7ifPDLKejFYf4uHPkQQSpU4lfrJhfuuGjpzMnDK1j5ZUFTU6c4Bp29dbUrDHq2VFaN5vBpo0Cp7Py3B0orb%2BI%2BnvuCOgajePGSMTjhAGIHQ3ITxbtzjWwZH%2FN0%2BTB5bBDqKhy3jpWtBa0fqdKE2xJyWch%2FOkzAsA%3D%3D", 
    jsx: `<Email padding="none" alignItems="center" emailBackground="none" topMargin="xl">
    <FlexBox direction="flex-col" padding="none" corners="none" background="none" gap="lg">
      <FlexBox direction="flex-col" alignItems="center" padding="none">
        <Logo size="36px" type="symbol" selfAlign="center" />
      </FlexBox>
      <FlexBox direction="flex-col" padding="none" background="base-0" gap="none">
        <Image objectFit="cover" altText="image 240px height" width="w-full" height="h-[240px]" corners="base" selfAlign="center" src="https://images.unsplash.com/photo-1533134486753-c833f0ed4866?crop=entropy&cs=srgb&fm=jpg&ixid=M3w0OTQ5NTB8MHwxfHNlYXJjaHwxfHxkYXJrJTIwcGF0dGVybnxlbnwwfHx8fDE3MTI2OTA0NzB8MA&ixlib=rb-4.0.3&q=85" />
        <FlexBox direction="flex-col" padding="lg" background="base-0">
          <Heading text="Key message" textSize="2xl" textColor="base-900" marginTop="none" marginBottom="base" lineHeight="normal" fontWeight="bold" textAlign="left" />
          <Paragraph text="Get to the point with this first short paragraph - 120 characters should be enough for introductory text." marginTop="none" marginBottom="none" />
          <Paragraph text="This medium-length paragraph provides more detail, suitable for sections that require a bit more explanation. It's perfect for content areas where you need to elaborate on a topic without overwhelming the reader with text." marginTop="none" marginBottom="none" />
          <Paragraph text="Conclude your email with a brief summary or simply by reiterating the main message or call to action." marginBottom="base" marginTop="none" />
          <Button size="medium" text="Call To Action" />
        </FlexBox>
      </FlexBox>
      <FlexBox direction="flex-col" alignItems="center" padding="none">
        <FlexBox gap="md" padding="sm" direction="flex-row" justifyContent="center">
          <IcoNoirIcon name="X" size="20" />
          <IcoNoirIcon name="Tiktok" size="20" />
          <IcoNoirIcon name="Linkedin" size="20" />
          <IcoNoirIcon name="Instagram" size="20" />
        </FlexBox>
        <Text text="Street Address, unit, San Francisco, CA 94103" textSize="sm" />
        <Link text="Unsubscribe" textSize="sm" />
      </FlexBox>
    </FlexBox>
  </Email>`
  }, 
  {
    apiName: 'email_newsletter_1',
    displayName: 'Newsletter Email',
    category: 'marketing',
    subcategory: 'newsletter',  
    rootComponent: 'Email',
    width: 800,
    height: 1200,
    description: `A newsletter email template with a hero image, heading, short content body, and a call-to-action button.`,
    thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714139047379.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=n6lLZfxNWQXRY6%2F0GkBq%2BS7p2FasG8ubMLEz%2FrjiV1%2FcEO2Il3st5bsL8TOzG3ayUZnsejUSRj2FkxseBPf4an3g83Vc%2F2AR%2FP9Ux1WBE74iaURIq0%2B7WL208WhOzKT9A2gtJm2oJlWjQ1YvjyT47Wi0l2WnUp1VRZKAGt399tZ5T1eyxSMbRf2OaTiNJpD6KPnvO697YtVrVQmhcRDJjoZa%2FFopAyaUrE42XAduurTkFb4Uv1Fj7Q0%2FfFf4JJ6C9fKjvS2jc%2F5naMFHDiAt5J9E6rrWeF6k2qtgnCEuYfDUvMiDeNts%2Fbb1Wz%2Bad1C4BetK8XMOSrGw%2BZ%2FAsC4WUg%3D%3D",
    jsx: `<Email padding="none" alignItems="center" emailBackground="none" topMargin="xl" pageBackground="base-0" width="640">
    <FlexBox direction="flex-col" padding="none" corners="none" background="none" gap="lg">
      <FlexBox direction="flex-col" padding="none" background="base-0" gap="none">
        <FlexBox direction="flex-col" padding="lg" background="base-0">
          <Paragraph text="Hey 👋, " marginTop="none" marginBottom="none">
            <Email padding="none" alignItems="center" emailBackground="none" topMargin="xl" />
          </Paragraph>
          <Paragraph text="Short pagaraph to start your email." marginBottom="base" marginTop="none" />
          <FlexBox direction="flex-col" padding="none" gap="xs" alignItems="center">
            <Image objectFit="cover" altText="Medium Image" width="w-full" height="h-[280px]" corners="base" selfAlign="center" />
            <Paragraph text="optional image comment or credit" marginTop="none" marginBottom="none" textSize="sm" />
          </FlexBox>
          <Paragraph text="This medium-length paragraph provides more detail, suitable for sections that require a bit more explanation. It's perfect for content areas where you need to elaborate on a topic without overwhelming the reader with text." marginTop="none" marginBottom="none" />
          <FlexBox direction="flex-col" padding="none" gap="xs" alignItems="center">
            <Divider direction="horizontal" width="3/4" />
          </FlexBox>
          <Heading text="Heading" textSize="lg" marginBottom="none" />
          <Paragraph text="It's perfect for content areas where you need to elaborate on a topic without overwhelming the reader with text." marginTop="none" marginBottom="none" />
          <Paragraph text="This medium-length paragraph provides more detail, suitable for sections that require a bit more explanation. It's perfect for content areas where you need to elaborate on a topic without overwhelming the reader with text." marginTop="none" marginBottom="none" />
          <Image objectFit="cover" altText="Medium Image" width="w-full" height="h-[200px]" corners="base" selfAlign="center" />
          <Paragraph text="It's perfect for content areas where you need to elaborate on a topic without overwhelming the reader with text." marginTop="none" marginBottom="none" />
          <FlexBox direction="flex-col" padding="none" gap="xs" alignItems="center">
            <Divider direction="horizontal" width="3/4" />
          </FlexBox>
          <Paragraph text="This medium-length paragraph provides more detail, suitable for sections that require a bit more explanation. It's perfect for content areas where you need to elaborate on a topic without overwhelming the reader with text." marginTop="none" marginBottom="none" />
          <FlexBox direction="flex-col" padding="none" gap="xs" alignItems="start" paddingX="none" paddingY="base">
            <Button text="Take a Quiz" />
          </FlexBox>
          <Paragraph text="Truly yours," marginBottom="base" marginTop="none" />
          <Paragraph text="Sam" marginBottom="base" marginTop="none" />
        </FlexBox>
      </FlexBox>
      <FlexBox direction="flex-col" alignItems="center" padding="none">
        <FlexBox gap="md" padding="sm" direction="flex-row" justifyContent="center">
          <IcoNoirIcon name="X" size="20" />
          <IcoNoirIcon name="Tiktok" size="20" />
          <IcoNoirIcon name="Linkedin" size="20" />
          <IcoNoirIcon name="Instagram" size="20" />
        </FlexBox>
        <Text text="Street Address, unit, San Francisco, CA 94103" textSize="sm" />
        <Link text="Unsubscribe" textSize="sm" />
      </FlexBox>
    </FlexBox>
  </Email>`  
  }, 
  {
    apiName: 'email_new_features',
    displayName: 'New Features Email',
    category: 'marketing',
    subcategory: 'newsletter',  
    rootComponent: 'Email',
    width: 800,
    height: 1200,
    description: `An email showcasing new product features. Contains a hero image, heading, short content body, and a call-to-action button.`,
    thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714139108091.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=grhxtlTEgsZjGikyq%2FH%2BPWIOqzD0Cl%2Fi8mPRQeDq%2FEoHX2%2BWtTM7cntI02mov8I3Hg0M9uWXAt93f56iCinAZpO20b5LZOg%2FLGXio%2FckdRwzmgQEsiaYjh%2BqcKdctROuZ7mAjmmlbvToKkAx4MRwstfpYTe2%2BWNWcFmhylt6mIEwH4J9YildXayyUYnrlvayD1uEKHZRVluPx7kOhTdheEcEWzRAlkNTYEDLgm0SUNVqSMA8unnwh8gThNDbe9bjfbJag5OsSIGYwHZL1Dsr9Sa99%2BnuFW1CQVkKrlMCKQvlSpnk7oPX6Ub9RuuaZrdQKa6i1id2VQTPDaD0XjcsAQ%3D%3D",
    jsx: `<Email padding="none" alignItems="start" emailBackground="none" topMargin="xl" width="640">
    <FlexBox direction="flex-col" padding="none" corners="none" background="none" gap="lg">
      <FlexBox direction="flex-col" alignItems="start" padding="none" justifyContent="center">
        <Logo size="36px" type="logo" selfAlign="auto" />
      </FlexBox>
      <FlexBox direction="flex-col" padding="none" background="base-0" gap="none">
        <FlexBox direction="flex-col" hasOutline height="h-[320px]" bgImageSrc="" justifyContent="center" alignItems="start" background="accent" padding="lg" gap="md">
          <Heading text="Email Title goes here" textSize="3xl" textColor="base-900" marginTop="none" marginBottom="none" lineHeight="normal" fontWeight="bold" textAlign="left" />
          <Paragraph text="This is a short paragraph with around 120 characters, ideal for brief descriptions or introductory text." />
          <Button size="medium" text="Call To Action" type="secondary" />
        </FlexBox>
        <FlexBox direction="flex-col" padding="lg" background="base-0">
          <Image objectFit="cover" altText="product gif" width="w-2/3" height="h-[200px]" corners="sm" selfAlign="center" />
        </FlexBox>
        <FlexBox direction="flex-row" padding="lg" background="base-0" gap="md">
          <FlexBox direction="flex-col" padding="lg" background="base-100">
            <Image objectFit="cover" altText="product image" width="w-full" height="h-[200px]" corners="sm" selfAlign="center" />
          </FlexBox>
          <FlexBox direction="flex-col" padding="none" background="none">
            <Heading text="Feature" textSize="xl" textColor="base-900" marginTop="none" marginBottom="none" lineHeight="normal" fontWeight="bold" textAlign="left" />
            <Paragraph text="This medium-length paragraph provides more detail, suitable for sections that require a bit more explanation. " marginTop="none" marginBottom="none" />
            <Link text="Try Now" underline="always" />
          </FlexBox>
        </FlexBox>
        <FlexBox direction="flex-row" padding="lg" background="base-0" gap="md">
          <FlexBox direction="flex-col" padding="none" background="none">
            <Heading text="Feature" textSize="xl" textColor="base-900" marginTop="none" marginBottom="none" lineHeight="normal" fontWeight="bold" textAlign="left" />
            <Paragraph text="This medium-length paragraph provides more detail, suitable for sections that require a bit more explanation. " marginTop="none" marginBottom="none" />
            <Link text="Watch Demo" underline="always" />
          </FlexBox>
          <FlexBox direction="flex-col" padding="lg" background="base-100">
            <Image objectFit="cover" altText="product image" width="w-full" height="h-[200px]" corners="sm" selfAlign="center" />
          </FlexBox>
        </FlexBox>
        <FlexBox direction="flex-col" padding="lg" background="accent" gap="md">
          <FlexBox gap="md" padding="none" direction="flex-row" justifyContent="start" paddingX="none">
            <IcoNoirIcon name="X" size="20" />
            <IcoNoirIcon name="Tiktok" size="20" />
            <IcoNoirIcon name="Linkedin" size="20" />
            <IcoNoirIcon name="Instagram" size="20" />
          </FlexBox>
          <FlexBox direction="flex-col" alignItems="start" padding="none" gap="xs">
            <Text text="This email was sent to email@company.com" textSize="sm" />
            <Text text="Street Address, unit, San Francisco, CA 94103" textSize="sm" />
            <Link text="Unsubscribe" textSize="sm" />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  </Email>`
  }


]
