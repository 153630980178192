import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';

export default function InputPIN(props) {
    const {
        length = 4, 
        state = 'default',
        bgColor = 'base-0',
        size = 'medium',
        width = 'auto',
        hasOutline = true,
        onComplete = () => {},
        onChange = () => {}, 
        attributes,
        listeners
    } = props;

    const { junoAttributes, showTag, outlineStyle, onPropertyUpdate=()=>{}, preview} = props.junoProps || {} /* remove line */

    const [code, setCode] = useState([...Array(length)].map(() => ""));
    useEffect(() => {
        setCode([...Array(length)].map(() => ""));
    }, [length]);

    const inputs = useRef([]);

    const handleFocus = (slot) => {
        inputs.current[slot].focus();
    };

    const processInput = (e, slot) => {
        const num = e.target.value;
        if (/[^0-9]/.test(num)) return; // Only allow digits
        const newCode = [...code];
        newCode[slot] = num;
        setCode(newCode);

        if (slot !== length - 1 && num !== "") {
            handleFocus(slot + 1);
        }

        if (newCode.every(num => num !== "")) {
            onComplete(newCode.join(""));
        }

        onChange(newCode.join("")); // Trigger onChange with the full PIN code
    };

    const onKeyUp = (e, slot) => {
        if (e.key === "Backspace" && !code[slot] && slot !== 0) {
            handleFocus(slot - 1);
        }
    };

    const sizeStyles = size === 'small' ? `text-xs w-5 py-1` : size === 'large' ? `text-base w-8 py-2` : `text-sm w-7 py-1.5`;
    const cornerStyles = size === "small" ? "rounded" : size === "large" ? "rounded-lg" : "rounded-md";
    const gapStyles = size == 'small' ? 'gap-2' : size == 'large' ? 'gap-4' : 'gap-3'
    
    let stateStyles = hasOutline ? `ring-1 ring-inset ring-base-300` : 'ring-1 ring-inset ring-transparent';
    if (state === 'disabled') {
        stateStyles = `bg-base-100 opacity-70 cursor-not-allowed ${hasOutline ? 'ring-1 ring-inset ring-base-300' : ''}`;
    } else if (state === 'error') {
        stateStyles = `text-warning-content ${hasOutline ? 'ring-1 ring-inset ring-warning-content' : ''}`;
    } else if (state === 'success') {
        stateStyles = `text-success-content ${hasOutline ? 'ring-1 ring-inset ring-success-content' : ''}`;
    }

    const bgStyles = (bgColor && bgColor !== 'none') ? `bg-${bgColor}` : '';

    const widthStyle = width !== 'auto' ? `w-${width}` : 'w-auto';
    
    let wrapperClasses = `flex flex-row items-center justify-evenly ${widthStyle} ${gapStyles} border border-transparent`;
    wrapperClasses += ` ${outlineStyle}` /* remove line */

    return (
        <div
        {...attributes} {...listeners}  
        {...junoAttributes} /* remove line */
        className={wrapperClasses}
        >
            {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Input PIN</div> /* remove line */}
                {code.map((num, idx) => (
                    <input
                        key={idx}
                        type="text"
                        inputMode="numeric"
                        maxLength={1}
                        value={num}
                        disabled={true} /* replace to 'disabled={state === 'disabled'}' */
                        className={`
                            ${sizeStyles} ${cornerStyles} ${bgStyles} ${stateStyles}
                             text-center focus:ring-2 focus:ring-accent 
                             pointer-events-none /* remove line */
                            ${state === 'disabled' && 'cursor-not-allowed'}`}
                        /* replace to 'onChange={(e) => processInput(e, idx)}' */
                        /* replace to  'onKeyUp={(e) => onKeyUp(e, idx)}' */
                        /* replace to 'ref={(ref) => inputs.current[idx] = ref}' */
                        
                    />
                ))}
            
        </div>
    );
}

InputPIN.propTypes = {
    length: PropTypes.number,
    state: PropTypes.oneOf(['default', 'disabled', 'error', 'success']),
    bgColor: PropTypes.oneOf(['none', 'base-0', 'base-50', 'base-100']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    hasOutline: PropTypes.bool,
    name: PropTypes.string,
    onComplete: PropTypes.func, // Callback when PIN is fully entered
    onChange: PropTypes.func, // Callback on every change
};

/* ignore rest */

InputPIN.definitions = {
    apiName: 'InputPIN',
    displayName: 'InputPIN',
    description: 'Input field used in code verification flows. Consists of multiple number fields.',
    ai_instructions: 'input field for code verification containing multiple number fields',
    type: 'input',
    relativeSize: 'small',
    acceptedChildren: 'none',
    propDefinitions: {
        state: {
            type: 'oneOf',
            options: ['default', 'disabled', 'error', 'success'],
            default: 'default', 
            displayName: 'State',
            tile: '1/2',
        },
        bgColor: {
            type: 'colors',
            options: ['none', 'base-0', 'base-50', 'base-100'],
            default: 'base-0', 
            displayName: 'Background',
            tile: '1/2',
        },
        width: {
            type: "width",
            options: ["auto", "1/2", "full"],
            displayName: "Width",
            default: "auto", 
            defaultOnMobile: 'full', 
            tile: '1/2',
        },
        
        size: {
            type: 'size',
            options: ['small', 'medium', 'large'],
            default: 'medium', 
            displayName: 'Size',
            tile: '1/2',
        },        
        length: {
            type: 'number',
            default: 4, 
            displayName: 'Length',
            tile: '1/2',
        },
        hasOutline: {
            type: "bool",
            displayName: "Outline",
            default: false, 
            ai_instructions: 'adds 1px outline',
        }, 
    }
};
