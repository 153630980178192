export function makeTailwindVars({varObject}) {
    
    
       
      const colorsArray = `colors: {
            primary: {
                DEFAULT: 'var(--primary)',
                content: 'var(--primary-content)',
                focus: 'var(--primary-focus)'
            },
            accent: {
                DEFAULT: 'var(--accent)',
                content: 'var(--accent-content)',
                focus: 'var(--accent-focus)'
            },
            base: {
                0: 'var(--base-0)',
                50: 'var(--base-50)',
                100: 'var(--base-100)',
                200: 'var(--base-200)',
                300: 'var(--base-300)',
                400: 'var(--base-400)',
                500: 'var(--base-500)',
                600: 'var(--base-600)',
                700: 'var(--base-700)',
                900: 'var(--base-900)',
                content: 'var(--base-content)',
            },
            error: {
                DEFAULT: 'var(--error)',
                content: 'var(--error-content)',
                focus: 'var(--error-focus)'
            },
            info: {
                DEFAULT: 'var(--info)',
                content: 'var(--info-content)',
                focus: 'var(--info-focus)'
            },
            success: {
                DEFAULT: 'var(--success)',
                content: 'var(--success-content)',
                focus: 'var(--success-focus)'
            },
            warning: {
                DEFAULT: 'var(--warning)',
                content: 'var(--warning-content)',
                focus: 'var(--warning-focus)'
            }
        }`;

    const cornersArray = `borderRadius: {
            'xs': 'var(--border-radius-xs)',
            'sm': 'var(--border-radius-sm)',
            'md': 'var(--border-radius-md)',
            'lg': 'var(--border-radius-lg)',
            'xl': 'var(--border-radius-xl)',
            '2xl': 'var(--border-radius-2xl)',
            '3xl': 'var(--border-radius-3xl)',
            'base': 'var(--border-radius-base)',
            'DEFAULT': 'var(--border-radius-base)',
        }`;

    const mainFont = varObject['mainFont']?.value || '';

    // Default font stack to always append
    const defaultFontStack = 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';

    const fontFamily = `fontFamily: {
            'sans': ['var(--mainFont)', ${[defaultFontStack].filter(Boolean).join(', ').split(',').map(font => `'${font.trim()}'`).join(', ')}],
            'serif': ${varObject['font-serif']?.value ? `[${varObject['font-serif'].value.split(',').map(font => `'${font.trim()}'`).join(', ')}]` : `['ui-serif', 'Georgia', 'serif']`},
            'mono': ${varObject['font-mono']?.value ? `[${varObject['font-mono'].value.split(',').map(font => `'${font.trim()}'`).join(', ')}]` : `['ui-monospace', 'SFMono-Regular', 'monospace']`}
        }`;

    const tailwindVars = `/** @type {import('tailwindcss').Config} */
module.exports = {
    content: [
        './src/**/*.{js,jsx,ts,tsx}',
        './public/index.html',  
        './node_modules/@juno/uikit/dist/uikit.js', // ← add this line
        ], 
    theme: {
    extend: {
        ${colorsArray}, 
        ${cornersArray},
        ${fontFamily}
    }
}`;

    return tailwindVars;

}
