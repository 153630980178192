import React from 'react';
import PropTypes from 'prop-types';
import { spacingMap } from '../helpers'; /* replace to 'import { spacingMap } from './helpers.js';' */

export default function Main(props) {
        
    const {
        background = 'base-0',
        direction = 'flex-col',

        marginX = null,
        marginY = null,
        paddingX = null,
        paddingY = null,
        gap = null, 
        
        alignItems = 'start',
        justifyContent = 'start',
        textSize = 'base',
        width = '960',
        selfAlign = 'center',
        corners = 'none',
        children,
        attributes,
        listeners
      } = props;
    const { junoAttributes, showTag, setRefs, outlineStyle} = props.junoProps || {} /* remove line */
    
    const bgStyles = background ?`bg-${background}` : '';
    
    const marginStyles = `${marginX ? ` px-${spacingMap[marginX]}` : ''}${marginY ? ` py-${spacingMap[marginY]}` : ''}`;
    const paddingStyles = `${paddingX ? ` px-${spacingMap[paddingX]}` : ''}${paddingY ? ` py-${spacingMap[paddingY]}` : ''}`;
    const gapStyles = gap ? `gap-${spacingMap[gap]}` : '';
    
    const fontSize = `text-`+textSize
    const alignItemsStyles = alignItems ? `items-${alignItems}` : '';
    const justifyContentStyles = justifyContent ? `justify-${justifyContent}` : '';
    
    const fontColor = (!background || background == 'none') ? '' : background == 'base-900' ? `text-base-0` : background?.startsWith('base') ? 'text-base-content' : `text-${background}-content`
    const alignMain = `items-${selfAlign}`
    const cornerStyles = corners === 'none' ? '' : `rounded-${corners}`;
    
    
    let outerClasses = `flex flex-col flex-grow w-full relative ${alignMain} ${fontColor} ${fontSize} ${marginStyles}`
    outerClasses += ` ${outlineStyle}` /* remove line */

    let innerClasses = `flex ${direction} flex-grow w-full z-0 relative  ${bgStyles} ${paddingStyles} ${gapStyles} ${alignItemsStyles} ${justifyContentStyles} ${cornerStyles}`
    innerClasses += ` ${outlineStyle}` /* remove line */
    
    return (
        <div 
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
        style={{
            minHeight: '100%', 
            /* replace to 'overflow: 'scroll'' */
        }}
        className={outerClasses}
        >
        {showTag && <div className='selected-element-inside' style={{zIndex: 2000}}>Main</div> /* remove line */ }
        <div 
        ref={setRefs} /* remove line */
        className={innerClasses} style={{width: '100%', maxWidth: width != 'stretch' ? `${width}px` : '100%'}}>
        {children}
        </div>
        </div>
    );
}

Main.propTypes = {
    background: PropTypes.oneOfType([
        PropTypes.oneOf(['base-0',  'base-50', 'base-100', 'base-200', 'primary', 'accent', 'base-900']),
        PropTypes.string]),
    width: PropTypes.oneOf(['stretch', '780', '960', '1200', '1440']),
    direction: PropTypes.oneOf(["flex-col", "flex-row"]),
    
    paddingX: PropTypes.oneOf(["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px']),
    paddingY: PropTypes.oneOf(["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px']),
    gap: PropTypes.oneOf(["4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"]),
    marginX: PropTypes.oneOf(["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px']),
    marginY: PropTypes.oneOf(["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px']),
    

    corners: PropTypes.oneOf(['none', 'sm', 'base', 'md', 'lg', 'xl', '2xl', '3xl']),
    selfAlign: PropTypes.oneOf(['start', 'center', 'end']),
    alignItems: PropTypes.oneOf(['start', 'center', 'end', 'stretch']),
    justifyContent: PropTypes.oneOf(['start', 'center', 'end', 'between', 'around', 'evenly']),
    textSize: PropTypes.oneOf(['sm', 'base', 'md']),
    children: PropTypes.node
};

/* ignore rest */

Main.definitions = {
    apiName: 'Main',
    displayName: 'Main Content',
    description: 'A versatile and customizable main content area for different layouts, featuring adjustable padding, background color, gap, alignment, text size, and minimum height.',
    ai_instructions: 'main content area. cannot be deleted',
    type: 'section',
    relativeSize: 'large',
    acceptedChildren: 'everything except table components, layouts, sections, dialogs',
    propDefinitions: {
        
        width: {
            type: 'oneOf',
            tile: '1/2',
            options: ['stretch', '780', '960', '1200','1440'],
            displayName: 'Width',
            ai_instructions: 'main content area stretches within appshell, but it has inner container with max-width',
            default: '960'
        },
        textSize: {
            type: 'oneOf',
            tile: '1/2',
            options: ['sm', 'base', 'md'],
            displayName: 'Text Size',
            default: 'base'
        },
        direction: {
            type: "direction",
            options: ["flex-col", "flex-row"],
            displayName: "Direction",
            tile: '1/2',
            default: "flex-col"
        },
        selfAlign: {
            type: 'alignItems',
            options: ['start', 'center', 'end'],
            displayName: 'Align Self',
            tile: '1/2',
            default: 'center'
        }, 
        background: {
            type: 'colors',
            options: ['base-0',  'base-50', 'base-100', 'base-200', 'primary', 'accent', 'base-900'],
            displayName: 'Background',
            default: 'base-0'
        },
        
        paddingX: {
            type: "spacing",
            tile: '1/2',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px'],
            displayName: "Padding X",
            ai_instructions: 'adds padding on left and right. in addition to overall padding property',
            default: null
        },
        paddingY: {
            type: "spacing",
            tile: '1/2',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px'],
            displayName: "Padding Y",
            ai_instructions: 'adds padding on top and bottom. in addition to overall padding property',
            default: null
        },
        marginX: {
            type: "spacing",
            tile: '1/2',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px'],
            displayName: "Margin X",
            default: null
        },
        marginY: {
            type: "spacing",
            tile: '1/2',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px'],
            displayName: "Margin Y",
            default: null
        },
        gap: {
            type: 'gap',
            options: ["4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Gap",
            default: null,
            tile: '1/2',
        },
        corners: {
            type: "corners",
            options: ["none", "sm", "base", "md", "lg", 'xl', '2xl', '3xl'],
            displayName: "Corners",
            tile: '1/2',
            default: 'none'
        },
        
        alignItems: {
            type: 'alignItems',
            options: ['start', 'center', 'end', 'stretch'],
            displayName: 'Align Items Inside',
            default: 'start', 
            tile: '1/2',
        },
        justifyContent: {
            type: 'justifyContent',
            options: ['start', 'center', 'end', 'between'],
            displayName: 'Justify Innner Content',
            default: 'start', 
            tile: '1/2',
        },

        
        
        
        
        
    }
};
