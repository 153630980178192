export default function SwitchInput({ currentValue, onChange, options }) {
    return (
      <div 
      className={`relative items-center flex flex-row rounded-lg bg-slate-200 p-0.5 h-8 gap-1 text-xs w-full flex-shrink-0
      ring-1 ring-slate-300`}
        
        style={{userSelect: 'none', }}
      >
        {options.map((option) => (
          <button
            key={option.value}
            className={`w-1/2 flex items-center justify-center h-full px-1.5 font-medium rounded-md flex-grow gap-1 group
            transition-all duration-150 ${currentValue === option.value ? 'ring-1 ring-slate-300 bg-base-0' : ''}  `}
            style={{
              flexGrow: 1,              
              zIndex: 2
            }}
            onClick={() => onChange(option.value)}
          >
            {option.icon}
            {option.label}
          </button>
        ))}
      </div>
    );
  }