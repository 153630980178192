import React from 'react';
import PropTypes from 'prop-types';
import { spacingMap } from '../helpers'; /* replace to 'import { spacingMap } from './helpers.js';' */

export default function IconBar(props) {

    const {
        paddingX = null,
        paddingY = null,
        gap = null,

        background = 'base-0',
        
        alignItems = 'stretch',
        justifyContent = 'start',
        position = 'left',
        width = '64px',
        display = true,
        textSize = 'base',
        children,
        attributes,
        listeners
      } = props;
      const { junoAttributes, showTag, outlineStyle } = props.junoProps || {} /* remove line */
    
      const bgStyles = background ?`bg-${background}` : '';
      const fontColor = (!background || background == 'none') ? '' : (background == 'base-900' || background == 'base-700') ? `text-base-0` : background?.startsWith('base') ? 'text-base-content' : `text-${background}-content`
      const paddingStyles = `${paddingX ? ` px-${spacingMap[paddingX]}` : ''}${paddingY ? ` py-${spacingMap[paddingY]}` : ''}`;
      const gapStyles = gap ? `gap-${spacingMap[gap]}` : '';
    
    
    const fontSize = `text-`+textSize
    const alignItemsStyles = alignItems ? `items-${alignItems}` : '';
    const justifyContentStyles = justifyContent ? `justify-${justifyContent}` : '';
    

    let classes = `flex flex-col flex-shrink-0 flex-grow-0 z-50 relative ${fontColor} ${fontSize} ${paddingStyles} ${bgStyles} ${gapStyles} ${alignItemsStyles} ${justifyContentStyles} transition-all`
    classes += ` ${outlineStyle}` /* remove line */ 

    if (display) return (
        <div 
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
        style={{
            order: position == 'left' ? '-2' : 2,
            minWidth: width, 
            minHeight: '100%', 
        }}
        className={classes}
        >
        {showTag && <div className='selected-element-inside' style={{zIndex: 2000}}>Iconbar</div> /* remove line */ }
        {children}
        </div>
    );
}

IconBar.propTypes = {
    background: PropTypes.oneOfType([
        PropTypes.oneOf(['base-0', 'base-50',  'base-100', 'base-200', 'base-700', 'base-content', 'primary', 'accent']),
        PropTypes.string]),
    position: PropTypes.oneOf(['left', 'right']),
    width: PropTypes.oneOf(['48px', '64px', '80px', '96px']),
    paddingX: PropTypes.oneOf(["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px']),
    paddingY: PropTypes.oneOf(["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px']),
    gap: PropTypes.oneOf(["4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"]),

    alignItems: PropTypes.oneOf(['start', 'end', 'center', 'baseline', 'stretch']),
    justifyContent: PropTypes.oneOf(['start', 'end', 'center', 'between', 'around', 'evenly']),
    hasOutline: PropTypes.bool,
    textSize: PropTypes.oneOf(['sm', 'base', 'md']),
    display: PropTypes.bool,
    children: PropTypes.node
};

/* ignore rest */

IconBar.definitions = {
    apiName: 'IconBar',
    displayName: 'Icon Bar',
    description: 'A versatile, vertical layout component ideal for displaying a series of icons or small widgets. Supports customization options including padding, background color, border, alignment, and minimum width. Can include a variety of child elements.',
    ai_instructions: 'vertical navigation bar with icon buttons. has flex-col property',
    type: 'section',
    relativeSize: 'large',
    acceptedChildren: 'everything except table components, layouts, sections, dialogs',
    propDefinitions: {
        width: {
            type: 'oneOf',
            options: ['48px', '64px', '80px', '96px'],
            displayName: 'Minimum Width',
            default: '64px', 
            tile: '1/2',
        },
        position: {
            type: 'oneOf',
            options: ['left', 'right'],
            displayName: 'Position',
            default: 'left', 
            tile: '1/2',
        },
        background: {
            type: 'colors',
            options: ['base-0',  'base-50', 'base-100', 'base-200', 'base-700', 'base-content', 'primary', 'accent'],
            displayName: 'Background',
            default: 'base-0'
        },
        paddingX: {
            type: "spacing",
            tile: '1/2',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px'],
            displayName: "Padding X",
            ai_instructions: 'adds padding on left and right. in addition to overall padding property',
            default: null
        },
        paddingY: {
            type: "spacing",
            tile: '1/2',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px'],
            displayName: "Padding Y",
            ai_instructions: 'adds padding on top and bottom. in addition to overall padding property',
            default: null
        },
        gap: {
            type: 'gap',
            options: ["4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Gap",
            default: null,
            tile: '1/2',
        },
        textSize: {
            type: 'oneOf',
            options: ['sm', 'base', 'md'],
            default: 'base'
        },
        alignItems: {
            type: 'alignItems',
            options: ['start', 'end', 'center', 'baseline', 'stretch'],
            displayName: 'Vertical Alignment',
            default: 'stretch', 
            tile: '1/2',
        },
        justifyContent: {
            type: 'justifyContent',
            options: ['start', 'end', 'center', 'between', 'around', 'evenly'],
            displayName: 'Horizontal Alignment',
            default: 'start', 
            tile: '1/2',
        },
        display: {
            type: 'bool',
            default: true,
            displayName: 'Display',
            defaultOnMobile: false
        },

        hasOutline: {
            type: 'bool',
            ai_instructions: 'adds subtle outline on one side of the sidebar, e.g. if iconbar position is left the outline will be on the right',
            displayName: 'Border',
            default: true
        },
    }
};
