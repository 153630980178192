export default function FlowGroups({ 
    flows, 
    onSelect
  }) {
    
    const tileWidth = 218;
  
  const width = 1440
  const height = 820
  // Calculate the scaling factor based on the largest dimension
  const scaleWidth = tileWidth / width;
  const scaleFactor = Math.min(1, scaleWidth); // Ensure the scaling does not exceed 1

  // Use the scaling factor to determine the dimensions
  const scaledWidth = width * scaleFactor;
  const scaledHeight = height * scaleFactor;

    return (
      <div className='flex flex-col relative px-1.5' style={{ height: '100%', position: 'relative' }}>
      <div className='flex flex-row gap-2 items-center justify-start text-xs py-2 font-medium'>
        <h1>Categories</h1>
        </div>
      {/* Fixed bar relative to the parent */}
      <div className='absolute bg-gradient-to-b from-white to-transparent top-0 w-full h-3 z-10'/>
      <div className='overflow-auto pt-2' style={{ height: '100%' }}>
          <div className='grid grid-cols-2 gap-2'>
          {flows.map(flow => (
            <div key={flow.name} className='flex flex-col w-full gap-1'>
              <div className={`flex flex-col gap-2 w-full bg-slate-200 text-center rounded-md p-2 text-xs items-center justify-center
              border border-slate-300 hover:border-slate-400 transition-all duration-75 text-gray-500
              hover:shadow-inner group cursor-pointer relative 
              `}
              style={{height: scaledHeight}}
              onClick={() => onSelect(flow)}
              
              >
                <div className="absolute inset-0 z-0 rounded-md group-hover:brightness-75" style={{
                backgroundImage: `url(${flow.frames?.[0]?.thumbnail})`,
                backgroundSize: 'cover',
              }} />
    
  
              <div className="opacity-0 group-hover:opacity-100 transition-all duration-75
              rounded-md bg-white py-1 px-3 ring-1 ring-gray-400 shadow-md z-50 text-gray-800 font-medium
              
              ">
                View {flow.frames?.length} templates
              </div>
              </div>
  
              <div className='flex flex-row gap-1 justify-between w-full font-normal text-xs  opacity-50'>
              <h4 className='capitalize flex-grow truncate'>
                {flow.name}
              </h4>
              
              </div>
            </div>
          ))}
        </div>
        </div>
      </div>
    );
  }
  