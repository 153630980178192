import React from 'react'
import PropTypes from 'prop-types'
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import { Icon } from '../index'; /* replace to 'import { Icon } from './index'' */
import { iconMap } from '../media/iconMap'; /* replace to 'import { iconMap } from './iconMap'' */

const allIconNames = Object.keys(iconMap) || []

export default function Tag(props) {
    
    const {
        text = 'Tag',
        type = 'filled',
        color = 'info-content',
        size = 'medium',
        leftIcon = 'none',
        rightIcon = 'close',
        isPill = true,
        attributes,
        listeners
      } = props;
    const { junoAttributes, showTag, outlineStyle, onPropertyUpdate=()=>{} } = props.junoProps || {} /* remove line */
    
    // CONTAINER STYLES
    const styleMap = {
        'filled': color == 'base-200' ? `bg-base-200 text-base-content border-transparent` : `bg-${color} text-white border-transparent` ,
        'outline': color == 'base-200' ? `text-base-600 border-base-300` : `text-${color} border-${color}`,
        'light': color == 'base-200' ? `bg-base-100 text-base-content border-base-200` : `text-${color} border-transparent`
    }
    
    const sizeStyleMap = {
        small: `text-xs py-0.5 gap-1.5 ${isPill ? 'rounded-full px-2' : 'rounded px-1.5'}`,
        medium: `text-sm py-1 gap-2 ${isPill ? 'rounded-full px-3' : 'rounded-md px-2'}`,
    };

    const sizeStyles = sizeStyleMap[size]

    let wrapperClasses = `flex font-semibold items-center flex-shrink-0 justify-center ${sizeStyles} ${styleMap[type]}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */    
    
    const LeftIconComponent = leftIcon !== 'none' ? <Icon icon={leftIcon?.toLowerCase()} className={`flex-shrink-0`}/> : null;
    const RightIconComponent = rightIcon !== 'none' ? <Icon icon={rightIcon?.toLowerCase()} className='flex-shrink-0 scale-90'/> : null;

    const truncateStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}

    return (
        <div 
            {...attributes} {...listeners} 
            {...junoAttributes} /* remove line */
            className={wrapperClasses} style={{
                borderWidth: 1,
                backgroundColor: type == 'light' && `color-mix(in srgb, var(--${color}) 16%, transparent)`
            }}
        >   
        {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Tag</div> /* remove line */}
        {LeftIconComponent}
        <span style={truncateStyle} className='flex-grow'>
        {<EditableOnDoubleClick text={text} whiteSpace='nowrap' onBlur={onPropertyUpdate('text')}/> /* replace to '            {text}' */}
        </span>
        {RightIconComponent}
        </div>
    ); 
}

Tag.propTypes = {
    text: PropTypes.string,
    type: PropTypes.oneOf(['filled', 'outline', 'light']),
    color: PropTypes.oneOf(['info-content', 'primary', 'accent', 'base-200', 'success-content', 'base-content', 'warning-content', 'error-content']),
    size: PropTypes.oneOf(['small', 'medium']),
    leftIcon: PropTypes.oneOf(['none', ...allIconNames]),
    rightIcon: PropTypes.oneOf(['none', 'close', 'check', 'check-circle']), 
    isPill: PropTypes.bool,
};

/* ignore rest */

Tag.definitions = {
    apiName: 'Tag',
    displayName: 'Tag',
    description: '',
    ai_instructions: '',
    type: 'indicators',
    relativeSize: 'small',
    acceptedChildren: 'none',
    propDefinitions: {
        text: {
            type: 'string',
            displayName: 'Text',
            default: 'Tag'
        },
        type: {
            type: 'oneOf',
            options: ['filled', 'outline', 'light'],
            displayName: 'Type',
            default: 'filled', 
            tile: '1/2'
        },
        size: {
            type: 'size',
            options: ['small', 'medium'],
            default: 'medium', 
            displayName: 'Size',
            tile: '1/2'
        },
        color: {
            type: 'colors',
            options: ['info-content', 'primary', 'accent', 'base-200', 'success-content', 'base-content', 'warning-content', 'error-content'],
            displayName: 'Color',
            default: 'info-content'
        },
        
        leftIcon: {
            type: 'icon',
            options: ['none', ...allIconNames],
            displayName: 'Left Icon',
            default: 'none' 
        },
        rightIcon: {
            type: 'icon',
            options: ['none', 'close', 'check', 'check-circle'],
            displayName: 'Right Icon',
            default: 'Xmark', 
        },
        isPill: {
            type: 'bool',
            displayName: 'Rounded',
            default: false
        }   
    }
};
