
const homePageHeaders = [{
    apiName: 'header_homepage',
    rootComponent: 'Header',
    displayName: 'Sample Header',
    description: 'contains logo, nav buttons, and login buttons.',
    width: 720, // Adjust the width as per your layout requirements
    height: 68, // Height will depend on the content
    thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714429773286.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=az4FLMqLLcRr1bPc7VyvAGRzk%2BB7hxFJxB1u4eF%2F7SssCpdwaZ2ZT8cUDgsIIzIe1BW%2B22z6YSo%2FPp%2Bj22CPEz89EuceBGI2MzOpl7n7iUhmV%2Bd7uRxN4p2jGQPDVic2Y%2BR9Xj%2FsaqQNUIt0GzlRH6AzLJxTU554%2BoAD730yreFsbjTkI8PtMuF%2FsQkmAszmhWs9Ckj3JYxqHhm8izPAcZ5%2Bg4t8zUH3gPgYWZ6JoyW8K0KLAaM5uTqm56T0sLdElEbM6nqjoGW1AQyE8ob7SOOmF39nLT3vcqpe2a72QUyoGp5pjlyFfLpMSDrTR2R6lguYo3Vd%2F7Pt0r89WLqZLA%3D%3D",
    jsx: `<Header hasBorder minHeight={68} background="base-0">
    <Module flexDirection="flex-row" height="h-full" alignItems="center" gap="lg">
      <Logo size="24px" type="logo" />
      <FlexBox direction="flex-row" padding="none" gap="sm">
        <HeaderLink text="Home" rightIcon="NavArrowDown" leftIcon="none" />
        <HeaderLink text="Products" rightIcon="NavArrowDown" dropdownTranslate="toRight" />
        <HeaderLink text="Overview" />
      </FlexBox>
    </Module>
    <Module height="h-full" alignItems="end" justifyContent="center">
      <FlexBox direction="flex-row" padding="none" gap="sm" justifyContent="end" alignItems="center">
        <Button type="secondary" text="Log In" size="small" />
        <Button type="accent" text="Try Free" size="small" />
      </FlexBox>
    </Module>
  </Header>`,
  }
  ]

const appHeaders = [
    {
        apiName: 'header_with_search',
        rootComponent: 'Header',
        displayName: 'Sample Header',
        description: 'contains logo, search bar, notification bell and user avatar',
        width: 720,
        height: 68,
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714428208183.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=p%2Ffp71Hi8F5uu%2F1wKTc81MlPAGWm%2FqDzsNUUs8Z3WKz1gE%2Bcq1XQ5DHgjXlFpdtvcatWlFAGdczofrGN9yvV435PGdhMD9cLMS0MxUS5%2FHvLeLNnePdwxb9zcoWIJutPCfq0ut%2Fmt4PdyYenBDZ3h6yo2g%2BgI9uWWqgEb8C2oJtzjyBs421pjPLTf5Vg1qeZYjH9OkBCDuhPua1nGzbopUUExJTwcmU5y0HEpOShmQl%2FQtZcDgC21yfGtTRV8%2F%2B9IBFZtoSxIqBfBrAnNJose0PwcF4qAPsNhPxfHH5glrVBU34UPdCsPYiRqR%2F3j1de35MHfqOHXaBel82PUfODfw%3D%3D",
        jsx: `<Header hasBorder minHeight={68} background="base-0">
        <Module height="h-full" justifyContent="center">
          <Logo size="24px" type="logo" />
        </Module>
        <Module height="h-full" justifyContent="center">
          <Search size="small" bgColor="base-0" hasOutline width="full" />
        </Module>
        <Module flexDirection="flex-row" justifyContent="end" height="h-full" alignItems="center">
          <ButtonIcon isPill size="xsmall" icon="Bell" type="link" />
          <Avatar size="small" type="icon" bgColor="accent" indicatorType="notification" indicatorColor="info-content" />
        </Module>
      </Header>`
    }
]

const notAuthHeaders = [
    {
        apiName: 'header_logo_buttons',
        rootComponent: 'Header',
        displayName: 'Header',
        description: 'for unathenticated users, contains logo, login and signup buttons.',
        width: 720,
        height: 68,
        thumbnail:  "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714878133724.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=W9eed89MuztjYgOYJ7yLvHf61ihLmCuqqFa6LzQIxWY9q77PZyCch3sUT3MIb%2B7o0ZDD%2FOsp8IBaUJa3N084pOE0PR8SJrYDxz8Z5xebzoeSeyK7%2FPrj4G5W18QYeeAz2w6xlZjpgkdlEBDsG91PBO5vrXPVAZYTnRfu4BbHLma7wC64gKODEKL8dYVPOl1RSZBTiEmP4rqZzHWipfkX28ZxvUw0ajbPtDl%2FtSRQRlEn55I6VmKKebKAbXuqRhranjLxDlMdgVdGmYKL6WFl1daZDIaT0zxCCZC5FJtss6icitnVYYgkY8sKr2S37n8uJvaNJaSKuSQsbggzTUXMuA%3D%3D",
        jsx: `<Header hasBorder minHeight={68} background="base-0" maxWidth="1200">
        <Module flexDirection="flex-row" width="full" alignItems="center" gap="lg" justifyContent="start" height="h-full">
          <Logo size="24px" type="logo" />
        </Module>
        <Module flexDirection="flex-row" width="full" alignItems="center" gap="md" justifyContent="end" height="h-full">
          <Button type="secondary" text="Log In" size="small" />
          <Button type="primary" text="Sign Up" size="small" />
        </Module>
      </Header>`
    }, 
    {
        apiName: 'header_logo_only',
        rootComponent: 'Header',
        displayName: 'Header',
        description: 'for unathenticated users, contains only logo. useful for authentication pages',
        width: 720,
        height: 68,
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714878196286.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=LeUd%2BKHi5QMcp5cpm60og3yDv2YvB4XCOpuqpwr90APYRfTLJ36RL%2BBHlZJoI3fa%2FGI8EQA8A3YEh4P3XsfPwuedAXfS8huopuL9fFi%2FflsLaPXaTAEZQ%2FrCt6hznSM0YtteyZepBXL40u1GJGjnoozgfacvJiI44wt7QCLP8ArL5BL2vlKFeq8GucdhPFlk79aV8Ad27YA2kgHWh0lYlQm%2BZ99UuAas9sIyU6ochSTr9qJ%2F%2B9R2NfT%2F4Xtnlz%2FCfpqG4HzOM7L2J7GTZLKHHLajjZy05Hq9CxMpnWgeopVQcepF0d8uUpv0mtDwz6oI4VDTvpraHmOzch4mjg%2FgAg%3D%3D",
        jsx: `<Header hasBorder minHeight={68} background="base-0" maxWidth="1200">
                <Module flexDirection="flex-row" width="1/4" alignItems="center" gap="lg" justifyContent="start">
                <Logo size="24px" type="logo" />
                </Module>
            </Header>`
    }

]

  
  export const headers = [
    ...homePageHeaders.map(m => ({ ...m, category: 'homePage' })),
    ...appHeaders.map(m => ({ ...m, category: 'authenticated' })),
    ...notAuthHeaders.map(m => ({ ...m, category: 'notAuthenticated' })),
    
]
