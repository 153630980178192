import { useContext, useEffect, useRef, useState } from "react";
import { EditorContext } from "../../EditorContext";
import { v1 as uuidv1 } from 'uuid';
import AddFromLibrary from "./AddFromLibrary";
import { MoreHoriz } from "iconoir-react";
import FlowName from "./FlowName";
import { createNewIds } from "../../../../utilities/helpers";

export default function FlowHeader() {
    const { selector, handleAction, set, project } = useContext(EditorContext);
    const page  = selector.page;
    const folder = selector.folder;

    function renameFlow(newName) {
        const currentPage = selector.page;
        if (!currentPage) return;
        const action = {
            type: 'UPDATE_PAGE',
            currentPage: currentPage,
            newPage: { ...currentPage, name: newName }
          };
      
          handleAction(action);
    }
    
    function deleteFlow() {
        const pagesLeft = selector.folder?.pages
        if (pagesLeft.length === 1) return

        if (window.confirm('Are you sure you want to delete this feature and all its pages?')) {
            const action = {
              type: 'DELETE_PAGE',
              page
            };
      
            handleAction(action);
            
          }
    }

    const duplicatePage = () => {
        const newPageId = uuidv1();
        const newPage = {
          ...page,
          id: newPageId,
          name: page.name + ' (copy)',
          index: page.index + 1, 
          frames: []
        };
      
        // console.log(newPage)
        handleAction({ type: 'INSERT_PAGE', newPage });
      
        page.frames?.forEach(frame => {
          const newFrameId = uuidv1();
          let newObjects = createNewIds([...frame.objects], frame.id, newFrameId);
          newObjects = newObjects.map(obj => ({...obj,frame: newFrameId})); // update frame property
          
          const newFrame = {
            ...frame,
            id: newFrameId,
            name: frame.name,
            objects: newObjects,
            page_id: newPageId
          };
      
          handleAction({ type: 'INSERT_FRAME', frame: newFrame });
        });
      };

    const pagesInFolder = project.pages?.filter((p) => !p.is_archived && p.folder_id === folder.id) || []
    return (
            <div className="flex flex-row items-end justify-between w-full pr-3 pl-6 pt-3"
              style={{height: 52}}>
                <div className="flex flex-col">
                <span className="text-2xs opacity-60">{selector.folder?.name}</span>
                <div className="text-base font-medium">
                <FlowName
                    currentName={selector.page?.name}
                    onBlur={renameFlow}
                    /></div>
                </div>
                
                <AddFromLibrary 
                    selector={selector}
                    handleAction={handleAction}
                    set={set}
                />
            </div>)
}

function MoreOptions({deleteFlow, allowDelete, duplicatePage}) {
    const [isOpen, setIsOpen] = useState(false);  // State to manage dropdown visibility
    
    const dropdownRef = useRef(null);  // Ref to help with detecting outside clicks

    useEffect(() => {
        // Function to handle clicking outside the dropdown
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            // Attach the listener if the dropdown is open
            document.addEventListener("mousedown", handleClickOutside);
        }

        // Clean up
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);
    
    const buttonStyles = 'whitespace-nowrap font-medium px-2 py-2 rounded-lg justify-start flex flex-row gap-2 items-center w-full transition-all'

    return (
        <div className="relative flex flex-row items-center bg-slate-50 hover:bg-white rounded-md p-[5px] ring-1 font-medium ring-slate-300" ref={dropdownRef}>
            <MoreHoriz height={16} width={16} style={{strokeWidth: 1}} 
            onClick={() => setIsOpen(!isOpen)}
            />
            
            {isOpen && 
            <div className={`absolute flex flex-col bg-white p-1 rounded-xl min-w-[180px] items-start border border-slate-200 text-gray-800 font-medium text-xs
            right-0 -bottom-3 translate-y-full
            `} 
            style={{  zIndex: 2000, 
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.04)' }} >
            <button onClick={(e) => { e.stopPropagation(); duplicatePage(); setIsOpen(false); }}
            className={`${buttonStyles} hover:bg-slate-100`}>
            Duplicate
          </button>

          {
          <button onClick={(e) => { allowDelete && e.stopPropagation(); deleteFlow(); setIsOpen(false); }} 
          className={`${buttonStyles}
          ${allowDelete ? 'text-primary  hover:text-white hover:bg-primary ' : 'text-slate-200 cursor-not-allowed'}
          `} 
          >
            
            Delete
          </button>}
          </div>}
        </div>
    )
}