import React, { useRef, useCallback, useEffect } from 'react';
import * as UIKit from '../ui-kit/exports/react';
import { getChildren, getDescendants } from '../utilities/helpers';

export default function LightRouter (props) {
    
    const { self, assets, descendants=[], defaultIconSet='iconoir'} = props;
    const ComponentToRender = UIKit[self.componentAPIName] || null

    const children = getChildren(self?.id, descendants);
    
    const attributes = {
        id: self.id,
    }
    const objectProps = {
        ...self.object_props,
        assets: assets,
        defaultIconSet: defaultIconSet, 
        attributes,
    };   
    // console.log('LightRouter', self.id)
    if (ComponentToRender) return (<>
    <ComponentToRender {...objectProps} >
        {children?.sort((a, b) => a.index - b.index).map(child => (
            <LightRouter 
                key={child.id} 
                self={child} 
                assets={assets} 
                defaultIconSet={defaultIconSet}
                descendants={getDescendants(child.id, descendants)} 
            />
        ))}
    </ComponentToRender>
    </>)
}