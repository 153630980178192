import PropTypes from 'prop-types'
import { Icon } from '../index'; /* replace to 'import { Icon } from './index'' */
import { iconMap } from '../media/iconMap'; /* replace to 'import { iconMap } from './iconMap'' */
import React, { useState } from 'react';

export default function SegmentedSwitchIcons(props) {
    
    const {
        size = 'medium',
        defaultOption = 0,
        width = 'auto',
        bgColor = 'base-200',
        options = ['heart', 'star', 'check'],
        selectedOptionColor = 'base-0',
        hasOutline = true,
        attributes,
        listeners
      } = props;
    const { junoAttributes, showTag, outlineStyle } = props.junoProps || {} /* remove line */

    const [selectedOption, setSelectedOption] = useState(defaultOption)
    function handleSelect(index) {
        setSelectedOption(index)
    }

    const widthStyle = `w-${width}`
    const sizeStyles = size == 'small' ? `text-xs` : size == 'large' ? `text-base` : `text-sm`;
    const cornerStyles = size == "small" ? "rounded" : size == "large" ? "rounded-lg" : "rounded-md"
    const textColor = bgColor.startsWith('base-') ? 'text-base-content' : `text-${bgColor}-content`

    const bgStyles = bgColor != 'none' ? `bg-${bgColor} text-${bgColor}-content` : ''
    const borderStyles = hasOutline ? 'border border-base-300' : 'border border-transparent';
    const classes = `flex flex-row items-center justify-between font-medium whitespace-nowrap flex-shrink-0 relative
    ${bgStyles} ${borderStyles} ${sizeStyles} ${cornerStyles}`
    
    
    const optionSizeStyles = size == 'small' ? `p-0.5 border-2 rounded` : size == 'large' ? `p-1.5 border-2 rounded-lg` : `p-0.5 border-2 rounded-md`;
    const optionClasses = `flex flex-row items-center justify-center text-center cursor-pointer border-${bgColor} ${optionSizeStyles} ${textColor}`
    
    const selectedTextColor = 
        selectedOptionColor.startsWith('success') || selectedOptionColor.startsWith('info') ? `text-${selectedOptionColor.replace('-content', '')}`
        : `text-${selectedOptionColor}-content`

    const selectedOptionClasses = `cursor-pointer text-center bg-${selectedOptionColor} border-${bgColor} ${selectedTextColor} ${optionSizeStyles}`

    const gapStyles = size == 'small' ? 'gap-0.5' : size == 'large' ? 'gap-1.5' : 'gap-1'
    let wrapperClasses = `flex flex-col ${widthStyle} ${gapStyles} select-none`
    wrapperClasses += ` ${outlineStyle}` /* remove line */

    return (
        <div
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
            className={wrapperClasses}
        >
        {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Switch Icon</div> /* remove line */}
        <div className={classes}>
        {options.map((icon, index) => {
            const IconComponent = <Icon icon={icon} className='flex-shrink-0 mx-auto scale-75' />
            return (
                <div 
                    className={selectedOption == index ? selectedOptionClasses : optionClasses}
                    style={{
                        width: `${100 / options?.length}%`,
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden', 
                    }}
                    key={index}
                    /* replace to 'onClick={()=> handleSelect(index)}' */
                >
                    {IconComponent}
                </div>
            );})}
        </div>
    </div>
);  
}



SegmentedSwitchIcons.propTypes = {
    width: PropTypes.oneOf(['auto', '1/2', 'full']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    bgColor: PropTypes.oneOf(['base-0', 'base-50', 'base-100', 'base-200', 'base-300']),
    selectedOptionColor: PropTypes.oneOf(['base-0', 'accent', 'primary', 'success-content', 'info-content']),
    defaultOption: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.string),
    hasOutline: PropTypes.bool,
};

/* ignore rest */

SegmentedSwitchIcons.definitions = {
    apiName: 'SegmentedSwitchIcons',
    displayName: 'SegmentedSwitchIcons',
    description: `A segmented switch component that allows users to select from a list of options represented as icons`,
    ai_instructions: `horizontal row of options as icons. user can select only one by clicking on it. The selected option is highlighted with a different background color.`,
    type: 'input',
    relativeSize: 'small',
    acceptedChildren: 'none',
    propDefinitions: {
        width: {
            type: "width",
            options: ["auto", "1/2", "full"],
            displayName: "Width",
            default: "auto", 
            tile: '1/2',
            defaultOnMobile: 'full'
        },
        size: {
            type: 'size',
            options: ['small', 'medium', 'large'],
            displayName: 'Size',
            tile: '1/2',
            default: 'medium'
        },
        bgColor: {
            type: 'colors',
            options: ['base-0', 'base-50', 'base-100', 'base-200'],
            displayName: 'Background',
            tile: '1/2',
            default: 'base-100'
        },

        selectedOptionColor: {
            type: 'colors',
            options: ['base-0', 'accent', 'primary', 'success-content', 'info-content'],
            displayName: 'Select Color',
            tile: '1/2',
            default: 'base-0'
        },
        options: {
            type: 'arrayOfStrings',
            displayName: 'Options',
            ai_instructions: 'array of icon names from Iconoir icon library',
            default: ['heart', 'star', 'check']
        },
        hasOutline: {
            type: "bool",
            displayName: "Outline",
            default: false, 
            ai_instructions: 'adds base-300 1px outline',
        }, 
    }
}


