import PropTypes from 'prop-types'
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import React, { useEffect, useState } from 'react';
import { Tooltip } from '../index'; /* replace to 'import { Tooltip } from './index';' */
import { Icon } from '../index'; /* replace to 'import { Icon } from './index'' */
import { iconMap } from '../media/iconMap'; /* replace to 'import { iconMap } from './iconMap'' */

const allIconNames = Object.keys(iconMap) || []

export default function SidebarLink(props) {
    
    const {
        text = 'Item',
        fontWeight = 'auto',
        leftIcon = 'Home',
        size = 'medium',
        displayChildren = true,
        width = 'full',
        indentLevel = '0',
        color = 'none',
        isCollapsed = false,
        isActive = false,
        usePadding = true,
        hoverEffect = false,
        onClick = () => {},
        children,
        attributes,
        listeners,
        defaultIconSet
      } = props;

    const { junoAttributes, showTag, preview, outlineStyle, isSelected, onPropertyUpdate=()=>{} } = props.junoProps || {} /* remove line */

    const fontWeightStyles = fontWeight == 'auto' ? 'font-normal' : `font-${fontWeight}`
    
    const sizeStylesMap = {
        small: usePadding ? `py-1 px-2 text-xs` : `py-1 px-0 text-xs`,
        medium: usePadding ? `py-1.5 px-2 text-sm` : `py-1.5 px-0 text-sm`,
        large: usePadding ? `py-3 px-3 text-base` : `py-3 px-0 text-base`
        
    }
    const gapStyles = isCollapsed ? 'gap-0' : size == 'small' ? 'gap-1.5' : size == 'large' ? 'gap-3' : 'gap-2' // was 3 for medium
    
    const sizeStyles = sizeStylesMap[size] || sizeStylesMap['medium']

    const widthStyle = (width == 'auto' || isCollapsed) ? `w-auto` : `w-${width} self-stretch`
    
    const cornerStyles = size == "small" ? "rounded" : size == "large" ? "rounded-lg" : "rounded-md"
    const borderStyles = `border border-transparent`
    
    const innerGap = size == 'small' ? 'gap-0' : size == 'large' ? 'gap-1.5' : 'gap-1'
    let wrapperClasses = `transition-all relative group flex flex-col duration-75
        ${widthStyle} ${fontWeightStyles} ${innerGap}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */

    const hoverStyles = 
    hoverEffect ? isActive ? 'juno-current-color-bg' : 
        '' /* replace to ''juno-current-color-hover-bg'' */
    : isActive ? 'opacity-100'
         : 'opacity-70' /* replace to ': 'opacity-70 hover:opacity-100'' */
    

    let innerClasses = `transition-all relative flex flex-row items-center justify-between cursor-default duration-150 
        ${hoverStyles} w-full ${sizeStyles} ${cornerStyles} ${borderStyles} ${gapStyles}` 

    const iconWidth = size == 'small' ? 'w-4' : size == 'large' ? 'w-6' : 'w-5'
    const IndentElement = <><div className={`flex-shrink-0 ${iconWidth}`}/></>
    const indentValue = parseInt(indentLevel) || 0

    const LeftIconComponent = leftIcon !== 'none' ? <Icon icon={leftIcon?.toLowerCase()} className={`flex-shrink-0  ${iconWidth}`} defaultIconSet={defaultIconSet} /> : null;

    const [isOpen, setIsOpen] = useState(displayChildren)
    useEffect(() => {
        setIsOpen(displayChildren)
    }, [displayChildren])
    
    
    return (
        <div className={wrapperClasses} 
        {...junoAttributes} /* remove line */
        {...attributes} {...listeners} >
        <div
             /* remove line */
            className={innerClasses}
            /* replace to '            onClick={onClick}' */
            style={{
                color: color != 'none' && `var(--${color})`,
                }}>

            {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>SidebarLink</div> /* remove line */}
            {!isCollapsed && Array(indentValue)?.fill()?.map((_, index) => (
            <React.Fragment key={index}>{IndentElement}</React.Fragment>
            ))}
            {LeftIconComponent}

            
            
            <div className={`flex flex-row flex-grow justify-between ${gapStyles} transition-all duration-0 ${isCollapsed ? 'w-0 h-0 pointer-events-none opacity-0' : 'w-auto'}`}>
                <EditableOnDoubleClick text={text} whiteSpace='nowrap' onBlur={onPropertyUpdate('text')} preview={preview} /* replace to '                {text}' */ />
                {children?.length > 0 ?
                        <Icon icon={'chevron-down'} 
                        defaultIconSet={defaultIconSet}
                        onClick={()=>setIsOpen(!isOpen)}
                        className={`flex-shrink-0 text-xs my-auto transition-all ${isCollapsed ? 'opacity-0' : 'opacity-0 group-hover:opacity-60'} transition-all ${isOpen ? 'rotate-180' : 'rotate-0'}`} 
                        />
                : null
                }
            </div>
            {isCollapsed && text != '' &&
                <Tooltip 
                    direction='right' // up, down, left, right // need to add a prop
                    size={size == 'small' ? 'small' : 'medium'} 
                    bgColor='base-content' 
                    text={text} 
                    junoProps={{isSelected}} /* remove line */
                    />
            }
        </div>
        {isOpen && !isCollapsed && children?.length > 0 &&
        <div className={`${isOpen ? 'h-auto' : 'h-0'} w-full flex flex-col ${innerGap} transition-all`}>
        {children}
        </div>}
        </div>
    );
}

SidebarLink.propTypes = {
    color: PropTypes.oneOfType([
        PropTypes.oneOf(["base-0", 'base-50', "base-100", "base-200", "primary", "accent", "none"]),
        PropTypes.string]),
    text: PropTypes.string,
    fontWeight: PropTypes.oneOf(['auto', 'light', 'normal', 'medium', 'semibold']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    leftIcon: PropTypes.oneOf(['none', ...allIconNames]),
    width: PropTypes.oneOf(['auto', 'full']),
    indentLevel: PropTypes.oneOf(['0', '1', '2']),
    isActive: PropTypes.bool,
    isCollapsed: PropTypes.bool,
    displayChildren: PropTypes.bool,
    children: PropTypes.node,
    usePadding: PropTypes.bool,
    hoverEffect: PropTypes.bool,
    onClick: PropTypes.func
};

/* ignore rest */

SidebarLink.stories = [
    {
        name: 'Default', 
        wrapperClasses: 'flex flex-col w-[220px] h-auto p-4 bg-white !bg-base-0',
        props: {
            text: 'Sidebar Link',
        }
    }
]

// import "../junoCss.css"; /* replace to 'import "./styles/junoCss.css";' */

SidebarLink.definitions = {
    apiName: 'SidebarLink',
    displayName: 'Sidebar Link',
    description: 'Special navigation link that goes into sidebar',
    ai_instructions: 'navigation link that goes into sidebar. generally the higher the indent the deeper the navigation level. at indent level higher than 1 the use of icons is not recommended.',
    type: 'navigation',
    relativeSize: 'small',
    acceptedChildren: 'none',
    propDefinitions: {
        text: {
            type: 'string',
            default: 'Item'
        },

        color: {
            type: 'colors',
            options: ['base-0', 'base-50', 'base-100', 'base-200', 'primary', 'accent', 'base-900', 'none'],
            displayName: 'Color',
            default: 'base-0', 
        },
        size: {
            type: 'size',
            options: ['small', 'medium', 'large'],
            displayName: 'Size',
            default: 'medium' , 
            tile: '1/2'
        },

        width: {
            type: "width",
            options: ["auto", "full"],
            displayName: "Width",
            default: "full", 
            tile: '1/2'
        },
        
        

        fontWeight: {
            type: 'oneOf',
            options: ['auto', 'light', 'normal', 'medium', 'semibold'],
            displayName: 'Font Weight',
            default: 'auto', 
            tile: '1/2'
        },
        indentLevel: {
            type: 'oneOf',
            displayName: 'Indent Level',
            options: ['0', '1', '2'],
            default: '0', 
            tile: '1/2'
        }, 
        leftIcon: {
            type: 'icon',
            options: ['none', ...allIconNames],
            displayName: 'Icon',
            default: 'Home' 
        },

        isCollapsed: {
            type: 'bool',
            displayName: 'Collapsed',
            default: false, 
            tile: '1/2'
        },
        isActive: {
            type: 'bool',
            displayName: 'Active',
            default: false, 
            tile: '1/2'
        },
        displayChildren: {
            type: 'bool',
            displayName: 'Show Children',
            default: true, 
            tile: '1/2'
        },

        usePadding: {
            type: 'bool',
            displayName: 'X Padding',
            default: true, 
            tile: '1/2'
        },
        hoverEffect: {
            type: 'bool',
            displayName: 'Hover Effect',
            default: false, 
            tile: '1/2'
        },
    }
};
