import React from 'react';
import PropTypes from 'prop-types';
import * as FeatherIcons from 'react-icons/fi';
import * as Ionicons from 'react-icons/io5'; 
import * as MaterialIcons from 'react-icons/md';
import * as HeroIcons from 'react-icons/hi';
import * as IconoirIcons from 'iconoir-react';

import { getIconName, iconMap } from './iconMap'; /* replace to 'import { getIconName, iconMap } from './iconMap'' */

const allIconNames = Object.keys(iconMap)

export default function Icon(props) {

    const {
        icon = 'add',
        library, 
        color = 'auto',
        size = 'auto',
        className = '',

        attributes,
        listeners
      } = props;

    const { junoAttributes, outlineStyle} = props.junoProps || {} /* remove line */

    const colorStyles = (color == 'auto' || color == 'none') ? '' : `text-${color}`
    let wrapperClasses = `${colorStyles}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */
    
    let globalIconSet = getComputedStyle(document.body).getPropertyValue('--iconset').trim()
    
    const fallbackIconSet = 'iconoir'
    const libraryMap = {
        feather: FeatherIcons,
        ionic: Ionicons,
        material: MaterialIcons,
        heroicons: HeroIcons,
        iconoir: IconoirIcons,
    };
    
    const defaultIconSet = libraryMap[globalIconSet] ? globalIconSet : fallbackIconSet;
    
    const useLibrary = (!library || libraryMap[library] === undefined) ? defaultIconSet : library;
    const mappedIconName = getIconName(icon, useLibrary);
    
    
    const IconLibrary = libraryMap[useLibrary];
    
    const IconComponent = IconLibrary[mappedIconName] || null

    const sizeInt = parseInt(size, 10);
    
    if (!IconComponent) return null;
    const iconSize = size == 'auto' ? `1.5em` : parseInt(sizeInt)
    return (
        <div
        className={wrapperClasses}
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
        >
        
        <IconComponent size={iconSize} color={color} className={className} />
        </div>
    );
}

Icon.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(allIconNames)]),
    color: PropTypes.oneOf(['auto', 'none', 'primary', 'accent', 'base-content', 'info-content', 'warning-content', 'success-content', 'error-content', 'base-100', 'base-200', 'base-300']),
    size: PropTypes.oneOf(['auto', '12px', '16px', '20px', '24px', '32px']),
    library: PropTypes.oneOf(['feather', 'ionic', 'material', 'heroicons', 'iconoir']), 
    className: PropTypes.string,
};

/* ignore rest */

Icon.definitions = {
    apiName: 'Icon',
    displayName: 'Icon',
    description: 'Dynamic icon that can switch between libraries',
    ai_instructions: 'icon component that can switch between libraries',
    type: 'media',
    acceptedChildren: 'none',
    relativeSize: 'small',
    propDefinitions: {
        icon: {
            type: 'icon',
            options: allIconNames,
            displayName: 'Icon',
            default: 'add'
        },
        color: {
            type: 'colors',
            options: ['none', 'primary', 'accent', 'base-content', 'info-content', 'warning-content', 'success-content', 'error-content', 'base-100', 'base-200', 'base-300'],
            displayName: 'Color',
            default: 'auto'
        },
        size: {
            type: 'oneOf',
            options: ['auto', '12px', '16px', '20px', '24px', '32px'],
            displayName: 'Size',
            default: 'auto'
        },
        library: {
            type: 'oneOf', 
            options: ['feather', 'ionic', 'material', 'heroicons', 'iconoir'], 
            displayName: 'Library',
            default: 'feather'
        }
    }
};
