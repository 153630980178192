import { useContext, useState } from "react";
import { Alert, Icon, MiniSnippet } from "../../../../../ui-kit/exports/react";
import { makeTailwindVars } from "./helpers/makeTailwindVars";
import { makeCssVars } from './helpers/makeCssVars';
import { safelist } from './helpers/safelist';
import getFontEmbedURL from "./helpers/getFontEmbedURL";
import { EditorContext } from "../../../EditorContext";

export default function Install({appliedTheme}) {


    const { effectiveVariables } = useContext(EditorContext)
    const iconSet = appliedTheme.iconSet || 'iconoir'

    const varObject = effectiveVariables.reduce((acc, current) => {
      acc[current.api_name] = current;
      return acc;
    }, {})
    
    // add iconset
    varObject.iconset = {value: iconSet}

    const tailwindVars = makeTailwindVars({varObject: varObject})
    const cssVars = makeCssVars({varObject: varObject})

    const tabs = [
        {   order: 1,
            label: 'Install UI-Kit', 
            description: 'Via npm command.',
            snippet: `npm install sambokher/uikit`
        },
        {   order: 2,
            label: 'Import JUNO Stylesheet', 
            description: 'Add this line to your App.js or similar.',
            snippet: `import '../node_modules/@juno/uikit/dist/uikit.css';`

        },
        {   order: 3,
            label: 'Configure Tailwind CSS', 
            description: `Add an extra line to the 'content' array and configure the theme object in your tailwind.config.js file.`,
            snippet: tailwindVars
        }, 
        {
            order: 4,
            label: 'Customize Theme Variables',
            description: `Add the following CSS variables to your global stylesheet. These variables are used in your currently selected theme${appliedTheme?.display_name ? ` (${appliedTheme.display_name})` : ''}.`,
            snippet: cssVars
        }
    ]

    return (
        <div className="w-full flex flex-col gap-3 h-full pl-3 overflow-scroll" 
        style={{ height: '100%', maxHeight: '100%', minHeight: '100%'}}>
        <div className="mb-5 text-xs text-gray-500">
            <Alert text='JUNO UI-Kit uses Tailwind CSS for styling. You need to install Tailwind CSS to use JUNO Design System.'
            size='small' type='info' icon='auto'
            onActionClick={() => window.open('https://tailwindcss.com/docs/installation', '_blank')}
            actionText='Install Tailwind CSS'
            actionType={'link'}
            />
        </div>
        
        {tabs.map((tab, index) => (
        <div className="w-full flex flex-row gap-6">
            {/* Number and Bar */}
            <div className="flex flex-col flex-grow-0 flex-shrink-0 items-center gap-3">
                <div className="w-6 h-6 rounded-md ring-[0.5px] ring-base-100 bg-base-50 font-semibold flex items-center justify-center text-2xs">
                    {tab.order}
                </div>
                
                <div className={`flex-grow w-px bg-base-100 ${index == tabs.length-1 ? 'hidden' : ''}`}></div>
            </div>

            {/* Title & Description */}
            <div className="flex flex-col w-[280px] flex-shrink-0 gap-1 pb-2 items-start mr-12">
                <div className="text-sm font-semibold text-base-content">
                    {tab.label}
                </div>
                <div className="text-sm text-slate-600 font-normal">
                    {tab.description}
                </div>
            </div>

            {/* Snippet Block */}
            <div className="flex flex-col flex-grow gap-2 pb-8 items-start">
                {tab.snippet &&
                <MiniSnippet 
                    text={tab.snippet} 
                    width='full'
                    size={'small'}
                    hasOutline={false}
                    bgColor={'base-50'}
                    maxHeight={320}
                    />}
                {tab.instruction}
            </div>

        </div>))}
          
        </div>
    )
}


