import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import FolderTab from "./FolderTab";
import { useContext } from 'react';
import { EditorContext } from '../../EditorContext';

export default function Folders({sidebarOpen, tooltipStyle}) {
    const { project, pages, handleAction, view, setView } = useContext(EditorContext)

    const addPage = (folder_id=null) => {
        handleAction({ type: 'APPEND_PAGE', folder_id});
      }; 

    const folders = project.folders?.filter(f => !f.is_archived) || []
    function handleDragEnd(event) {
        const { active, over } = event;
        if (active.id !== over.id) {
          
          // Logic to reorder frames
          const movingFolder = folders.find(f => f.id == active.id)
          const overFolder = folders.find(f => f.id == over.id)
          
          if (movingFolder && overFolder && movingFolder != overFolder) {
            const action = {
              type: 'RELOCATE_FOLDER', 
              folder: movingFolder, 
              newIndex: overFolder.index
            }
            handleAction(action)
          } 
        }
        
      }
    
      const sensors = useSensors(
        useSensor(PointerSensor, {
          activationConstraint: {
            distance: 8,
          },
        })
      )
      //${sidebarOpen ? 'hidden md:flex' : 'flex'}
    return (
    
    <div className={`flex flex-col gap-1 overflow-visible`}
    style={{height: '100%', maxHeight: '100%', maxWidth: '100%'}}
    >


        <DndContext onDragEnd={handleDragEnd} collisionDetection={closestCenter} sensors={sensors}>
            <SortableContext items={folders} strategy={verticalListSortingStrategy}>
            
            {folders.length > 0 && 
              folders
              .sort((a, b) => a.index - b.index)
              .map(folder => 
                <FolderTab
                  sidebarOpen={sidebarOpen}
                  tooltipStyle={tooltipStyle}
                  key={folder.id}
                  folder={folder}
                  onAddPage={addPage}
                  isOnlyFolder={folders.length === 1}
                  pages={pages.filter(p => p.folder_id == folder.id).filter(p => !p.is_archived)}
                />
              )
            }
            </SortableContext>
          </DndContext>
          {/*sidebarOpen && <div className={`${sidebarOpen ? 'hidden md:flex' : 'hidden'}`}><AddFolderAfter folders={folders} /></div>*/}
      </div>
)
}