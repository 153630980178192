import { useContext, useEffect, useState, useRef } from "react";
import { createNewFrame, createNewIds } from '../../../../utilities/helpers';
import { EditorContext } from "../../EditorContext";
import TypicalLayouts from "./layouts/TypicalLayouts";
import { sections } from '../../../../templates/sections';
import { modules } from '../../../../templates/modules';
import Pages from "./templates/Pages";
import { Book, LayoutLeft, OpenBook, Plus, Xmark } from "iconoir-react";
import Library from "./Library";


export default function OpenLibrary({isDragging}) {
    const { selector, set, handleAction, view } = useContext(EditorContext)
    const [showLibrary, setShowLibrary] = useState(false)
    

    function insertNewFrame({objects=[]}) {
      
      const page = selector.page
      if (!page) return
      const frames = page?.frames
      const insertIndex = frames?.length > 0 
        ? Math.max(...frames.map(frame => frame.index)) + 1 // add as last to the page
        : 1;
    
      let newFrame = createNewFrame({ ...page, type: 'page' }, [], 'Untitled frame', { width: 800, height: 800 });
      newFrame = { ...newFrame, index: insertIndex };

      let newObjects = objects.map(obj => ({...obj, frame: newFrame.id}));
      newObjects = newObjects.map(obj => obj.parent == 'rootObject' ? {...obj, parent: newFrame.id} : obj); 
      
      const action = {type: 'INSERT_FRAME', frame: {...newFrame, objects: newObjects}}
      handleAction(action);
      setShowLibrary(false)
    }

    const myRef = useRef(null);
    
    // Close the library when clicking outside of it
    useEffect(() => {
      function handleClickOutside(event) {
        if (myRef.current && !myRef.current.contains(event.target)) {
          setShowLibrary(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [myRef]);
    
    return (
      <div className="pl-3 py-1"
      ref={myRef}
      >
      <div className={`flex flex-col items-center justify-center rounded-md gap-0
      whitespace-nowrap flex-shrink-0 text-gray-700 group relative bg-slate-200/50 backdrop-blur-sm
      cursor-pointer flex-shrink-0 flex-grow-0 font-normal
      ${showLibrary && ' text-gray-800 bg-slate-200/50'}
      ${!isDragging && 'transition-all duration-75 hover:bg-slate-200/50'}`}
      onClick={() => setShowLibrary(!showLibrary)}
      style={{width: 52, height: 52}}
      >
        <LayoutLeft height={20} width={20} className={`${showLibrary ? 'scale-90 -mt-1' : 'group-hover:scale-90 group-hover:-mt-1'} stroke-[1.2px] transition-all duration-75`}/>
        <span className={`${showLibrary ? 'h-3' : 'h-0 group-hover:h-3 opacity-0 group-hover:opacity-100 '}
         transition-all duration-75 text-xs whitespace-nowrap`}>
        Library
        </span>
      </div>
      {showLibrary &&
      <div className={`absolute left-2 -bottom-2  bg-gray-50 translate-y-full z-50 rounded-xl border-[0.5px] border-gray-400 shadow-xl
      flex flex-col pt-2 overflow-hidden`} style={{ width: 480,  height: 580, }}>
          <Xmark 
            width={20} 
            height={20} 
            className="absolute top-2 right-2 hover:scale-110 transition-all duration-75 opacity-40 hover:opacity-80 cursor-pointer"
            onClick={() => setShowLibrary(false)}
            
            />
            <Library 
              onSelect={insertNewFrame} 
              set={set} 
              draggable={!view?.overview}
            
            />
            </div>}
            </div>
            )

}

