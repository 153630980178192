import * as IconoirIcons from 'iconoir-react';
import { useContext } from 'react';
import { EditorContext } from '../../EditorContext';


export default function ZoomOverview() {
  const {view, setView } = useContext(EditorContext)  
  const columnWidth = view.columnWidth
  
  const values = [144, 288, 432, 576, 770];
    const mapping = {
      144: '10%',
      288: '20%', 
      432: '30%',
      576: '40%',  
      770: '50%',  
    }
    const currentIndex = values.indexOf(columnWidth);
    const canZoomOut = currentIndex > 0;
    const canZoomIn = currentIndex < values.length - 1;
  
    const handleZoomIn = () => {
      if (canZoomIn) {
        const value = values[currentIndex + 1]
        setView({...view, columnWidth: value})
      }
    };
  
    const handleZoomOut = () => {
      if (canZoomOut) {
        const value = values[currentIndex - 1]
        setView({...view, columnWidth: value})
      }
    };
  
    const ZoomIn = IconoirIcons['ZoomIn'];
    const ZoomOut = IconoirIcons['ZoomOut'];
    
    return (
      <div className='relative group gap-2 transition-all rounded-lg  hover:bg-white hover:ring-1 hover:ring-slate-200 flex-shrink-0 justify-between flex flex-row gap-1 items-center font-medium overflow-hidden text-xs '>
        <button 
          onClick={handleZoomOut} 
          className={`h-7 pl-2 flex items-center opacity-0 group-hover:opacity-100 transition-all duration-100 justify-center ${canZoomOut ? 'hover:scale-110 cursor-pointer' : 'text-gray-400'}`}
          disabled={!canZoomOut}
        >
          <IconoirIcons.ZoomOut height={16} width={16} className='hover:scale-110 transition-all duration-75' />
        </button>
        {mapping[columnWidth]}
        <button 
          onClick={handleZoomIn} 
          className={`h-7 pr-2 flex items-center transition-all duration-100 justify-center ${canZoomIn ? 'hover:scale-110 cursor-pointer' : 'text-gray-400'}`}
          disabled={!canZoomIn}
        >
          <IconoirIcons.ZoomIn height={16} width={16} className='hover:scale-110 transition-all duration-75 opacity-0 group-hover:opacity-100 hidden group-hover:flex' />
          <IconoirIcons.ZoomIn height={16} width={16} className='hover:scale-110 transition-all duration-75 opacity-100 group-hover:opacity-0 flex group-hover:hidden' />
        </button>
      </div>
    );
  }
  