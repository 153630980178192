import React, { useContext, useState, useEffect, useRef } from 'react';
import { EditorContext } from '../../EditorContext';
import {NavArrowRight, Folder, NavArrowLeft, Flower, LogOut, NavArrowDown} from 'iconoir-react';
import { useNavigate } from 'react-router-dom';
import Folders from './Folders';
import AddFolder from './AddFolderAfter';
import UserMenu from './UserMenu';
import ProjectMenu from './ProjectMenu';
import AddFolderPlus from './AddFolderPlus';

export default function Navigation() {
    const { view, setView, project } = useContext(EditorContext)
    
    const isOpen = view.overviewSidebar
    const setIsOpen = (value) => setView({...view, overviewSidebar: value})

    const navigationRef = useRef(null);
    
    function useOutsideClick(ref, callback) {
      useEffect(() => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            callback();
          }
        }
    
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref, callback]);
    }
    const folders = project.folders?.filter(f => !f.is_archived) || []
    useOutsideClick(navigationRef, () => setView({...view, leftSide: false}));

    const tooltipStyle = `opacity-0 group-hover:opacity-100 invisible group-hover:visible whitespace-nowrap text-gray-100
    w-auto bg-slate-800 border border-gray-800  font-normal rounded-md px-2 py-0.5 text-2xs ${isOpen ? 'absolute md:hidden' : 'absolute'}
    top-1/2 mb-2 -right-3 transform -translate-y-1/2 translate-x-3/4 group-hover:translate-x-full transition-all`
    
    //bg-gradient-to-b from-slate-50 to-white 
    //       ${view.inOverview == 'designSystem' ? '' : 'border-r-[0.5px] border-slate-200'}

    return(
      <div className={`flex flex-col h-full flex-shrink-0 gap-1 relative transition-all duration-75 relative
      py-3 px-3 ${isOpen ? 'w-[52px] md:w-60 ' : 'w-[52px]'} duration-75`}
      onClick={() => !isOpen && setIsOpen(true)}
      style={{
        height: `100%`,
        maxHeight: `100vh`,
        cursor: 'default', 
        zIndex: 1000,
      }}
      ref={navigationRef} 
      >
        <div className='w-full flex flex-col gap-1 items-start justify-start'>
          <ProjectMenu  sidebarOpen={isOpen} tooltipStyle={tooltipStyle}/>
          <OpenDesignSystem sidebarOpen={isOpen} tooltipStyle={tooltipStyle}/>
        </div>

        {/* overflow-hidden  take out */}
        <div className='flex flex-col w-full flex-grow h-full w-full text-xs gap-1'>
          <div className={` gap-1 items-center justify-between mt-3 px-0 pr-1 py-1 group relative font-medium
          overflow-hidden
          ${isOpen ? 'hidden md:flex md:flex-row' : '!hidden'} 
          `}>
              <h2 className={`opacity-60 w-full text-xs items-center justify-between px-1`}>
                  Folders
            </h2>
            <div></div>
            <AddFolderPlus folders={folders}/>
          </div>
          <Folders sidebarOpen={isOpen} 
          tooltipStyle={tooltipStyle}
          />
        </div>
        <Exit sidebarOpen={isOpen} tooltipStyle={tooltipStyle}/>
        {/*
        <div className='w-full flex flex-col gap-1 items-start justify-start'>
          <UserMenu sidebarOpen={isOpen} tooltipStyle={tooltipStyle}/>
        </div>
        {/**/}
        </div>
    )
  }

// <div className='flex xl:hidden flex-grow'/>


function OpenDesignSystem({sidebarOpen, tooltipStyle}) {
  
  const { view, setView, setSelector } = useContext(EditorContext);
  const buttonStyles = `flex flex-row ${sidebarOpen ? 'gap-2' : 'md:gap-2 gap-0'} items-center justify-start text-xs px-1.5 py-1.5 rounded transition-all relative group self-center md:self-start w-auto md:w-full`
  
  return (
    
        
        <div className={`${buttonStyles} ${view?.inOverview == 'designSystem' ? 'font-medium bg-slate-100' : 'font-normal'} hover:bg-slate-100`}
        onClick={() => {setView({...view, inOverview: 'designSystem'})}}
        style={{lineHeight: '16px'}}
        >

          {/* <div className="flex-shrink-0" style={designSystemStyles}/> */}
          <Flower height={16} width={16} className='flex-shrink-0 scale-90 stroke-f[2px]'/>
          
          {sidebarOpen &&
          <span className={`${sidebarOpen ? 'hidden md:flex' : 'hidden'} transition-all duration-150 whitespace-nowrap`}
          >Design System</span>}
          
          {<span className={`${tooltipStyle} `}>
                Design System
          </span>}
        
    </div>
  )

}

  function Exit({sidebarOpen, tooltipStyle}) {
    
    const buttonStyles = `flex flex-row ${sidebarOpen ? 'gap-2' : 'md:gap-2 gap-0'} items-center justify-start text-xs px-1.5 py-1.5 rounded transition-all relative group self-center md:self-start w-auto md:w-full`
    const navigate = useNavigate();
    return (
<div className={`${buttonStyles} hover:bg-slate-100`}
onClick={() => navigate('/projects')}
style={{lineHeight: '20px'}}
>
  
  {<LogOut height={16} width={16} className='-scale-x-100' />}
  
  
  {sidebarOpen &&
          <span className={`${sidebarOpen ? 'hidden md:flex' : 'hidden'} transition-all duration-150 whitespace-nowrap`}
          >To Projects</span>}
          {<span className={`${tooltipStyle} `}>
                To Projects
          </span>}
  </div>
    )
  }
  
  function SidebarToggle({isOpen, setIsOpen}) {
    return (
      <div className={`flex-shrink-0 flex items-center text-xs justify-end px-2.5 py-2 mt-1 rounded-lg hover:scale-110 cursor-pointer transition-all duration-150
      opacity-60 hover:opacity-100 md:visible invisible self-start`}
      onClick={() => setIsOpen(!isOpen)}
      >
        {!isOpen ? <NavArrowRight height={16} width={16}/> :
        <NavArrowLeft height={16} width={16} />}
      </div>
    )
}

