import { useState, useEffect } from "react";
import Icon from "../../components/icons/Icon";
import AceEditor from 'react-ace';
import { convertObjectsToJSX } from "../../utilities/AIhelpers";

export default function CodePreview({showCode, selectedFrame, setShowCode}) {
    const [copied, setCopied] = useState(false);
    let jsx = selectedFrame && convertObjectsToJSX({frame: selectedFrame})
  
    useEffect(() => {
      if (copied) {
        const timer = setTimeout(() => {
          setCopied(false);
        }, 3000);
  
        return () => clearTimeout(timer);
      }
    }, [copied]);
  
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(() => {
        setCopied(true);
      })
        .catch(err => {
          console.log('Something went wrong', err);
        })
    }
    return (
  <div 
              className={`absolute right-2 bg-slate-100 rounded-md shadow-lg border border-slate-300
              overflow-hidden flex flex-col transition-all duration-100 ease-in-out
              ${showCode ? "opacity-100" : "opacity-0"} 
              `}
              style={{height: 'calc(100vh - 80px)', top: 44,
              visibility: showCode ? 'flex' : 'none', 
              width: showCode ? 360 : 0,
            }}
              >
                <div className='flex flex-row justify-between items-center px-2 py-2 font-medium text-xs drop-shadow border-b border-slate-300 ' >
                  {copied ? (
                    <div className=''>
                      copied
                    </div>
                  ) 
                : (
                    <button
                      className='hover:scale-110 transition-all cursor-pointer opacity-80 hover:opacity-90'
                      onClick={() => copyToClipboard(jsx)}>
                      <Icon width={16} height={16} fill="#333" name="copy" />
                    </button>
                  )}
                  <button
                      className='hover:scale-110 transition-all cursor-pointer opacity-80 hover:opacity-90 mr-1'
                      onClick={() => setShowCode(false)}>
                      <Icon width={12} height={12} fill="#333" name="xmark" />
                    </button>
                </div>
                <AceEditor
                  mode="javascript"
                  theme="tomorrow"
                  value={jsx}
                  editorProps={{ $blockScrolling: true }}
                  fontSize={13}
                  showPrintMargin={true}
                  showGutter={true}
                  highlightActiveLine={true}
                  style={{ width: '100%', height: '100%' }}
                  readOnly={true}
                />
              </div>
    )
  }