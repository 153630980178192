import devLogos from "./dev_logos_2.svg";
import { useEffect, useRef, useState } from "react";
import { Icon } from "../../ui-kit/exports/react";

export default function Why() {
    
    const themes = [
        {
            name: 'juno', 
            hFontFamily: 'Lato', 
            hFontColor: 'text-gray-800  font-semibold',
            fontFamily: 'Inter', 
            bgClasses: 'bg-gradient-to-tl from-stone-50 to-white hover:from-slate-50 hover:to-white ring-[0.5px] ring-slate-200 hover:ring-slate-300', 
            sectionBgClasses: 'bg-stone-100',
            textColor: 'text-gray-700', 
            boxRadius: 'rounded-3xl', 
            buttonStyles: 'px-3 py-2 bg-primary text-white hover:bg-primary-focus rounded-xl', 
            isDark: false, 
            iconSet: 'iconoir', 
            
            
        }, 
         
        {
            name: 'brooklyn blackout',
            hFontFamily: 'Inter',
            hFontColor: 'text-stone-200 font-normal',
            fontFamily: 'Inter',
            bgClasses: 'bg-gradient-to-t from-stone-950 to-stone-800 ring-[1px] ring-stone-900',
            sectionBgClasses: 'bg-stone-800',
            textColor: 'text-stone-300',
            boxRadius: 'rounded-md',
            buttonStyles: 'px-3 py-5 bg-stone-800 text-stone-300 hover:bg-stone-700 rounded',
            isDark: true,
            iconClasses: 'hover:scale-110',
            iconSet: 'material', 
            stickyColors: {
                yellow: 'bg-gradient-to-tl from-yellow-400 to-yellow-500 text-black',
                green: 'bg-gradient-to-br from-lime-400 to-green-400 text-black', 
                blue: 'bg-gradient-to-br from-blue-400 to-indigo-400 text-black'
            }, 
            imageFilter: 'saturate-50 hover:saturate-100'


        },
        {
            name: 'tofu',
            hFontFamily: '',
            hFontColor: 'text-stone-800 font-bold font-mono ',
            fontFamily: '',
            bgClasses: 'bg-gradient-to-tl from-stone-100 to-stone-100 ring-[2px] ring-white rounded-xl',
            sectionBgClasses: 'bg-stone-100',
            textColor: 'text-netral-600 font-mono ',
            boxRadius: 'rounded-sm',
            buttonStyles: 'px-4 py-2 w-[80%] bg-orange-500 ring-2 ring-white rounded-md hover:bg-orange-600 text-white',
            isDark: false,
            iconClasses: '',
            iconSet: 'feather', 
            stickyColors: {
                yellow: 'bg-stone-200 text-stone-700 font-mono border-2 border-white',
                green: 'bg-stone-200 text-stone-700 font-mono border-2 border-white',
                blue: 'bg-stone-200 text-stone-700 font-mono border-2 border-white',
            },
            imageFilter: 'grayscale',

        },
        {
            name: 'brat',
            hFontFamily: 'Arial',
            hFontColor: 'text-black  blur-[0.8px]  font-normal',   
            fontFamily: 'Times New Roman', 
            bgClasses: 'bg-lime-500 !ring-0',
            sectionBgClasses: 'bg-lime-400',
            textColor: 'text-neutral-700',
            boxRadius: 'rounded-sm',
            buttonStyles: ' px-2 py-4 bg-transparent text-black underline blur-[0.8px] hover:blur-[0px] hover:bg-white hover:ring-1 hover:ring-black',
            isDark: false,
            iconClasses: 'blur-[0.4px] hover:blur-[0px]',
            iconSet: 'ionic', 
            stickyColors: {
                yellow: 'bg-white border-2 border-double border-black text-black',
                green: 'bg-white border-2 border-dashed border-black text-black', 
                blue: 'bg-white border-2 border-dotted border-black text-black !font-normal'
            },
            imageFilter: 'brightness-0 blur-sm',
        },
        
    ]

    const [currentIndex, setCurrentIndex] = useState(0);
    const selectedTheme = themes[currentIndex];
    const themeBefore = currentIndex - 1 < 0 ? themes[themes.length - 1] : themes[currentIndex - 1];
    const themeAfter = currentIndex + 1 >= themes.length ? themes[0] : themes[currentIndex + 1];
    const [pingVisible, setPingVisible] = useState(false);

    function nextTheme() {
        const nextIndex = currentIndex + 1 >= themes.length ? 0 : currentIndex + 1;
        
        setPingVisible(true);

        // Hide the ping effect after 1 second
        setTimeout(() => {
            setPingVisible(false);
        }, 500); // 1 second (1000ms)

        setCurrentIndex(nextIndex);
    }
    
    const iconAttributes = {
        library: selectedTheme.iconSet,
        className: `scale-90 ${selectedTheme.iconClasses}`
    }

    const [isPastThreshold, setIsPastThreshold] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const developmentSection = document.getElementById('about');
            if (!developmentSection) return;

            const sectionTop = developmentSection.offsetTop;
            const sectionHeight = developmentSection.clientHeight;
            const scrollY = window.scrollY;
            const windowHeight = window.innerHeight;

            // Calculate the scroll progress as a percentage
            const scrollProgress = Math.min(
                Math.max((scrollY + windowHeight - sectionTop) / (sectionHeight + windowHeight), 0),
                1
            );

            // Determine if we're past the threshold
            const newIsPastThreshold = scrollProgress >= 0.5;

            // Only update if the state has changed
            if (newIsPastThreshold !== isPastThreshold) {
                setIsPastThreshold(newIsPastThreshold);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isPastThreshold]);


    return (
        
        <div className={`flex flex-col w-full relative items-center h-auto  px-5 max-w-[1120px] mb-8
        
        `}>
            <div className="flex flex-col w-full flex-grow-0 flex-shrink-0 items-center px-5 py-20 md:py-12 justify-center"
        style={{ fontFamily: 'Unbounded, Inter, system-ui, -apple-system, BlinkMacSystemFont, Roboto, sans-serif'}}

        >
            <h2 className="w-full mx-auto leading-relaxed text-lg md:text-3xl text-center font-normal flex flex-col items-center justify-center">
            founders partner with us to
            <span className="text-primary text-xl md:text-4xl">plan, design & code apps</span>
            </h2>
            </div>

        <div className="flex flex-col md:flex-row w-full gap-8 items-stretch h-full  flex-grow justify-center  text-md" 
        style={{fontFamily: 'ui-monospace'}}
        >

            <ProductStrategy 
                iconAttributes={iconAttributes}
                selectedTheme={selectedTheme}
                isPastThreshold={isPastThreshold}
                
            /> 
            <Design 
                nextTheme={nextTheme}
                selectedTheme={selectedTheme}
                pingVisible={pingVisible}
                iconAttributes={iconAttributes}
            />
            
            {/* Dev */}
            <Development 
                iconAttributes={iconAttributes}
                selectedTheme={selectedTheme}
                isPastThreshold={isPastThreshold}
                
            />

            

            
                
            </div>
            
        
        </div>
        
    );
}

function Box({children, selectedTheme}) {

    //hover:-translate-y-1
    return (
        <div className={`w-full md:w-1/3 h-auto md:h-full flex flex-col flex-grow gap-4  items-start justify-start relative group
            transition-all duration-500 min-h-[400px] md:min-h-[560px]  ${selectedTheme.textColor} 
            px-6 pt-9 pb-3 ${selectedTheme.bgClasses} ${selectedTheme.boxRadius} font-normal overflow-hidden`}
            style={{fontFamily: `${selectedTheme.fontFamily}`}}
            >
            {children}
        </div>
    )
}

function ProductStrategy({
    selectedTheme, iconAttributes, 
    isPastThreshold
}) {
    const yellowStickyRef = useRef(null);
    const greenStickyRef = useRef(null);
    const blueStickyRef = useRef(null);

    useEffect(() => {
        if (isPastThreshold) {
            // Apply final styles when the target element is in view
            if (yellowStickyRef.current) {
                yellowStickyRef.current.style.bottom = '5rem';
                yellowStickyRef.current.style.left = '-2rem';
                yellowStickyRef.current.style.rotate = '1deg';
            }

            if (greenStickyRef.current) {
                greenStickyRef.current.style.bottom = '2rem';
                greenStickyRef.current.style.left = '4rem';
                greenStickyRef.current.style.rotate = '-1deg';
            }

            if (blueStickyRef.current) {
                blueStickyRef.current.style.right = '-2rem';
                blueStickyRef.current.style.top = '12rem';
                blueStickyRef.current.style.rotate = '-5deg';
            }
        } else {
            // Reset to the initial styles
            if (yellowStickyRef.current) {
                yellowStickyRef.current.style.bottom = '0';
                yellowStickyRef.current.style.left = '-50%';
                yellowStickyRef.current.style.rotate = '3deg';
            }

            if (greenStickyRef.current) {
                greenStickyRef.current.style.bottom = '-6rem';
                greenStickyRef.current.style.left = '33.33%';
                greenStickyRef.current.style.rotate = '-3deg';
            }

            if (blueStickyRef.current) {
                blueStickyRef.current.style.right = '-100%';
                blueStickyRef.current.style.top = '8rem';
                blueStickyRef.current.style.rotate = '0deg';
            }
        }
    }, [isPastThreshold]);

    const hasStickyColors = selectedTheme.stickyColors;

    return (
        <Box className="relative" selectedTheme={selectedTheme}>
            <h2 className={`text-2xl ${selectedTheme.hFontColor} transition-all duration-150`}
                style={{fontFamily: selectedTheme.hFontFamily}}>
                    Product Strategy</h2>
            <p className="font-normal leading-relaxed">
                While looks are important we focus on the UX first and add all the bells and whistles later.
            </p>
            <div className="flex flex-row gap-3 items-center mt-3">
                <Icon icon={'rocket'} {...iconAttributes}  /> MVP Definition
            </div>
            <div className="flex flex-row gap-3 items-center">
                <Icon {...iconAttributes} icon='list' /> Product Scoping
            </div>
            <div className="flex flex-row gap-3 items-center">
                <Icon icon={'smiley'} {...iconAttributes} /> UX Research
            </div>

            {/* yellow sticky */}
            <div
                ref={yellowStickyRef}
                
                className={`hidden md:absolute -left-1/2 w-32 h-32 rotate-3 bottom-0 transition-all duration-1000 z-0 md:flex md:flex-col items-center justify-start p-5 text-sm font-semibold select-none
                    ${hasStickyColors ? selectedTheme.stickyColors.yellow : 'bg-amber-200  shadow-md handlee'} 
                    `}
                >
                User Stories
            </div>

            {/* green sticky */}
            <div
                ref={greenStickyRef}
                
                className={`hidden md:absolute left-1/3 w-32 h-32 -rotate-3 -bottom-3 transition-all 
                    ${hasStickyColors ? selectedTheme.stickyColors.green : 'bg-lime-200  shadow-md handlee'} 
                    duration-1000 z-10  md:flex md:flex-col items-center justify-start p-5 text-sm font-semibold select-none`}
            >
                Journey Map
            </div>

            {/* blue sticky */}
            <div
                ref={blueStickyRef}
                className={`hidden md:absolute right-8 w-32 h-32 -rotate-3 top-48 transition-all duration-1000 z-10 md:flex md:flex-col items-center justify-start p-5 text-sm font-semibold select-none
                    ${hasStickyColors ? selectedTheme.stickyColors.blue : 'bg-indigo-200 shadow-md handlee'} 
                    `}
            >
                hello, world
            </div>
        </Box>
    );
}

function Design({
    nextTheme,
    selectedTheme,
    pingVisible, 
    iconAttributes, 
}) {
    
    
    
    return (
        <div className={`w-full md:w-1/3 h-auto md:h-full flex flex-col flex-grow gap-4  items-start justify-between relative group
            transition-all duration-500 min-h-[560px]  ${selectedTheme.textColor} 
            px-6 pt-9 pb-3 ${selectedTheme.bgClasses} ${selectedTheme.boxRadius} font-normal overflow-hidden`}
            style={{fontFamily: `${selectedTheme.fontFamily}`}}
            >
                <div className="w-full flex flex-col gap-4 items-start justify-start">
                <h2 className={`text-2xl ${selectedTheme.hFontColor} transition-all duration-150`}
                style={{fontFamily: `${selectedTheme.hFontFamily}, Inter, sans-serif`}}
                >UX/UI Design</h2>
                <p className="leading-relaxed text-balance ">
                    User-centered approach that balances aesthetics, functionality and design principles.
                </p>
                <div className="flex flex-row gap-3 items-center mt-3 transition-all duration-150">
                        <Icon 
                            icon='flower' 
                            {...iconAttributes}
                        /> Scalable Design System
                </div>
                <div className="flex flex-row gap-3 items-center transition-all duration-150">
                        <Icon
                            icon={selectedTheme.isDark ? 'moon' : 'sun'}
                            {...iconAttributes}
                            /> 
                            Dark/Light Themes
                </div>
                <div className="flex flex-row gap-3 items-center transition-all duration-150">
                        <Icon
                            icon={'smartphone'}
                            {...iconAttributes}
                            /> Desktop & Mobile
                </div>
                
                <div className="flex flex-row gap-3 items-center transition-all duration-150">
                        <Icon
                            icon={'code'}
                            {...iconAttributes}/>  Optimized for development
                </div>
                </div>  

                

                <div className="flex flex-col items-center justify-between gap-4 w-full"
                style={{height: '10rem'}}
                >
                
                <span className="text-xs opacity-0 h-6 mx-auto">
                    {selectedTheme.name}
                </span>
                <button className={`${selectedTheme.buttonStyles} transition-all duration-500 ease-out select-none z-10`} onClick={nextTheme}
                >
                
                    Click Me
                </button>
                {pingVisible && 
                <span class="
                animate-ping absolute inline-flex h-1/2 aspect-square rounded-full bg-black opacity-10 z-0"></span>}
                <span className="text-xs opacity-50 h-6 mx-auto">
                    {selectedTheme.name}
                </span>
                </div>
                
            </div>
    )
}

function Development({
    iconAttributes,
    selectedTheme,
    isPastThreshold
}) {
    
    return (
        <Box id="development" className="relative" selectedTheme={selectedTheme}>
            <div
                className={`hidden md:flex
                    md:absolute w-full h-1/2 md:h-full right-0 -bottom-4
                    transition-all duration-500
                    bg-no-repeat bg-[length:120%] md:bg-[position:50%_112%]
                    origin-bottom-right
                    ${selectedTheme.imageFilter}
                    ${isPastThreshold ? 'scale-100' : 'scale-75'} 
                `}
                style={{
                    backgroundImage: `url(${devLogos})`,
                }}
            />

<h2 className={`text-2xl ${selectedTheme.hFontColor} transition-all duration-150`}
                style={{fontFamily: `${selectedTheme.hFontFamily}`}}>Development</h2>
            <p className="text-balance leading-relaxed">
                We work with the latest technologies that offer high performance and an active developer community.
            </p>
            <div className="flex flex-row gap-3 items-center mt-3">
                <Icon icon={'globe'} {...iconAttributes} /> Hosting & Deployment
            </div>
            <div className="flex flex-row gap-3 items-center">
                <Icon icon='cube' {...iconAttributes}/> Architecture Design
            </div>
            <div className="flex flex-row gap-3 items-center">
                <Icon icon={'layers'} {...iconAttributes} /> Modern Stack
            </div>
        </Box>
    );
}