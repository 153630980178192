import { useState } from "react";

export default function ShortString({ propValue, onSave, propKey }) {
    const [value, setValue] = useState(propValue);

    function handleBlur(e) {
        onSave(propKey, e.target.value);
    }

    function handleInputChange(e) {
        setValue(e.target.value);
    }

    return (
            <input
                className='bg-base-100 w-full rounded-md px-2.5 py-0.5 border focus:ring-1 focus:ring-primary focus:bg-white transition-all'
                value={value || ''}
                onChange={handleInputChange}
                onBlur={handleBlur}
            />
    );
}
