import React from 'react';
import Icon from '../../../../../components/icons/Icon';

export default function SelectWidth({ propKey, widthOptions, currentWidth, onWidthSelect }) {

  const handleWidthSelect = (value) => { 
    onWidthSelect(propKey, value);
  };

  
  return (
    <div 
        className='items-center flex flex-row flex-grow w-full rounded-md p-0.5  bg-base-100 hover:bg-base-200 text-sm transition-all duration-150
        ring-1 ring-base-200
        '
        style={{userSelect: 'none'}}
      >
        {widthOptions.map((width) => (
          <button
            key={width}
            className={`w-full pointer text-sm flex items-center justify-center rounded-md py-1  transition-all duration-150 lowercase
            ${currentWidth === width ? `bg-base-0 ring-1 ring-base-200 `
            : `opacity-80 hover:opacity-100`}`}
            style={{
              fontWeight: 500,
              flexGrow: 1,
              zIndex: 2
            }}
            onClick={() => handleWidthSelect(width)}
          >
            {width}
          </button>
        ))}
      </div>
  );
};
