import React from 'react'
import PropTypes from 'prop-types'
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import { Button, Link, Icon } from '../index'; /* replace to 'import { Button, Link, Icon } from './index'' */
import { iconMap } from '../media/iconMap'; /* replace to 'import { iconMap } from './iconMap'' */

const allIconNames = Object.keys(iconMap) || []

// needs mobile behavior
export default function Alert(props) {
    
    const {
        type = 'base',
        size = 'medium',
        icon = 'none',
        hasCloseButton = false,
        text = 'This is an alert message',
        style = 'light',
        actionText = '',
        actionType = 'link',
        title = '',
        width = 'auto',
        onActionClick,
        attributes,
        listeners
      } = props;
    const { junoAttributes, showTag, outlineStyle, onPropertyUpdate=()=>{} } = props.junoProps || {} /* remove line */

    const styleMap = {
        'filled': type == 'base' ? `bg-base-content text-base-0` : `bg-${type}-content text-base-0` ,
        'outline': type == 'base' ? `text-base-600 ring-1 ring-inset ring-base-300` : `text-${type}-content ring-1 ring-inset ring-${type}-content`,
        'light': type == 'base' ? `bg-base-100 text-base-content` : `bg-${type}  text-${type}-content`
    }

    const typeStyles = `${styleMap[style]}`
    
    const sizeStyles = size == 'small' ? `py-2 px-2 gap-2 text-xs` : `py-2.5 px-4 gap-3 text-sm`;
    const widthStyle = width == 'auto' ? `w-auto` : `w-${width}`
    const cornerStyles = size == "small" ? "rounded" : size == "large" ? "rounded-lg" : "rounded-md"

    let wrapperClasses = `flex flex-row items-start justify-between transition-all duration-100 ${typeStyles} ${sizeStyles} ${cornerStyles} ${widthStyle}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */

    const iconStyleMap = {
        info: 'info',
        error: 'warning',
        base: 'info',
        warning: 'warning',
        success: 'check-circle',
    };
    
    const truncateStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}
    
    const useIcon = icon == 'auto' ? iconStyleMap[type] : icon;
    
    const IconComponent = icon !== 'none' ? <Icon icon={useIcon?.toLowerCase()}  className='flex-shrink-0' /> : null;

    return (
        <div 
            {...attributes} {...listeners} 
            {...junoAttributes} /* remove line */
            className={wrapperClasses}
        >   
            {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Alert</div> /* remove line */}
            {IconComponent}
            <div className={`flex flex-col flex-grow-1 w-full items-start ${size == 'small' ? 'gap-0.5' : 'gap-1'}`}>
                {title && title != '' && <h2 className='font-semibold' style={truncateStyle}>
                    {<EditableOnDoubleClick text={title} whiteSpace='nowrap' onBlur={onPropertyUpdate('title')}/> /* replace to '{title}' */}
                </h2>}
                {<EditableOnDoubleClick text={text} onBlur={onPropertyUpdate('text')}/> /* replace to '{text}' */}
                {(actionText && actionText != '') ? 
                actionType == 'button' ?
                <Button
                    text={actionText} 
                    size={'small'}
                    type={type == 'base' ? 'secondary' : type}
                    style={'filled'}
                    marginTop={'6px'}
                    onClick={onActionClick}
                    junoProps={{onPropertyUpdate:  () => onPropertyUpdate('actionText') }} /* remove line */
            /> : 
                <Link 
                    text={actionText} 
                    onClick={onActionClick}
                    underline='always'
                    junoProps={{onPropertyUpdate:  () => onPropertyUpdate('actionText') }} /* remove line */
                    />
                    : null}
                
            </div>
            
            {hasCloseButton && <Icon icon='close' className='flex-shrink-0 mt-0.5'/>}   
        </div>
    ); 
    
}

Alert.propTypes = {
    width: PropTypes.oneOf(["auto", "1/2", "full"]),
    style: PropTypes.oneOf(['filled', 'outline', 'light']),
    text: PropTypes.string.isRequired,
    title: PropTypes.string,
    type: PropTypes.oneOf(['info', 'base', 'error', 'warning', 'success']),
    actionText: PropTypes.string,
    actionType: PropTypes.oneOf(['button', 'link']),
    size: PropTypes.oneOf(['small', 'medium']),
    icon: PropTypes.oneOfType([
        PropTypes.oneOf(['none', 'auto', ...allIconNames]),
        PropTypes.string
    ]),
    hasCloseButton: PropTypes.bool,
    children: PropTypes.node
};

/* ignore rest */

Alert.stories = [
    {
        name: 'Info',
        wrapperClasses: 'flex flex-col w-[320px] h-auto p-4 bg-base-0',
        props: {
            type: 'info',
            text: 'This is an alert message type info',
        }
    }, 
    {
        name: 'Warning', 
        wrapperClasses: 'flex flex-col w-[320px] h-auto p-4 bg-base-0',
        props: {
            type: 'warning',
            text: 'This is an alert message type warning',
        }
    }

]

Alert.definitions = {
    apiName: 'Alert',
    displayName: 'Alert',
    description: 'A flexible alert component for displaying important messages with options for icon, type, size, text, action, and the inclusion of a close button. Supports different styles and interactive elements.',
    ai_instructions: 'message-like alert that can appear next to text or in forms. can have call to action and a close button. Different from Banner',
    type: 'indicators',
    relativeSize: 'small',
    acceptedChildren: 'none',
    propDefinitions: {
        title: {
            type: 'string',
            displayName: 'Title',
            default: '', 

        },
        text: {
            type: 'longString',
            isRequired: true,
            displayName: 'Message',
            default: 'This is an alert message'
        },
        actionText: {
            type: 'string',
            displayName: 'Action',
            default: '', 
            tile: '1/2'
        },
        actionType: {
            type: 'oneOf',
            options: ['button', 'link'],
            default: 'link', 
            tile: '1/2'
        },
        
        width: {
            type: "width",
            options: ["auto", "1/2", "full"],
            displayName: "Width",
            default: "auto", 
            defaultOnMobile: 'full', 
            tile: '1/2'
        },

        size: {
            type: 'size',
            options: ['small', 'medium'],
            displayName: 'Size',
            default: 'medium', 
            tile: '1/2'
        },
        type: {
            type: 'oneOf',
            options: ['info', 'base', 'error', 'warning', 'success'],
            displayName: 'Type',
            default: 'info', 
            tile: '1/2'
        },
        style: {
            type: 'oneOf',
            options: ['filled', 'outline', 'light'],
            displayName: 'Style',
            default: 'filled', 
            tile: '1/2'
        },
        icon: {
            type: 'icon',
            options: ['none', 'auto', ...allIconNames],
            displayName: 'Left Icon',
            default: 'none'
        },
        hasCloseButton: {
            type: 'bool',
            displayName: 'Close Button',
            default: false
        }
    }
};