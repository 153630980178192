import { useContext, useState } from "react";
import { EditorContext } from "../EditorContext";
import UserContext from "../../../UserContext";

export default function UserMenu() {
    const {user} = useContext(UserContext);
    const { view, project, setView } = useContext(EditorContext);
    const firstName = user?.firstname;
    const lastName = user?.lastname;
    
    return (
        <div className="flex flex-row gap-2 items-center justify-between transition-all group"
        onClick={() => setView({...view, settings: 'user'})}    
        >
            
            <div className={`w-7 h-7  rounded-full flex-shrink-0 flex text-xs items-center justify-center font-medium
            transition-all duration-75 hover:scale-110 ring-1 ring-slate-800
            bg-slate-700 hover:bg-slate-600 text-slate-200 hover:text-slate-50 hover:font-semibold `}>
                    {firstName ? firstName[0].toUpperCase() : 'U'}
            </div>
            
            
        </div>
    );
}
