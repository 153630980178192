import { Check, HomeSimpleDoor, Laptop, Link, NavArrowLeft, NavArrowRight, Notes, Phone, SmartphoneDevice } from "iconoir-react";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Logo } from "../../ui-kit/local";
import CopyLinkButton from "./CopyLinkButton";

export default function ShareFrameHeader({ showCode, setShowCode, isMobile, setIsMobile, project, selectedFrame, narrowViewport, 
  showNotes, setShowNotes, assets
}) {
  
      
    const tooltipStyle = `absolute opacity-0 group-hover:opacity-100 whitespace-nowrap
    w-auto bg-base-700 text-base-50 font-medium rounded px-2 py-1 text-2xs invisible group-hover:visible
    -bottom-5 mb-2 transition-all`
    const navigate = useNavigate();

    return (
      <div className="flex flex-row justify-between gap-2 py-1 w-full text-xs">
        {/* Title & Logo */}
        <div className="flex flex-row items-center gap-2 w-full md:w-1/4 relative z-50">
          <div className="group relative bg-base-100 rounded-md w-6 h-6 flex items-center justify-center
          hover:bg-base-200 transition-all cursor-pointer "
          title="JUNO"
          //ring-[0.5px] ring-base-300
          onClick={() => navigate('/')}
          >
            <HomeSimpleDoor />
          </div>
          <div className="group relative rounded-md w-5 h-5 flex items-center justify-center">
          <Logo size={'20px'} type={'symbol'} assets={assets}/>
          </div>
          <h1 className="select-none font-medium h-6 px-1 flex items-center whitespace-nowrap">
          {selectedFrame?.name}  
          </h1>
        </div>
        <div className="flex flex-row justify-center gap-2 text-xs items-center font-medium">
        
        
        </div>

        {!narrowViewport && (<div className="flex flex-row justify-end items-center gap-2  w-1/4">
            <button
              className={`${showNotes && "bg-base-200"} w-6 h-6 flex items-center justify-center transition-all rounded-md hover:scale-110 text-slate-700 group relative z-50 hover:bg-base-100`}
              onClick={() => { setShowNotes(!showNotes); setShowCode(false)}}
            >
              <Notes />
              <span className={` ${tooltipStyle} right-0 transform translate-y-full  `}
              style={{ zIndex: 1001 }}>
                Notes
              </span>
            </button>
            
            <CopyLinkButton tooltipStyle={tooltipStyle}/>
          </div>
        )}
      </div>
    );
  }



