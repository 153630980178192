import { useContext, useEffect, useRef, useState } from 'react';
import { EditorContext } from '../EditorContext';
import { Check, EmojiSad, OpenNewWindow, Screenshot } from 'iconoir-react';
import Spinner from '../../../components/Spinner';
import axios from 'axios';

export default function ShareButton() {
    const { project, selector } = useContext(EditorContext);
    const [isOpen, setIsOpen] = useState(false);  // State to manage dropdown visibility
    
    const dropdownRef = useRef(null);  // Ref to help with detecting outside clicks

    useEffect(() => {
        // Function to handle clicking outside the dropdown
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            // Attach the listener if the dropdown is open
            document.addEventListener("mousedown", handleClickOutside);
        }

        // Clean up
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    const tooltipStyle = `absolute opacity-0 group-hover:opacity-100 invisible group-hover:visible whitespace-nowrap
    w-auto bg-gray-700 text-gray-50 font-medium rounded px-2 py-1 text-xs
    -bottom-5 mb-2 transition-all`

    const isEmail = selector.frame?.objects?.find(obj => obj.componentAPIName == 'Email');
    return (
        <div className='relative flex flex-row items-center font-medium text-xs transition-all duration-75 rounded-lg'
        ref={dropdownRef}
        >
            <button className={`px-2 h-7 flex items-center justify-center rounded-lg group cursor-pointer bg-slate-200 ring-[0.5px] ring-slate-400 ring-inset`}
                    onClick={() => setIsOpen(!isOpen)}>  
                Share
                {!isOpen && 
                <span className={` ${tooltipStyle} right-0 transform translate-y-full `} style={{ zIndex: 1001 }}>
                Share Options
                </span>}
            </button>
            {isOpen && (  
                <div className='absolute -bottom-3 translate-y-full min-w-full bg-white right-0 flex flex-col p-1 rounded-lg
                border border-slate-200 text-gray-800 font-medium'
                
                >
                    
                    <button className='whitespace-nowrap px-3 py-2 hover:bg-slate-100 rounded-md flex flex-row gap-2 items-center'
                            disabled={!selector.frame}
                            onClick={() => window.open(`/share/frame/${selector.frame.id}`, '_blank')}>
                        <OpenNewWindow height={16} width={16} style={{ strokeWidth: 2 }} />
                        Preview {isEmail ? 'Email' : 'Page'}
                    </button>
                    <button className='whitespace-nowrap px-3 py-2 hover:bg-slate-100 rounded-md flex flex-row gap-2 items-center'
                            disabled={!selector.page}
                            onClick={() => window.open(`/share/feature/${selector.page.id}`, '_blank')}>
                        <OpenNewWindow height={16} width={16} style={{ strokeWidth: 2 }} />
                        Preview Feature
                    </button>
                    {/*<button className='whitespace-nowrap px-3 py-2 hover:bg-slate-100 rounded-md flex flex-row gap-2 items-center'
                            onClick={() => window.open(`/share/project/${project.project.id}`, '_blank')}>
                    <OpenNewWindow height={16} width={16} style={{ strokeWidth: 2 }} />
                        Preview Project
                    </button>*/}

                    <div className='w-full h-0 border-t border-slate-100 mx-auto my-1'></div>
                    <SaveImage />
                    
                </div>
            )}
        </div>
    );
}


function SaveImage() {
    const {selector} = useContext(EditorContext);
    const [generationStatus, setGenerationStatus] = useState('idle');  // 'loading', 'success', 'error', 'idle'

    const frameId = selector.frame ? selector.frame.id : null;
    const frameName = selector.frame ? selector.frame.name : 'default';
    
    const takeScreenshot = async () => {
        if (!frameId) return;
        setGenerationStatus('loading');

        const payload = { frameId };
    
        try {
            const response = await axios.post('/save-frame-image', payload, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'image/jpeg' }));
            const link = document.createElement('a');
            const fileName = `frame_${frameName.replace(/\s+/g, '_')}.jpeg`; // File extension as .jpeg
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            
            setGenerationStatus('success');
            setTimeout(() => {
                setGenerationStatus('idle');
            }, 2000);
        } catch (error) {
            console.error('Error taking screenshot:', error);
            setGenerationStatus('error');
            setTimeout(() => {
                setGenerationStatus('idle');
            }, 2000);
        }
    };
    
    return (
        <button className={`whitespace-nowrap px-3 py-2 hover:bg-slate-100 rounded-md text-center flex flex-row gap-2 items-center`}
        onClick={takeScreenshot}
        >
            {generationStatus === 'loading' ? (
                <Spinner size='w-4 h-4 border-2' />
            ) : generationStatus === 'success' ? (
                <Check height={16} width={16} style={{ strokeWidth: 2, color: 'green' }} />
            ) : generationStatus === 'error' ? (
                <EmojiSad height={16} width={16} style={{ strokeWidth: 2, color: '#E04C18' }} />
            ) : (
                <Screenshot height={16} width={16} style={{ strokeWidth: 2 }} />
            )}

            Save as PNG
        </button>
    );
}
