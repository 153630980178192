import React, { useContext, useRef, useEffect, useState } from "react";
import { EditorContext } from "../../EditorContext";
import UserFlow from "../flow/UserFlow";
import FlowName from "../insideflow/FlowName";
import ShareButton from "../../header/ShareButton";
import UserMenu from "../../header/UserMenu";
import AddFromLibrary from "../insideflow/AddFromLibrary";
import SearchInput from "../search/OverviewSearch";
import FolderName from "./FolderName";

export default function FolderDisplay({draggedFrame, refreshKey}) {
    const { project, view, set, selector, handleAction } = useContext(EditorContext);
  
    const columnWidth = view.columnWidth
    const folder = selector.folder
    const page = selector.page
    function renameFlow(newName, page) {
      const currentPage = page
      if (!currentPage) return;
      const action = {
          type: 'UPDATE_PAGE',
          currentPage: currentPage,
          newPage: { ...currentPage, name: newName }
        };
    
        handleAction(action);
  }

    const pages = project.pages?.filter((p) => !p.is_archived && p.folder_id === folder.id)

    if (folder && page) return (
      <div className={`relative flex flex-col h-full  w-full relative transition-all max-h-full overflow-hidden pl-1
        border-l-[0.5px] border-slate-300 bg-white box-border h-full`} >

        {/* Header */}
        <Controls /> 

        <div className="w-full flex-row flex gap-2 justify-between items-end text-xl font-medium mb-3 pl-6 pr-3"
        style={{minHeight: 52}}
        >
        <FolderName
                    folder={selector.folder}
                    handleAction={handleAction}
                    />
        <AddFromLibrary
                    selector={selector}
                    handleAction={handleAction}
                    set={set}
                />
        </div>
        <div className="overflow-scroll">
            {pages
                  .filter((p) => !p.is_archived && p.folder_id === folder.id)
                  .sort((a, b) => a.index - b.index)
                  .map((page) => (
                    <div key={page.id} className="flex flex-col gap-0 mb-4">
                    <div className="text-md pl-6 font-medium -mb-2 ">
                    <FlowName 
                    currentName={page.name}
                    onBlur={(newName) => renameFlow(newName, page)}
                    /></div>
                <UserFlow
                      
                      frames={page.frames}
                      page={page}
                      folder={folder}
                      isMobile={false}
                      columnWidth={columnWidth}
                      draggedFrame={draggedFrame}
                      refreshKey={refreshKey}
                    />
                    </div>
                ))}
              </div>
        
      </div>
    );
    }  

    
    //<div className="pl-6">
      //    <AddNewFlow columnWidth={columnWidth-4} onClick={()=>{}}  />
        //  </div>


    
function Controls() {
  const { selector, handleAction, set } = useContext(EditorContext);
  
  return(
    <div className="w-full flex flex-row gap-2 text-lg items-center justify-between px-3 flex-shrink-0"
    style={{ height: 48, maxHeight: 48 }}
    >
      

      {/* Search */}
      <SearchInput />
      
      <div className="w-auto justify-end flex flex-row gap-3 items-center">
      {/*<ZoomOverview />*/}
      <ShareButton />
      <UserMenu />
      
      
      </div>
    </div>
  )
}

