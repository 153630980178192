import { emailModules } from "./modules/emailModules";
import { mainModules } from "./modules/mainModules";
import { sidebarModules } from "./modules/sidebarModules";
import {headerModules} from "./modules/headerModules";


export const modules = [
  { name: 'Sidebar Modules', description: 'Modules used in sidebars', rootComponent: 'Sidebar', templates: sidebarModules },
  { name: 'Main Modules', description: 'Modules used in main content area', rootComponent: 'Main', templates: mainModules },
  { name: 'Email Modules', description: 'Modules used in email templates', rootComponent: 'Email', templates: emailModules}, 
  { name: 'Header Modules', description: 'Modules used in headers', rootComponent: 'Header', templates: headerModules }
  
];