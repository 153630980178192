import { useEffect, useState } from "react";

export default function FlowName({ currentName, onBlur }) {
    
    const [editable, setEditable] = useState(false);
    const [name, setName] = useState(currentName);

    useEffect(() => {
      // Update the name state when the folder prop changes
      setName(currentName);
    }, [currentName]);

    function handleDoubleClick(e) {
      e.stopPropagation();
      setEditable(true);
    }


    function handleInputChange(e) {
      setName(e.target.value);
    }

    return (
      <input 
        type="text"
        value={name}
        onChange={handleInputChange}
        onBlur={(e) => editable && onBlur(name)}
        onClick={handleDoubleClick}
        onKeyDown={(e) => e.key === 'Enter' && e.target.blur()}
        readOnly={!editable}
        className={`bg-transparent focus:bg-white placeholder-gray-700 text-gray-800 focus:text-gray-900
        py-1 overflow-hidden whitespace-nowrap ellipsis truncate transition-all`}
        style={{
          userSelect: !editable ? 'none' : '',
          minWidth: 200,
          textAlign: 'left'
        }}
      />
    )
  }
