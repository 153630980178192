import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'; 
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */

export default function ToggleSwitch(props) { 
    
    const {
        label = 'toggle label',
        type = 'standard',
        isChecked = false,
        size = 'medium',
        attributes,
        listeners
      } = props;
    const { junoAttributes, showTag, outlineStyle, onPropertyUpdate=()=>{} } = props.junoProps || {} /* remove line */

    const [isToggled, setIsToggled] = useState(isChecked);
    useEffect(() => {
        setIsToggled(isChecked);
    }, [isChecked]);

    const sizeStyles = size == 'small' ? `gap-1.5 text-xs` : size == 'large' ? `gap-3 text-base` : `gap-2 text-sm`;

    const typeMap = {
        'primary': `bg-primary text-primary-content`,
        'standard': `bg-base-content text-base-content`,
        'accent': `bg-accent text-accent-content`,
        'warning': 'bg-warning-content text-warning-content',
        'success': 'bg-success-content text-success-content',
        'disabled': 'bg-base-300 text-base-300',
    };

    let wrapperClasses = `flex flex-row items-start ${sizeStyles} cursor-default`
    wrapperClasses += ` ${outlineStyle}` /* remove line */
    
    const switchSizeMap = {
        'small': 'w-7 p-[px]',
        'medium': 'w-8 p-[px]',
        'large': 'w-10 p-[px]',
    }

    const switchStyles = isToggled ? typeMap[type] : `bg-base-300`;
    const switchClasses = `relative flex flex-row justify-${isToggled ? 'end' : 'start'} ${switchStyles} ${switchSizeMap[size]} rounded-full items-center select-none transition duration-200 ease-in`
    const labelClasses = `${type == 'disabled' ? 'opacity-60' : ''} cursor-default`

    const borderMap = {
        'primary': `border-primary`,
        'standard': `border-base-content`,
        'accent': `border-accent`,
        'warning': 'border-warning-content',
        'success': 'border-success-content',
        'disabled': 'border-base-300',
    };
    
    const toggleSize = {
        'small': 18,
        'medium': 21,
        'large': 24
    }
    const toggleSpanStyle = `${type == 'disabled' ? 'bg-base-200 cursor-not-allowed': 'bg-white'} ${isToggled ? borderMap[type] : 'border-base-300'}  rounded-full shadow transform transition ease-in-out duration-200`    
    
    return (
        <div 
            {...attributes} {...listeners} 
            {...junoAttributes} /* remove line */
            className={wrapperClasses}
            /* replace to 'onClick={() => setIsToggled(!isToggled)}' */ 
        >
            {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Switch</div> /* remove line */}
            <div className={switchClasses}>
                <div className={toggleSpanStyle}
                style={{
                    borderWidth: size == 'large' ? '2.5px' : '2px', 
                    height: toggleSize[size],
                    width: toggleSize[size],
                
                }}
                />
            </div>
            {label && <span className={labelClasses} style={{textWrap: 'balance'}}>
            <EditableOnDoubleClick text={label} onBlur={onPropertyUpdate('label')} /* replace to '{label}' */ />
            </span>}
        </div>
    );
}

ToggleSwitch.propTypes = {
    type: PropTypes.oneOf(['primary', 'standard', 'accent', 'warning', 'disabled', 'success']),
    label: PropTypes.string,
    isChecked: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
};

/* ignore rest */
ToggleSwitch.defaultProps = {
    type: 'standard',
    label: 'toggle label',
    isChecked: false,
    size: 'medium',
};

ToggleSwitch.definitions = {
    apiName: 'ToggleSwitch',
        displayName: 'Toggle Switch',
        description: 'A toggle switch component allowing users to switch between two states. Comes in various styles including primary, standard, accent, warning, and success.',
        ai_instructions: 'switch with an optional label',
        type: 'input',
        relativeSize: 'small',
        acceptedChildren: 'none',
        propDefinitions: {
            label: {
                type: 'string',
                displayName: 'Label',
                default: 'toggle label'
            },
            type: {
                type: 'oneOf',
                options: ['primary', 'standard', 'accent', 'warning', 'disabled', 'success'],
                displayName: 'Type',
                default: 'standard', 
                tile: '1/2'
            },
            size: {
                type: 'size',
                options: ['small', 'medium', 'large'],
                displayName: 'Size',
                default: 'medium', 
                tile: '1/2',
            },
            isChecked: {
                type: 'bool',
                displayName: 'Is Toggled',
                default: false
            }, 
            
        }, 
};
