import { useContext, useEffect, useState } from "react";
import UserContext from "../../../../UserContext";
import { EditorContext } from "../../EditorContext";
import { Check, CheckCircle, WarningCircle } from "iconoir-react";
import MakeTemplate from "./MakeTemplate";

export default function FrameEditor({selector}) {
    const { handleAction, isAdmin, project, templates } = useContext(EditorContext);
    
    const currentFrame = selector?.frame;

    // State for input and textarea
    const [frameName, setFrameName] = useState(currentFrame?.name);
    const [frameNotes, setFrameNotes] = useState(currentFrame?.notes || '');
    const [checked, setChecked] = useState(currentFrame?.use_template || false);

    useEffect(() => {
        setFrameName(currentFrame?.name);
        setFrameNotes(currentFrame?.notes || '');
        setChecked(currentFrame?.use_template || false);
    }, [currentFrame]); // Only currentFrame in the dependency array
      

    function updateFrameName() {
        const action = { type: 'UPDATE_FRAME', currentFrame: currentFrame, newFrame: { ...currentFrame, name: frameName }};
        handleAction(action);
    }

    function updateFrameNotes(newNotes) {
        const action = { type: 'UPDATE_FRAME', currentFrame: currentFrame, newFrame: { ...currentFrame, notes: newNotes }};
        handleAction(action);
    }

    function handleUseTemplate() {
        // Toggle the checked state
        const newCheckedState = !checked;
        setChecked(newCheckedState);
    
        // Dispatch the action with the new state
        const action = { 
            type: 'UPDATE_FRAME', 
            currentFrame: currentFrame, 
            newFrame: { ...currentFrame, use_template: newCheckedState }
        };
        handleAction(action);
    }
    


    // Handler for name change
    const handleNameChange = (e) => {
        setFrameName(e.target.value);
    };

    // Handler for notes change
    const handleNotesChange = (e) => {
        setFrameNotes(e.target.value);
    };

    // Handler for saving notes
    const handleSaveNotes = () => {
        updateFrameNotes(frameNotes);
    };
    
    return (
        <div className="flex flex-col items-start gap-3 pt-1 text-sm py-2.5 px-3">
            <span className="text-xs capitalize -mb-1">Frame Name</span>
            <input
                type="text"
                value={frameName}
                onChange={handleNameChange}
                onBlur={updateFrameName}
                className="w-full py-2 px-3 bg-slate-100 focus:bg-slate-200 rounded-md transition-colors ease-in-out duration-200 flex-shrink-0"
                placeholder="Frame Name"
            />
            { isAdmin && <APIName frameName={frameName} project={project} currentFrame={currentFrame} />}
            <span className="text-xs capitalize -mb-1">Notes</span>
            <textarea
                value={frameNotes}
                onChange={handleNotesChange}
                onBlur={handleSaveNotes}
                className="appearance-none resize-none w-full py-2 px-3 bg-slate-100 focus:bg-slate-200 rounded-md transition-colors ease-in-out duration-200 "
                placeholder="Add page notes, e.g. description or notes for the developer"
                rows={4}
            />
            <AddToFavorites is_favorite={currentFrame.is_favorite} handleAction={handleAction} currentFrame={currentFrame}/>
            { isAdmin && <div className="flex flex-row gap-2 text-slate-500"
            onClick={handleUseTemplate}
            >
                <span
                className={`w-5 h-5 border rounded-md flex items-center justify-center ${checked ? 'bg-slate-500 border-slate-500' : 'bg-white border-gray-400'}`}>
                {checked && (
                    <svg className="w-5 h-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                    )}
                </span>
            
                use template
                </div>}
                {isAdmin && 
                <code
                className="px-2 py-1 rounded cursor-pointer select-text bg-slate-100 text-xs"
                onClick={() => navigator.clipboard.writeText(selector.frame?.id)}
                >{selector.frame?.id}</code>}
                {selector.object && isAdmin &&
                    <MakeTemplate selector={selector} templates={templates}/>
                }
        </div>
    );
}

function APIName({ frameName, currentFrame,  project}) {

    const allFolders = project?.folders.sort((a, b) => a.index - b.index);
    const allFlows = project?.pages.filter(p => allFolders.map(f=>f.id).includes(p.folder_id)).sort((a, b) => a.index - b.index);
    const allFrames = allFlows?.flatMap(f => f.frames).filter(frame => !frame.isArchived).sort((a, b) => a.index - b.index);
    
    
    const formatName = (name) => {
        return name
            .replace(/[•–]/g, ' ') // Replace special characters with space
            .replace(/-/g, '_') // Replace hyphens with underscores
            .split(' ')
            .map((word, index) => index === 0 ? word.charAt(0).toLowerCase() + word.slice(1) : word.charAt(0).toUpperCase() + word.slice(1))
            .join('')
            .replace(/[^a-zA-Z0-9_]/g, ''); // Allow underscores in the final step
    };
    
    const apiName = formatName(frameName)
    const allFramesAPI = allFrames.filter(frame => frame.id !== currentFrame.id).map(frame => formatName(frame.name));
    const isUnique = !allFramesAPI.includes(apiName);

    return <span className={`w-full py-1 px-3 font-medium font-mono rounded-md text-xs whitespace-nowrap truncate flex flex-row gap-2 items-center justify-between
    ${isUnique ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'}
    `}>
        api: {apiName} 
        {isUnique ? <Check className="w-4 h-4"/> : <WarningCircle className="w-4 h-4"/>}
        </span>

}

function AddToFavorites({is_favorite, handleAction, currentFrame}) {
    function toggleFavorite() {
        
        // Dispatch the action with the new state
        const action = { 
            type: 'UPDATE_FRAME', 
            currentFrame: currentFrame, 
            newFrame: { ...currentFrame, is_favorite: !is_favorite }
        };
        handleAction(action);
    }
    
    return (<div className="flex flex-row gap-2 text-slate-500"
                onClick={toggleFavorite}
            >
                <span
                className={`w-5 h-5 border rounded-md flex items-center justify-center ${is_favorite ? 'bg-slate-500 border-slate-500' : 'bg-white border-gray-400'}`}>
                {is_favorite && (
                    <svg className="w-5 h-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                    )}
                </span>
            
                add to favorites
                </div>
    )
}