import React from 'react';
import PropTypes from 'prop-types';
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */

export default function StatusCard(props) {

    const {
        title = "Status",
        value = "Active",
        textSize = 'small',
        statusColor = 'success-content',
        helpText = null,
        attributes,
        listeners,
      } = props;
      
    const { junoAttributes, showTag, outlineStyle, onPropertyUpdate=()=>{}, preview} = props.junoProps || {} /* remove line */

    const sizeStyles = `w-full h-auto ${textSize == 'small' ? 'text-sm' : 'text-base'}`
    const truncateStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}

    let wrapperClasses = `flex flex-row items-stretch justify-start gap-3 ${sizeStyles}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */

    const titleFont = textSize == 'small' ? 'text-base' : 'text-lg';
    const smallerFont = textSize == 'small' ? 'text-sm' : 'text-base';

    return (
        <div 
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
        className={wrapperClasses}>
        {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Status Card</div> /* remove line */ }
        
        {/* Status Circle */}
        <div className={`flex-shrink-0 w-5 h-5 mt-1 flex items-center justify-center rounded-full`}
        style={{backgroundColor: `color-mix(in srgb, var(--${statusColor}) 50%, transparent)`}}>
            <div className={`flex-shrink-0 w-3 h-3 rounded-full`} style={{backgroundColor:`var(--${statusColor})`}}/>
        </div>

        {/* CONTENT BLOCK */}
        <div className={`flex flex-col flex-grow items-start ${smallerFont} gap-1.5`} style={truncateStyle}>
            
            {title && <div className={`flex-shrink-0 flex flex-row items-center relative group`}>
                <EditableOnDoubleClick text={title} onBlur={onPropertyUpdate('title')} whiteSpace="nowrap" /* replace to '{title}' */ />
            </div>}

            {/* Value */}
            <h3 className={`${titleFont} flex flex-row font-semibold px-3 rounded`} 
            style={{
                ...truncateStyle, 
                color: `var(--${statusColor})`,
                backgroundColor: `color-mix(in srgb, var(--${statusColor}) 12%, transparent)`
            }}>
                {preview ? value : <EditableOnDoubleClick text={value} onBlur={onPropertyUpdate('value')} whiteSpace="nowrap"/> /* replace to '{value}' */}
            </h3>
            

            {/* Description */}
            {helpText && <div className={smallerFont} style={truncateStyle}>
                {preview ? helpText : <EditableOnDoubleClick text={helpText} onBlur={onPropertyUpdate('helpText')} whiteSpace="nowrap"/> /* replace to '{helpText}' */}
            </div>}
        </div>
        </div>
    );
}


StatusCard.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    textSize: PropTypes.oneOf(['small', 'medium']),
    statusColor: PropTypes.oneOf(['primary', 'accent', 'info-content', 'error-content', 'success-content', 'warning-content']),
    helpText: PropTypes.string,
};


/* ignore rest */

StatusCard.definitions = {
    apiName: 'StatusCard',
    displayName: 'Status Card',
    description: `Shows status of a specific process. Similar in size to DataCard, but should be used for data display`,
    ai_instructions: `Shows status of a specific process. Similar in size to DataCard, but should be used for data display`,
    type: 'cards',
    relativeSize: 'medium',
    acceptedChildren: 'none',
    propDefinitions: {
        title: {
            type: 'string',
            default: "Status",
            displayName: 'Title',
        }, 
        value: {
            type: 'string',
            default: "Active", 
            displayName: 'Value',
            required: true
        }, 
        helpText: {
            type: 'string',
            default: "Updated Feb 03, 2024 • 8:00 AM GMT+8",
            ai_instructions: null,
        },
        textSize: {
            type: 'size',
            options: ['small', 'medium'],
            ai_instructions: 'small sets title, price to text-sm and description to text-xs, medium sets title and price to text-base and description to text-sm',
            displayName: 'Text Size',
            default: 'small'
        },
        
        statusColor: {
            type: 'colors',
            options: ['primary', 'accent', 'info-content', 'error-content',
            ,'success-content', 'warning-content'],
            displayName: 'Status Color',
            default: 'success-content'
        },
        
    }
};
