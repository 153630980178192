import { Search, Slash } from "iconoir-react"
import { useState } from "react"

export default function SearchInput() {
    const [isFocused, setIsFocused] = useState(false)
    return (
      <div className={`relative group flex flex-row gap-2 items-center w-full max-w-60 ${isFocused ? 'bg-base-100' : 'bg-base-0 hover:bg-base-50'} 
        rounded-md px-2 ml-1 py-2 transition-all duration-75`}
  
        style={{minWidth: 200, maxWidth: 320}}
        
        >
        <Search width={16} height={16} className="scale-90 stroke-[2px] flex-shrink-0"/>
        <input 
          type="text" 
          placeholder="Search" 
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className="w-full bg-transparent border-none text-xs font-normal placeholder-gray-500"
        />
        <Slash width={16} height={16} className={`scale-90 stroke-[2px] flex-shrink-0 opacity-0
        ${isFocused ? 'opacity-80' : 'group-hover:opacity-80'}
        `}/>
        </div>
    )
  }