import React, { useState, useEffect, useContext, useRef } from 'react';
import { EditorContext } from '../../EditorContext';
import { AlignLeft, ArrowDown, ArrowDownTag, ArrowUpTag, Calendar, CheckCircle, CheckSquare, Cube, Dialpad, Emoji, Flower, Iconoir, InputField, LayoutLeft, Link, MediaImage, Menu, MouseButtonLeft, NavArrowDown, NavArrowRight, Page, Plus, Reports, Table2Columns, Text, TextBox, ViewGrid } from 'iconoir-react';
import DnDContext from '../../dragNdrop/DnDContext';
import Keypad from './Keypad';

export default function UIKit({setPortalData}) {
  const { selector, assets, effectiveVariables, handleAction } = useContext(EditorContext);
  const { handleDragStart, handleDragEnd } = useContext(DnDContext);
  
  const emptyFrame = selector.frame?.objects?.length == 0

  const junoControls = {
    assets,
    selector, 
    frameId: selector.frame?.id,
    effectiveVariables,
    handleAction,
    handleDragStart,
    handleDragEnd, 
  }


  const [showGallery, setShowGallery] = useState(true)

  return (
    <div className='w-full h-full flex flex-col justify-start gap-2' style={{   userSelect: 'none' }}>
      <Keypad {...junoControls} />
      
      
      {/*<div className='flex flex-row gap-2 px-2 py-1 rounded-md  bg-slate-100 text-xs items-center justify-between ring-1 ring-slate-200'
      onClick={() => setShowGallery(!showGallery)}>
        {showGallery ? 'Back' : 'Browse All'} 
        <NavArrowRight width={12} height={12} />
      </div>*/}
    </div>
  );
}
