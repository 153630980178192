import React, { useState, useEffect, useContext } from 'react';
import { EditorContext } from '../../../EditorContext';
import DnDContext from '../../../dragNdrop/DnDContext';
import Categories from './Categories';
import Category from './Category';



export default function ElementsTemplates({categories}) {
  const { selector, view, effectiveVariables, dndState, handleAction} = useContext(EditorContext);
  const { handleDragStart, handleDragEnd } = useContext(DnDContext);
  const frameId = selector.frame?.id
  
  const [selectedOption, setSelectedOption] = useState(null);
  
  /*useEffect(() => {
    setSelectedOption(null)
  }
  , [categories])*/
  
  
  return (
    <div className='w-full h-full flex flex-col gap-2 ' style={{  overflow: 'hidden', userSelect: 'none' }}>
      {!selectedOption ? 
      <Categories 
        categories={categories} 
        onSelect={setSelectedOption}  
        effectiveVariables={effectiveVariables}
        /> : 
      <Category
      category={selectedOption} 
      onBack={() => setSelectedOption(null)} 
      frameId={selector.frame?.id} 
      dndState={dndState}
      selector={selector} 
      view={view} 
      effectiveVariables={effectiveVariables} 
      handleAction={handleAction} 
      handleDragStart={handleDragStart} 
      handleDragEnd={handleDragEnd}

      />
      }
    </div>
  );
}




