import { useEffect, useState } from "react";


export default function FrameName({ currentName, onBlur }) {
    
    const [name, setName] = useState(currentName);
  
    useEffect(() => {
      setName(currentName);
    }, [currentName]);
  
    function handleBlur(e) {
      e.stopPropagation();
      onBlur(name);
    }
  
    return (
      
      <input 
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onBlur={(e) => handleBlur(e)}
        onKeyDown={(e) => e.key === 'Enter' && e.target.blur()}
        className={`bg-transparent text-sm font-medium focus:bg-white placeholder-gray-700 text-gray-800 focus:text-gray-900
        px-1 overflow-hidden whitespace-nowrap ellipsis truncate transition-all
        cursor-default select-none focus:cursor-text
        `}
        style={{

          minWidth: 200,
          textAlign: 'left'
        }}
      />

    )
  }
  
  