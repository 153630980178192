import SearchResult from "./SearchResult";

export default function SearchResults({searchResults,
  effectiveVariables, assets, handleDragStart, handleDragEnd

}) {

  return (
    <div className='w-full h-full flex flex-col' style={{  overflow: 'scroll' }}>
    
    {searchResults?.length === 0 ? 
      <div className="bg-base-100 px-3 py-2 text-xs rounded-md">No search results</div>
     :
    
    <div className='flex flex-col relative rounded-lg gap-2' style={{ height: '100%', position: 'relative' }}>
    <div className='absolute bg-gradient-to-b from-white to-transparent top-0 w-full h-3 z-10'/>
    <div className='overflow-auto pt-2 flex flex-row flex-wrap gap-2 items-start justify-start' style={{ maxHeight: '100%' }}>
        {searchResults?.slice(0, 10)
        .map((template, index) => (
            
            <SearchResult 
              component={template} 
              key={index} 
              effectiveVariables={effectiveVariables}
              assets={assets}
              handleDragStart={handleDragStart}
              handleDragEnd={handleDragEnd}

              />
          
          ))}</div>
          
      </div>}
      </div>
    )
  }
  
  
  