import React from 'react';
import * as IconoirIcons from 'iconoir-react';

export default function SelectDirection({ propKey, directionOptions, currentDirection, onDirectionSelect }) {

  const handleDirectionSelect = (value) => {
    onDirectionSelect(propKey, value);
  };

  const directionOptionsIcons = {
    "grid": "ViewGrid",
    "flex-row": "ArrowRight",
    "flex-col": "ArrowDown",
  };

  return (
    <div 
      className='items-center flex flex-row flex-grow w-full rounded-lg p-1 bg-base-100 hover:bg-base-200 text-sm transition-all duration-150'
      style={{userSelect: 'none'}}
    >
      {directionOptions.map((direction) => {
        const iconName = directionOptionsIcons[direction];
        const DynamicIcon = IconoirIcons[iconName];

        return (
          <button
            key={direction}
            className={`w-full pointer text-sm flex items-center justify-center rounded-md py-1  transition-all duration-150 lowercase
            ${currentDirection === direction ? `bg-base-0 ring-1 ring-base-100 `
            : `opacity-80 hover:opacity-100`}`}
            style={{
              fontWeight: 500,
              flexGrow: 1,
              zIndex: 2
            }}
            onClick={() => handleDirectionSelect(direction)}
          >
            {DynamicIcon && <DynamicIcon height={16} width={16} />}
          </button>
        );
      })}
    </div>
  );
};
