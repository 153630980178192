import { useContext, useState } from "react";
import Controls from "./DSControls";
import Themes from "./themes/Themes";
import Brand from "./brandkit/Brand";
import { convertThemeVariablesToStyles } from "../../canvas/helpers";
import { EditorContext } from "../../EditorContext";
import Install from "./install/Install";
import UIKitDisplay from "./uikit/UIKit";


export default function DesignSystem() {
    const {ds, currentTheme, setDS, effectiveVariables, defaultTheme, selectTheme} = useContext(EditorContext)
    const [currentTab, setCurrentTab] = useState('Themes');
    const tabs = [
        
        {name: 'Themes', isActive: currentTab === 'Themes'},
        {name: 'UI-Kit', isActive: currentTab === 'UI-Kit'},
        {name: 'Logos', isActive: currentTab === 'Logos'},
        {name: 'Install', isActive: currentTab === 'Install'},
    ]

    
    const themes = ds?.themes;
    const [tempTheme, setTempTheme] = useState(null)
    
    const appliedTheme = tempTheme || currentTheme || defaultTheme; 
    const themeStyles = convertThemeVariablesToStyles(appliedTheme?.variables || []);
    
return (
    <div
        className="relative flex flex-col gap-2 w-full pt-8 pb-2 px-4 pl-1 transition-all rounded-lg
        text-slate-700 !text-base-content mx-2 my-2
        border-[0.5px] border-slate-300 !border-base-300
        bg-white !bg-base-0
        box-border h-full
        "
        // bg-white 
        style={{ 
            ...themeStyles,
            overflow: 'hidden', 
            height: 'calc(100vh - 16px)',
            maxHeight: 'calc(100% - 16px)',
            }}>
    
    <Controls currentTab={currentTab} setCurrentTab={setCurrentTab} tabs={tabs} />
    <div className="flex px-2 py-3 overflow-hidden h-full">
        {currentTab === 'Themes' && <Themes themes={themes} tempTheme={tempTheme} appliedTheme={appliedTheme} setTempTheme={setTempTheme}/>}
        {currentTab === 'Install' && <Install appliedTheme={appliedTheme}/>}
        {currentTab === 'UI-Kit' && <UIKitDisplay />}
        {currentTab === 'Logos' && <Brand />}
    </div>
    </div>
)
}


//