import React, { useContext, useRef, useEffect, useState, useCallback } from 'react';
import { EditorContext } from './EditorContext';
import {v1 as uuidv1 } from 'uuid' // for everything else
import { convertJSXToObjectsSync } from '../../utilities/AIhelpers';
import Navigation from './overview/sidebar/Navigation';
import DesignSystem from './overview/designsystem/DesignSystem';
import FlowDisplay from './overview/insideflow/FlowDisplay';
import DragToClose from './overview/SidebarDragToClose';
import { Folder } from 'iconoir-react';
import FolderDisplay from './overview/insidefolder/FolderDisplay';
import ProjectDisplay from './overview/insideproject/ProjectDisplay';

export default function ProjectView({refreshKey}) {
  const { selector, view, project, streaming, activeSticky, setView } = useContext(EditorContext);
  
  
  const noFolderSelected = !selector.folder
  
  function setSidebarOpen(value=false) {
    setView({...view, overviewSidebar: value})
  }
  
  // console.log(selector.folder)
  return (
    <div className={`flex flex-row flex-grow h-full w-full items-start justify-end w-screen`} style={{ maxHeight: '100dvh', maxWidth: '100vw',  overflow: 'scroll'}}>  
    <div className='flex flex-col w-full items-center w-full justify-stretch h-full overflow-y-scroll relative' style={{ zIndex: 100}} > 
      <div className="flex flex-row relative items-start w-full justify-stretch h-full overflow-scroll select-none">  
        {<Navigation />}
        

        
        {view.inOverview == 'designSystem' ? <DesignSystem /> : 
        
        
        (view.inOverview == 'project' && !selector.folder) ? <ProjectDisplay refreshKey={refreshKey}/> :
        view.showAllFolders ? 
        <FolderDisplay refreshKey={refreshKey}/> :
        <FlowDisplay refreshKey={refreshKey}/>
        }
        
        </div>

    </div>
    

</div> 
  );
  }

  {/*<DragToClose dragOffset={220} setIsOpen={setSidebarOpen} view={view} />*/}

  function createInsertFrame(layout) {
    
    let newFrame = {
      id: uuidv1(), 
      objects: [],
      viewport: { width: 800, height: 800 }, 
      name: formatString(layout.name) || 'Untitled Frame', 
      index: null, 
      is_archived: false,
      notes: layout.notes || null, 
      page_id: null
    }
    
    let parsedObjects = convertJSXToObjectsSync(layout?.jsx) || [];
    parsedObjects = parsedObjects.map((o) => 
      o.parent === "rootObject"
        ? { ...o, parent: newFrame.id, frame: newFrame.id }
        : { ...o, frame: newFrame.id }
    );
    newFrame = { ...newFrame, objects: parsedObjects};
    return newFrame;
  }
  

  function formatString(str) {
    // Replace underscores with ' - '
    let formattedStr = str.replace(/_/g, ' - ');
  
    // Add a space before capital letters, considering the newly added spaces from the previous step
    formattedStr = formattedStr.replace(/([A-Z])/g, ' $1');
  
    // Trim any leading space and capitalize the first letter
    formattedStr = formattedStr.trim();
    formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1);
  
    return formattedStr;
  }
  