import React, { useRef, useCallback, useEffect } from 'react';
import * as UIKit from '../../../ui-kit/local';
import { findSectionParent, getChildren } from '../dragNdrop/helpers';
import { getOutlineStyle } from './helpers';
import { isEqual } from 'lodash';


function compareState(prevProps, nextProps) {
    // LIST ALL SITUATIONS WHEN TO RERENDER FRAME
    
    if (!isEqual(prevProps, nextProps)) {
        return false; // Return true to prevent a re-render
    }
    /*
    // Object changed: new props, new parent, new index
    if (!isEqual(prevProps.self, nextProps.self)) {
        return false; // Return false to indicate props are not equal, causing a re-render
    }

    if (prevProps.environment?.editorProps?.selector?.hoveredObject?.id !== nextProps.environment?.editorProps?.selector?.hoveredObject?.id) {
        return false
    }

    if (prevProps.environment?.editorProps?.selector?.object?.id !== nextProps.environment?.editorProps?.selector?.object?.id) {
        return false
    }

    // Frame objects changed
    const insideBefore = getDescendants(prevProps.self.id, prevProps.environment?.frame?.objects)
    const insideAfter = getDescendants(nextProps.self.id, nextProps.environment?.frame?.objects)
    if (!isEqual(insideBefore, insideAfter)) {
        console.log('children changed');
        return false; // Return false because the objects have changed
    }

    // theme changes
    if (prevProps.environment?.editorProps?.currentThemeId !== nextProps.environment?.editorProps?.currentThemeId) {
        return false; // Return false because the objects have changed
    }
    
    if (nextProps.environment?.editorProps?.dndState?.isDragging) {
        return false
    }

    if (nextProps.environment?.location != 'canvas') {
        return false
    }
    if (prevProps.environment?.editorProps?.view?.mode !== nextProps.environment?.editorProps?.view?.mode) {
        return false
    }
    // children changed
    // const children = getChildren(self.id, frameObjects) */
    
    // If none of the above conditions are met, return true to prevent a re-render
    return true;
}

const Router = React.memo((props) => {
    
    const { self, environment } = props;
    const { frame, location, isMobile, editorProps={}, assets=[], defaultIconSet  } = environment
    const { 
        view, setView, streaming, hoveredObjId, 
        selector, setSelector, 
        handleAction, 
        handleMouseEnter, handleMouseLeave, 
        dndState, handleDragStart, handleDragOver, handleDragEnd, collectRefs } = editorProps

    const myRef = useRef(null);
    //console.log(hoveredObjId)
    const ComponentToRender = findComponentIgnoreCase(self.componentAPIName, UIKit) || null;
      
    const setRefs = useCallback((node) => {if (node !== null) {myRef.current = node}}, []);
    const renderCountRef = useRef(0); // Initialize a ref to store the render count
    renderCountRef.current++; 


    const frameObjects = frame?.objects || []
    const children = getChildren(self?.id, frameObjects) 
    
    useEffect(() => {
        if (dndState?.isDragging && collectRefs && location == 'canvas') {
            collectRefs({id: self.id, index: self.index, node: myRef?.current})
        }
    }, [dndState?.isDragging]);

    const isSelected = selector && selector.object && selector?.object.id === self.id && view?.mode != 'eye'
    const isHovered = hoveredObjId === self.id
    
    const hasSelectedChild = isSelected && children.some(child => child.id === selector?.object?.id)    
    const primitives = Object.values(UIKit).map(component => component.definitions);
    const sectionNames = primitives.filter(p => p?.type == 'section').map(p => p.apiName)
    const isSection = sectionNames.includes(self.componentAPIName)
    const isModule = self.componentAPIName === 'Module';
    const isAppShell = self.componentAPIName === 'AppShell';
    // isSelected && console.log('object rerender', self)
    // console.log('rendering', self)

    const outlineStyle = getOutlineStyle({
        self, view, dndState, selector, hasSelectedChild, streaming,
        isAppShell, isModule, isSelected, isHovered})

    function handleClick(e) {
        e.stopPropagation();

        setSelector({
            ...selector,
            object: self,
            section: isSection ? self : findSectionParent(self, frameObjects) || null, 
            objectRef: myRef?.current           
        });
    }  
    
    function handleDoubleClick(e) {
        e.stopPropagation();

        if (selector.object?.id === self.id) {
            setSelector({
                ...selector,
                object: self,
                section: isSection ? self : findSectionParent(self, frameObjects) || null, 
                objectRef: myRef?.current           
            });
        }
        if (view.rightSide !== 'editor') {setView({...view, rightSide: 'editor' })}
    }  



    const cantDrag = ['AppShell', 'Main', 'Email', 'Header', 'Footer', 'Sidebar', 'IconBar', 'FeaturePanel']
    const dialogs = ['Drawer', 'Popover', 'Modal', 'Toast', 'Banner']

    const dndAttributes = {draggable: !cantDrag.includes(self.componentAPIName) && !dialogs.includes(self.componentAPIName) ? true : false};

    const dndListeners = {
        onDragStart: (event) => {event.stopPropagation(); handleDragStart(event, self, myRef)},
        onDragOver: (event) => {event.stopPropagation(); handleDragOver(event, self, myRef)},
        onDragEnd: (event) => {event.stopPropagation(); handleDragEnd(event, self, myRef)},
    };
    

    // console.log('rerender')
    const onPropertyUpdate = (propertyName) => (e) => {
        let newObject = { ...self };
        newObject.object_props = { ...newObject.object_props, [propertyName]: e.target.innerText };
        
        handleAction({
            type: 'UPDATE_OBJECT',
            currentObject: self,
            newObject: newObject
        });
    };

    const showTag = isSelected && !dndState.isDragging && view.mode == 'editor';
    const preview = (view?.mode == 'eye' || location != 'canvas') && !location == 'thumbnail'
    const eventListeners = (location != 'canvas' || view?.mode == 'eye') ? {} : {
        onClick: e => handleClick(e),
        onDoubleClick: e => handleDoubleClick(e),
        onMouseOver: e => handleMouseEnter(props.self?.id, e),
        onMouseOut: e => handleMouseLeave(props.self?.id, e), 
    }
    const dndProps = (view?.disableDnD || view?.mode != 'editor' ) ? {} : {
        dndAttributes,
        dndListeners,
        dndState,    
    }

    const junoAttributes = {
        id: `canvas-${self.id}`,
        ...eventListeners, 
        ...dndAttributes,
        ... dndListeners, 
        ref: setRefs
    }
    const junoProps = { 
        setRefs, showTag, self, preview, 
        dndProps, 
        junoAttributes, 
        onPropertyUpdate, eventListeners, environment, outlineStyle, children, isSelected }
    
    const objectProps = {
        ...self.object_props,
        assets: assets,
        defaultIconSet,
        junoProps,
    };   
    
    if (!ComponentToRender) return (<UIKit.NotFound {...objectProps} />)
    
    else return (<>
    <ComponentToRender {...objectProps} >
        {children?.sort((a, b) => a.index - b.index).map(child => (
            <Router key={child.id} self={child} environment={environment} />
        ))}
    </ComponentToRender>
    </>)
}, compareState);

export default Router;


const findComponentIgnoreCase = (componentName, moduleObj) => {
    const normalizedComponentName = componentName.toLowerCase();
    const componentNameKey = Object.keys(moduleObj)
      .find(key => key.toLowerCase() === normalizedComponentName);
  
    return moduleObj[componentNameKey];
  };