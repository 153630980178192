import React, { useContext, useState } from 'react';
import { EditorContext } from '../../EditorContext';
import ThemePreview from './ThemePreview';
import { Flower } from 'iconoir-react';

export default function ThemesPicker() {
    
        const {ds, currentTheme, view, setView, defaultTheme, selectTheme} = useContext(EditorContext)
        
        const themes = ds.themes
        const appliedTheme = currentTheme || defaultTheme;
        
        const tileHeight = 56
        const [state, setState] = useState('list')
        
        return (
            <div className="w-full flex flex-col h-full overflow-y-scroll">    
                    <div className='flex flex-row justify-between items-end w-full gap-2 mb-3 pt-3 pb-2 px-2.5 
                    rounded-t-xl bg-gray-50 border-b border-gray-200
                    '>
                    <h1 className='text-md font-medium'>Themes</h1>
                    <span className="font-medium text-gray-500 text-xs transition-all duration-150 flex flex-row gap-2 items-center
                    cursor-pointer border border-base-200 hover:border-base-300 rounded-lg px-2 py-0.5"
                    onClick={()=> setView({...view, overview: true, inOverview: 'designSystem'})}
                    >
                    Manage
                    <Flower width={16} height={16} />
                    </span></div>
                    <div className="flex flex-col w-full gap-2 items-stretch py-1 px-2.5 ">
                    
                    {state == 'list' && themes
                    .sort((a, b) => a.display_name.localeCompare(b.display_name))
                    .sort((a, b) => b.is_default - a.is_default)
                    .map(theme=>
                    <ThemePreview
                        key={theme.id} 
                        theme={theme} 
                        isSelected={currentTheme?.id == theme.id}
                        onSelect={selectTheme}
                        tileHeight={tileHeight}
                    />)}
                    
                    
                    </div>
                    
                </div>
        )
    }