import PropTypes from 'prop-types'
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import React from 'react';

export default function RadioButton(props) {
    
    const { 
        state='default', 
        label='radio button', 
        isSelected=false, 
        style='standard',
        size='medium', 
        width='auto',
        onSelect,
        attributes, listeners } = props
    const { junoAttributes, showTag, outlineStyle, onPropertyUpdate=() => {}, preview } = props.junoProps || {} /* remove line */
    

    const sizeStyles = size == 'small' ? `gap-1.5 text-xs`: size == 'large' ? `gap-3 text-base` : `gap-2 text-sm`;
    const paddingStyles = size == 'small' ? `py-1 px-1.5` : size == 'large' ? `py-2 px-3` : `py-1.5 px-2`;
        
    const fillColorMap = {
        'default': `border border-primary`,
        'warning': `border border-warning-content`,
        'success': `border border-success-content`,
        'disabled': 'bg-base-200 border border-base-300'
    };
    
    const stateStyles = isSelected ? fillColorMap[state] : 'border border-base-300'
    const borderStyles = isSelected ? `border border-primary` : `border border-base-200`
    const bgSttyles = isSelected ? `bg-transparent` : `bg-transparent hover:bg-base-100 transition-all duration-75`
    
    const styleMap  = {
        standard: `flex items-start ${sizeStyles} cursor-default w-${width}`, 
        button: `flex flex-row ${paddingStyles} rounded items-center ${sizeStyles} cursor-pointer w-${width} ${borderStyles} ${bgSttyles}`
    }

    let wrapperClasses = styleMap[style] || styleMap['standard']
    wrapperClasses += ` ${outlineStyle}` /* remove line */

    const outerCircleDimensions = 
        style == 'standard' ? 
        size == 'small' ? 'w-4 h-4 mt-px' : size == 'large' ? 'w-6 h-6 ' : 'w-5 h-5' :
        size == 'small' ? 'w-3 h-3 mt-px' : size == 'large' ? 'w-5 h-5 ' : 'w-4 h-4'
    const outerCircleClasses = `${outerCircleDimensions} rounded-full flex items-center justify-center ${stateStyles} `

    const labelClasses = `whitespace-nowrap  ${state == 'disabled' ? 'opacity-60' : ''}`
    const checkColorMap = {
        'default': 'primary',
        'warning': 'warning-content',
        'success': 'success-content',
        'disabled': 'base-300'
    }
    const innerCircleSize = size == 'small' ? 8 : size == 'large' ? 12 : 10
    
    return (
        <div 
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
            className={wrapperClasses} 
            /* replace to 'onClick={onSelect}' */
        >
            {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Radio</div> /* remove line */}
            <div className="relative flex-shrink-0 inline-block">
                <span className={outerCircleClasses}>
                    {isSelected && <div
                    style={{width: innerCircleSize, height: innerCircleSize}}
                    className={`flex-shrink-0 rounded-full bg-${checkColorMap[state]}`}
                    />}
                </span>
            </div>
            {label && <span className={labelClasses} style={{textWrap: 'nowrap'}}>
            {!preview ? <EditableOnDoubleClick text={label} onBlur={onPropertyUpdate('label')} whiteSpace='nowrap'/> : label /* replace to '{label}' */}
            </span>}
        </div>
    );
}



RadioButton.propTypes = {
    width: PropTypes.oneOf(["auto", "1/2", "full"]),
    style: PropTypes.oneOf(['standard', 'button']),
    state: PropTypes.oneOf(['default', 'disabled', 'error', 'success']),
    label: PropTypes.string,
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
};  

/* ignore rest */

RadioButton.definitions = {
    apiName: 'RadioButton',
    displayName: 'Radio Button',
    description: 'A radio button component for selecting one option from a set, featuring customizable color options and an optional label. The component can be set to a default state of checked or unchecked.',
    ai_instructions: 'radio button with an optional label',
    type: 'input',
    acceptedChildren: 'none',
    relativeSize: 'small',
    propDefinitions: {
        label: {
            type: 'string',
            displayName: 'Label',
            default: 'radio button'
        },
        width: {
            type: "width",
            options: ["auto", "1/2", "full"],
            displayName: "Width",
            default: "auto", 
            defaultOnMobile: 'full', 
            tile: '1/2',
        },
        state: {
            type: 'oneOf', 
            options: ['default', 'disabled', 'error', 'success'], 
            default: 'default',
            tile: '1/2'
        },
        style: {
            type: 'oneOf',
            options: ['standard', 'button'],
            default: 'standard',
            tile: '1/2'
        },
        size: {
            type: 'size',
            options: ['small', 'medium', 'large'],
            default: 'medium', 
        }, 
        isSelected: { 
            type: 'bool', 
            displayName: 'Selected',
            default: false
        },
    }
};
