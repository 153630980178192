import React from 'react'
import PropTypes from 'prop-types'
import { spacingMap } from '../helpers'; /* replace to 'import { spacingMap } from './helpers.js';' */

export default function Drawer(props) {
    
    const {
        backdrop = 'none',
        position = 'right',
        
        paddingX = '16px',
        paddingY = '16px',
        gap = '12px',

        bgColor = 'base-0',
        
        alignItems = 'stretch',
        justifyContent = 'start',
        width = '360px',
        children,
        attributes,
        listeners
      } = props;
    const { junoAttributes, dndProps, showTag, setRefs, outlineStyle, eventListeners } = props.junoProps || {}

    
    const darkBackground = `color-mix(in srgb, var(--base-content) 24%, transparent)`
    const lightBackground = `color-mix(in srgb, var(--base-content) 12%, transparent)`
    const overlayClasses = `absolute top-0 flex flex-col w-full h-full`

    // STYLES
    const bgStyles = bgColor ? `bg-${bgColor}` : '';
    const borderStyles = position == 'left' ? 'border-l border-base-300' : 'border-r border-base-300';
    
    const paddingStyles = `${paddingX ? `px-${spacingMap[paddingX]}` : ''} ${paddingY ? `py-${spacingMap[paddingY]}` : ''}`;
    const gapStyles = gap ? `gap-${spacingMap[gap]}` : '';

    const alignItemsStyles = alignItems ? `items-${alignItems}` : '';
    const justifyContentStyles = justifyContent ? `justify-${justifyContent}` : '';

    let wrapperClasses = `flex flex-col relative h-full w-full flex-grow-0 flex-shrink-0 shadow-md ${paddingStyles} ${bgStyles} ${borderStyles}  ${gapStyles} ${alignItemsStyles} ${justifyContentStyles}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */
    
    return (
        /* Overlay */
        <div  className={overlayClasses} 
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
        style={{
            backgroundColor: backdrop == 'none' ? 'transparent' : backdrop == 'dark' ? darkBackground : lightBackground,
            zIndex: 50, 
            backdropFilter: backdrop == 'blurred' && 'blur(2px)',
            overflowX: 'auto',
            overflowY: 'hidden',
            alignItems: position == 'left' ? 'flex-start' : 'flex-end',
            WebkitBackdropFilter: backdrop == 'blurred' && 'blur(2px)', /* For Safari compatibility */
        }}>
            {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Overlay</div> /* remove line */}
            <div className={wrapperClasses}
            ref={setRefs} {...eventListeners} {...dndProps?.dndAttributes} {...dndProps?.dndListeners} /* remove line */
            style={{width: '100%', maxWidth: width, pointerEvents: 'all' }}>
            {showTag && <div className={`selected-element-above`} style={{zIndex: 2000}}>Drawer</div> /* remove line */}
            {children}
            </div>
        </div>
    );
}

Drawer.propTypes = {
    backdrop: PropTypes.oneOf(['dark', 'blurred', 'none']),
    position: PropTypes.oneOf(['right', 'left']),
    paddingX: PropTypes.oneOf(["0px", "8px", "12px", "16px", "24px", "32px", "48px"]),
    paddingY: PropTypes.oneOf(["0px", "8px", "12px", "16px", "24px", "32px", "48px"]),
    bgColor: PropTypes.oneOf(['base-0', 'base-50', 'base-100', 'base-200']),
    border: PropTypes.oneOf(['left', 'right', 'none']),
    gap: PropTypes.oneOf(["0px", "8px", "12px", "16px", "24px", "32px"]),
    alignItems: PropTypes.oneOf(['start', 'end', 'center', 'stretch']),
    justifyContent: PropTypes.oneOf(['start', 'end', 'center', 'between']),
    display: PropTypes.bool,
    width: PropTypes.oneOf(['360px', '480px', '640px', '780px', '50%', '33%', '25%']),
    children: PropTypes.node
};

/* ignore rest */

Drawer.stories = [
    {
        name: 'Default',
        wrapperClasses: 'flex flex-col w-full max-w-[640px] h-full max-h-[480px] bg-base-0',
        props: {
            width: '360px', 
            backdrop: 'dark', 
            bgColor: 'base-0',
        }
    }

]

Drawer.definitions = {
    apiName: 'Drawer',
    displayName: 'Drawer',
    description: `Drawer usually slides from either side of the screen. It is used to temporarily open some features that can be hidden when not needed`,
    ai_instructions: `slides in from the side of the screen. Limited to 1 per page. useful in displaying temporary info that can be hidden`,
    type: 'dialogs',
    acceptedChildren: 'everything except table components, layouts, sections, dialogs',
    relativeSize: 'large',
    propDefinitions: {
        width: {
            type: 'oneOf',
            options: ['360px', '480px', '640px', '780px', '50%', '33%', '25%'],
            displayName: 'Width',
            default: '360px', 
            tile: '1/2'
        },
        position: {
            type: 'oneOf',
            options: ['right', 'left'],
            displayName: 'Position',
            default: 'right', 
            tile: '1/2'
        },

        bgColor: {
            type: 'colors',
            options: ['base-0', 'base-50', 'base-100', 'base-200'],
            displayName: 'Background',
            default: 'base-0', 
            tile: '1/2'
        },
        backdrop: {
            type: 'oneOf',
            options: ['dark', 'blurred', 'none'],
            displayName: 'Backdrop',
            default: 'none', 
            tile: '1/2'
        },
        paddingX: {
            type: 'spacing',
            options: ['0px', '8px', '12px', '16px', '24px', '32px', '48px'],
            displayName: 'Padding X',
            default: '16px', 
            tile: '1/2'
        },
        paddingY: {
            type: 'spacing',
            options: ['0px', '8px', '12px', '16px', '24px', '32px', '48px'],
            displayName: 'Padding Y',
            default: '16px', 
            tile: '1/2'
        },
        gap: {
            type: 'spacing',
            options: ['0px', '8px', '12px', '16px', '24px', '32px'],
            displayName: 'Gap',
            default: '12px', 
        },
        alignItems: {
            type: 'alignItems',
            options: ['start', 'end', 'center', 'stretch'],
            displayName: 'Align Items',
            default: 'stretch', 
            tile: '1/2'
        },
        justifyContent: {
            type: 'justifyContent',
            options: ['start', 'end', 'center', 'between',],
            displayName: 'Justify Content',
            default: 'start',
            tile: '1/2'
        },
        display: {
            type: 'bool',
            default: true,
            displayName: 'Display'
        },
    }
};