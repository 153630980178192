export const spacingMap = {
    '0px': '0', 'none': '0',
    '2px': '0.5',
    '4px': '1',
    '6px': '1.5',
    '8px': '2',
    '10px': '2.5',
    '12px': '3',
    '16px': '4',
    '20px': '5',
    '24px': '6',
    '32px': '8',
    '40px': '10',
    '48px': '12',
}