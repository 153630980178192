import React from 'react';
import Icon from '../../../../../components/icons/Icon';

export default function SelectAlignItems({ propKey, options, currentOption, onSelect, currentDirection }) {
    const direction = currentDirection || 'flex-col'
    const handleSelect = (value) => {
      onSelect(propKey, value);
  };

  // console.log(currentDirection)
  const optionsIcons = {
    'start': direction === 'flex-row' ? 'align-top' : 'align-left',
    'center': direction === 'flex-row' ? 'align-horizontal-center' : 'align-vertical-center',
    'end': direction === 'flex-row' ? 'align-bottom' : 'align-right',
    'stretch' : direction === 'flex-row' ? 'align-stretch-vertically' : 'align-stretch-horizontally',
  };

  const iconAttributes = {
    width: 16, 
    height: 16, 
    fill: 'currentColor'
  }
  return (
    <div 
        className='items-center flex flex-row flex-grow w-full rounded-lg p-1 bg-base-100 hover:bg-base-200 text-sm transition-all duration-150'
        style={{userSelect: 'none'}}
      >
        {options.map((option) => (
          <button
            key={option}
            className={`w-full pointer text-sm flex items-center justify-center rounded-md py-1  transition-all duration-150 lowercase
            ${currentOption === option ? `bg-base-0 ring-1 ring-base-100 `
            : `opacity-80 hover:opacity-100`}`}
            style={{
              fontWeight: 500,
              flexGrow: 1, 
              zIndex: 2
            }}
            onClick={() => handleSelect(option)}
          >
            <Icon name={optionsIcons[option]} {...iconAttributes} />
          </button>
        ))}
      </div>
  );
};
