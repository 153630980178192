const allHeaderModules = [

    {
        apiName: 'module_header_logo_navlinks',
        displayName: 'Logo and Nav Links',
        allowedSections: ['Header'],
        ai_template: true,
        ai_instructions: 'contains logo and navigation links',
        width: 320,
        height: 48,
        tileWidth: '100%',
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714429995964.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=Eqe0FzKWPkDSAuUoIanBhUwb%2BNIiJe2K34PvxaCvNs3%2B8%2BqeCV1qiFR1lNOX8MiHPZhfkcHMe1nPOdoJKfdZYK6zsHJjzAS%2BxOSq5pEE%2BIXh0S%2FI7Wqil%2FRv0WwAXZFqQkVP6Cxa72RxdHOJZ7aliEPeGs5omw5EJRI5XpjqZVlvuw6fJZ%2BeUkOL3LizQTF64l7bBXrbfdTmljbFkt286S%2BNXa9xHjyB%2B9Qa7klIpRHsx%2B592tzU6JRecNrUFX4NWDc0Aq36%2FxAiFk3tNIQ3GYah3fqmxp1fz39SDg01Bafg5rM9H1Zrud%2FBCXXB6hbH1LZFUvzQucl21ZXkI56TNQ%3D%3D",
        jsx: `<Module flexDirection="flex-row" height="h-full" alignItems="center" gap="lg">
        <Logo size="24px" type="logo" />
        <FlexBox direction="flex-row" padding="none" gap="sm">
          <HeaderLink text="Home" rightIcon="NavArrowDown" leftIcon="none" />
          <HeaderLink text="Products" rightIcon="NavArrowDown" dropdownTranslate="toRight" />
          <HeaderLink text="Overview" />
        </FlexBox>
      </Module>
      `
    },
    {   
        apiName: 'module_header_search',
        displayName: 'Search',
        allowedSections: ['Header'],
        ai_template: true,
        ai_instructions: 'contains search bar',
        width: 200,
        height: 48,
        tileWidth: '100%',
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714427821343.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=KjLZoQfJbB4pUzYCm0s0iFCv9Xw6SQyCwC%2BrVZOJPFRT74l6bENCORmHIJWmvqbzIPLGoxuCWpMWVsBHMv57rkPi%2BDwGm3QbKdBYtvcDgvIxzHtjY3qXlNcL1%2FVW8%2BxUzpuAkHaOrfu%2BBjsawfPDaTQUMBnr%2Bi8tc7%2FUfZTAspCMU61jOFBBG5n3yFelEFJVkaIEhXbDf9O1CHJf5WiVrhy3gt%2F2suJwyHvBJF6sC2%2Fm3W1cNT2rQH%2BdEvue8WsMk8pLZFlVUPWA%2BEkdyc8O48qISznDdc9TC5x2NYbBYQQCgOG1YbJLbTP9PZ9pM7Pebo8mZX7cOfSHdIIKTG40zQ%3D%3D",
        jsx: `<Module height="h-full" justifyContent="center">
                <Search size="small" bgColor="base-0" hasOutline width="full" />
            </Module>`
    }, 
    {
        apiName: 'module_header_logo',
        displayName: 'Logo',
        allowedSections: ['Header'],
        ai_template: true,
        ai_instructions: 'only contains logo, typically used on the left ',
        width: 200,
        height: 48,
        tileWidth: '100%',
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714427765154.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=NwvCwmvGdEUNo2Sl6lEd0ezIAcGs1gbjKrd8ksL0tXNbBteUczCOGlecNDm5TJv6mP1braFgBV1x9Qj2uv0lhvFWbOLi0%2FlQXmwX1fsKOB0IkxUaWO2GECWhFidWVWgmlJKtdW6jLv2CLuYHSTXueuk8dscLg%2BDudvaNgT2jKIMbi1AvN9%2F9QEAOHHo9XXWo97j0Jq%2BPAcIRS1nGNhXNI6v2jtnSXly1AHnys2z4CFWknEsdCxPKxm9mPBbMWLmnCGU80tZzk5ZRIu293FKK7TydZM12RhZGOIHJIdBeXWRE8A6ul4KWGoZzVLqyrjrwIJwjIdRA865TpMjKuW0OYg%3D%3D",
        jsx: `<Module height="h-full" justifyContent="center">
                <Logo size="24px" type="logo" />
            </Module>`
    }, 
    {   
        apiName: 'module_header_usermenu_notifications',
        displayName: 'UserMenu and Notification',
        allowedSections: ['Header'],
        ai_template: true,
        ai_instructions: 'contains notification bell and user avatar',
        width: 200,
        height: 48,
        tileWidth: '100%',
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714427864675.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=lx7cijr0Ak33CV%2FneKlStBWrHjZmRK1I%2BMvkmtL7khTrT3eca5go9m463VQhvBxv6rk2cirrlUtf5dxKaKxDjLF4tVRr2V6d8vZm%2B3UMaUuMX60o3uu8e94RbyRd7c8X3eOyDwk1XkKdrp4BDreahEQMxUJp72itQjmaNevnmx4IZTnxp7PbqrObqBQCcXwKtQ4JSdPxvhaCkqaudjU%2FcZsiZmOvaqQYGsqSm0nwmiSOtWY53Y92feaAKT3lK%2BcXS2AQUCymXblq60eJkyc94qSDqtxA%2Br4Kb%2FPPRbQWK713O%2FQvTTLweOzfwuxVQ%2BzKGmNx1Y363kqxJo6AZxeggA%3D%3D",
        jsx: `<Module flexDirection="flex-row" justifyContent="end" height="h-full" alignItems="center">
        <ButtonIcon isPill size="xsmall" icon="Bell" type="link" />
        <Avatar size="small" type="icon" bgColor="accent" indicatorType="notification" indicatorColor="info-content" />
      </Module>`
    }, 
    
    {   
        apiName: 'module_header_buttons',
        displayName: 'Login and Signup Buttons',
        allowedSections: ['Header'],
        ai_template: true,
        ai_instructions: 'contains login and signup buttons',
        width: 240,
        height: 60,
        tileWidth: '100%',
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1714427941357.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=gCnGIiQjfZq3U9DdoOv%2BFzrkGFIqFawbU26jRL50BC%2FkV2FMRNAGlpqqqK%2Fqb0xbHjEttP555CruewNRcyxYgv3p5nkipUJ9A1VgN8bzY5ZgABumB25Ay2U0vTwgoYuYM591Qj69Wd5Sr8aD%2FgTC7x6JGXvvxfPa17TO9m2I5jyKwSnZ%2BhBXWPsyjdEdCQanZBfL%2F8IH1kIYpKzVx4xTPCoBUIt%2BoyOkiMeWGvVF6PSunZuiUnDHr%2BgNwuTx7E0RGnRS8SqEiKG3svx6%2BwwvJfznIjzsd0wt2rPchIS1N9O405GDm%2BT%2F%2BWkODYwYKG5H%2BTWG6ruPrhxYfHiHK7buzw%3D%3D",
        jsx: `<Module flexDirection="flex-row" height="h-full"  alignItems="center" gap="md" justifyContent="end">
                <Button type="secondary" text="Log In" size="small" />
                <Button type="primary" text="Sign Up" size="small" />
            </Module>`
    }, 
    {
        apiName: 'module_header_buttons_help_forum',
        displayName: 'Help and Forum Buttons',
        allowedSections: ['Header'],
        ai_template: true,
        ai_instructions: 'contains help and forum buttons',
        width: 280,
        height: 68,
        tileWidth: '100%',
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/screenshot-1715475129649.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=sh%2FWIdNWSheScoP%2BIiOS0PsPihlM%2FNe%2FsnIaUKdVpHddVqCVaJsP5J0ELAK%2B5v6RLPp%2BxkQ7GQzYUMaisAE2DHf1TUbypLGw8hkCeBTQMz1ybrfJNYckIr6wkDtv%2FR517LqTdduQ4Yk11Kj3JA8gPzVSOcgsQJNbthQYOfoKtjttqioA6eqPHR6jCRZgE29hf2mHrcg7444W%2FwG9YeABMFP7dRJlSMZAkW88JWu%2BoEtOfK6C7YMr5al8K%2FQmLEU4AJCtoFVDnNZYZuD4Xot5UwjlqTYroZ9LPPp0fHo0m3vg2hv7RYQa0hUmfiigKB06pMX5tZiTRwYspqMc3qfRWA%3D%3D",
        jsx: `<Module flexDirection="flex-row" alignItems="center" gap="md" justifyContent="end">
                <Button type="secondary" text="Forum" size="small" leftIcon="MultiBubble" />
                <Button type="secondary" text="Help" size="small" leftIcon="Lifebelt" />
            </Module>`
    }

]
export const headerModules = [
    ...allHeaderModules.map(m => ({ ...m, category: 'misc', displayName: 'All' })),
]

