import { useContext, useEffect, useState } from "react";
import { EditorContext } from "../../EditorContext";
import UserContext from "../../../../UserContext";
import sample_symbol from '../../../../ui-kit/local/assets/brand/symbol.svg';
import * as projectsDB from '../../../../api/projectsAPI'

export default function ProjectMenu({sidebarOpen, tooltipStyle}) {
    const {user} = useContext(UserContext);
    const { view, project, setSelector, selector, setView } = useContext(EditorContext);
    
    const assets = project?.assets
    const symbolAsset = assets?.find(asset => asset.api_name == 'symbol')?.file_url || sample_symbol
    
    function handleClick() {
      setSelector({ ...selector, folder: null, page: null, frame: null, object: null });
      setView({...view, inOverview: 'project',});
    }

    const isSelected = !selector.folder && view.inOverview == 'project';
    return (
        <div className={`flex flex-row gap-2 items-center justify-between text-xs px-1.5 py-1 rounded-lg transition-all group
        self-start duration-75 w-auto w-full
        ${isSelected ? 'bg-slate-100' : 'bg-transparent hover:bg-slate-100'}
        `}
        onClick={handleClick}
        
        >
            {
            <div className={`w-4 h-4 rounded flex-shrink-0 flex text-xs items-center justify-center font-semibold text-primary
            
            ${!symbolAsset && 'bg-accent'}
            `}
            style={{
                backgroundImage: `url(${symbolAsset})`,
                backgroundSize: 'cover',
            }}
            >
            

            </div>}
            <div className={`${sidebarOpen ? 'hidden md:flex' : 'hidden'} flex-grow font-semibold text-gray-800 cursor-default whitespace-nowrap w-[120px] max-w-full truncate text-ellipsis transition-all`}
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {project?.project?.name}
                
            </div>
            
            
        </div>
    );
}

//<ProjectName project={project} setProject={setProject} />
function ProjectName({project, setProject}) {
    

    const [editable, setEditable] = useState(false);
    const [name, setName] = useState(project?.project?.name);
  
    
    useEffect(() => {
      // Update the name state when the frame prop changes
      setName(project?.project?.name);
    }, [project?.project?.name]);
  
    const handleBlur = () => {
      setEditable(false);
      const updatedProject = { ...project, project: { ...project.project, name } };
      setProject(updatedProject);
      projectsDB.update(updatedProject.project);
    };
  
    
    return (
        <input 
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={(e) => editable && handleBlur(e)}
          onClick={(e) => {e.stopPropagation(); setEditable(true)}}
          onKeyDown={(e) => e.key === 'Enter' && e.target.blur()}
          readOnly={!editable}
          className={`bg-transparent text-xs focus:bg-white placeholder-gray-700 text-gray-800 focus:text-gray-900
          select-none cursor-default
          py-1 overflow-hidden whitespace-nowrap ellipsis truncate transition-all`}
          style={{
            userSelect: !editable ? 'none' : '',
            minWidth: 200,
            textAlign: 'left'
          }}
        />
    
      )
    }
    
    