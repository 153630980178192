import {  useState } from "react";
import TypicalLayouts from "./layouts/TypicalLayouts";
import Pages from "./templates/Pages";
import Layouts from "./templates/Layouts";


export default function Library({onSelect, set, draggable=false}) {
    
    const tabs = 
    [{ label: 'Templates', value: 'templates' },
      { label: 'Layouts', value: 'layouts' },] 
    const [tab, setTab] = useState(tabs[0]);

    const [showSearch, setShowSearch] = useState(false)
    const [searchResults, setSearchResults] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    
    
    return (
      <>
          <TabGroup tabs={tabs} selectedTab={tab.value}  onSelect={setTab}/>     
          
            <div className="w-full flex flex-col pt-3 gap-2 px-2.5 overflow-scroll scrollbar-thin bg-white h-full" >
              {/*<Search
                searchResults={searchResults} 
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                setSearchResults={setSearchResults} 
                showSearch={showSearch} 
                setShowSearch={setShowSearch} 
                items={searchItems}
              />*/}
              
              {/*!showSearch && tab?.value == 'layouts' && 
              <TypicalLayouts 
                insertNewFrame={onSelect}
                draggable={draggable}
              
            />*/}
              {!showSearch && tab?.value == 'templates' && set && <Pages 
                insertNewFrame={onSelect}
                draggable={draggable}
                />}
                {!showSearch && tab?.value == 'layouts' && set && <Layouts 
                insertNewFrame={onSelect}
                draggable={draggable}
                />}
              {showSearch &&  
              <div> requires update after refactoring </div>
                /*<SearchResults  
                  searchQuery={searchQuery} 
                  assets={assets} 
                  handleDragStart={handleDragStart} handleDragEnd={handleDragEnd} 
                  effectiveVariables={effectiveVariables} 
                  searchResults={searchResults} 
              />*/}
            </div>
      </>)

}


function TabGroup({tabs, selectedTab, onSelect}) {
    
    
  return (
      <div className="w-full flex flex-row justify-start gap-2 items-center border-b border-slate-300 px-2.5">
          {tabs.map(tab => (
              <div 
              key={tab.value}
              className={`cursor-pointer -mb-px px-2 relative group pt-1 pb-2 text-xs font-medium flex flex-row gap-2 transition-all duration-75  whitespace-nowrap`}
              onClick={() => onSelect(tab)}
              >
                  {tab.label}
                  <div 
                  className={`absolute bottom-0 left-0 w-full h-[3px] rounded-t-xs transition-all duration-75
                  ${selectedTab === tab.value ? `bg-primary` : 'bg-slate-300 opacity-0 group-hover:opacity-100'}
                  `}
                  />
              </div>
          ))}
      </div>
  )
}