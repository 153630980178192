export default function cleanUpObjectProps(currentObject, updatedObject, propDefinitions) {
    // Ensure only keys in propDefinitions are retained in object_props
    const cleanedProps = Object.keys(updatedObject.object_props)
        .filter(key => propDefinitions.hasOwnProperty(key))
        .reduce((obj, key) => {
            obj[key] = updatedObject.object_props[key];
            return obj;
        }, {});

    // Ensure values for 'oneOf' types are valid or use value from currentObject if available
    for (const key in cleanedProps) {
        // Skip processing for 'width' and 'height' properties
        if (key === 'width' || key === 'height') {
            continue;
        }
    
        if (propDefinitions[key].type === 'oneOf') {
            const isValidOption = propDefinitions[key].options.includes(cleanedProps[key]);
            if (!isValidOption) {
                // If the value is not valid, use the value from currentObject if it exists, else use default
                cleanedProps[key] = (currentObject && currentObject.object_props[key]) ?? propDefinitions[key].default;
            }
        }
    }
    

    // Update the object_props of the updatedObject
    updatedObject.object_props = cleanedProps;
}
