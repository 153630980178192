import React from 'react';
import PropTypes from 'prop-types'
import { spacingMap } from '../helpers'; /* replace to 'import { spacingMap } from './helpers.js';' */

export default function Dot(props) {
    
    const {
        size = '8px',
        color = 'base-300',
        marginX = null,
        marginY = null, 
        attributes,
        listeners
      } = props;
    
      const { junoAttributes, outlineStyle} = props.junoProps || {}
    
    let classes = `py-${spacingMap[marginY]} px-${spacingMap[marginX]}`
    classes += ` ${outlineStyle}` /* remove line */
    
    const inlineStyles = {
        width: size, 
        height: size,
    }

    return (
        <div 
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
            className={classes}
        >
            <div className={`bg-slate-300 !bg-${color} rounded-full`} style={inlineStyles} />
        </div>)
}

Dot.propTypes = {
    color: PropTypes.oneOfType([
        PropTypes.oneOf(['base-0', 'base-100', 'base-200', 'base-300', 'base-content', 'primary', 'accent', 'success', 'warning', 'error', 'info', 'success-content', 'warning-content', 'error-content', 'info-content']),
        PropTypes.string]),
    marginX: PropTypes.oneOf(['6px', '8px', '12px', '16px', '24px']),
    marginY: PropTypes.oneOf(['6px', '8px', '12px', '16px', '24px']),
    size: PropTypes.oneOf(['4px', '6px', '8px', '12px', '16px']),
};

/* ignore rest */  

Dot.definitions = {
    apiName: 'Dot',
    displayName: 'Dot',
    description: 'A simple dot component used for indicators or decorations, with customizable size, color, and margins. Adapts to horizontal or vertical orientations based on the parent container’s direction.',
    ai_instructions: 'dot is sed to separate small pieces of content like text',
    type: 'separator',
    relativeSize: 'tiny',
    acceptedChildren: 'none',
    propDefinitions: {
        size: {
            type: 'oneOf',
            options: ['4px', '6px', '8px', '12px', '16px'],
            displayName: 'Size',
            default: '8px', 
        },
        color: {
            type: 'colors',
            options: ['base-0', 'base-100', 'base-200', 'base-300', 'base-content', 'primary', 'accent', 'success', 'warning', 'error', 'info', 'success-content', 'warning-content', 'error-content', 'info-content'],
            displayName: 'Color',
            default: 'base-300', 
        },
        marginX: {
            type: 'spacing',
            options: ['6px', '8px', '12px', '16px', '24px'],
            displayName: 'Margins',
            default: null,
            tile: '1/2'
        },
        marginY: {
            type: 'spacing',
            options: ['6px', '8px', '12px', '16px', '24px'],
            displayName: 'Margins',
            default: null,
            tile: '1/2'
        },
        
    }
};
