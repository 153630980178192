import React, { useState, useRef, useContext, useEffect } from 'react';
import * as IconoirIcons from 'iconoir-react';
import { EditorContext } from "../../EditorContext";

export default function ModeSwitch() {
    const {view, setView, selector, setSelector, streaming, activeSticky} = useContext(EditorContext);
    

    function handleSelectPanView() {
      let rightSide = view.rightSide
      if (rightSide == 'editor' || rightSide == 'assets' || rightSide == 'elements') {
        rightSide = null
      }
      setView({...view, mode:'eye', rightSide})
      setSelector({...selector, object: null, objectRef: null}) // clear selector
    }
    const currentValue = view.mode
    const options = [
      {value: 'editor', 
      icon: <IconoirIcons.EditPencil width={16} height={16} className='flex-shrink-0' />, 
      label: 'Edit', 
      note: 'Drag, style, and edit elements in the canvas.',
      onClick: ()=>setView({...view, mode:'editor'})},
      {value: 'eye', 
      icon: <IconoirIcons.PlaySolid width={16} height={16} className='flex-shrink-0' />, 
      note: 'Preview the design as it will appear to users.',
      label: 'Preview', onClick: handleSelectPanView},
    ]    
    
    const tooltipStyle = `absolute opacity-0 group-hover:opacity-100 invisible group-hover:visible whitespace-normal
    w-full bg-gray-100 text-gray-800 ring-1 ring-gray-300 font-medium rounded-md px-2 py-1 text-xs z-50
    -bottom-3 transform translate-y-full transition-all left-1/2 -translate-x-1/2 duration-300`

    return (
      <div 
        className={`relative items-center flex flex-row rounded-lg bg-slate-200 p-0.5 h-7 gap-1 text-xs w-40
        ring-1 ring-slate-300`}
        style={{userSelect: 'none'}}
      >
        {/* Sliding background */}
        {options.map((option, index) => (
          
          <button
            key={option.value}
            className={`w-1/2 flex items-center justify-center h-full px-1.5 font-medium rounded-md flex-grow gap-1 group
            transition-all duration-150 ${currentValue === option.value ? 'ring-1 ring-slate-300 bg-base-0' : ''}  `}
            style={{flexGrow: 1, zIndex: 2}}
            onClick={option.onClick}
          >
            {option.icon}
            {option.label}
            {/*
            <span className={tooltipStyle}
            >
                {option.note}
              </span>
            */}
          </button>
        ))}
      </div>
    );
  }
